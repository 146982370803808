import { HTTPClient } from '../network/fetch';

const client = new HTTPClient({ prefix: 'notifications' });

export function listUnseenNotifications() {
    return client.getResource('?seen=false')
        .then(notifications => {
            const sixMonthsAgo = new Date();
            sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

            return notifications
                .filter(notification => new Date(notification.timestamp) >= sixMonthsAgo)
                .slice(0, 5);
        });
}


export function listSeenNotifications() {
    return client.getResource('?seen=true')
        .then(notifications => {
            const sixMonthsAgo = new Date();
            sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

            return notifications
                .filter(notification => new Date(notification.timestamp) >= sixMonthsAgo)
                .slice(0, 5);
        });
}

export function GetlistUnseenNotifications() {
    return client.getResource('/seen');
}

export function DeleteNotification(notificationId) {
    return client.deleteResource(`/${notificationId}`);
}   

export function flagNotificationsAsSeen(notificationIds = []) {
    return client.putResource('/seen', notificationIds);
}