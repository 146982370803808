import React, { useState } from 'react';
import { getCurrentLogin, patchCurrentLogin, resendActivationEmail } from 'http/accounts';
import { useRootUser } from 'util/useUser';
import i18n from 'i18n/pages/Profile/WantsNews';
import ColoredText from 'design/atoms/ColoredText';
import ProfileForm from './ProfileForm';
import IconHeader from './IconHeader';
import { toast } from 'react-toastify';

const LoginInformation = () => {
    const [resendHappened, setResendHappened] = useState(false);
    const user = useRootUser();

    const renderEmailVerification = () => {
        if (user.login.isVerified) {
            return null;
        }

        return (
            <>
                <IconHeader content='Manglende emailverificering' icon='info circle' />
                <p>
                    {`Vi har sendt dig en e-mail, hvor vi beder dig bekræfte din emailadresse. Hvis du ikke har modtaget denne mail, kan du modtage en ny bekræftelse ved at `}
                    <ColoredText
                        link
                        color='green'
                        icon={resendHappened ? 'check' : 'send'}
                        content='klikke her'
                        disabled={resendHappened}
                        onClick={() => resendActivationEmail().then(() => {
                            setResendHappened(true);
                            toast.success('Ny verificeringsmail sendt');
                        })}
                    />
                </p>
            </>
        );
    };

    return (
        <>
            <ProfileForm
                getData={getCurrentLogin}
                patchData={patchCurrentLogin}
                form={[
                    {
                        path: 'loginEmail',
                        label: 'E-mail',
                    },
                    {
                        path: 'phone',
                        label: 'Telefon',
                    },
                    {
                        path: 'createdAt',
                        label: 'Oprettelsesdato',
                        type: 'date',
                        disabled: true,
                    },
                    [
                        {
                            path: 'wantsNews',
                            label: 'Tilmeldt nyhedsbrev',
                            type: 'boolean',
                            tooltip: i18n.wantsNews,
                        },
                        {
                            path: 'wantsStatusMails',
                            label: 'Tilmeldt driftsmails',
                            type: 'boolean',
                            tooltip: i18n.wantsStatusMails,
                        },
                    ],
                ]}
            />
            {renderEmailVerification()}
        </>
    );
};

export default LoginInformation;