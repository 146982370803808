import { HTTPClient } from "../network/fetch";

const client = new HTTPClient({ prefix: 'ofinda/api' });

export function registerCredentials(companyNo, email, password) {
    return client.postResource('register', {
        companyNo,
        email,
        password,
    });
}

export function extractPrefillValues (token) {
    return client.getResource(`credentials/${token}/userdata`);
}