import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    freebieSectionTooltip1: i18n({
        [daDK]: 'Dine felter er slørede, fordi du prøver produktet i en demoversion.',
        [enUS]: 'Your fields are blurred because you are trying out the product in demo version.',
    }),
    freebieSectionTooltip2: i18n({
        [daDK]: 'Klik <link_container> for at købe adgang. Du er også velkommen til at klikke dig videre i produktet for at se, hvad der ellers gemmer sig.',
        [enUS]: 'Click <link_container> to purchase access. You are also welcome to click further down the product to see what else is hidden.',
    }),
    freebieSectionTooltipHere: i18n({
        [daDK]: 'her',
        [enUS]: 'here',
    }),
    freebieContinueDemo: i18n({
        [daDK]: 'Fortsæt demo',
        [enUS]: 'Continue demo',
    }),
};