const QueryParams = (search = window.location.search) => {
    if (!search) {
        return {};
    }

    const splitted = search.substring(1, search.length).split('&');
    const params = {};
    splitted.forEach((str) => {
        const arr = str.split('=');
        params[decodeURIComponent(arr[0])] = decodeURIComponent(arr[1]);
    });
    return params;
};

export function getParam(key, defaultValue) {
    const params = QueryParams();
    return params[key] || defaultValue;
}

export function hasQueryParam(key) {
    return !!getParam(key);
}

export function makeQueryParams (params = {}) {
    let pairs = [];
    for (let [key, value] of Object.entries(params)) {
        if (value === undefined) {
            continue;
        }
        pairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
    return '?' + pairs.join('&');
}

/**
 * Sets the query params of the browser without causing a reload
 * @param {*} params 
 */
export function setQueryParams (params = {}) {
    if (!window.history) {
        return;
    }
    const { protocol, host, pathname } = window.location;
    const base = `${protocol}//${host}${pathname}`;
    const newFullURL = base + makeQueryParams(params);
    window.history.pushState({ path: newFullURL }, '', newFullURL);
}

export function setQueryParam(key, value) {
    return setQueryParams({
        ...QueryParams(),
        [key]: value,
    });
}

export function unsetParam(key) {
    const params = QueryParams();
    delete params[key];
    setQueryParams(params);
}

export function resetQueryParams() {
    return setQueryParams({});
}

export function setQueryParamJSON(key, toEncode) {
    const asJSON = JSON.stringify(toEncode);
    setQueryParam(key, asJSON);
}

export function getQueryParamJSON(key, fallback) {
    try {
        return JSON.parse(getParam(key));
    } catch {
        return fallback;
    }
}

export default QueryParams;