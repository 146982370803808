import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Header, Icon, Button } from 'semantic-ui-react';

import UploadArea from './UploadArea';
import FileList from './FileList';

class Dialog extends React.Component {
	static propTypes = {
		dialogIsActive: PropTypes.bool.isRequired,
        toggleDialog: PropTypes.func.isRequired,
        files: PropTypes.array.isRequired,
        uploadFile: PropTypes.func.isRequired,
        saving: PropTypes.bool,
	};

	static defaultProps = {
		dialogIsActive: false,
    	saving: false,
	};

    render () {
        const  { dialogIsActive, toggleDialog, files, uploadFile, updateFileList, saving, deleteFile } = this.props;
        return (
            <Modal
                open={dialogIsActive}
                onClose={() => toggleDialog(false)}
            >
                <Modal.Header>
                    <Header sub>
                        <Icon name='upload' />
                        <Header.Content>Upload fil</Header.Content>
                    </Header>
                </Modal.Header>

                <Modal.Content>
                    <UploadArea
                        uploadFile={uploadFile}
                        saving={saving}
                    />
                    <FileList
                        files={files}
                        updateFileList={updateFileList}
                        deleteFile={deleteFile}
                    />
                </Modal.Content>

                <Modal.Actions>
                    <Button color='black' onClick={() => toggleDialog(false)}>
                        Luk
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}
export default Dialog;
