import React, { Component } from 'react';
import { withSubscription } from './pubsub';
import TokenUser            from './TokenUser';
import {
    getRelevantToken,
    ACCESS_TOKEN_CHANGED,
    IMPERSONATION_TOKEN_CHANGED,
    getAccessToken,
} from './token';

const createUserDataHOC = (tokenGetter, ...relevantTopics) => {
    return WrappedComponent => {
        const UserDataHOC = class extends Component {    
            render () {
                const token = tokenGetter();
                if (!token) {
                    return null;
                }
                return <WrappedComponent userData={new TokenUser(token)} {...this.props}/>;
            }
        };
        return withSubscription(
            UserDataHOC,
            ...relevantTopics,
        );
    };
};

const withUserData = createUserDataHOC(
    getRelevantToken,
    ACCESS_TOKEN_CHANGED,
    IMPERSONATION_TOKEN_CHANGED,
);

export const withRootUserData = createUserDataHOC(
    getAccessToken,
    ACCESS_TOKEN_CHANGED,
);

export default withUserData;