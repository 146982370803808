import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    emailLabel: i18n({
        [daDK]: 'Email:',
        [enUS]: 'Email:',
    }),
    emailPlaceholder: i18n({
        [daDK]: 'Email',
        [enUS]: 'Email',
    }),
    passwordLabel: i18n({
        [daDK]: 'Kodeord: ',
        [enUS]: 'Password: ',
    }),
    passwordPlaceholder: i18n({
        [daDK]: 'Indtast kodeord',
        [enUS]: 'Input password',
    }),
    loginButton: i18n({
        [daDK]: 'Log ind',
        [enUS]: 'Log in',
    }),
    linkToRegister: i18n({
        [daDK]: 'Opret bruger',
        [enUS]: 'Create account',
    }),
    forgotten: i18n({
        [daDK]: 'Glemt kodeord?',
        [enUS]: 'Forgot password?',
    }),
    errorHeader: i18n({
        [daDK]: 'Fejl',
        [enUS]: 'Error',
    }),
};