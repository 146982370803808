import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    uploadTooltip: i18n({
        [daDK]: 'Vedhæft fil',
        [enUS]: 'Upload file',
    }),
    file: i18n({
        [daDK]: 'fil',
        [enUS]: 'file',
    }),
    files: i18n({
        [daDK]: 'filer',
        [enUS]: 'files',
    }),
    loading: i18n({
        [daDK]: 'Henter data',
        [enUS]: 'Loading',
    }),
};