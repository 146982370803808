import React, { Component } from 'react';
import { Loader, Table } from 'semantic-ui-react';
import { getCreditsBox } from 'http/credits';
import { formatNumber } from 'util/format/Number';
import { moms } from 'util/moms';
import { CreditsContext } from 'design/molecules/CreditsProvider';
import CreditsIcon from 'design/atoms/CreditsIcon';

class InvoiceOverview extends Component {
    state = {
        creditsBox: null,
        loading: true,
    };

    componentDidMount = async () => {
        const creditsBox = await getCreditsBox();
        this.setState({
            loading: false,
            creditsBox,
        });
    };

    renderOverdrawOverview = () => {
        const balance = Math.min(this.context.credits, 0);

        const { overdrawPricePerCredit } = this.state.creditsBox;
        const toPay = -(this.state.creditsBox.overdrawPricePerCredit * balance);
        const toPayWithVat = moms(toPay);
        const vatAmount = toPayWithVat - toPay;

        const borderBottomStyle = {
            style: { borderBottom: '1px solid gray' },
        };

        return (
            <Table>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell><b>Antal credits i overtræk</b></Table.Cell>
                        <Table.Cell textAlign='right'>
                            {-balance} × <CreditsIcon />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><b>At betale pr. credit</b></Table.Cell>
                        <Table.Cell textAlign='right'>
                            {formatNumber(overdrawPricePerCredit)} kr.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><b>Subtotal</b></Table.Cell>
                        <Table.Cell textAlign='right'>
                            {formatNumber(toPay)} kr.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell {...borderBottomStyle}><b>Moms</b></Table.Cell>
                        <Table.Cell {...borderBottomStyle} textAlign='right'>
                            {formatNumber(vatAmount)} kr.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><b>At betale i alt</b></Table.Cell>
                        <Table.Cell textAlign='right'>
                            <b>{formatNumber(toPayWithVat)} kr.</b>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        );
    };

    render = () => {
        if (this.state.loading) {
            return <Loader
                inline='centered'
                active
            />;
        }

        return this.renderOverdrawOverview();
    };
}

InvoiceOverview.contextType = CreditsContext;

export default InvoiceOverview;