import Hint from "../Hint";
import HintContainerBasic from "./basic/HintContainerBasic";

import { HintTypes } from "../HintTypes";

// ==================================================

const getHintContainer = (hint, customHintProps) => {
    let hintContainer = null;
    switch(hint.type) {
        case HintTypes.BASIC: {
            hintContainer = HintContainerBasic;
            break;
        }
        case HintTypes.VIDEO: {
            hintContainer = HintContainerBasic;
            break;
        }
        case HintTypes.VIDEOS_ERP: {
            const { selectedErp } = customHintProps;

            const videos = hint.data.videos;
            const videoToShow = videos.find(video => video.id === selectedErp && Boolean(video.ytVideoID));

            if(selectedErp && videoToShow) {
                hintContainer = HintContainerBasic;
            }
            break;
        }
        case HintTypes.NODE_DATA: {
            break;
        }
    }
    return hintContainer;
};

// ==================================================

const HintContainer = props => {
    const {
        hint,
        hasPaid,
        factsBeingUpdated,
        productData,
        productMetadata,
        sectionFieldWrappers,
        customHintProps
    } = props;
    
    const renderHintContainer = () => {
        const hintRender = (
            <Hint
                hint={hint}
                hasPaid={hasPaid}
                factsBeingUpdated={factsBeingUpdated}
                productMetadata={productMetadata}
                productData={productData}
                sectionFieldWrappers={sectionFieldWrappers}
                customHintProps={customHintProps}
            />
        );

        let result = null;
        const HintContainer = getHintContainer(hint, customHintProps);
        if(HintContainer) {
            result = <HintContainer hint={hint} hintRender={hintRender} />;
        } else {
            result = hintRender;
        }

        return result;
    };

    return renderHintContainer();
};

export default HintContainer;
