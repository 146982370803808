import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getReportingEntities } from 'http/accounts';
import { refreshTokens } from 'network/fetch/refreshTokens';
import { formatDateTime } from 'util/format/DateTime';
import { useRootUser } from 'util/useUser';
import { setChosenUser } from 'util/token';

import ProfileTable from './ProfileTable';
import RoleLabel from './RoleLabel';

const LoginEntities = () => {
    const [reportingEntities, setReportingEntities] = useState([]);
    const [loading, setLoading] = useState(true);
    const user = useRootUser();
    const history = useHistory();

    useEffect(() => {
        const doFetchEntities = async () => {
            const reportingEntities = await getReportingEntities();
            setReportingEntities(reportingEntities);
            setLoading(false);
        };
        doFetchEntities();
    }, [user.id]);

    const newEntitySelected = async entityIdx => {
        const entity = reportingEntities[entityIdx];
        setChosenUser(entity.uid);
        await refreshTokens();
        history.push('/');
    };

    return (
        <ProfileTable
            loading={loading}
            header={['Navn', 'Sidst tilgået', 'Din tilknytning']}
            body={reportingEntities.map(({ uid, displayName, lastAccessed, role }) => {
                return {
                    key: uid,
                    cells: [
                        displayName,
                        formatDateTime(lastAccessed),
                        <RoleLabel {...role} />,
                    ],
                };
            })}
            actions={[{
                label: 'Gå til virksomhed',
                icon: 'exchange',
                onClick: newEntitySelected,
                hidden: idx => {
                    const entity = reportingEntities[idx];
                    return entity.uid === user.id;
                },
            }]}
        />
    );
};

export default LoginEntities;