import { $ } from '../../printRenderEngine';
import { styles } from './styles';
import DRLogo from '../../../../images/digital-revisor/digital-revisor-big-logo.png';

function falsyEmptyString (val) {
    return val ? val : '';
}

function renderStep (step, hasPaid) {
    const { title, fields, requiresPayment } = step;
    if (requiresPayment && !hasPaid) {
        return $('div', [
            $('h4', title),
            $('p', 'Betal for at se denne sektion'),
        ]);
    }
    return $('div', [
        $('h4', title),
        ...fields.map(renderField),
    ]);
}

function renderField ({ label, value, suffix, isDivider }) {
    if (isDivider) {
        return $('hr');
    }
    label  = falsyEmptyString(label);
    value  = falsyEmptyString(value);
    suffix = falsyEmptyString(suffix);
    if (suffix) {
        value += ` ${suffix}`;
    }
    return $('div', { style: styles.valueRow }, [
        $('span', { style: styles.left }, label),
        $('span', { style: styles.right }, value),
        $('br'),
    ]);
}

export default data => {
    const { mainHeader, subHeader, year, steps, hasPaid } = data;
    let finalMainHeader = mainHeader;
    if (year) {
        finalMainHeader += ` ${year}`;
    }
    return (
        $('div', { style: styles.mainContainer }, [
            $('img', { src: DRLogo, style: styles.logo }, []),
            $('h2', { style: styles.mainHeader }, finalMainHeader),
            $('h3', { style: styles.subHeader }, subHeader),
            ...steps.map(s => renderStep(s, hasPaid)),
        ])
    );
};