import { useState, useRef, useLayoutEffect } from 'react';

const useMeasure = () => {
    const ref = useRef();
    const [bounds, setBounds] = useState({ height: 0, ready: false });

    useLayoutEffect(() => {
        if (ref.current) {
            const resizeObserver = new ResizeObserver(([entry]) => {
                setBounds({
                    height: entry.contentRect.height,
                    ready: true,
                });
            });
            resizeObserver.observe(ref.current);

            return () => resizeObserver.disconnect();
        }
    }, []);

    return [{ ref }, bounds];
};

export default useMeasure;