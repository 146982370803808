import React, { Component } from 'react';
import { Label, Button, Icon, Popup } from 'semantic-ui-react';
import FileModal from '../../molecules/FileAttachmentWidget/Dialog';
import i18n from '../../../i18n/pages/UploadFileModal';
import styles from './FileUploadModal.module.scss';

class FileUploadModal extends Component {
    state = {
        open: false,
        loading: true,
        working: false,
        error: false,
        files: [],
    };

    toggleModal = open => this.setState({ open });

    async componentDidMount () {
        try {
            await this.listFiles();
        } catch {
            this.setState({ error: true });
        } finally {
            this.setState({ loading: false });
        }
    }

    performWork = async workFn => {
        this.setState({ working: true });
        try {
            await workFn();
        } catch {
            this.setState({ error: true });
        }
        this.setState({ working: false });
    };

    listFiles = async () => {
        await this.performWork(async () => {
            const { listFiles } = this.props;
            const files = await listFiles();
            this.setState({ files });
        });
    };

    uploadFile = async file => {
        await this.performWork(async () => {
            const { uploadFile } = this.props;
            await uploadFile(file);
            await this.listFiles();
        });
    };

    deleteFile = async (fileID, fileIndex) => {
        await this.performWork(async () => {
            const { deleteFile } = this.props;
            await deleteFile(fileID, fileIndex);
            await this.listFiles();
        });
    };

    renderModal = () => {
        const { loading, working, files, open } = this.state;
        if (!open || loading) {
            return null;
        }
        return (
            <FileModal
                dialogIsActive
                files={files}
                saving={working}
                uploadFile={this.uploadFile}
                deleteFile={this.deleteFile}
                toggleDialog={this.toggleModal}
            />
        );
    };

    renderDefaultTrigger = () => {
        const { loading, working, files } = this.state;
        const lorw = loading || working;
        let icon;
        let tooltip;
        let upload = false;
        
        if (lorw) {
            icon = 'spinner';
            tooltip = i18n.loading;
            upload = false;
        } else if (files.length === 1) {
            icon = 'file';
            tooltip = `1 ${i18n.file}`;
            upload = true;
        } else if (files.length > 1) {
            icon = 'copy';
            tooltip = `${files.length} ${i18n.files}`;
            upload = true;
        } else {
            icon = 'attach';
            tooltip = i18n.uploadTooltip;
            upload = false;
        }

        const tooltipTrigger = (
            <Button as={Label}
                disabled={lorw}
                className={!upload ? styles.fileUploadBtn : styles.fileUploadBtn2}
                onClick={this.toggleModal}
                icon={<Icon name={icon} loading={lorw} />}  
            />
        );
        return (
            <Popup
                trigger={tooltipTrigger}
                content={tooltip}
                position='left center'
            />
        );  
    };

    renderTrigger = () => {
        const { renderTrigger } = this.props;
        if (renderTrigger) {
            return renderTrigger(this.state, this.toggleModal);
        }
        return this.renderDefaultTrigger();
    };
    
    render () {
        return <>
            {this.renderTrigger()}
            {this.renderModal()}
        </>;
    }
}

export default FileUploadModal;
