import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    loading: i18n({
        [daDK]: 'Indlæser betalingsside',
        [enUS]: 'Loading payment form',
    }),
    goBack: i18n({
        [daDK]: 'Tilbage',
        [enUS]: 'Go back',
    }),
    header: i18n({
        [daDK]: 'Opdater kortoplysninger',
        [enUS]: 'Update card',
    }),
    noCardFound: i18n({
        [daDK]: 'Intet betalingskort registreret',
        [enUS]: 'No card found',
    }),
    saveCard: i18n({
        [daDK]: 'Gem kort',
        [enUS]: 'Save card',
    }),
    updateCard: i18n({
        [daDK]: 'Opdater kort',
        [enUS]: 'Update card',
    }),
    cardUpdated: i18n({
        [daDK]: 'Dit kort blev opdateret',
        [enUS]: 'Your card was updated',
    }),
    retryingSubscription: i18n({
        [daDK]: 'Foretager gentegning',
        [enUS]: 'Re-trying subscription payment',
    }),
    highfive: i18n({
        [daDK]: 'Sådan!',
        [enUS]: 'Job\'s done!',
    }),
    retryFailureHeader: i18n({
        [daDK]: 'Gentegningen mislykkedes',
        [enUS]: 'Subcription renewal failed',
    }),
    retrySuccess: i18n({
        [daDK]: 'Dit abonnement blev fornyet, og du har nu adgang til {productName} {taxYear}.',
        [enUS]: 'Your subscription was renewed. You now have access to {productName} {taxYear}.',
    }),
    retryFailure: i18n({
        [daDK]: 'Dit abonnement kunne ikke fornyes.',
        [enUS]: 'Your subscription could not be renewed.',
    }),
    redirectionNotice: i18n({
        [daDK]: 'Du vil blive sendt videre til produktet om få sekunder',
        [enUS]: 'You will be redirected to the product shortly',
    }),
    activeSubscription: i18n({
        [daDK]: 'Aktivt abonnement på {productName}',
        [enUS]: 'Active subscription to {productName}',
    }),
    unknownError: i18n({
        [daDK]: 'Ukendt fejl. Kontakt os venligst i supporten',
        [enUS]: 'Uknown error. Please contact us through the support chat',
    }),
    noProductsSelected: i18n({
        [daDK]: 'Der er ingen produkter i din indkøbskurv!',
        [enUS]: 'You have no items in your shopping cart!',
    }),
    stripeErrorResponses: {
        card_declined: i18n({
            [daDK]: 'Dit kort er blevet afvist.',
        }),
        card_declined_card_not_supported: i18n({
            [daDK]: 'Dit kort understøttes ikke.',
        }),
        card_number_in_name_field: i18n({
            [daDK]: 'Sørg for, at navnefeltet ikke indeholder et kortnummer.',
        }),
        card_number_in_postal_code_field: i18n({
            [daDK]: 'Sørg for, at postnummerfeltet ikke indeholder et kreditkortnummer.',
        }),
        customer_canceled_authentication: i18n({
            [daDK]: 'Du skal bekræfte for at gøre denne transaktion færdig.',
        }),
        email_invalid: i18n({
            [daDK]: 'Ugyldig e-mailadresse.',
        }),
        expired_card: i18n({
            [daDK]: 'Dit kort er udløbet.',
        }),
        incomplete_au_bank_account_bsb: i18n({
            [daDK]: 'Det indtastede BSB-nummer er ufuldstændigt.',
        }),
        incomplete_au_bank_account_number: i18n({
            [daDK]: 'Det indtastede kontonummer er ikke komplet.',
        }),
        incomplete_card: i18n({
            [daDK]: 'Indtast dine kortoplysninger.',
        }),
        incomplete_cvc: i18n({
            [daDK]: 'Dit korts sikkerhedskode er ufuldstændig.',
        }),
        incomplete_expiry: i18n({
            [daDK]: 'Kortets udløbsdato er ufuldstændig.',
        }),
        incomplete_iban: i18n({
            [daDK]: 'Det indtastede IBAN-nummer er ufuldstændigt.',
        }),
        incomplete_number: i18n({
            [daDK]: 'Kortnummeret er ufuldstændigt.',
        }),
        incomplete_zip: i18n({
            [daDK]: 'Postnummeret er ufuldstændigt.',
        }),
        incorrect_cvc: i18n({
            [daDK]: 'Dit korts sikkerhedskode er forkert.',
        }),
        incorrect_number: i18n({
            [daDK]: 'Kortnummeret er forkert.',
        }),
        incorrect_zip: i18n({
            [daDK]: 'Kortnummeret og postnummeret matcher ikke.',
        }),
        invalid_au_bank_account_bsb: i18n({
            [daDK]: 'Det indtastede BSB-nummer er ugyldigt.',
        }),
        invalid_au_bank_account_number_testmode: i18n({
            [daDK]: 'Dit kontonummer er ugyldigt.',
        }),
        invalid_cvc: i18n({
            [daDK]: 'Dit korts sikkerhedskode er ugyldig.',
        }),
        invalid_expiry_month: i18n({
            [daDK]: 'Dit korts udløbsdato er ugyldig.',
        }),
        invalid_expiry_month_past: i18n({
            [daDK]: 'Dit korts udløbsdato er udløbet.',
        }),
        invalid_expiry_year: i18n({
            [daDK]: 'Kortets udløbsår er ugyldigt.',
        }),
        invalid_expiry_year_past: i18n({
            [daDK]: 'Kortets udløbsår er udløbet.',
        }),
        invalid_iban: i18n({
            [daDK]: 'Det indtastede IBAN-nummer er ugyldigt.',
        }),
        invalid_iban_country_code: i18n({
            [daDK]: 'Den indtastede IBAN er ugyldig, "{code}" er ikke en gyldig landekode.',
        }),
        invalid_iban_start: i18n({
            [daDK]: 'Dit IBAN-nummer skal starte med en landekode på to bogstaver.',
        }),
        invalid_number: i18n({
            [daDK]: 'Kortnummeret er ugyldigt.',
        }),
        payment_intent_authentication_failure: i18n({
            [daDK]: 'Vi var ikke i stand til at bekræfte din betalingsmetode. Vælg en anden betalingsmetode, og prøv igen.',
        }),
        payment_intent_unexpected_state: i18n({
            [daDK]: 'Der opstod en behandlingsfejl.',
        }),
        process_error_intransient: i18n({
            [daDK]: 'Der opstod en fejl under behandling af dit kort.',
        }),
        processing_error: i18n({
            [daDK]: 'Der opstod en fejl under behandling af dit kort. Prøv igen om et øjeblik.',
        }),
        setup_intent_authentication_failure: i18n({
            [daDK]: 'Vi var ikke i stand til at bekræfte din betalingsmetode. Vælg en anden betalingsmetode, og prøv igen.',
        }),
        setup_intent_unexpected_state: i18n({
            [daDK]: 'Der opstod en behandlingsfejl.',
        }),
        unexpected: i18n({
            [daDK]: 'Der opstod en uventet fejl.',
        }),
        call_issuer: i18n({
            [daDK]: 'Dit kort blev afvist. Du kan ringe til din bank for at få flere oplysninger.',
        }),
        card_not_supported: i18n({
            [daDK]: 'Dit kort understøttes ikke.',
        }),
        card_velocity_exceeded: i18n({
            [daDK]: 'Dit kort blev afvist, fordi der blev foretaget gentagne forsøg for ofte.',
        }),
        currency_not_supported: i18n({
            [daDK]: 'Dit kort understøtter ikke denne valuta.',
        }),
        fake_merchant_test_card: i18n({
            [daDK]: 'Dette bekræftelsesudløserkort blev afvist. Dette kort skal bruges til en brugerdefineret konto, som blev oprettet i testtilstand.',
        }),
        generic_decline: i18n({
            [daDK]: 'Dit kort blev afvist.',
        }),
        incorrect_pin: i18n({
            [daDK]: 'Forkert PIN.',
        }),
        insufficient_funds: i18n({
            [daDK]: 'Ikke penge nok på kortets konto.',
        }),
        invalid_account: i18n({
            [daDK]: 'Ugyldig konto.',
        }),
        invalid_amount: i18n({
            [daDK]: 'Ugyldigt beløb.',
        }),
        live_mode_test_card: i18n({
            [daDK]: 'Kortet blev afvist. Anmodningen fandt sted i direkte tilstand, men du benyttede et kendt testkort.',
        }),
        not_permitted: i18n({
            [daDK]: 'Kortholder er ikke tilmeldt/ikke tilladt',
        }),
        pin_try_exceeded: i18n({
            [daDK]: 'Det tilladt antal PIN-forsøg er overskredet.',
        }),
        test_mode_live_card: i18n({
            [daDK]: 'Dit kort blev afvist. Din anmodning var i testtilstand, men du benyttede et kort, som ikke er et testkort. Gå til https://stripe.com/docs/testing for at se en liste med gyldige testkort.',
        }),
        transaction_not_allowed: i18n({
            [daDK]: 'Dit kort understøtter ikke denne form for køb.',
        }),
        failed_to_process_payment: i18n({
            [daDK]: 'Der skete en fejl under behandlingen af din ordre.',
        }),
        invalid_billing_address: i18n({
            [daDK]: 'Ugyldig faktureringsadresse.',
        }),
        invalid_payer_email: i18n({
            [daDK]: 'Ugyldig e-mailadresse.',
        }),
        invalid_payer_phone: i18n({
            [daDK]: 'Ugyldigt telefonnummer.',
        }),
        invalid_shipping_address: i18n({
            [daDK]: 'Beklager, men der er ingen forsendelsesmuligheder til den valgte adresse.',
        }),
        invalid_shipping_address_short: i18n({
            [daDK]: 'Ugyldig forsendelsesadresse.',
        }),
        shipping_option_unavailable: i18n({
            [daDK]: 'Beklager, den valgte forsendelsesmåde er ikke tilgængelig.',
        }),
        api_connection_error: i18n({
            [daDK]: 'Vi har problemer med oprette forbindelse til vores betalingsudbyder. Du er ikke blevet opkrævet. Kontrollér din internetforbindelse, og prøv igen.',
        }),
        incomplete_payment_details: i18n({
            [daDK]: 'Angiv komplette betalingsoplysninger.',
        }),
        payment_method_invalid_parameter: i18n({
            [daDK]: 'De betalingsoplysninger, du har angivet, er ugyldige.',
        }),
        payment_method_microdeposit_verification_amounts_mismatch: i18n({
            [daDK]: 'De beløb, der er angivet, svarer ikke til de beløb, der blev sendt til bankkontoen.',
        }),
        payment_method_microdeposit_verification_attempts_exceeded: i18n({
            [daDK]: 'Du har overskredet antallet af tilladte bekræftelsesforsøg.',
        }),
        processing_error_intransient: i18n({
            [daDK]: 'Noget gik galt. Prøv igen senere.',
        }),
    },
};