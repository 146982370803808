import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Header, Icon, Input, Loader, Modal, Segment, Transition } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import * as fileStorage from 'http/file-storage';
import { downloadText } from 'util/downloadBlob';
import { readAsBase64 } from 'util/files';
import reportEditor from 'http/reportEditor';
import createMarkdownFromSection from './createMarkdownFromSection';
import ColoredText from 'design/atoms/ColoredText';
import UploadLogo from 'design/molecules/UploadLogo';

const ReportThemeSettingsModal = ({ onClose, onSave }) => {
    const [theme, setTheme] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        reportEditor.getUserDefinedTheme()
            .then(theme => setTheme(theme || {}))
            .finally(() => setLoading(false));
    }, []);

    const updateTheme = (updator = {}) => {
        setTheme({
            ...theme,
            ...updator,
        });
    };

    const updateThemeColor = (colorKey, colorValue) => {
        const currentColors = theme.colors || {};
        updateTheme({
            colors: {
                ...currentColors,
                [colorKey]: colorValue,
            },
        });
    };

    const validateHexColor = (hexColor) => {
        if (!hexColor) return true;
        return /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hexColor);
    };

    return (
        <Modal open>
            <Modal.Header>
                <Icon name='cog' />
                Report settings
            </Modal.Header>
            <Modal.Content>
                {loading && <Loader inline='centered' active />}
                {!loading && (
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field error={!validateHexColor(theme.colors?.primary)}>
                                <label>Primary brand color</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('primary', value)}
                                    defaultValue={theme.colors?.primary}
                                    error={!validateHexColor(theme.colors?.primary)}
                                    placeholder='Primary hex color...'
                                />
                            </Form.Field>
                            <Form.Field error={!validateHexColor(theme.colors?.secondary)}>
                                <label>Secondary brand color</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('secondary', value)}
                                    defaultValue={theme.colors?.secondary}
                                    placeholder='Secondary hex color...'
                                />
                            </Form.Field>
                            <Form.Field error={!validateHexColor(theme.colors?.tertiary)}>
                                <label>Tertiary brand color</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('tertiary', value)}
                                    defaultValue={theme.colors?.tertiary}
                                    placeholder='Tertiary hex color...'
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <label>Font</label>
                            <Dropdown
                                selection
                                clearable
                                placeholder='Overwrite the default font...'
                                defaultValue={theme?.fontAssetID}
                                onChange={(_, { value }) => updateTheme({ fontAssetID: value })}
                                // matches fonts defined in the report-editor service at:
                                // src/routes/assets/static/'
                                options={[
                                    { text: 'Arial', value: 'font_arial.ttf' },
                                    { text: 'Open Sans', value: 'font_open_sans.ttf' },
                                    { text: 'Roboto', value: 'font_roboto.ttf' },
                                    { text: 'Segoe UI', value: 'font_segoe_ui.ttf' },
                                    { text: 'Tinos', value: 'font_tinos.ttf' },
                                ]}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Logo</label>
                            <UploadLogo />
                        </Form.Field>
                    </Form>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color='black'
                    content='Close'
                    onClick={() => onClose()}
                />
                <Button
                    primary
                    icon='save'
                    content='Save'
                    onClick={async () => {
                        await reportEditor.putUserDefinedTheme(theme);
                        onSave();
                        onClose();
                    }}
                />
            </Modal.Actions>
        </Modal>
    );
};

const IXBRLViewer = ({ value, yearReportData, regenerateDocument, factsBeingUpdated, productMetadata, id }) => {
    const [markdown, setMarkdown] = useState(null);
    const [templateMetadata, setTemplateMetadata] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [yearReportDataExecutionStatus, setYearReportDataExecutionStatus] = useState('NOT_STARTED');

    const documentGeneratorProductFact = productMetadata.facts[id];
    const yearReportDataTriggerFactID = documentGeneratorProductFact.options.yearReportTriggerFact;

    useEffect(() => {
        switch (yearReportDataExecutionStatus) {
            case 'NOT_STARTED': {
                if (factsBeingUpdated.has(yearReportDataTriggerFactID)) {
                    setYearReportDataExecutionStatus('EXECUTING');
                }
                break;
            }

            case 'EXECUTING': {
                if (!factsBeingUpdated.has(yearReportDataTriggerFactID)) {
                    setYearReportDataExecutionStatus('DONE');
                }
                break;
            }

            default: break; // no-op
        }
    }, [factsBeingUpdated, yearReportDataExecutionStatus, yearReportDataTriggerFactID]);

    const primaryPdfDocumentComponentsFileID = value?.primaryPdfDocumentComponentsFileID;
    const inputHash = value?.inputHash;
    const reportTemplateID = yearReportData?.data?.reportTemplateID;
    const reportTemplateVersion = yearReportData?.data?.reportTemplateVersion;

    useEffect(() => {
        if (yearReportDataExecutionStatus !== 'DONE') return;
        if (!primaryPdfDocumentComponentsFileID) return;
        if (!reportTemplateID) return;
        if (!reportTemplateVersion) return;

        setLoading(true);

        Promise.all([
            fileStorage.getFileData(primaryPdfDocumentComponentsFileID),
            reportEditor.getTemplateMetadata(reportTemplateID, reportTemplateVersion),
        ])
            .then(([file, metadata]) => {
                const bytes = Uint8Array.from(file.data.data);
                const rawJSON = new TextDecoder().decode(bytes)
                const documentComponents = JSON.parse(rawJSON);
                const [firstSection] = documentComponents;
                const compiled = createMarkdownFromSection(firstSection);
                setTemplateMetadata(metadata);
                setMarkdown(compiled);
            })
            .catch(e => {
                console.error(e);
                setError(true);
            })
            .finally(() => setLoading(false));

    }, [primaryPdfDocumentComponentsFileID, inputHash, reportTemplateID, reportTemplateVersion, factsBeingUpdated, yearReportDataExecutionStatus]);

    const handleDownloadIXBRL = async () => {
        setIsDownloading(true);
        setDownloadProgress(0);

        let documentWrapperXHTML = (
            document.getElementById('documentWrapper')
            .outerHTML
            // close unclosed tags
            .replace(/<link\b([^>]*)>/gi, '<link$1 />')
            .replace(/<br\b([^>]*)>/gi, '<br$1 />')
            .replace(/<img\b([^>]*)>/gi, '<img$1 />')
        );

        const assetsPattern = new RegExp(reportEditor.getAssetURL('.+?'), 'g');

        const usedAssets = new Set();
        while (true) {
            const match = assetsPattern.exec(documentWrapperXHTML);
            if (!match) break;
            usedAssets.add(match[0]);
        }

        let totalDownloaded = 0;

        await Promise.all([...usedAssets].map(async assetURL => {
            const assetResponse = await fetch(assetURL);
            if (!assetResponse.ok) {
                throw new Error(`Got ${assetResponse.status} fetching asset: ${assetURL}`);
            }

            const blob = await assetResponse.blob();
            const base64 = await readAsBase64(blob);

            const assetURLPattern = new RegExp(assetURL, 'g');

            documentWrapperXHTML = documentWrapperXHTML.replace(assetURLPattern, () => {
                return base64;
            });

            totalDownloaded++;

            setDownloadProgress(totalDownloaded / usedAssets.size * 100);
        }));

        const xmlHeader = '<?xml version="1.0" encoding="UTF-8"?>';
        const xhtmlAttributes = 'xmlns="http://www.w3.org/1999/xhtml" xmlns:ix="http://www.xbrl.org/2008/inlineXBRL" xml:lang="en"';
        const wrapped = `${xmlHeader}<html ${xhtmlAttributes}><body style="margin: 0;">${documentWrapperXHTML}</body></html>`;

        downloadText('esg_report.xhtml', 'application/xhtml+xml', wrapped);
    };

    const renderDocumentElement = ({ ...markdown }) => {
        const {
            tag,
            style,
            children,
            innerText,
            ...other
        } = markdown;

        const props = {
            style,
            ...other,
        };

        let convertedChildren;
        if (innerText) {
            const listOfTexts = Array.isArray(innerText) ? innerText : [innerText];
            
            convertedChildren = listOfTexts.map(ele => {
                if (typeof ele === 'string') {
                    return ele;
                } else {
                    return renderDocumentElement(ele);
                }
            });
        } else if (Array.isArray(children) && children.length > 0) {
            convertedChildren = children.map(renderDocumentElement);
        }

        return React.createElement(tag, props, convertedChildren);
    };

    const contentIsReady = !loading && !error && markdown;

    const documentWrapper = (
        <Transition visible={contentIsReady} duration={500} animation='fade'>
            <div>
                <div style={{ all: 'initial' }} id='documentWrapper'>
                    {templateMetadata?.theme?.fontAssetID && (
                        <style>
                            {`
                                @font-face {
                                    font-family: CustomFont;
                                    src: url('${reportEditor.getAssetURL(templateMetadata.theme.fontAssetID)}') format('truetype');
                                    font-weight: normal;
                                    font-style: normal;
                                }
                            `}
                        </style>
                    )}
                    <div style={{ fontFamily: 'CustomFont' }}>
                        {markdown && renderDocumentElement(markdown)}
                    </div>
                </div>
            </div>
        </Transition>
    );

    const updatedAtTimestamp = new Date(value?.updatedAt).toLocaleString('en-GB', {
        day: 'numeric', 
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    let color;
    let icon;
    let text;
    if (loading) {
        color = 'grey';
        icon = 'spinner';
        text = 'Your ESG-report is loading...';
    } else if (error) {
        color = 'red';
        icon = 'warning sign';
        text = 'An error occured and the ESG-report could not be generated...';
    } else {
        color = 'green';
        icon = 'check circle';
        text = 'Your ESG-report is ready';
    }

    return (
        <>
            <Segment color={color}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <Header>
                            <Icon
                                name={icon}
                                color={color}
                                loading={loading}
                            />
                            <Header.Content>
                                {text}
                                {contentIsReady && (
                                    <Header.Subheader>
                                        <b>Last updated:</b> {updatedAtTimestamp}
                                    </Header.Subheader>
                                )}
                            </Header.Content>
                        </Header>
                    </div>
                    {contentIsReady && (
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            <ColoredText
                                content='Report settings'
                                icon='cog'
                                iconPosition='left'
                                onClick={() => setSettingsModalOpen(true)}
                                underlined={false}
                                link
                            />&nbsp;&nbsp;
                            <Button
                                onClick={() => {
                                    handleDownloadIXBRL()
                                        .catch(() => toast.error('The document could not be downloaded...'))
                                        .finally(() => setIsDownloading(false));
                                }}
                                content={isDownloading ? `Downloading: ${downloadProgress.toFixed(2)} %` : 'Download as iXBRL'}
                                icon={<Icon name={isDownloading ? 'spinner' : 'download'} loading={isDownloading} />}
                                labelPosition='right'
                                disabled={isDownloading}
                                primary
                            />
                        </div>
                    )}
                </div>
            </Segment>
            {documentWrapper}
            {settingsModalOpen && (
                <ReportThemeSettingsModal
                    onClose={() => setSettingsModalOpen(false)}
                    onSave={async () => {
                        setYearReportDataExecutionStatus('NOT_STARTED');
                        setTemplateMetadata(null);
                        setMarkdown(null);
                        setLoading(true);
                        regenerateDocument();
                    }}
                />
            )}
        </>
    );
};

export default IXBRLViewer;
