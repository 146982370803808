import React, { Component } from 'react';
import { Loader, Header, Transition, Segment } from 'semantic-ui-react';
import CrossfadeImage from 'react-crossfade-image';
import pdfjs from '@bundled-es-modules/pdfjs-dist';
import cn from 'classnames';
import { prepareDownloadLink } from '../../../http/file-storage';
import { Media } from '../Media';
import { isDraggedOutsideScreen } from '../../../util/draggedOutsideScreen';
import getEventPath from '../../../util/getEventPath';
import withUserData from '../../../util/withUserData';
import { getReportLogo } from '../../../util/userMethods';
import ColoredText from '../../atoms/ColoredText';
import DropZone from './DropZone';
import NavBtn from './NavBtn';

import styles from './PDFView.module.scss';

class PDFView extends Component {
    constructor (props) {
        super(props);
        this.pdfCanvas    = this.prepareCanvas();
        this.downloadLink = this.prepareDownloadLink();
        this.pdf          = null;
        this.totalPages   = null;
        this.nextPDF      = null;
        this.dropID       = 'droppoint';

        this.state = {
            page: 0,
            newPDF: false,
            rendering: false,
            fileDragged: false,
            latestRender: this.getImageData(),
        };
    }

    prepareDownloadLink = () => {
        const out = document.createElement('a');
        out.rel = 'noopener noreferrer';
        out.target = '_blank';
        return out;
    };

    prepareCanvas = () => {
        const out = document.createElement('canvas');
        out.width = this.props.viewportWidth;
        out.height = this.props.viewportHeight;
        return out;
    };

    getImageData = () => {
        return this.pdfCanvas.toDataURL('image/png');
    };

    isWorking = () => {
        const { rendering } = this.state;
        return rendering || this.props.parentUpdating;
    };

    resetPageTrack = () => {
        return new Promise(resolve => {
            this.setState({ page: 0 }, resolve);
        });
    };

    componentDidUpdate = async (props, state) => {
        const pdfIdHasChanged = props.pdfID !== this.props.pdfID;
        const hashHasChanged = props.pdfHash !== this.props.pdfHash;

        if (pdfIdHasChanged || hashHasChanged) {
            this.enqueueUpdate(this.props.pdfID);
        } else if (this.state.page !== state.page) {
            await this.renderPage(false);
        }
    };

    getProvidedPDF = () => {
        const { pdfID } = this.props;

        if (!pdfID) {
            return null;
        }

        // return early if provided PDF is base64
        if (pdfID.startsWith('data:application/pdf;base64,')) {
            return pdfID;
        }

        return prepareDownloadLink(pdfID);
    };

    onOpenClicked = async () => {
        const pdf = await this.getProvidedPDF();
        if (!pdf) {
            return;
        }

        document.body.appendChild(this.downloadLink);
		this.downloadLink.href = pdf;
        this.downloadLink.click();
        document.body.removeChild(this.downloadLink);
    };

    componentDidMount = async () => {
        document.body.addEventListener('dragenter', this.onDragStart, false);
        document.body.addEventListener('drop', this.onDrop, false);
        document.body.addEventListener('dragleave', this.onDragLeave, false);
        window.addEventListener('blur', this.onDragLeave, false);

        const { pdfID } = this.props;
        if (pdfID) {
            this.enqueueUpdate(pdfID)}
    };

    componentWillUnmount = () => {
        document.body.removeEventListener('dragenter', this.onDragStart, false);
        document.body.removeEventListener('drop', this.onDrop, false);
        document.body.removeEventListener('dragleave', this.onDragLeave, false);
        window.removeEventListener('blur', this.onDragLeave, false);
    };

    setUpdating = tf => {
        this.setState({ rendering: tf });
    };

    enqueueUpdate = pdf => {
        this.nextPDF = pdf;
        if (!this.state.rendering) {
            this.setUpdating(true);
            this.prepareNextPDF();
        }
    };

    prepareNextPDF = async () => {
        // store next PDF to render
        const pdfToRender = this.nextPDF;

        // reset "nextPDF"
        this.nextPDF = null;

        // do load PDF
        await this.resetPageTrack();
        await this.load(pdfToRender);

        // check if any PDF was enqueued during last load/render
        if (this.nextPDF) {
            this.prepareNextPDF();
        } else {
            this.setUpdating(false);
        }
    };

    load = async (pdfToRender) => {
        if (!pdfToRender) {
            return;
        }
        const path2pdf = await this.getProvidedPDF();
        await this.loadPDF(path2pdf);
        await this.renderPage(true);
    };

    loadPDF = async path2pdf => {
        const task = pdfjs.getDocument(path2pdf);
        const pdf = await task.promise;
        this.pdf = pdf;
        this.totalPages = pdf.numPages;
    };

    renderPage = async isNewPDF => {
        try {
            const pidx = this.state.page;
            const pdf = this.pdf;
            const page = await pdf.getPage(pidx + 1);
            const viewport = page.getViewport({ scale: 1 });
            const renderCtx = {
                canvasContext: this.getCanvasContext(),
                viewport: viewport,
            };

            // OBS: the PDF is intentionally rendered twice,
            // as some computers will not render the PDF correctly
            // if hardware acceleration is turned on in the users browser.
            // (probably has to do with the users graphics card)
            // 
            // See: https://github.com/mozilla/pdf.js/issues/14641
            await page.render(renderCtx).promise;
            await page.render(renderCtx).promise;

            this.setState({
                latestRender: this.getImageData(),
                newPDF: isNewPDF,
            });
        } catch (e) {
            console.warn('Tried to render PDFView while unmounted');
        }
    };

    getCanvasContext = () => this.pdfCanvas.getContext('2d');

    setPage = page => {
        if (this.isWorking()) {
            return;
        }
        if (page < 0 || page >= this.totalPages) {
            return;
        }
        this.setState({ page });
    };

    nextPage = () => {
        const { page } = this.state;
        this.setPage(page + 1);
    };

    prevPage = () => {
        const { page } = this.state;
        this.setPage(page - 1);
    };

    renderCurrentLoadedPage = () => {
        const { latestRender, newPDF } = this.state;

        const containerStyle = {
            width: this.props.viewportWidth,
            height: this.props.viewportHeight,
            border: '1px solid lightgray',
        };

        return <div style={containerStyle}>
            <CrossfadeImage
                src={latestRender}
                style={{ opacity: this.isWorking() ? '0.30' : '1' }}
                duration={newPDF ? 1250 : 100}
            />
        </div>;
    };

    renderNavigationOverlay = () => {
        const { page, rendering } = this.state;
        const hasPrev = page > 0;
        const hasNext = page < this.totalPages - 1;
        return (
            <div className={styles.overlayContainer}>
                <div className={styles.navOverlay}>
                    <div className={cn(styles.navContainer, styles.tal)}>
                        <NavBtn
                            onClick={this.prevPage}
                            disabled={!hasPrev || rendering}
                            link={hasPrev}
                            direction='left'
                        />
                    </div>
                    <div className={cn(styles.navContainer, styles.tar)}>
                        <NavBtn
                            onClick={this.nextPage}
                            disabled={!hasNext || rendering}
                            link={hasNext}
                            direction='right'
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderUpdatingOverlay = () => {
        const content = (
            <div className={styles.overlayContainer}>
                <div className={styles.updatingOverlay}>
                    <Segment circular padded='very' secondary>
                        <Header content='Arbejder...' size='medium' />
                        <Loader inline active size='huge' indeterminate style={{ zIndex: 5 }} />
                    </Segment>
                </div>
            </div>
        );
        return (
            <Transition.Group duration={300} animation='fade'>
                { this.isWorking() && content }
            </Transition.Group>
        );
    };

    renderUploadLogoOverlay = () => {
        const { page, fileDragged, rendering } = this.state;
        const { onUploadLogo, userData, parentUpdating, uploadLogo } = this.props;

        if (!uploadLogo) {
            return null;
        }

        if ([
            parentUpdating,
            getReportLogo(userData),
            page !== 0,
            rendering,
        ].some(x => x)) {
            return;
        }

        let containerClasses = [styles.uploadLogoContainer];
        let content;
        if (fileDragged) {
            containerClasses.push(styles.dragHover);
            content = <span id={this.dropID}>Slip logo her</span>;
        } else {
            content = <>
                Træk logo hertil<br />
                eller klik <span onClick={onUploadLogo} className={styles.link}>Upload</span>
            </>;
        }

        return <Media gte='computer'>
            <DropZone
                dropID={this.dropID}
                content={content}
                containerClasses={containerClasses}
            />
        </Media>;
    }

    blockDefault = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    onDragStart = e => {
        this.blockDefault(e);
        this.setState({ fileDragged: true });
    };

    onDragLeave = e => {
        this.blockDefault(e);
        if (isDraggedOutsideScreen(e)) {
            this.setState({ fileDragged: false });
        }
    };

    onDrop = e => {
        this.blockDefault(e);
        this.setState({ fileDragged: false });
        const elements = getEventPath(e);
        for (let elm of elements) {
            if (elm.id === this.dropID) {
                this.handleDrop(e);
                break;
            }
        }
    };

    // e.dataTransfer.files[0]:
    // name: "logo.jpg"
    // size: 84182
    // type: "image/jpeg"
    handleDrop = (e) => {
        const { onUploadLogo } = this.props;
        e.preventDefault();
        e.stopPropagation();
        if (e.type !== 'drop') return;
        if (!e.dataTransfer || !e.dataTransfer.files || e.dataTransfer.files.length === 0) return;
        const f = e.dataTransfer.files[0];
        if (!f || !f.name || !f.size || !f.type) return;
        if (onUploadLogo) onUploadLogo(f);
        // TODO Display proper error messages
    };

    renderComputerTabletView = () => (
            <Media gte='tablet'>
                <div className={styles.tac}>
                    <div className={styles.canvasContainer}>
                        {this.renderCurrentLoadedPage()}
                        {this.renderUpdatingOverlay()}
                    </div>
                    {this.renderNavigationOverlay()}
                    {this.renderUploadLogoOverlay()}
                </div>
            </Media>
        );

    renderMobileView = () => {
        const {
            viewportWidth,
            viewportHeight,
            mobileScale,
        } = this.props;

        const width = viewportWidth * mobileScale;
        const height = viewportHeight * mobileScale;
        return <Media lt='tablet'>
            <div className={styles.tac} >
                <img
                    onClick={this.onOpenClicked}
                    src={this.state.latestRender}
                    height={height}
                    width={width}
                    alt='Preview'
                />
                <br />
                <ColoredText
                    onClick={this.onOpenClicked}
                    color='green'
                    icon='eye'
                    content='Åbn PDF'
                    link
                />
            </div>
        </Media>;
    };

    render = () => (
        <>
            {this.renderComputerTabletView()}
            {this.renderMobileView()}
        </>
    );
}

PDFView.defaultProps = {
    viewportWidth: 595,
    viewportHeight: 841,
    mobileScale: 0.33,
};

export default withUserData(PDFView);