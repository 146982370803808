import React 				from 'react';
import PropTypes     		from 'prop-types';
import Form        		    from 'design/atoms/Form';
import YesNo                from 'design/atoms/YesNo';

class InputToggleWidget extends React.Component {
	static propTypes = {
		defaultValue: PropTypes.bool,
		label: PropTypes.string,
		error: PropTypes.string,
		yesLabel: PropTypes.string,
		noLabel: PropTypes.string,
        isLocked: PropTypes.bool,
        tooltip: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.array,
        ]),
	};

	static defaultProps = {
		error: null,
		yesLabel: 'Ja',
		noLabel: 'Nej',
	};

	handleChange = val => {
		const { onChange, supply } = this.props;
		onChange(supply, {
			boolean: val,
		});
	};

	getOptionTexts () {
		const { alternateYes, alternateNo } = this.props;
		return {
			yesLabel: alternateYes ? alternateYes : 'Ja',
			noLabel: alternateNo ? alternateNo : 'Nej',
		};
	}

	render () {
		const { defaultValue, label, tooltip, youtubeVideo, error, isLocked } = this.props;
		const { yesLabel, noLabel } = this.getOptionTexts();
		return (
			<Form.Row>
				<Form.LabelField
					label={label}
					tooltip={tooltip}
					youtubeVideo={youtubeVideo}
				/>
				<Form.Field error={error} textAlign='right'>
					<YesNo
						value={defaultValue}
						yesLabel={yesLabel}
						noLabel={noLabel}
						disabled={isLocked}
						onChange={this.handleChange}
					/>
				</Form.Field>
			</Form.Row>
		);
	}
}

export default InputToggleWidget;
