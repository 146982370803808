import React from 'react';
import { Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ColoredText from 'design/atoms/ColoredText';

/**
 * Compiles provided markdown into Semantic UI components
 * Input is chunked by newlines.
 * Each chunk is transformed to either:
 * - A paragraph or 
 * - A <Header> (if it starts with "#")
 * - A <li> list item (if it starts with "*"). Consecutive list item will be wrapped in a <ul> tag
 * @param {string} markdown
 */
export function compileMarkdown(markdown) {
    let res = [];
    if (!markdown) {
        return res;
    }
    if (typeof markdown !== "string") {
        return res;
    }

    let listItemBuffer = []; // buffer empty <=> not currently processing a sequence of list items
    const lines = markdown.split(/\n/);
    let output = [];
    lines.forEach((l, idx) => {
        const linkRegex = /\[(.+?)\]\((.+?)\)/gi;
        const line = l.trim();

        if (l.startsWith('  ')) {
            output.push(<div style={{ whiteSpace: 'pre' }}>{l}</div>);
        } else if (linkRegex.test(line)) {
            linkRegex.lastIndex = 0;

            const match = linkRegex.exec(line);
            if (!match) {
                return;
            }

            const text = match[1].trim();
            const link = match[2].trim();
            const isExternal = link.startsWith('http');
            const utext = <ColoredText link color='green'>{text}</ColoredText>;
            if (isExternal) {
                output.push(
                    <a
                        rel='noopener noreferrer'
                        target='_blank'
                        href={link}
                        children={utext}
                    />
                );
            } else {
                output.push(
                    <Link
                        to={link}
                        children={utext}
                    />
                );
            }
        } else if (line.startsWith("#")) {
            endList(listItemBuffer, output, idx);
            output.push(<Header key={idx}>{line.replace(/^[#]+/gi, "")}</Header>);
        } else if (line.startsWith("* ")) {
            const elm = line.replace(/^[*]\s*/, "");
            listItemBuffer.push(elm);
        } else if (line.startsWith('**') && line.endsWith('**')) {
            output.push(<b>{line.substring(2, line.length - 2)}</b>);
        } else {
            endList(listItemBuffer, output, idx);
            let toWrite;
            if (!line) {
                // Empty lines should cause newlines
                toWrite = <span>&nbsp;</span>;
            } else {
                toWrite = line;
            }
            output.push(<div key={idx}>{toWrite}</div>)
        }
    })
    endList(listItemBuffer, output);

    return output;
};

const endList = (listItemBuffer, output, key) => {
    if (listItemBuffer.length > 0) {
        output.push(<ul key={`ul${key}`}>{listItemBuffer.map((elm,i) => <li key={`li-${i}`}>{elm}</li>)}</ul>)
    }

    // clear buffer (which is pass-by-reference)
    while (listItemBuffer.length > 0) {
        listItemBuffer.shift();
    }
}