import React from 'react';
import ColoredText from 'design/atoms/ColoredText';

function ResetButton ({ onReset }) {
    return (
        <ColoredText
            link
            underlined={false}
            content='Reset til før signaturflow'
            iconPosition='left'
            icon='repeat'
            onClick={onReset}
        />
    );
}

export default ResetButton;