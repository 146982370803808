import { HTTPClient } from '../network/fetch';

const client = new HTTPClient({ prefix: 'e-conomic/api' });

export function registerToken(token) {
    return client.postResource('register', { token });
}

export function extractPrefillValues(token) {
    return client.getResource(`prefill/${token}`);
}

// --------------------------------------------------

/* Functions regarding e-conomic invoices */

/**
 * Gets all the invoices of a customer
 */
export async function getCustomerInvoices() {
    const { bookedInvoices } = await client.getResource('invoices/booked');
    return bookedInvoices;
}

/**
 * Retrieves the invoice pdf encoded as a base64 string
 * @param {number} invoiceID - The ID of the invoice to get
 */
export function getInvoicePdf(invoiceID) {
    return client.getResource(`invoices/pdf?invoiceID=${invoiceID}`);
}

// --------------------------------------------------
