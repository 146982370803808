import React from 'react';
import { Table, Label, Loader, Icon } from 'semantic-ui-react';
import { formatDateTime, monthNamesDK } from '../../../util/format/DateTime';
import { formatNumber } from '../../../util/format/Number';
import CSV from '../../../util/CSV';

import styles from './LogTable.module.css';

const amountOfColumns = 5;

function LogTable ({ logs, userCount, loading, year, month }) {
    const downloadAsCSV = () => {
        const csv = new CSV([
            'cvr',
            'name',
            'transactionDate',
            'isRefund',
            'amount',
        ]);

        const numberFormatter = new Intl.NumberFormat('da-DK');

        for (const { cvr, name, transactionDate, isRefund, amount } of logs) {
            csv.addToRow('cvr', cvr?.toString());
            csv.addToRow('name', name?.toString());
            csv.addToRow('transactionDate', transactionDate?.toString());
            csv.addToRow('isRefund', isRefund?.toString());
            csv.addToRow('amount', numberFormatter.format(amount).replace(/\./g, ''));
            csv.newLine();
        }

        csv.download(`${monthNamesDK[month - 1]}-${year}-betalingshistorik`);
    };

    const renderHeader = () => {
        return <Table.Header>
            <Table.Row>
                <Table.HeaderCell>CVR</Table.HeaderCell>
                <Table.HeaderCell>Navn</Table.HeaderCell>
                <Table.HeaderCell>Transaktionsdato</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>Beløb</Table.HeaderCell>
            </Table.Row>
        </Table.Header>;
    };

    const renderFooter = () => {
        if (logs.length === 0) {
            return;
        }
        const totalPrice = logs.reduce((acc, { amount }) => acc += amount, 0);
        const content = <>
            <Label className={styles.dlbtn} active onClick={downloadAsCSV}>
                <Icon name='download' />
                Download som CSV
            </Label>
            <Label>
                <Icon name='user' />
                <u>{userCount}</u> {userCount === 1 ? 'ny' : 'nye'} {userCount === 1 ? 'bruger' : 'brugere'}
            </Label>
            <Label>
                <Icon name='dollar sign' />
                <u>{logs.filter(({ isRefund }) => !isRefund).length}</u> køb i alt
            </Label>
            <Label>
                <Icon name='undo' />
                <u>{logs.filter(({ isRefund }) => isRefund).length}</u> refunds i alt
            </Label>
            <Label>
                Betalt i alt: <u>{formatNumber(totalPrice)}</u> kr.
            </Label>
        </>;
        return <Table.Footer>
            <Table.Row>
                <Table.HeaderCell colspan={amountOfColumns} textAlign='right'>
                    {!loading && content}
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    };

    const renderRow = ({ cvr, name, transactionDate, isRefund, amount }) => {
        return <Table.Row>
            <Table.Cell>{cvr}</Table.Cell>
            <Table.Cell>{name}</Table.Cell>
            <Table.Cell>{formatDateTime(transactionDate)}</Table.Cell>
            <Table.Cell>{isRefund ? 'Refund' : 'Køb'}</Table.Cell>
            <Table.Cell textAlign='right'>{formatNumber(amount)} kr.</Table.Cell>
        </Table.Row>
    };

    const renderBody = () => {
        let content;
        if (loading) {
            content = <Table.Row textAlign='center'>
                <Table.Cell colspan={amountOfColumns}>
                    <Loader inline active size='huge' />
                </Table.Cell>
            </Table.Row>;
        } else {
            content = logs.map(renderRow);
        }
        return <Table.Body>
            {content}
        </Table.Body>;
    };

    return <Table>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
    </Table>;
}

export default LogTable;