import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { unregisterERP } from 'http/erpBroker';
import { getReportingPeriodPair } from 'http/financial-dates';
import { formatDateTime, ISOFromYearMonthDay } from 'util/format/DateTime';
import { readCP437AsText } from 'util/files'
import FileuploadButton from 'design/atoms/FileuploadButton';
import ColoredText from 'design/atoms/ColoredText';

import * as sieImporter from './sieImporter';
import ToolDescription from './toolDescription';

const STATUSES = {
    AWAITING_SIE: {
        title: 'Afventer SIE-fil',
        color: 'grey',
        icon: 'file excel',
    },

    VALIDATION_ERROR: {
        color: 'orange',
        icon: 'warning circle',
    },

    SIE_ACCEPTED: {
        title: 'SIE-filen er læst og valideret',
        color: 'green',
        icon: 'check circle',
    },

    IMPORTING: {
        title: 'Importerer kontoplan',
        color: 'grey',
        icon: 'spinner',
        loading: true,
    },

    IMPORT_ERROR: {
        title: 'Kontoplanen kunne ikke importeres',
        color: 'orange',
        icon: 'warning circle',
    },

    DATA_IMPORTED: {
        title: 'Bogføringsdata importeret',
        color: 'green',
        icon: 'check circle',
    },

    WRONG_YEAR_ERROR: {
        title: 'Forskel i regnskabsperiode og regnskabsår',
        color: 'orange',
        icon: 'warning circle',
    },
};

const SIEImporter = ({ fetchERPData, values, id, options, taxYear, productLabel }) => {
    const [status, setStatus] = useState(STATUSES.AWAITING_SIE);
    const [title, setTitle] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [currentAccounts, setCurrentAccounts] = useState(null);

    useEffect(() => {
        const fact = get(values, id, {});
        setCurrentAccounts(get(fact, 'value'));
    }, [id, values]);

    useEffect(() => {
        if (!currentAccounts) {
            setStatus(STATUSES.AWAITING_SIE);
            return;
        }

        if (currentAccounts.error) {
            setStatus(STATUSES.IMPORT_ERROR);
            return;
        }

        if (currentAccounts.accounts.length === 0) {
            setStatus(STATUSES.AWAITING_SIE);
            return;
        }

        setStatus(STATUSES.DATA_IMPORTED);
    }, [currentAccounts]);
    
    useEffect(() => {
        if (status?.title) {
            setTitle(status.title);
        }
    }, [status]);

    const onFileSelected = async file => {
        // exit early if user cancelled the file popup
        if (!file) {
            return;
        }

        // reset any previous error messages
        setErrorMessage(null);
        
        const reportingPeriodPair = await getReportingPeriodPair(taxYear, productLabel);
        const { start, end } = reportingPeriodPair.matchingPeriod;
        const [startDate, endDate] = [start, end].map(ISOFromYearMonthDay);
        
        // parse the actual SIE-file
        const rawSIE = await readCP437AsText(file);
        const [importedAccounts, error] = sieImporter.parseSIE(rawSIE, startDate, endDate);
        if (error === "årsfejl") {
            setStatus(STATUSES.WRONG_YEAR_ERROR);
            return;
        }

        // trigger ERP-action w/ imported accounts in the update context
        setStatus(STATUSES.IMPORTING);
        await fetchERPData({
            importedAccounts,
        });

        // the useEffect on [values] will run when the accounts have finished importing
        // and change the status there
    };

    const onUnregisterERP = () => {
        // causes this component to unmount
        return unregisterERP();
    };

    const renderChooseSIESection = () => {
        const uploadTrigger = (
            <Button
                primary
                content='Vælg SIE-fil'
                size='small'
                icon='upload'
            />
        );

        return (
            <>
                <div style={{ textAlign: 'right' }}>
                    <FileuploadButton
                        clearFileAfterSelection={true}
                        accept='se'
                        onChange={onFileSelected}
                        trigger={uploadTrigger}
                    />
                </div> 
            </>
        );
    };

    const renderTimeOfImport = () => {
        // get trigger fact
        const triggerFact = values[options?.trigger];
        if (!triggerFact) {
            return null;
        }

        const dateUpdated = triggerFact.value?.number;
        if (!dateUpdated) {
            return null;
        }    

        return <span>
            Kontoplan sidst importeret den {formatDateTime(dateUpdated)}.
        </span>;
    };

    const renderImporterContent = () => {
        switch (status) {
            case STATUSES.AWAITING_SIE:
                return (
                    <div>
                        Når du har en SIE Type 4 fil, kan du importere den her.
                        {renderChooseSIESection()}
                    </div>
                );

            case STATUSES.VALIDATION_ERROR:
                return (
                    <div>
                        {errorMessage}
                        {renderChooseSIESection()}
                    </div>
                );

            case STATUSES.IMPORTING:
                return (
                    <div>
                        Din kontoplan importeres. Vent venligst...
                    </div>
                );
            
            case STATUSES.IMPORT_ERROR:
                return (
                    <div>
                        Der opstod en fejl da vi forsøgte at importere din kontoplan.
                        {renderChooseSIESection()}
                    </div>
                );

            case STATUSES.DATA_IMPORTED:
                return (
                    <div>
                        <p>
                            Kontoplanen er blevet importeret og vi har gjort din bogføring klar til årsrapporten.
                            Du kan nu se og rette i den importerede kontoplan på næste sektion.
                        </p>
                        <p>
                            Du kan overskrive nuværende bogføringsdata ved at importere en SIE-fil på ny.
                        </p>
                        <p>
                            {renderTimeOfImport()}
                        </p>
                        {renderChooseSIESection()}
                    </div>
                );

            case STATUSES.WRONG_YEAR_ERROR:
                return (
                    <div>
                        Den angivne SIE's regnskabsperiode med den valgte årsrapports regnskabsår. Tjek venligst om du har uploadet den rigtige SIE fil eller valgt det rigtige skatteår.
                        {renderChooseSIESection()}
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <Header>Importér kontoplan</Header>
            <ToolDescription />

            {/* Widget for SIE import */}
            <Segment color={status.color}>
                <Header
                    icon={
                        <Icon
                            name={status.icon}
                            color={status.color}
                            loading={status.loading}
                        />
                    }
                    content={
                        <>
                            Status:
                            &nbsp;
                            <ColoredText
                                color={status.color}
                                content={title}
                            />
                        </>
                    }
                />
                {renderImporterContent()}
            </Segment>
            <ColoredText
                link
                content='Skift bogføringssystem'
                iconPosition='left'
                color='blue'
                icon='exchange'
                onClick={onUnregisterERP}
            />
        </>
    );
};

export default SIEImporter;