import { createBrowserHistory } from 'history';
import { makeQueryParams } from 'util/QueryParams';

const history = createBrowserHistory();

export const paymentRedirect = ({ product, taxYear, redirect, clickRef, hash }) => {
    // add default redirect
    if (!redirect) {
        redirect = window.location.pathname;
    }

    // apply hash to redirect url (if provided)
    if (hash) {
        redirect += `#${hash}`;
    }
    
    // build params
    const params = makeQueryParams({
        redirect,
        clickRef,
    });

    // do payment redirect
    history.push(`/betaling/${product}/${taxYear}${params}`);
};

export default history;
