import ColoredText from 'design/atoms/ColoredText';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

function FlowUpdateButton ({ onUpdate }) {
    const [working, setWorking] = useState(false);

    return (
        <ColoredText
            link
            underlined={false}
            content='Updater flow'
            iconPosition='left'
            icon='sync'
            loading={working}
            disabled={working}
            onClick={async () => {
                try {
                    setWorking(true);
                    await onUpdate();
                } catch {
                    toast.error('Kunne ikke opdatere signaturflowet');
                } finally {
                    setWorking(false);
                }
            }}
        />
    );
}

export default FlowUpdateButton;