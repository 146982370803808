import React, { useEffect, useState } from 'react';
import { Button, Icon, Modal, Popup } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { getLoginEmailByLoginID } from 'http/accounts';
import ColoredText from 'design/atoms/ColoredText';
import { formatDateTime } from 'util/format/DateTime';
import DeleteIcon from './DeleteIcon';

const emailCache = {};
const getEmailByLogin = loginID => {
    if (loginID in emailCache) {
        return emailCache[loginID];
    }

    return emailCache[loginID] = getLoginEmailByLoginID(loginID);
};

const AuthorPopup = ({ loginID }) => {
    const [loginEmail, setLoginEmail] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!loginID) return;

        getEmailByLogin(loginID)
            .then(email => setLoginEmail(email))
            .finally(() => setLoading(false));
    }, [loginID]);
    
    if (!loginID) {
        return null;
    }

    return (
        <Popup
            position='top center'
            trigger={
                <Icon name='user' />
            }
            content={
                loading ? <Icon name='spinner' loading /> : loginEmail
            }
        />
    );
};

const CommentsViewer = ({ accountInfo, updateAccountInfo, accountNumber }) => {
    const comments = accountInfo?.comments || [];

    const [isCreatingComment, setIsCreatingComment] = useState(comments.length === 0);
    const [newCommentText, setNewCommentText] = useState('');
    const [commentBeingEditedIndex, setCommentBeingEditedIndex] = useState(-1);
    const [working, setWorking] = useState(false);

    useEffect(() => {
        if (!isCreatingComment) {
            setNewCommentText('');
            setWorking(false);
            setCommentBeingEditedIndex(-1);
        }
    }, [isCreatingComment]);

    const handleDeleteComment = async commentIdx => {
        await updateAccountInfo(accountNumber, {
            ...accountInfo,
            comments: comments.filter((_, idx) => idx !== commentIdx),
        });
    };

    const renderComments = () => {
        if (comments.length === 0) {
            return <ColoredText color='grey' content='Ingen noter oprettet...' italic />;
        }
        
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    gap: '1em',
                }}
            >
                {comments.map(({ timestamp, message, creatorLoginID }, idx) => (
                    <div
                        style={{
                            background: 'rgb(240, 240, 240)',
                            border: '1px solid lightgray',
                            borderRadius: '1em',
                            padding: '1em',
                            position: 'relative',
                        }}
                    >
                        <div style={{ whiteSpace: 'pre-wrap' }}>{message}</div>
                        <ColoredText color='grey' style={{ opacity: 0.8 }}>
                            <AuthorPopup loginID={creatorLoginID} />
                            {formatDateTime(timestamp)}
                        </ColoredText>
                            <div style={{
                                position: 'absolute',
                                top: '0.5em',
                                right: '0.5em',
                                cursor: 'pointer',
                            }}>
                                <Icon name='pencil' onClick={() => {
                                    setNewCommentText(message);
                                    setCommentBeingEditedIndex(idx);
                                    setIsCreatingComment(true);
                                }} />
                                <DeleteIcon
                                    text='Slet note'
                                    onDelete={() => handleDeleteComment(idx)}
                                />
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    if (isCreatingComment) {
        const handleCreateNote = async () => {
            if (!newCommentText) {
                toast.error('Indtast note');
                return;
            }

            setWorking(true);

            if (commentBeingEditedIndex === -1) {
                await updateAccountInfo(accountNumber, {
                    ...accountInfo,
                    comments: [...comments, {
                        timestamp: new Date(),
                        message: newCommentText,
                    }],
                });
            } else {
                await updateAccountInfo(accountNumber, {
                    ...accountInfo,
                    comments: comments.map((comment, idx) => {
                        if (idx !== commentBeingEditedIndex) {
                            return comment;
                        }
                        
                        return { ...comment, message: newCommentText };
                    }),
                });
            }
            
            setIsCreatingComment(false);
        };

        return (
            <>
                <Modal.Content>
                    <textarea
                        autoFocus
                        placeholder='Indtast note...'
                        disabled={working}
                        defaultValue={newCommentText}
                        onChange={e => setNewCommentText(e.target.value)}
                        style={{
                            resize: 'vertical',
                            height: '200px',
                            width: '100%',
                            outline: 'none',
                        }}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='black'
                        content='Annuller'
                        onClick={() => setIsCreatingComment(false)}
                    />
                    <Button
                        primary
                        icon='plus'
                        content='Gem note'
                        disabled={working}
                        loading={working}
                        onClick={() => handleCreateNote()}
                    />
                </Modal.Actions>
            </>
        );
    } 

    return (
        <>
            <Modal.Content scrolling>
                {renderComments()}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content='Ny note'
                    icon='plus'
                    onClick={() => setIsCreatingComment(true)}
                />
            </Modal.Actions>
        </>
    );
};

export default CommentsViewer;