import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    title: i18n({
        [daDK]: 'Arkiv: <product>, <variation> <year>',
        [enUS]: 'Archive: <product>, <variation> <year>',
    }),
    baseInfo: i18n({
        [daDK]: 'Grundlæggende oplysninger',
        [enUS]: 'Basic information',
    }),
    productInfo: i18n({
        [daDK]: 'Skatteår <year>',
        [enUS]: 'Tax year <year>',
    }),
    saveAsPdfLabel: i18n({
        [daDK]: "Gem som PDF",
        [enUS]: "Save as PDF",
    }),
    seeAttachments: i18n({
        [daDK]: "Se bilag",
        [enUS]: "See attachments",
    }),
    goBack: i18n({
        [daDK]: "Tilbage",
        [enUS]: "Go back",
    }),
};