import i18n from '../../i18n';
import lc from '../../languageCodes';
import RegisterForm from './RegisterForm';

const { daDK, enUS } = lc;

const RedirectNotice = {
    message: i18n({
        [daDK]: 'Er du allerede oprettet som bruger:',
        [enUS]: 'If you are already registered:',
    }),
    link: i18n({
        [daDK]: 'Login her',
        [enUS]: 'Login here',
    }),
};

export default {
    title: i18n({
        [daDK]: 'Opret gratis bruger',
        [enUS]: 'Create free account',
    }),
    paymentTitle: i18n({
        [daDK]: 'Betaling',
        [enUS]: 'Payment',
    }),
    subtitle: i18n({
        [daDK]: 'Prøv gratis uden binding.',
        [enUS]: 'Try for free without entering payment details.',
    }),
    errorHeader: i18n({
        [daDK]: 'Fejl',
        [enUS]: 'Error',
    }),
    RedirectNotice,
    RegisterForm,
};
