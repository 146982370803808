import React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { getCurrentEntityChatEntries } from 'http/api';
import useRestResource from 'util/useRestResource';
import ChatHistoryViewer from 'design/molecules/ChatHistoryViewer';


const EntityChatHistory = () => {
    const { data, loading, error } = useRestResource({
        fetcher: getCurrentEntityChatEntries,
    });

    if (loading) {
        return <Loader inline='centered' active />;
    }

    if (error) {
        return <Message color='orange' content='Kunne ikke indlæse chathistorik...' />;
    }

    return <ChatHistoryViewer chatEntries={data} />;
};

export default EntityChatHistory;