import React from 'react';
import { Icon, Label, Popup } from 'semantic-ui-react';
import useUser from 'util/useUser';

export default () => {
    const user = useUser();

    const getEntityTextVariations = () => {
        if (user.cvr) {
            if (user.isSelskab()) {
                return {
                    pluralIndefinite: 'selskaber',
                    singularDefiniteGenitive: 'selskabets',
                    singularDefinite: 'selskabet',
                };
            }
            
            return {
                pluralIndefinite: 'virksomheder',
                singularDefiniteGenitive: 'virksomhedens',
                singularDefinite: 'virksomheden',
            };
        }

        return {
            pluralIndefinite: 'boligudlejere',
            singularDefiniteGenitive: 'udlejningens',
            singularDefinite: 'boligudlejeren',
        };
    };

    const textVariations = getEntityTextVariations();

    const popupContent = <span>
        Dette svar er gældende for langt de fleste {textVariations.pluralIndefinite},
        men vi kan reelt ikke aflæse det ud fra {textVariations.singularDefiniteGenitive} tal.<br />
        Derfor er det op til {textVariations.singularDefinite} selv at svare korrekt, hvis det skulle afvige fra standardsvaret.
    </span>;

    const label = <Label
        content={<span>Standardsvar <Icon name='question circle' /></span>}
        style={{ marginLeft: '4px' }}
        size='small'
        color='blue'
        basic
    />;

    return <Popup
        trigger={label}
        content={popupContent}
        position='top center'
    />;
};