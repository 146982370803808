import React                                       from 'react';
import compose                                     from 'lodash.flowright';
import { withRouter }                              from 'react-router-dom';
import { Icon, Container, Segment }                from 'semantic-ui-react';
import i18n                                        from 'i18n/pages/Frontpage';
import FormBox                                     from 'design/atoms/FormBox';
import AccountantOverview                          from 'design/molecules/AccountantOverview';
import ProductPage                                 from 'design/molecules/ProductPage';
import ProductDisplayer                            from 'design/molecules/ProductsDisplayer';
import withUserData                                from 'util/withUserData';
import getProducts                                 from 'util/getProducts';
import createProductHomeLink                       from 'util/createProductHomeLink';
import {
    hasProductAccess,
    isAllowedToViewProduct,
} from 'util/userMethods';
import PageHeader from 'design/atoms/PageHeader';

class Home extends React.Component {
    state = {
        loading: true,
        products: [],
    };

    componentDidMount = async () => {
        if (!this.showAccountantOverview()) {
            await this.fetchProducts();
        }
        this.setState({ loading: false });
    };

    componentDidUpdate = async () => {
        if (!this.showAccountantOverview()) {
            await this.fetchProducts();
        }
    };

    fetchProducts = async () => {
        if (this.state.products.length > 0) {
            return;
        }
        const products = await getProducts();
        this.setState({ products }, this.redirectIfOneProduct);
    };

    showAccountantOverview = () => {
        const user = this.props.userData;

        if (!user.isAccountant()) {
            return false;
        }

        if (user.impersonation) {
            return false;
        }

        return true;
    };

    isAllowedToViewProduct = product => {
        const user = this.props.userData;

        // @todo: remove
        // special case
        // we're shutting down solceller && vaerelsesudlejning
        // for all users who haven't bought the products already
        if (['solceller', 'vaerelsesudlejning'].includes(product.id)) {
            if (!hasProductAccess(user, product.id)) {
                return false;
            }
        }

        return isAllowedToViewProduct(user, product);
    };

    categorizeProducts = () => {
        const user = this.props.userData;
        const bought = [];
        const notBought = [];
        const all = [];
        for (let product of this.state.products) {
            if (!this.isAllowedToViewProduct(product)) {
                continue;
            }

            if (hasProductAccess(user, product.id)) {
                product.bought = true;
                bought.push(product);
            } else {
                product.bought = false;
                notBought.push(product);
            }

            all.push(product);
        }
        return { bought, notBought, all };
    };

    redirectIfOneProduct = () => {
        const { history } = this.props;
        const { bought, notBought } = this.categorizeProducts();
        const allProds = [ ...bought, ...notBought ];
        if (allProds.length === 1) {
            const product = allProds[0];
            history.push(createProductHomeLink(product));
        }
    };

    renderNoProductsMessage = () => {
        const { businessForm } = this.props.userData;
        return <Container textAlign='center'>
            <br />
            <FormBox>
                <Icon
                    color='grey'
                    name='folder open'
                    size='huge'
                    circular
                />
                <h1>{i18n.noProductsHeader}</h1>
                <p>{i18n.noProductsBody} <b><i>{businessForm}</i></b>.</p>
            </FormBox>
        </Container>;
    };

    renderContent = () => {
        const { loading } = this.state;
        const { userData } = this.props;

        if (loading) {
            return null;
        }

        // render special frontpage for accountants
        if (this.showAccountantOverview()) {
            return <AccountantOverview user={userData} />;
        }

        const { all } = this.categorizeProducts();
        
        // no products available?
        if (all.length === 0) {
            return this.renderNoProductsMessage();
        }

        return (
            <Segment basic textAlign='center'>
                <ProductDisplayer products={all} />
            </Segment>
        );
    };

    renderSubHeader = () => {
        if (this.showAccountantOverview()) {
            return (
                <PageHeader
                    content='Klientliste'
                    icon='users'
                />
            );
        }

        return (
            <PageHeader
                content='Overblik'
                icon='search'
            />
        );
    };

    render () {
        const { loading } = this.state;

        return <ProductPage loading={loading} subHeader={this.renderSubHeader()}>
            {this.renderContent()}
        </ProductPage>;
    }
}

export default compose(
    withUserData,
    withRouter,
)(Home);
