import React from 'react';
import { monthNamesDK } from 'util/format/DateTime';
import { ShoppingCartSegment } from '../shared/ShoppingCart';

const SubscriptionDetails = ({ subscription }) => {
    const {
        productName,
        anchorDay,
        anchorMonth,
    } = subscription;

    // convert month number => month name
    const monthName = monthNamesDK[anchorMonth - 1].toLowerCase();

    return (
        <ShoppingCartSegment icon='redo' title='Automatisk fornyelse'>
            Ved køb, tegner du et abonnement på <b style={{ whiteSpace: 'nowrap' }}>{productName}</b>,
            som fornyes automatisk hvert år d. {anchorDay} {monthName}.
        </ShoppingCartSegment>
    );
};

export default SubscriptionDetails;