import set from 'lodash.set';
import { Header, Icon, Transition } from 'semantic-ui-react';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import reportEditor from 'http/reportEditor';

import SectionView from './SectionView';
import SectionMenuItem from './SectionMenuItem';
import useRestResource from 'util/useRestResource';

const ReportEditor = ({
    documentOverrideConfiguration,
    setDocumentOverrideConfiguration,
    templateID,
    templateVersion,
    templateVariables,
    logoID,
    productID,
    productLabel,
    yearReportDataNodeID,
    isInternal,
    language,
    isFirstYear,
}) => {
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [documentComponents, setDocumentComponents] = useState(null);
    const [working, setWorking] = useState(false);

    const blocksFetcher = useCallback(async () => {
        const blocks = await reportEditor.getTemplateBlocks(templateID, templateVersion);

        const blockIdToType = {};

        const collectIdToTypeMapping = ({ block, children }) => {
            blockIdToType[block.id] = block.kind;
            for (const child of children || []) collectIdToTypeMapping(child);
        };
        
        blocks.forEach(collectIdToTypeMapping);

        return blockIdToType;
    }, [templateID, templateVersion]);

    const { data: blockIdToType, loading } = useRestResource({ fetcher: blocksFetcher });

    useEffect(() => {
        const doUpdate = async () => {
            setWorking(true);
            try {
                const { documentComponents } = await reportEditor.executeTemplate({
                    productID,
                    productLabel,
                    yearReportDataNodeID,
                    logoID,
                    isInternal,
                    language,
                    isFirstYear,
                    variables: templateVariables,
                    overrideConfiguration: documentOverrideConfiguration,
                    reportTemplateID: templateID,
                    reportTemplateVersion: templateVersion,
                });

                setDocumentComponents(documentComponents);
            } catch {
                toast.error('Der opstod en fejl');
            }
            setWorking(false);
        };

        doUpdate();
    }, [productID, productLabel, yearReportDataNodeID, isInternal, language, isFirstYear, templateID, templateVersion, logoID, templateVariables, documentOverrideConfiguration]);

    const isReady = documentComponents && !loading; 

    const renderLoader = () => {
        if (isReady) return null;

        return (
            <div style={{ width: '100%', textAlign: 'center', height: '800px' }}>
                <Header icon size='small'>
                    <Icon name='spinner' loading /> Indlæser...
                </Header>
            </div>
        );
    };
    
    const renderSectionMenu = () => {
        return (
            <Transition visible={isReady} animation='fade right' duration={350}>
                <div style={{ flex: '1', overflowY: 'auto', paddingRight: '0.5em' }}>
                    {documentComponents?.map(({ data: page }, pageIdx) => {            
                        const isHidden = (documentOverrideConfiguration?.buildingBlockOverrides || {})[page.id]?.hidden;
                        const isActive = pageIdx === currentPageIndex;

                        return (
                            <SectionMenuItem
                                isFirst={pageIdx === 0}
                                isLast={pageIdx === documentComponents.length - 1}
                                title={page.title}
                                isActive={isActive}
                                isHidden={isHidden}
                                onClick={() => setCurrentPageIndex(pageIdx)}
                                onHiddenChange={newHiddenValue => {
                                    const cpy = { ...documentOverrideConfiguration };
                                    set(cpy, ['buildingBlockOverrides', page.id, 'hidden'].join('.'), newHiddenValue);
                                    setDocumentOverrideConfiguration(cpy);
                                }}
                            />
                        );
                    })}
                </div>
            </Transition>
        );
    };

    const renderSelectedSection = () => {
        return (
            <Transition animation='fade' duration={350} visible={isReady}>
                <div
                    style={{
                        paddingLeft: '0.5em',
                        justifyContent: 'right',
                    }}
                >
                    <SectionView
                        section={documentComponents && documentComponents[currentPageIndex]}
                        documentOverrideConfiguration={documentOverrideConfiguration}
                        setDocumentOverrideConfiguration={setDocumentOverrideConfiguration}
                        blockIdToType={blockIdToType}
                        working={working}
                    />
                </div>
            </Transition>
        );
    };

    return (
        <div>
            {renderLoader()}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {renderSectionMenu()}
                {renderSelectedSection()}
            </div>
        </div>
    );
};

export default ReportEditor;