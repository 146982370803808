import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    wrongUserTypeError: {
        header: i18n({
            [daDK]: 'Forkert brugertype',
            [enUS]: 'Wrong user type',
        }),
        body: i18n({
            [daDK]: 'Du har ikke rettigheder til at se dette produkt med din brugertype',
            [enUS]: 'You are not allowed to view this product with your user type',
        }),
        goBackButton: i18n({
            [daDK]: 'Gå til forsiden',
            [enUS]: 'Go to frontpage',
        }),
    },
    noRelevantTaxYearsError: {
        header: i18n({
            [daDK]: 'Dit første regnskabsår er ikke afsluttet endnu',
        }),
        body: i18n({
            [daDK]: [
                'Du har ikke afsluttet dit første regnskabsår endnu. Du kan desværre først beregne dit første regnskabsår, efter det er afsluttet. Når dit regnskabsår er færdigt, kan du begynde at beregne dit regnskabsår her på siden.',
                'Vi arbejder ihærdigt på en løsning, der sætter dig i stand til at lave en midlertidig årsrapport, selvom regnskabsåret endnu ikke er afsluttet, så du kan få en fornemmelse af værktøjet. Det er lige på trapperne, så kig forbi en gang imellem.',
                'Har du spørgsmål til det, er du velkommen til at sende en mail eller give os et opkald.',
                'Skriv til os på support@digitalrevisor.nu eller ring til +45 53 69 41 42.',
            ].join('\n\n'),
        }),
    },
    taxYear: i18n({
        [daDK]: 'Skatteår',
        [enUS]: 'Tax year',
    }),
    edit: i18n({
        [daDK]: 'Rediger',
        [enUS]: 'Edit',
    }),
};
