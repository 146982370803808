import FieldRenderingContext from "design/pages/Product/FieldRenderingContext";
import get from "lodash.get";
import set from "lodash.set";

function createResourceRenderingContext(resource, field, values, slug, resourceFactMap, fieldDataChanged) {
    const getQuestionByFactID = factID => {
        return field.questions.find(question => question.id === factID);
    };
    
    const getMainProperty = factID => {
        return getQuestionByFactID(factID)?.mainProperty;
    };

    const getTag = factID => {
        return getQuestionByFactID(factID)?.tag;
    };
    
    // prepare change handler for this resource
    const resourceChangeHandler = (changedFactID, data, ctx) => {
        // lookup fact in global fact map
        const factLookup = get(values, `${changedFactID}.value`, {
            values: {},
            lastYearValues: {},
        });

        // get updated data
        const updatedData = get(data, getMainProperty(changedFactID));

        // do set value for current resource
        set(factLookup, `values.${slug}`, updatedData);

        // handle input of last year numbers
        if (typeof data.lastyear === 'number') {
            set(factLookup, `lastYearValues.${slug}`, data.lastyear);
        }

        // inject slug && tag into ctx
        ctx = ctx || {};
        ctx.updatedTag = getTag(changedFactID);
        ctx.updatedSlug = slug;

        // do update data
        return fieldDataChanged(changedFactID, factLookup, ctx);
    };

    // prepare "isVisible" handler for this resource
    const resourceIsVisibleHandler = (visibilityReferences, values) => {
        if (!visibilityReferences) {
            return true;
        }

        for (let referece of visibilityReferences) {
            const lookup = get(values, `${referece}.value.number`, 0);
            if (!lookup) {
                return false;
            }
        }

        return true;
    };

    // --------------------------------------------------

    // extract validation result
    const resourceValidationResult = get(resource, 'invalidFields', {});

    // prepare rendering context for current resource
    const renderingContext = new FieldRenderingContext({
        values: resourceFactMap,
        onChange: resourceChangeHandler,
        isVisible: resourceIsVisibleHandler,
        validationResult: resourceValidationResult,
        createFileUploadID: wrapper => wrapper.fieldId,
    });

    return renderingContext;
}

export {
    createResourceRenderingContext
};
