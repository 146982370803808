import set from 'lodash.set';
import get from 'lodash.get';
import { useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Input, Loader, Table } from 'semantic-ui-react';
import { formatNumber } from 'util/format/Number';
import * as productEngine from 'http/productEngine';
import Tooltip from 'design/atoms/Tooltip';
import NumberInput from 'design/atoms/NumberInput';
import { createCleanResource } from './FactWidgets/resource';

import styles from './ResourceTable.module.scss';

const TableColumnInputComponent = ({ tableColumn, onChange, defaultValue }) => {
    const { dataType, header } = tableColumn;

    const placeholder = `${header}...`;

    switch (dataType) {
        case 'number': {
            return (
                <NumberInput
                    className={styles.borderlessInput}
                    onChange={value => onChange(Number(value))}
                    value={defaultValue}
                    placeholder={placeholder}
                    fluid
                />
            );
        }

        case 'string': {
            return (
                <Input
                    className={styles.borderlessInput}
                    onChange={(_, { value }) => onChange(value)}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    fluid
                />
            );
        }

        case 'enumString': {
            return (
                <Dropdown
                    selection
                    search
                    fluid
                    style={{ border: 'none' }}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={(_, { value }) => onChange(value)}
                    options={tableColumn.questionOptions.map(({ optionText, optionValue, optionTooltip }) => {
                        return {
                            text: optionText,
                            value: optionValue,
                            description: optionTooltip,
                        };
                    })}
                />
            );
        }

        default: {
            return null;
        }
    }
};

const ResourceTable = ({ field, values, onChange }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userResources, setUserResources] = useState([]);

    const { table, resourceTemplateSlug, resourceNodeID } = field;

    const addResource = async () => {
        const clean = createCleanResource();
        await productEngine.upsertResource(resourceTemplateSlug, clean);
        const fromServer = await productEngine.getResources(resourceTemplateSlug);
        setUserResources(fromServer);
    };

    const deleteResource = async resourceSlug => {
        await productEngine.deleteUserResource(resourceSlug);
        setUserResources(await productEngine.getResources(resourceTemplateSlug));
    };

    useEffect(() => {
        Promise.all([
            productEngine.getResources(resourceTemplateSlug),
        ])
            .then(([userResources]) => {
                setUserResources(userResources);
            })
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [resourceTemplateSlug]);

    const colSpan = table.columns.length + 1;

    const resourcesData = get(values, `${resourceNodeID}.value.resourcesData`, {});

    return (
        <Table compact='very' celled>
            <Table.Header>
                <Table.Row>
                    {table.columns.map(column => {
                        return (
                            <Table.HeaderCell>
                                {column.header}{' '}
                                <Tooltip
                                    triggerOverwrite={<Icon color='grey' name='question circle' />}
                                />
                            </Table.HeaderCell>
                        );
                    })}
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {loading && (
                    <Table.Row>
                        <Table.Cell colSpan={colSpan} textAlign='center'>
                            <Loader inline active />
                            <br />
                            Indlæser...
                        </Table.Cell>
                    </Table.Row>
                )}

                {error && (
                    <Table.Row>
                        <Table.Cell colSpan={colSpan} textAlign='center' error>
                            <Icon name='warning sign' /> Data kunne ikke indlæses...
                        </Table.Cell>
                    </Table.Row>
                )}

                {userResources.map(userResource => {
                    return (
                        <Table.Row key={userResource.slug}>
                            {table.columns.map(column => {
                                let componentToShow;
                                let cellBackgroundColor;

                                if (column.isQuestion) {
                                    const valueMap = get(values, `${column.questionFactID}.value`, { values: {}, lastYearValues: {} });
                                    const defaultValue = get(valueMap, `values.${userResource.slug}`);

                                    componentToShow = (
                                        <TableColumnInputComponent
                                            tableColumn={column}
                                            onChange={value => {
                                                set(valueMap, `values.${userResource.slug}`, value);
                                                onChange(column.questionFactID, valueMap);
                                            }}
                                            defaultValue={defaultValue}
                                        />
                                    );
                                } else {
                                    // not a question, treat as calculation
                                    const value = get(resourcesData, `${userResource.slug}.calculationResults.${column.tag}`, 0);
                                    cellBackgroundColor = 'rgb(250, 250, 250)';
                                    componentToShow = (
                                        <span style={{ padding: '1em 1em' }}>
                                            {(
                                                Math.abs(Number(value)) < 1
                                                    ? new Intl.NumberFormat('da-DK').format(value)
                                                    : formatNumber(value)
                                            )}
                                        </span>
                                    );
                                }

                                return (
                                    <Table.Cell
                                        textAlign='right'
                                        style={{ padding: 0, background: cellBackgroundColor }}
                                        content={componentToShow}
                                    />
                                );
                            })}
                            <Table.Cell textAlign='right' width={1} style={{ background: 'rgb(250, 250, 250)' }}>
                                <Icon name='trash' link onClick={() => deleteResource(userResource.slug)} />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan={colSpan} textAlign='right'>
                        <Button onClick={addResource} content='Tilføj' icon='plus' />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

export default ResourceTable;