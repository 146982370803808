import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { useMediaQuery as _useMediaQuery, Context as MediaContext } from 'react-responsive';
import isTestMode from 'util/test-utils/isTestMode';

// wrapper around "useMediaQuery" from "react-responsive"
// "useMediaQuery" will always return false when using jest-dom
const useMediaQuery = (query) => {
    if (isTestMode()) {
        const screenWidth = window.innerWidth;
        const minWidth = get(query, 'minWidth');
        const maxWidth = get(query, 'maxWidth');

        // check lower boundery (if provided)
        if (minWidth !== undefined && screenWidth < minWidth) {
            return false;
        }

        // check upper boundary (if provided)
        if (maxWidth !== undefined && screenWidth > maxWidth) {
            return false;
        }

        return true;
    }
    
    // use real library function
    return _useMediaQuery(query);
};

const MediaComponent = ({ minWidth, maxWidth, children }) => {
    const isVisible = useMediaQuery({ minWidth, maxWidth });
    return isVisible && children;
};

const breakpointNames = {
    mobile: 'mobile',
    tablet: 'tablet',
    computer: 'computer',
};

export const breakpoints = {
    [breakpointNames.mobile]: {
        min: 0,
        max: 764,
    },
    [breakpointNames.tablet]: {
        min: 765,
        max: 974,
    },
    [breakpointNames.computer]: {
        min: 975,
        max: Number.MAX_SAFE_INTEGER,
    },
};

const getBreakpoint = width => {
    for (let [key, { min, max }] of Object.entries(breakpoints)) {
        if (width >= min && width <= max) {
            return key;
        }
    }
};

const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return width;
};

const doSelectionOperation = (operator, a, b) => {
    const handler = {
        eq:  () => a === b,
        gt:  () => a > b,
        gte: () => a >= b,
        lt:  () => a < b,
        lte: () => a <= b,
    }[operator];
    return handler && handler();
};

export const useBreakpoints = () => {
    const width = useWindowWidth();
    const [currentBreakpoint, setCurrentBreakpoint] = useState(getBreakpoint(width));

    useEffect(() => {
        const newBreakpoint = getBreakpoint(width);
        if (newBreakpoint !== currentBreakpoint) {
            setCurrentBreakpoint(newBreakpoint);
        }
    }, [width, currentBreakpoint]);

    return {
        isMobile: currentBreakpoint === breakpointNames.mobile,
        isTablet: currentBreakpoint === breakpointNames.tablet,
        isComputer: currentBreakpoint === breakpointNames.computer,
        current: currentBreakpoint,
        minWidth: breakpoints[currentBreakpoint].min,
        maxWidth: breakpoints[currentBreakpoint].max,
        select: caseMap => {
            let defaultValue;

            for (let [caseExpression, out] of Object.entries(caseMap)) {
                // save default value to return if no other matches
                if (caseExpression === 'default') {
                    defaultValue = out;
                    continue;
                }

                const [operator, breakpoint] = caseExpression.split(' ');
                const targetValue = Object.keys(breakpoints).indexOf(breakpoint);
                const currentValue = Object.keys(breakpoints).indexOf(currentBreakpoint);

                if (doSelectionOperation(operator, currentValue, targetValue)) {
                    return out;
                }
            }

            return defaultValue;
        },
    };
};

export const withBreakpoints = Component => {
    return props => {
        const breakpoints = useBreakpoints();
        return <Component breakpoints={breakpoints} {...props} />;
    };
};

/**
 * Breakpoints
 * @typedef {'mobile'|'tablet'|'computer'} screenType
 */

export function withMediaQuery(WrappedComponent) {
    return props => {
        return <MediaContext.Consumer>
            { media => <WrappedComponent {...props} media={media} /> }
        </MediaContext.Consumer>
    };
}

/**
 * 
 * @param {object} props    
 * @param {screenType} props.gt
 * @param {screenType} props.gte
 * @param {screenType} props.lt
 * @param {screenType} props.lte
 * @param {screenType} props.eq
 * @returns 
 */
export function Media({ gt, gte, lt, lte, eq, children }) {
    // if equality operation is provided, ignore other props
    if (eq) {
        const { min, max } = breakpoints[eq];
        return <MediaComponent
            minWidth={min}
            maxWidth={max}
            children={children}
        />;
    }

    // define boundary
    let min;
    let max;

    if (gt) {
        min = breakpoints[gt].min + 1;
    } else if (gte) {
        min = breakpoints[gte].min;
    }

    if (lt) {
        max = breakpoints[lt].min;
    } else if (lte) {
        max = breakpoints[lte].min + 1;
    }

    return <MediaComponent
        minWidth={min}
        maxWidth={max}
        children={children}
    />;
}