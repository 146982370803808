import React from 'react';
import { Segment, Container } from 'semantic-ui-react';

import heroImage from 'images/login_hero.webp';
import logo from 'images/digital-revisor/digital-revisor-big-logo.svg';

import styles from './HeroForm.module.scss';
import { Media } from '../Media';

const HeroForm = ({ children }) => {
    return (
        <>
            <Media gte='computer'>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <img className={styles.logo} src={logo} alt='Logo' size='small' />
                        <img src={heroImage} alt='Login' className={styles.heroImage} />
                    </div>
                    <div className={styles.right}>
                        <Segment className={styles.form}>
                            {children}
                        </Segment>
                    </div>
                </div>
            </Media>
            <Media lt='computer'>
                <Container>
                    <Segment>
                        <div>
                            <img src={logo} alt='Logo' />
                        </div>
                        <br />
                        {children}
                    </Segment>
                </Container>
                
            </Media>
        </>
    );
};

export default HeroForm;
