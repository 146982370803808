import React from 'react';
import { isAccountant, isAdmin, isHolding, isSwedish } from 'util/userMethods';
import withUserData from 'util/withUserData';
import ManualReviewBooker from './ManualReviewBooker';
import PlanEditor from './PlanEditor';
import CSVPlanImporter from './CSVPlanImporter';
import SIEImporter from './SIEImporter';

const ManualAccounting = ({ userData, ...props }) => {
    if (isSwedish(userData)) {
        return <SIEImporter {...props} />;
    }

    // accountants and admins: edit account plan view
    if (isAdmin(userData) || isAccountant(userData)) {
        const values = props?.values || {};
        const accountPlan = values[props?.id]?.value;

        if (!isHolding(userData) || accountPlan?.useCsvImporter) {
            return (
                <CSVPlanImporter
                    {...props}
                />
            );
        }
        
        return (
            <PlanEditor
                {...props}
            />
        );
    }

    // normal users: make appointments & view status
    return <ManualReviewBooker {...props} />;
};

export default withUserData(ManualAccounting);