import React from 'react';
import {
  Button,
  Image,
  Icon,
  Table,
  Dropdown,
} from 'semantic-ui-react'
import styles from './UiElementAutomatedBooking.module.scss'
import withUserData from 'util/withUserData';
import { getMetadata, book, getJournals } from 'http/erpBroker';
import { getReportingPeriodPair } from 'http/financial-dates';
import { formatNumber } from 'util/format/Number';
import { ISOFromYearMonthDay } from 'util/format/DateTime';
import erpSystems from 'util/erpSystems';
import { isErhverv, isAccountant } from 'util/userMethods';

// TODO: provide error details through response.body
// instead of in the response message
// https://gitlab.com/Digital-Revisor/erp-broker/-/issues/36
export const extractBookingError = (errorMessage) => {
  // Format: <status code message>, reason: <error code message>
  const match = errorMessage.match(/.*, reason: (.*)/);
  if (!match) {
    return '';
  }

  return match[1];
};

const targetsMetadata = {
  CORPORATE_TAX: {
    header: {
      minuendHeader: 'Beregnet skat',
      subtrahendHeader: 'Bogført skat',
      differenceHeader: 'Forskellen som vil blive bogført',
    },
    info: {
      name: 'selskabsskatten',
      requiredAccounts: {
        CurrentTaxExpense: {
          type: 'drift',
          text: 'Beregnet skat af årets skattepligtige indkomst'
        },
        ShorttermTaxPayables: {
          type: 'balance',
          text: 'Kortfristet skyldig skat'
        }
      }
    },
    tags: {
      minuendTag: 'BeregnetAaretsSkat',
      subtrahendTag: 'CurrentTaxExpense',
      accountTag: 'CurrentTaxExpense',
      balancingAccountTag: 'ShorttermTaxPayables',
    },
  },
  DEFERRED_TAX: {
    header: {
      minuendHeader: 'Beregnet udskudt skat',
      subtrahendHeader: 'Bogført udskudt skat',
      differenceHeader: 'Regulering af udskudt skat som vil blive bogført',
    },
    info: {
      name: 'udskudt skat',
      requiredAccounts: {
        AdjustmentsForDeferredTax: {
          type: 'drift',
          text: 'Årets regulering af udskudt skat'
        },
        ProvisionsForDeferredTax: {
          type: 'balance',
          text: 'Hensættelser til udskudt skat'
        }
      }
    },
    tags: {
      minuendTag: 'BeregnetUdskudtSkat',
      subtrahendTag: 'ProvisionsForDeferredTax',
      accountTag: 'ProvisionsForDeferredTax',
      balancingAccountTag: 'AdjustmentsForDeferredTax',
    },
  },
  DIVIDEND: {
    header: {
      minuendHeader: 'Ønsket udbytte',
      subtrahendHeader: 'Bogført udbytte',
      differenceHeader: 'Forskellen som vil blive bogført',
    },
    info: {
      name: 'udbytte',
      requiredAccounts: {
        RetainedEarnings: {
          type: 'balance',
          text: 'Overført resultat'
        },
        ProposedDividendRecognisedInEquity: {
          type: 'balance',
          text: 'Foreslået udbytte indregnet under egenkapitalen'
        }
      }
    },
    tags: {
      minuendTag: 'ProposedDividendRecognisedInEquityMember',
      subtrahendTag: 'ProposedDividendRecognisedInEquity',
      accountTag: 'RetainedEarnings',
      balancingAccountTag: 'ProposedDividendRecognisedInEquity',
    },
  }
}

class UiElementAutomatedBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      awaitingBooking: false,
      errorMessages: null,
      loading: true,
      journals: undefined,
      selectedJournalID: undefined,
    }
  }

  static activeFetchJournalsRequest = null;

  componentDidMount = async () => {
    const { erp } = this.props.userData;
    const erpSystem = erpSystems[erp];

    if (!erpSystem.allowChoosingJournalForBooking) {
      return this.setState({ loading: false });
    }
    
    // Ensures only one call for fetching journals is active at a time
    // as multiple "UiElementAutomatedBooking" will be rendered at once
    if (!UiElementAutomatedBooking.activeFetchJournalsRequest) {
      UiElementAutomatedBooking.activeFetchJournalsRequest = getJournals();
    }
    
    let journals;
    try {
      journals = await UiElementAutomatedBooking.activeFetchJournalsRequest;
    } catch {/* ignore, use standard approach */}
    UiElementAutomatedBooking.activeFetchJournalsRequest = null;

    this.setState({
      loading: false,
      journals,
    });
  };

  getNodeById = (id) => {
    const node = Object.values(this.props.values).find(node => node.id === id);
    return node;
  }

  getNodeByTag = (tag) => {
    const node = Object.values(this.props.values).find(node => node.tag === tag);
    return node;
  }

  getTags = () => {
    const { target } = this.props.automatedBooking;
    return targetsMetadata[target].tags;
  }

  getAccountPlanNode = () => {
    return this.getNodeByTag('AccountPlans');
  }

  getAccountLinesByTag = (tag) => {
    return this.getAccountPlanNode().value.accounts.filter(accountLine => (tag === (accountLine.taxonomy.overrideValue || accountLine.taxonomy.value)) && ['Balance', 'Drift'].includes(accountLine.accountType));
  };

  isAccountPlanApproved = () => {
    const accountPlanNode = this.getAccountPlanNode();
    return accountPlanNode.value.needsRevision ? accountPlanNode.value.revised : true;
  };

  analyseMapping = () => {
    const { accountTag, balancingAccountTag } = targetsMetadata[this.props.automatedBooking.target].tags;
    const analysis = {};
    [accountTag, balancingAccountTag].forEach(tag => analysis[tag] = this.getAccountLinesByTag(tag));
    return analysis;
  };

  isAccountPlanMappingValid = (analysis) => Object.values(analysis).every(x => x.length === 1);

  getMinuend = () => {
    const { minuendTag } = this.getTags();
    return this.getNodeByTag(minuendTag)?.value?.number || 0;
  }

  getSubtrahend = () => {
    const { subtrahendTag } = this.getTags();
    if(this.props.automatedBooking.target === 'DEFERRED_TAX') {
      const accountLines = this.getAccountLinesByTag(subtrahendTag);
      return accountLines.length > 0 ? Number(accountLines[0].amount) : 0;
    } else {
      return this.getNodeByTag(subtrahendTag)?.value?.number || 0;
    }
  }

  calculateDifference = () => {
    return this.getMinuend() - this.getSubtrahend();
  };

  toDanishFormat = (n) => {
    return formatNumber(n) + ' kr.'
  }

  triggerFetchFigures = async () => {
    const { id } = this.getAccountPlanNode();
    const trigger = this.getNodeById(`${id} trigger`);
    await this.props.onChange(trigger.id, {
      number: Date.now(),
    });
  };

  getFeedback = () => {
    const buildFeedbackElement = (color, icon, loading, feedback) => {
      return (
        <div className={styles['feedback-container']}>
          <Icon color={color} name={icon} loading={loading}/>
          <span className={styles['feedback-text']}>{feedback}</span>
        </div>
      );
    };
      
    const { userData: user } = this.props;
    const erhvervUser = isErhverv(user);
    const accountantUser = isAccountant(user);
    
    const mappingAnalysis = this.analyseMapping();
    const accountPlanMappingValid = this.isAccountPlanMappingValid(mappingAnalysis);
    const accountPlanApproved = this.isAccountPlanApproved();
    const zeroDifference = this.calculateDifference() === 0;

    const { awaitingBooking } = this.state;

    let color = 'yellow';
    let icon = 'warning circle';
    let feedback;
    let accountPlanIsCorrect = false;
    let highLighted = (text) => <span style={{fontWeight: 'bold'}}>{text}</span>;

    if(!accountPlanApproved) {
       if(accountantUser) {
        feedback = 'Du skal have mapningen på plads før du kan bogføre';
      } else if(erhvervUser) {
        feedback = 'Vi kigger din kontoplan igennem, du kan først bogføre når den er på plads';
      }
    }  else if(!accountPlanMappingValid) {
      if(accountantUser) {
        const targetInfo = targetsMetadata[this.props.automatedBooking.target].info;
        const accountsMissing = Object.values(mappingAnalysis).every(x => x.length === 0);
        if(accountsMissing) {
          const intro = `For at kunne bogføre ${targetInfo.name}, skal følgende konti tilføjes i kontoplanen:`;
          const outro = `OBS! Det er vigtigt, at der kun er én konto der er mappet med hvert tag til denne feature.`;
          feedback = <div>
            <span>{intro}</span><br/>
            <ul>{ Object.values(targetInfo.requiredAccounts).map(({type, text}) => <li>{`${type.charAt(0).toUpperCase() + type.substring(1)}: "${text}"`}</li>) }</ul>
            <span>{outro}</span>
          </div>;
        } else {
          const requiredAccounts = Object.values(targetInfo.requiredAccounts).map(({ text }) => `"${text}"`);
          const zeroAccountCount = Object.values(mappingAnalysis).filter((accounts) => accounts.length === 0).length; // mapped 0 accounts to tag
          const manyAccountCount = Object.values(mappingAnalysis).filter((accounts) => accounts.length >= 2).length; // mapped 2 or more accounts to tag
          let intro, outro;
          if(zeroAccountCount > 0 && manyAccountCount > 0) {
            // mixed case
            intro = <> Der skal være én mapning med {highLighted(requiredAccounts.join(' og '))}. Vi kan se, at </>;
            outro = <> Tilføj den manglende konto i mapningen. Du skal derudover sikre dig, at der kun er én konto med {highLighted(requiredAccounts.join(' og '))}.</>;
          } else if(zeroAccountCount > 0 && manyAccountCount === 0) {
            // only zero accounts
            intro = <>Der skal være én mapning med {highLighted(requiredAccounts.join(' og '))}. Vi kan se, at </>;
            if(zeroAccountCount === 1) {
              outro = <>Tilføj den manglende konto i mapningen.</>;
            } else {
              outro = <>Tilføj de manglende konti i mapningen.</>;
            }
          } else {
            // only many accounts
            intro = <> Der må kun være være én mapning med {highLighted(requiredAccounts.join(' og '))}. Vi kan se, at </>;
            outro = <>  {highLighted("Det skal ændres i mapningen")} så der kun er én konto med {highLighted(requiredAccounts.join(' og '))}.</>;
          }
          feedback = <div style={{paddingRight: '15px'}}>
            <span>{intro}</span>
            { Object.entries(mappingAnalysis).filter(([_, accounts]) => accounts.length !== 1).map(([xbrlTag, accounts]) => {
              const x = accounts.length === 0 ? `ingen konto mappet` : `mappet konti nr. ${accounts.map(x => x.number).join(' og ')}`;
              return <span>{` der er `}{highLighted(x)}{` med "${targetInfo.requiredAccounts[xbrlTag].text}"`}</span>
            }) }
            <span>{outro}</span>
          </div>;
        }
      } else if(erhvervUser) {
        feedback = `Årsrapporten er ikke opsat korrekt, kontakt os i supporten.`;
      }
    } else if(zeroDifference) {
      feedback = 'Der er ingen forskel at bogføre';
      icon = 'check circle';
      color = 'green';
    } else {
      accountPlanIsCorrect = true;
      
      if (awaitingBooking) {
        feedback = 'Dine tal bogføres ...';
        icon = 'spinner';
        color = 'black';
      } else if (this.userMustSelectJournal() && !this.state.selectedJournalID) {
        if (this.state.journals.length === 0) {
          feedback = 'Vi kan ikke finde en kassekladde i bogføringssystem at bogføre i';
        } else {
          feedback = 'Vælg en kassekladde at bogføre i';
          icon = 'info circle';
          color = 'blue';
        }
      }
    
     else {
      accountPlanIsCorrect = true;
      
      if (awaitingBooking) {
        feedback = 'Dine tal bogføres ...';
        icon = 'spinner';
        color = 'black';
      } else if (this.userMustSelectJournal() && !this.state.selectedJournalID) {
        if (this.state.journals.length === 0) {
          feedback = 'Vi kan ikke finde en kassekladde i bogføringssystem at bogføre i';
        } else {
          feedback = 'Vælg en kassekladde at bogføre i';
          icon = 'info circle';
          color = 'blue';
        }
      } else {
        feedback = 'Klar til at bogføre';
        icon = 'check';
        color = 'green';
      }
    }
  }
    return {
      feedback: buildFeedbackElement(color, icon, awaitingBooking, feedback),
      accountPlanIsCorrect,
    };
  };

  userMustSelectJournal = () => {
    if (!this.state.journals) {
      return false;
    }

    const standardJournalFound = this.state.journals.some(journal => journal.isStandard);
    if (standardJournalFound) {
      return false;
    }

    return true;
  };

  isBookingDisabled = () => {
    if (this.state.loading) {
      return true;
    }

    if (this.userMustSelectJournal() && !this.state.selectedJournalID) {
      return true;
    }

    if(!this.isAccountPlanApproved()) {
      return true;
    } else {
      const mappingAnalysis = this.analyseMapping();
      return !this.isAccountPlanMappingValid(mappingAnalysis) || this.calculateDifference() === 0;
    }
  };

  book = async () => {
    const { awaitingBooking } = this.state;
    if(awaitingBooking) return;

    const { automatedBooking, taxYear, productLabel } = this.props;
    const difference = this.calculateDifference()
    this.setState({
      awaitingBooking: true,
      errorMessages: null,
    });
    const { text } = await getMetadata(automatedBooking.target, taxYear)
    const { accountTag, balancingAccountTag } = targetsMetadata[automatedBooking.target].tags;
    const [ accountLine, balancingAccountLine ] = [
      ...this.getAccountLinesByTag(accountTag),
      ...this.getAccountLinesByTag(balancingAccountTag),
    ];

    const reportingPeriodPair = await getReportingPeriodPair(taxYear, productLabel);
    const { start, end } = reportingPeriodPair.matchingPeriod;
    const [startDate, endDate] = [start, end].map(ISOFromYearMonthDay);
    let bookingSuccessful = false;
    try {
      await book({
        date: endDate,
        text,
        amount: difference,
        accountNumber: accountLine.number,
        balancingAccountNumber: balancingAccountLine.number,
        accountVatCode: accountLine.vatcode,
        taxYearPeriod: `${startDate} - ${endDate}`,
      }, this.state.selectedJournalID);
      bookingSuccessful = true;
    } catch(error) {
      const bookingValidationErrors = error.data;

      if (bookingValidationErrors) {
        const accountNumberErrors = Object.entries(bookingValidationErrors.errorMessagesByAccountNumber).flatMap(([accountNumber, errors]) => {
          return errors.map(error => `Vedr. kontonr. ${accountNumber}: ${error}`);
        });

        const errorMessages = [...bookingValidationErrors.generalErrorMessages, ...accountNumberErrors];
        this.setState({ errorMessages })
      } else {
        const errorMessage = extractBookingError(error.message) || 'Der opstod en ukendt fejl';
        this.setState({ errorMessages: [errorMessage] });
      }
    }
    if(bookingSuccessful) {
      await this.triggerFetchFigures(); 
    }
    this.setState({
      awaitingBooking: false,
    });
  }

  renderBookkeepBtn = () => {
    const { awaitingBooking } = this.state;
    return <Button className={styles['btn-bookkeep']} disabled={this.isBookingDisabled()} loading={awaitingBooking} onClick={() => this.book()} positive>Bogfør</Button>;
  }

  renderErpLogo = (erp) => erpSystems[erp] ? erpSystems[erp].logo : null

  renderLogo = (erp) => {
    const logoSrc = this.renderErpLogo(erp);
    return <Image src={logoSrc} className={`${styles['header-logo']}`}/>;
  }

  renderTable = (erp) => {
    const bookingTargetMetadata = targetsMetadata[this.props.automatedBooking.target];
    const { feedback, accountPlanIsCorrect } = this.getFeedback();
    return (
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='2'>{this.renderLogo(erp)}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{bookingTargetMetadata.header.minuendHeader}</Table.Cell>
            <Table.Cell textAlign='right'>{this.toDanishFormat(this.getMinuend())}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{bookingTargetMetadata.header.subtrahendHeader}</Table.Cell>
            <Table.Cell textAlign='right'>{this.toDanishFormat(this.getSubtrahend())}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{bookingTargetMetadata.header.differenceHeader}</Table.Cell>
            <Table.Cell textAlign='right'>{this.toDanishFormat(this.calculateDifference())}</Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Footer>
          <Table.Row >
            <Table.HeaderCell colSpan='2' >{
              <>
                <div className={styles['footer']}>
                  {feedback}
                  <div>
                    {this.userMustSelectJournal() && accountPlanIsCorrect && this.state.journals.length > 0 && (
                      <Dropdown
                        selection
                        placeholder='Vælg kassekladde'
                        style={{ marginRight: '0.5em' }}
                        defaultValue={this.state.selectedJournalID}
                        disabled={this.state.awaitingBooking}
                        onChange={(_, { value }) => this.setState({ selectedJournalID: value })}
                        options={this.state.journals.map(journal => {
                          return {
                            text: journal.name,
                            value: journal.id,
                          };
                        })}
                      />
                    )}
                    {this.renderBookkeepBtn()}
                  </div>
                </div>
                <div>
                  {this.state.errorMessages && this.state.errorMessages.map(message => {
                    return <div className={styles['error-msg']}>&bull; {message}</div>;
                  })}
                </div>
              </>
            }</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }

	render () {
    const { erp } = this.props.userData;
    return <>
      {this.renderTable(erp)}
    </>;
	}
}

export default withUserData(UiElementAutomatedBooking);
