import React, { useState, useEffect, useRef } from 'react';

function TextareaAutoResize({ value, ...props }) {
    const [height, setHeight] = useState('auto');
    const textareaRef = useRef(null);

    useEffect(() => {
        const textarea = textareaRef.current;
        setHeight(`${textarea.scrollHeight + 5}px`);
    }, [value]);

    return (
        <textarea
            {...props}
            ref={textareaRef}
            value={value}
            style={{ height }}
            autoFocus
        />
    );
}

export default TextareaAutoResize;