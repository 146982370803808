import React, { isValidElement } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import { Divider, Grid, Popup, Icon, Modal, Button, Image } from 'semantic-ui-react';
import { describeYoutubeVideo } from 'http/youtube';
import { getAddonProductPurchases } from 'http/payment';
import { compileMarkdown } from 'util/markdown';
import ColoredText from 'design/atoms/ColoredText';
import styles from './Tooltip.module.scss';

const TAX_REGIME_VIDEO_ID = 'KFV7iIn0a-Q';

export class TooltipVideoModal extends React.Component {
	state = { open: false, isPlayingVideo: false, loading: true, locked: false };

	setOpen = open => this.setState({ open, isPlayingVideo: false });

	componentDidMount = async () => {
		const { youtubeVideo } = this.props;

		let videoLocked = youtubeVideo?.locked;
		
		// HACK!
		// See https://gitlab.com/Digital-Revisor/model-editor/-/issues/294
		if (youtubeVideo?.id === TAX_REGIME_VIDEO_ID) {
			const userAddonProductPurchases = await getAddonProductPurchases('accountantSparring');
			const taxYear = this.props.match.params.year;
			const ownsAccountantSparring = userAddonProductPurchases.some(purchase => purchase.data.taxYear === taxYear);
			videoLocked = !ownsAccountantSparring;
		}

		this.setState({ loading: false, locked: videoLocked });
	};

	togglePlayerMode = () => {
		if (this.state.locked) {
			return;
		}

		this.setState({ isPlayingVideo: !this.state.isPlayingVideo })
	};

	renderPurchaseDescription = () => {
		if (!this.state.locked) {
			return null;
		}

		// HACK!
		// See https://gitlab.com/Digital-Revisor/model-editor/-/issues/294
		let videoLockedDescription;
		if (this.props.youtubeVideo.id === TAX_REGIME_VIDEO_ID) {
			const { year, productID } = this.props.match.params;
			const redirectParam = encodeURIComponent(window.location.pathname);
			const upgradeLink = `/betaling/opgrader/${productID}/${year}/ACCOUNTANT_SPARRING?redirect=${redirectParam}`;
			videoLockedDescription = (
				<p>
					Ved køb af{' '}
					<ColoredText
						icon='conversation'
						content='Skattepakken m. revisorsparring'
						color='green'
						iconPosition='left'
						link={upgradeLink}
						underlined={false}
					/>
					{' '}får du adgang til hjælpevideoen
				</p>
			);
		} else {
			videoLockedDescription = <p>Ved køb af produktet får du adgang til hjælpevideoen</p>;
		}

		return (
			<div>
				<Divider />
				{videoLockedDescription}
			</div>
		);
	};

	renderVideoDescription = () => {
		const { youtubeVideo } = this.props;
		return (
			<span>
				<ColoredText
					bold
					content='Videobeskrivelse'
					icon='info circle'
					iconPosition='left'
				/>
				<br />
				{
					youtubeVideo.description
						? compileMarkdown(youtubeVideo.description)
						: <i>Beskrivelse mangler</i>
				}
			</span>
		);
	};

	renderVideoPlayer = () => {
		const { youtubeVideo } = this.props;

		if (this.state.isPlayingVideo) {
			return (
				<YouTube
					videoId={youtubeVideo.id}
					key={youtubeVideo.id}
					onReady={e => e.target.playVideo()}
					onEnd={() => this.setState({ isPlayingVideo: false })}
					
					opts={{
						width: '100%',
						height: '640px',
						playerVars: {
							rel: 0,
							modestbranding: 1,
						},
					}}
				/>
			);
		}

		const thumbnailStyle = {
			cursor: this.state.locked ? 'initial' : 'pointer',
		};

		return (
			<Grid columns={2} stackable>
				{/* Thumbnail */}
				<Grid.Column>
					<div className={styles.thumbnailWrapper} style={thumbnailStyle}>
						<Image
							className={styles.thumbnail}
							src={youtubeVideo.thumbnail}
						/>
						<div className={styles.thumbnailOverlay} onClick={this.togglePlayerMode}>
							<Icon
								name={this.state.locked ? 'lock ' : 'play circle'}
								size='huge'
								className={styles.playIcon}
							/>
							<div className={styles.duration}>
								{youtubeVideo.duration}
							</div>
						</div>
					</div>
				</Grid.Column>

				{/* Video description */}
				<Grid.Column>
					{this.renderVideoDescription()}
					{this.renderPurchaseDescription()}
				</Grid.Column>
			</Grid>
		);
	};

	// video + modal + trigger
	render = () => {
		const { youtubeVideo, trigger, fitted } = this.props;
		const { isPlayingVideo } = this.state;
		
		const defaultTrigger = <Icon
			className={styles.tticon}
			fitted={fitted}
			name='video play'
			size='large'
			link
		/>;

		return <Modal
			closeIcon
			size='large'
			trigger={trigger || defaultTrigger}
			open={this.state.open}
			onOpen={() => this.setOpen(true)}
			onClose={() => this.setOpen(false)}
		>
			<Modal.Header>{youtubeVideo.title}</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					{this.renderVideoPlayer()}
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					icon={isPlayingVideo ? 'arrow left' : 'play'}
					content={isPlayingVideo ? 'Tilbage' : 'Afspil video'}
					color={isPlayingVideo ? 'black' : 'green'}
					disabled={this.state.locked}
					onClick={this.togglePlayerMode}
				/>
			</Modal.Actions>
		</Modal>;
	}
}


class ToolTip extends React.Component {
    static propTypes = {
        /** The data the tooltip should display */
        data: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.array,
		]),
		youtubeVideo: PropTypes.object,
		youtubeID: PropTypes.string,
		videoLocked: PropTypes.bool,
		label: PropTypes.string,
	};

	static defaultProps = {
		inverted: false,
		videoLocked: false,
		position: 'right center',
		wide: 'very',
		className: styles.tticon,
		size: 'large',
	};

	constructor(props) {
		super(props);

		const state = {
			loading: false,
			youtubeID: null,
			youtubeVideo: null,
		};

		if (props.youtubeVideo) {
			state.youtubeVideo = props.youtubeVideo;
		} else if (props.youtubeID) {
			state.youtubeID = props.youtubeID;
			state.loading = true;
		}

		this.state = state;
	}

	componentDidMount = async () => {
		const { youtubeID } = this.state;
		const { videoLocked } = this.props;
		if (!youtubeID) {
			return;
		}

		const youtubeVideo = await describeYoutubeVideo(youtubeID);

		youtubeVideo.locked = videoLocked;

		this.setState({
			loading: false,
			youtubeVideo,
		});
	};

	prepareTooltipContent = () => {
		const data = this.props.data || this.props.children;
		
		if (!data) {
			return;
		}

		if (isValidElement(data)) {
			return data;
		}

		if (Array.isArray(data)) {
			return data.map((ele, index) => {
				return <p key={ 'tip-' + index }>{ele}</p>;
			});
		}

		if (typeof data === 'object') {
			const out = [];
			let toolText = data.text;
			let replacementValues = data.replacementValues;
			let replacementCharacter = data.replacementCharacter? data.replacementCharacter : '¤';
			let replacementCount = 0;

			for (let i = 0; i < toolText.length; i++) {
				if (toolText.charAt(i) === replacementCharacter) {
					let repTag = replacementValues[replacementCount].tag;
					let repText = replacementValues[replacementCount].text;
					replacementCount++;

					let newElement = React.createElement(repTag, {key:i}, repText);

					out.push(newElement);
				} else {
					out.push(toolText.charAt(i));
				}
			}

			return out;
		}
			
		return compileMarkdown(data);
	};

	renderAsRegularTooltip = () => {
		const { inverted, position, wide, className, size, fitted, disabled, triggerOverwrite, icon, onClick, basic } = this.props;
	
		const trigger = triggerOverwrite || <Icon
			onClick={onClick}
			link={!!onClick}
			className={className}
			name={icon || 'question circle'}
			color='grey'
			disabled={disabled}
			size={size}
			fitted={fitted}
		/>;

		const content = this.prepareTooltipContent();
		if (!content) {
			return null;
		}

		return (
			<Popup
				basic={basic}
				content={content}
				trigger={trigger}
				position={position}
				on={['hover', 'click']}
				wide={wide}
				inverted={inverted}
			/>
		);
	};

	renderAsYoutubeTooltip = () => {
		return <TooltipVideoModal
			{...this.props}
			youtubeVideo={this.state.youtubeVideo}
		/>;
	};

	render = () => {
		if (this.state.loading) {
			return <Icon
				name='spinner'
				color='blue'
				loading
			/>;
		}

		if (this.state.youtubeVideo) {
			return this.renderAsYoutubeTooltip();
		}

		return this.renderAsRegularTooltip();
	}
}

export default withRouter(ToolTip);