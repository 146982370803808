import { POST } from './index';
import { isAccessTokenExpired, setImpersonationToken } from '../../util/token';
import fetchAccessToken from './fetchAccessToken';

let activeRequest;

async function fetchImpersonationToken (uid) {
    const resp = await POST(`token/impersonation/${uid}`, {}, {
        token: 'accessToken',
        skipTokenValidation: true,
    });
    if (!resp.wasSuccessful()) {
        throw new Error(resp.getMessage());
    }
    setImpersonationToken(resp.getData());
} 

export default async function (impersonationID) {
    if (isAccessTokenExpired()) {
        await fetchAccessToken();
    }

    if (!activeRequest) {
        activeRequest = fetchImpersonationToken(impersonationID);
    }
    await activeRequest;
    activeRequest = null;
}