import React, { Component } from 'react';

const subscribers = {};
let currentHandlerID = 0;

export function subscribe (topic, handler) {
    const handlerID = currentHandlerID++;
    if (!subscribers[topic]) {
        subscribers[topic] = [];
    }
    subscribers[topic].push({
        handlerID,
        handler,
    });
    return {
        handlerID, topic,
    };
}

export function unsubscribe (subscriptionToken) {
    const { handlerID, topic } = subscriptionToken;
    for (let i in subscribers[topic]) {
        const handler = subscribers[topic][i];
        if (handler.handlerID === handlerID) {
            subscribers[topic].splice(i, 1);
            break;
        }
    }
}

export function publish (topic, message) {
    if (!subscribers[topic]) {
        return;
    }
    for (let { handler } of subscribers[topic]) {
        handler(message);
    }
}

export function withSubscription (WrappedComponent, ...topics) {
    return class extends Component {
        subTokens = [];
        state = {
            data: undefined,
        };

        componentDidMount = () => {
            this.subTokens = topics.map(topic => {
                return subscribe(topic, data => {
                    this.setState({ data });
                });
            });
        };

        componentWillUnmount = () => {
            this.subTokens.forEach(t => unsubscribe(t));
        };

        render = () => {
            return <WrappedComponent
                {...this.props}
                eventData={this.state.data}
            />;
        };
    }
}