import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { getInvoiceMethod, setInvoiceMethod } from 'http/payment';
import { withRootUserData } from 'util/withUserData';
import MultipleChoice from 'design/atoms/MultipleChoice';

export const invoiceMethods = {
    AUTOMATIC: 'AUTOMATIC',
    MANUAL: 'MANUAL',
};

class InvoiceMethod extends Component {
    state = {
        invoiceMethod: null,
        loading: true,
        working: false,
    };


    componentDidMount = async () => {
        const invoiceMethod = await getInvoiceMethod();
        this.setState({ invoiceMethod, loading: false });
    };

    invoiceMethodChanged = async method => {
        const { onChange } = this.props;
        this.setState({ working: true });
        const invoiceMethod = await setInvoiceMethod(method);
        this.setState({ working: false, invoiceMethod });
        onChange && onChange(invoiceMethod);
        toast.success('Din faktueringsmetode blev opdateret');
    };

    getOptions = () => {
        const { userData } = this.props;
        return [
            {
                key: invoiceMethods.AUTOMATIC,
                value: invoiceMethods.AUTOMATIC,
                text: 'Automatisk kortbetaling',
                tooltip: 'Du vil automatisk blive trukket på dit betalingskort',
                disabled: !userData.isEntityAdmin(),
            },
            {
                key: invoiceMethods.MANUAL,
                value: invoiceMethods.MANUAL,
                text: 'Manuel fakturering',
                tooltip: 'Du vil få tilsendt en faktura til manuel indbetaling',
                disabled: !userData.isEntityAdmin(),
            },
        ];
    };

    render () {
        const { working, invoiceMethod } = this.state;
        return (
            <span>
                <p>
                    På Digital Revisor fakturerer vi for hvert enkelt årsregnskab du åbner.
                    Disse samles til en månedlig faktura, som du kan betale på to måder:
                </p>
                <MultipleChoice
                    value={invoiceMethod}
                    disabled={working}
                    onChange={this.invoiceMethodChanged}
                    options={this.getOptions()}
                />
            </span>
        );
    }
}

export default withRootUserData(InvoiceMethod);