import React, { Component } from 'react';
import { Flag, Modal, Header, Icon, Dropdown, Button } from 'semantic-ui-react';
import i18n from '../../../i18n/pages/LanguagePicker';
import languageCodes, {
    getSelectedLanguage,
    getLanguageDescription,
    setSelectedLanguage,
} from '../../../i18n/languageCodes';

class LanguagePicker extends Component {
    state = {
        modal: false,
        selectedLang: getSelectedLanguage(),
    };

    flagClicked = () => {
        this.setState({ modal: true });
    };

    getLanguageOptions = () => {
        const out = [];
        for (let langCode of Object.values(languageCodes)) {
            const { semanticFlag, description } = getLanguageDescription(langCode);
            out.push({
                key: langCode,
                value: langCode,
                flag: semanticFlag,
                text: description,
            });
        }
        return out;
    };

    renderModal = () => {
        const { modal } = this.state;
        if (!modal) {
            return null;
        }
        return (
            <Modal open={modal} onClose={() => this.setState({ modal: false })} >
                <Modal.Header>
                <Header>
                    <Icon name='font awesome flag' />
                    <Header.Content>
                        {i18n.title}
                        <Header.Subheader>
                            {i18n.subheader}
                        </Header.Subheader>
                    </Header.Content>
                </Header>
                </Modal.Header>

                <Modal.Content>
                    <Dropdown
                        fluid
                        selection
                        options={this.getLanguageOptions()}
                        defaultValue={this.state.selectedLang}
                        onChange={(_, { value }) => this.setState({ selectedLang: value })}
                    />
                </Modal.Content>

                <Modal.Actions>
                    <Button color='black' onClick={() => this.setState({ modal: false })}>
                        {i18n.cancelBtn}
                    </Button>
                    <Button
                        primary
                        id='confirmDeleteAccountBtn'
                        onClick={() => setSelectedLanguage(this.state.selectedLang)}
                    >
                        {i18n.saveBtn}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    render () {
        const selectedLang = getSelectedLanguage();
        const { semanticFlag } = getLanguageDescription(selectedLang);
        return (
            <>
                <Flag name={semanticFlag} />
                {this.renderModal()}
            </>
        );
    }
}

export default LanguagePicker;