import React, { useState } from 'react';
import { Button, Icon, Segment } from 'semantic-ui-react';

const ApprovalModule = ({ onApproval }) => {
    const [message, setMessage] = useState('');

    return (
        <Segment.Group>
            <Segment secondary style={{ padding: '0.5em' }}>
                <strong><Icon name='tasks' /> Godkendelse af kontoplan</strong>
            </Segment>
            <Segment style={{ padding: 0 }}>
                <textarea
                    onChange={e => setMessage(e.target.value)}
                    placeholder='Skriv evt. en bemærkning vedrørende kontoplanen...'
                    style={{
                        width: '100%',
                        height: '100px',
                        resize: 'vertical',
                        border: 'none',
                        outline: 'none',
                    }}
                />
            </Segment>
            <Segment secondary textAlign='right' style={{ padding: '0.5em' }}>
                <Button primary content='Godkend kontoplan' onClick={() => onApproval(message)} />
            </Segment>
        </Segment.Group>
    );
};

export default ApprovalModule;