import { HTTPClient } from '../network/fetch';

const client = new HTTPClient({ prefix: 'businesscentral/api' });

export function registerCredentials(username, password) {
    return client.postResource('register', {
        username,
        password,
    });
}

export function extractPrefillValues(token) {
    return client.getResource(`prefill/${token}`);
}