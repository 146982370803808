import React from 'react';
import { ShoppingCartSegment } from './ShoppingCart';
import { Icon } from 'semantic-ui-react';

function BankTransferPurchase() {
    return (
        <ShoppingCartSegment title='Betal med bankoverførsel' icon='credit card outline'>
            <div>
                <b><Icon name='info circle' />OBS:</b>
            </div>
            <div>
                Dit køb overstiger 20.000 kr. og du skal derfor betale med bankoverførsel.
            </div>
            <br />
            <div>
                Ved køb vil du modtage en faktura på mail som skal betales via en bankoverførsel i løbet af 7 dage.
            </div>
        </ShoppingCartSegment>
    );
}

export default BankTransferPurchase;