import { Component } from 'react';

class YearReportData extends Component {
    componentDidMount () {
        const { isLocked, options } = this.props;
        if (isLocked) return;
        this.props.onChange(options.triggerFact, { time: Date.now() }, { run: true });
    }

    render () {
        return null;
    }
}

export default YearReportData;
