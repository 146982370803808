import { DRError } from 'network/fetch/DRResponse';
import { getResource, deleteResource, UPLOAD } from '../network/fetch';
import { API_LOCATION } from '../config/config';

const fileStorageLocation = `${API_LOCATION}file-storage/`;

export async function uploadFile (file) {
    const formData = new FormData();
    formData.append('file', file);
    const resp = await UPLOAD(formData, fileStorageLocation, 'files');
    if (!resp.wasSuccessful()) {
        throw new DRError(resp.getMessage(), null, resp.getStatusCode());
    }
    return resp.getData();
}

export function getFile (fileID) {
    return getResource(`files/${fileID}`, {
        api: fileStorageLocation,
    });
}

export function getFileData (fileID, base64 = false) {
    return getResource(`files/${fileID}/data${base64 ? '?base64=true' : ''}`, {
        api: fileStorageLocation,
    });
}

/**
 * Prepares a download link for the given file
 * @param {string} fileID - ID of the file
 * @param {("inline"|"attachment")} disposition - "inline" or "attachment". Defaults to "inline"
 */
export async function prepareDownloadLink (fileID, disposition = 'inline') {
    const { link } = await getFile(fileID);
    return `${fileStorageLocation}files/download/${link}?disposition=${disposition}`;
}

export function deleteFile (fileID) {
    return deleteResource(`file-storage/files/${fileID}`);
}

export function getFileMetadata(fileID) {
    return getResource(`file-storage/files/${fileID}/metadata`);
}