import langCodes, { getSelectedLanguage } from './languageCodes';

export const allTranslations = {};

/**
 * Picks a translation based on the users chosen language
 * @param {*} translations - An object containing the different translations
 * @returns {String}
 */
function i18n (translations) {
	const chosenLang = getSelectedLanguage();
	const translation = translations[chosenLang] || translations[langCodes.daDK];
	return translation;
}

export default i18n;