import React, { createRef, useState } from 'react';
import { Popup, List, Segment, Input, Icon, Table } from 'semantic-ui-react';

import { xbrlTranslation } from '../../ClosingSheet/xbrlCodesDK';
import { revcodeTranslation } from '../../ClosingSheet/accountantCodesDK';

import styles from './TagPicker.module.scss';

function TagPicker ({ tags, value, onChange, disabled, displayError }) {
    const [ filter, setFilter ] = useState();
    const [ open, setOpen ] = useState(false);
    const searchRef = createRef();

    const getDisplayName = val => xbrlTranslation(val) || revcodeTranslation(val);
    const displayName = getDisplayName(value.value);
    const tagsWithI18n = (tags || []).map(t => ({ tag: t, description: getDisplayName(t) }));
    const tagValid = !displayError || tags.includes(value.value);

    // tags: 
    // Array(54) [ "AssetsHeldForSaleInventories", "CashAndCashEquivalents", "CompletedDevelopmentProjects", "ContractWorkInProgress", "ContributedCapital", "CurrentDeferredTaxAssets", "DeferredIncomeAssets", "DepositsLongtermInvestmentsAndReceivables", "DepositsShorttermLiabilitiesOtherThanProvisions", "DevelopmentProjectsInProgressAndPrepaymentsForIntangibleAssets", … ]

    // value: 
    // Object { confidence: "1", overrideValue: "", value: "ProposedDividendRecognisedInEquity" }


    // Service methods
    const tagClicked = tag => {
        return () => {
            onChange({
                value: tag,
                confidence: '1',
                overrideValue: '',
            });
            setOpen(false);
        };
    };

    const focusSearchTxf = () => {
        if (searchRef.current) {
            const { scrollX, scrollY } = window;
            searchRef.current.focus();
            window.scrollTo(scrollX, scrollY);
        }
    };

    const filterChanged = (_, { value }) => {
        setFilter(value);
    };

    const getFilteredTags = () => {
        if (filter) {
            const filterLC = filter.toLowerCase()
            return tagsWithI18n.filter(t => {
                return t.tag.toLowerCase().includes(filterLC) || t.description.toLowerCase().includes(filterLC);
            });
        }
        return tagsWithI18n;
    };

    // Rendering methods
    const renderPopupTrigger = () => {
        const { selectedTag, disabledTag } = styles;
        return (
            <span className={disabled ? disabledTag : selectedTag} onClick={() => setOpen(true)}>
                {!tagValid && <Icon name='warning circle' color='red' />}
                {displayName}
            </span>
        );
    };

    const renderTagItem = t => {
        const active = t.tag === value.value;
        const cn = active ? styles.selectedTag : styles.listTag;
        const listItem = <List.Item
            key={t.tag}
            className={cn}
            onClick={tagClicked(t.tag)}
            children={<>
                {t.description} {active && <Icon name='check' />}
            </>}
        />;
        return <Popup key={t.tag} inverted trigger={listItem} content={t.tag} />;
    };

    const renderPopupContent = () => {
        const ftags = getFilteredTags();
        return <Segment basic>
            <Table basic='very' compact='very'>
                <Table.Body>
                <Table.Row verticalAlign='top'>
                <Table.Cell collapsing>
                    <b>Valgt tag:</b>
                </Table.Cell>
                <Table.Cell>
                    {displayName}<br/>
                    <span className={styles.headerTag}>{value.value}</span>
                </Table.Cell>
                </Table.Row>
                </Table.Body>
            </Table>            
            <Input
                ref={searchRef}
                onChange={filterChanged}
                fluid placeholder='Filtrer tags'
                icon='search'
            />
            <List divided className={styles.container}>
                {ftags.map(renderTagItem)}
            </List>
            <p>
                <i>Viser {ftags.length} ud af {tags.length} tags</i>
            </p>
        </Segment>;
    };
    
    return <Popup
        flowing
        hoverable
        disabled={disabled}
        open={open}
        onMount={focusSearchTxf}
        mouseEnterDelay={250}
        mouseLeaveDelay={500}
        position='bottom right'
        trigger={renderPopupTrigger()}
        content={renderPopupContent()}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onUnmount={() => setFilter()}
    />;
}

export default TagPicker;