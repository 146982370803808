import { formatDate } from './format/DateTime';

/**
 * @param {*} currentPeriod - model.Period from financial-dates
 * @param {*} lastPeriod - model.Period from financial-dates
 */
export const getTaxYearLabels = (currentPeriod, lastPeriod) => {
    const activeTaxYear = currentPeriod?.taxYear?.toString();
    const lastTaxYear = lastPeriod?.taxYear?.toString();

    const out = {
        activeYear: {
            label: '',
            tooltip: '',
        },
        lastYear: {
            label: '',
            tooltip: '',
        },
    };

    if (!activeTaxYear) {
        return out;
    }
    if (activeTaxYear !== lastTaxYear) {
        // tax years are different - no need for formatting
        out.activeYear.label = activeTaxYear;
        out.lastYear.label = lastTaxYear || activeTaxYear - 1;
        return out;
    }
    
    // do some extra formatting to make the two years more distinguishable
    // + add a tooltip containing the periods
    const [activeYearInfo, lastYearInfo] = [currentPeriod, lastPeriod].map(({ taxYear, start, end }) => {
        const startYear = start.year?.toString();
        const endYear = end.year?.toString();

        let label;
        if (startYear !== endYear) {
            label = `${startYear}/${endYear.substring(2)}`;
        } else {
            label = taxYear.toString();
        }

        const formatFiscalDate = date => {
            const asDate = new Date(date.year, date.month - 1, date.day);
            return formatDate(asDate);
        };
        const tooltip = [start, end].map(formatFiscalDate).join(' - ');

        return [label, tooltip];
    });

    const [
        [activeYearLabel, activeYearTooltip],
        [lastYearLabel, lastYearTooltip],
    ] = [activeYearInfo, lastYearInfo];

    out.activeYear.label = activeYearLabel;
    out.activeYear.tooltip = activeYearTooltip;
    out.lastYear.label = lastYearLabel;
    out.lastYear.tooltip = lastYearTooltip;
    
    return out;
};