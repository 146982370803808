import { HTTPClient } from 'network/fetch';

const client = new HTTPClient({ prefix: 'uniconta/api' });

export function searchCompanies(username, password) {
    return client.postResource('companies', { username, password });
}

export function registerLoginCredentials(username, password, companyID, sess=null) {
    return client.postResource('register', {
        username,
        password,
        companyid: companyID,
        ...(sess && { sess }),
    });
}