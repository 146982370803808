import React from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './PDFView.module.scss';

function NavBtn ({ onClick, direction, disabled, link }) {
    return <div onClick={onClick} className={styles.navArrow}>
        <Icon
            disabled={disabled}
            link={link}
            name={`chevron circle ${direction}`}
            color='grey'
            size='huge'
        />
    </div>;
}

export default NavBtn;