import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useUser from 'util/useUser';
import { stopImpersonation } from 'util/impersonation';
import { getProduct } from 'http/productEngine';
import { hasAccessToAddonProduct } from 'http/payment';
import ProductPage from 'design/molecules/ProductPage';
import { ACCOUNTANT_COURSE_PRODUCT_ID, ACCOUNTANT_COURSE_MODEL_ID } from 'config/config';
import Product from '../Product';

const AccountantCourseProductWrapper = props => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hasAccess, setHasAccess] = useState(false);
    const [product, setProduct] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const [hasAccess, product] = await Promise.all([
                hasAccessToAddonProduct(ACCOUNTANT_COURSE_PRODUCT_ID),
                getProduct(ACCOUNTANT_COURSE_MODEL_ID),
            ]);

            setHasAccess(hasAccess);
            setProduct(product);
        };

        fetchData()
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, []);

    return (
        <Product
            {...props}
            {...props.match.params}
            isTeaser={false}
            alwaysShowPaymentButton={true}
            error={error}
            loading={loading}
            modelId={product?.id}
            hasPayed={hasAccess}
            product={product}
            paymentURL='/betaling/raadgiverkursus'
            pagePrefix='/raadgiverkursus'
            title='Kursus'
            icon='university'
        />
    );
};

const AccountantCourse = props => {
    const user = useUser();
    const history = useHistory();

    const isImpersonating = user.impersonation;
    const userIsAccountant = user.isAccountant();

    useEffect(() => {
        if (!userIsAccountant) {
            history.push('/');
            return;
        }

        if (isImpersonating) {
            stopImpersonation();
        }
    }, [isImpersonating, userIsAccountant, history]);

    if (isImpersonating) {
        return <ProductPage />;
    }

    return <AccountantCourseProductWrapper {...props} />;
};

export default AccountantCourse;