import React, { Component } from 'react';
import SimpleValue from   '../../../components/SimpleValue';

const rubrikLabels = {
    'rubrik111': 'Rubrik 111 - Overskud før renter',
    'rubrik117': 'Rubrik 117 - Renteudgifter',
    'rubrik142': 'Rubrik 142 - Kapitalafkast',
    'rubrik147': 'Rubrik 147 - Virksomhedsordningen vælges',
    'rubrik148': 'Rubrik 148 - Kapitalafkast',
    'rubrik149': 'Rubrik 149 - Indkomst til beskatning i virksomhedsordningen',
    'rubrik150': 'Rubrik 150 - Rentekorrektion',
    'rubrik151': 'Rubrik 151 - Overført fra opsparet overskud',
    'rubrik152': 'Rubrik 152 - Årets samlede overførsler',
    'rubrik184': 'Rubrik 184 - Vælg kapitalafkastordningen',
    'rubrik984': 'Rubrik 984 - Indskudskonto ultimo',
    'rubrik308': 'Rubrik 308 - Ejendomsavance',
    'rubrik300': 'Rubrik 300 - Indtast cvr-nummer/cpr-nummer',
    'rubrik301a': 'Rubrik 301 - Vælges ja',
    'rubrik302': 'Rubrik 302 - Hak i Nettoomsætning under 300.000',
    'rubrik303': 'Rubrik 303 - Vælges ja',
    'rubrik304': 'Rubrik 304 - Hak i 4 - "Andet"',
    'rubrik305': 'Rubrik 305 - Hak i 4 - Uden forbehold og supplerende oplysninger/ikke udarbejdet',
    'rubrik301b': 'Rubrik 301 - Vælges nej',
    'rubrik207': 'Rubrik 207 - Erhvervsmæssig udlejning',
};

export default class TaxCalc extends Component {
    fetchData () {
        const { onChange, tag } = this.props;
        onChange(tag, new Date().valueOf());
    }

    render () {
        const data = this.props.value;

        if (data === undefined) return null;

        const renderRubrikker = () => {
            return Object.keys(data).map(key => {
                if (!rubrikLabels[key]) {
                    return null;
                }
                const value = Math.round(Number(data[key]));
                if (!value) return null;

                return <SimpleValue label={rubrikLabels[key] || key} value={value} styleOptions={{}}/>;
            });
        };

        return (
            <div>
                {renderRubrikker()}
            </div>
        );
    }
}
