import React from 'react';
import CreateEntityForm from 'design/molecules/CreateEntityForm';
import { useRootUser } from 'util/useUser';
import { Icon, Header, Message } from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';

const CreateEntity = () => {
    const user = useRootUser();

    return <>
        {
            user.isAccountant() &&
            <Message
                color='blue'
                content={
                    <>
                        <Header>
                            <Icon name='info circle' fitted />&nbsp;OBS
                        </Header>
                        <p>
                            Du er nu ved at oprette en ny virksomhed på Digital Revisor, der er knyttet til dig som person.
                        </p>
                        <p>
                            Ønsker du i stedet at lave et regnskab for en klient, der er knyttet til {user.displayName}, skal du gøre det fra
                            &nbsp;<ColoredText icon='users' iconPosition='left' link='/' content='klientlisten' noWrap underlined={false} />.
                        </p>
                    </>
                }
            />
        }
        <CreateEntityForm />
    </>
};

export default CreateEntity;