function getPath (e) {
    // Other browsers
    if (e.path) return e.path;
    if (e.composedPath) return e.composedPath();
    // IE
    let target = e.target;
    const path = [target];
    while (target.parentNode !== null) {
        target = target.parentNode;
        target && path.push(target);
    }
    return path;
}

export default getPath;