import React from 'react';
import { useHistory } from 'react-router-dom';
import { Message, Container, Segment, Button } from 'semantic-ui-react';
import { isAkademi } from 'util/userMethods';
import useUser from 'util/useUser';
import QueryParams from 'util/QueryParams';

function PurchaseNotAllowed({ header, msg }) {
    const user = useUser();
    const history = useHistory();

    const goBack = () => {
        const { redirect } = QueryParams();
        if (redirect) {
            return history.push(`${decodeURIComponent(redirect)}`);
        }
        history.goBack();
    };

    return (
        <Container>
            <Segment>
                <Message
                    warning={!isAkademi(user)}
                    info={isAkademi(user)}
                    header={header}
                    content={msg}
                    icon='ban'
                />
                <div style={{ textAlign: 'right' }}>
                    <Button
                        labelPosition='left'
                        icon='caret left'
                        content='Tilbage'
                        onClick={goBack}
                    />
                </div>
            </Segment>
        </Container>
    );
}

export default PurchaseNotAllowed;