import React, { Component } from 'react';
import { Transition } from 'semantic-ui-react';
import cn from 'classnames';
import styles from './PDFView.module.scss';

class DropZone extends Component {
    mounted = true;
    state = {
        hidden: true,
    };

    componentWillUnmount = () => {
        this.mounted = false;
    };

    componentDidMount = () => {
        // Triggers an animation due to the transition group
        setTimeout(() => {
            this.mounted && this.setState({ hidden: false });
        }, 750);
    };

    blockDefault = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    render = () => {
        const { dropID, content, containerClasses } = this.props;
        return (
            <Transition.Group>
                { !this.state.hidden && (
                    <div
                        id={dropID}
                        className={styles.uploadLogoWrapper}
                        onDrag={this.blockDefault}
                        onDragStart={this.blockDefault}
                        onDragOver={this.blockDefault}
                        onDragEnter={this.blockDefault}
                        onDragLeave={this.blockDefault}
                        onDragEnd={this.blockDefault}
                    >
                        <div className={cn(...containerClasses)}>
                            <div className={styles.dropHereWrapper}>
                                <div className={styles.dropHereContainer}>
                                    <div className={styles.dropHereContent}>
                                        {content}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) }
            </Transition.Group>
        );
    };
}

export default DropZone;