import ProductPage from 'design/molecules/ProductPage';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Loader, Segment } from 'semantic-ui-react';
import { erpIdentifiers } from 'util/erpSystems';
import queryParams, { makeQueryParams } from 'util/QueryParams';

function EconomicIntegration () {
    const [error, setError] = useState(false);
    const history = useHistory();
    const { token } = queryParams();

    useEffect(() => {
        if (!token) {
            setError(true);
            return;
        }

        const afterEconomicIntegrationRedirectPath = window.sessionStorage.afterEconomicIntegrationRedirectPath;
        if (!afterEconomicIntegrationRedirectPath) {
            // user came directly from e-conomic market, send them to registration form
            history.push('/register/erhverv' + makeQueryParams({
                referrer: erpIdentifiers.economic,
                token: token,
            }));
            return;
        }

        const fullRedirectURL = afterEconomicIntegrationRedirectPath + makeQueryParams({
            erp: erpIdentifiers.economic,
            token: token,
        });

        if (fullRedirectURL.startsWith('http')) {
            window.location.href = fullRedirectURL;
        } else {
            history.push(fullRedirectURL);
        }
    }, [token, history]); 

    return (
        <ProductPage>
            {!error && <Loader inline='centered' active />}
            {error && (
                <Segment>
                    <Icon name='warning sign' color='red' />&nbsp;
                    Der kunne ikke oprettes forbindelse til E-conomic
                </Segment>
            )}
        </ProductPage>
    );
}

export default EconomicIntegration;