import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Loader, Message, Segment } from 'semantic-ui-react';
import compose from 'lodash.flowright';

import withUserData from 'util/withUserData';
import variationLabels from 'util/variationLabels';
import { ERHVERV } from 'util/userMethods';
import { getProductByID } from 'util/getProducts';
import { getAddonProductByID, getAddonProductPurchases } from 'http/payment';
import { getArchivedAppEngineVariations } from 'http/accounts';
import { getFact, getServiceByID } from 'http/productEngine';
import { userStatusesEnum } from 'design/molecules/AppLauncher/userStatuses';
import { withErrorBoundary } from 'design/atoms/ErrorBoundary';
import productStatuses from 'design/molecules/AppLauncher/productStatuses';
import AppLauncher from 'design/molecules/AppLauncher';
import ProductPage from 'design/molecules/ProductPage';

import { prepareVariations, extractValue } from './adapter';
import PageHeader from 'design/atoms/PageHeader';

class ProductEngineAppLauncher extends Component {
    productID = this.props.match.params.productID;
    state = {
        loading: true,
        error: null,
        product: {},
        taxYears: [],
        ownedAddonProducts: [],
        addonProducts: [],
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.productID = this.props.match.params.productID;
            this.setState({ loading: true }, this.loadService);
        }
    };

    componentDidMount = async () => {
        await this.loadService();
    };

    getAddonProductIdsAssociatedWithProduct = product => {
        const tiers = product.subscriptionPackageSet?.tiers || [];
        const addonProductIds = new Set();
        tiers.forEach(tier => tier.addonProductID && addonProductIds.add(tier.addonProductID));
        return [...addonProductIds];
    };

    getAddonProductsAssociatedWithProduct = product => {
        const addonProductIds = this.getAddonProductIdsAssociatedWithProduct(product);
        return Promise.all(addonProductIds.map(productID => {
            return getAddonProductByID(productID);
        }));
    };

    getRelatedOwnedAddonProducts = async product => {
        const addonProductIds = this.getAddonProductIdsAssociatedWithProduct(product);
        const ownedAddonProducts = [];

        await Promise.all(addonProductIds.map(async productID => {
            const purchases = await getAddonProductPurchases(productID);
            ownedAddonProducts.push(...purchases);
        }));

        return ownedAddonProducts;
    };

    reloadRelatedOwnedAddonProducts = async () => {
        const ownedAddonProducts = await this.getRelatedOwnedAddonProducts(this.state.product);
        this.setState({ ownedAddonProducts });
    };

    loadService = async () => {
        try {
            // fetch SAAS product
            const product = await getProductByID(this.productID);

            // fetch connected variations & addon products
            const { serviceID } = product;
            const [variations, ownedAddonProducts, addonProducts] = await Promise.all([
                this.getVariations(serviceID),
                this.getRelatedOwnedAddonProducts(product),
                this.getAddonProductsAssociatedWithProduct(product),
            ]);

            // convert variations => tax years for launcher
            const isErhverv = product.roles.includes(ERHVERV);
            const taxYears = await prepareVariations(product.id, variations, isErhverv);

            taxYears.forEach(taxYear => {
                const archivedVariations = taxYear.variations.filter(variation => {
                    return variation.isArchived;
                });

                // if the tax year contains archived variations
                // make sure to remove any duplicates w/ the same type & tax year
                // as the archived variation
                for (const archivedVariation of archivedVariations) {
                    taxYear.variations = taxYear.variations.filter(variation => {
                        if (variation.year !== archivedVariation.year) {
                            return true;
                        }

                        if (variation.type?.toLowerCase() !== archivedVariation.type?.toLowerCase()) {
                            return true;
                        }

                        if (variation.isArchived) {
                            return true;
                        }

                        return false;
                    });
                }
            });

            // inject the users status into every variation of every tax year
            await Promise.all(taxYears.map(({ variations }) => {
                return Promise.all(variations.map(async variation => {
                    const userStatus = await this.getUserStatus(variation.modelId, variation.label);
                    const extraStatus = await this.getTaxSystemStatus(variation.modelId, variationLabels.regular.id, "c4oc109qecnaq74iolqg");
                    variation.userStatus = userStatus;
                    variation.extraStatus = extraStatus;
                }));
            }));

            this.setState({
                product,
                taxYears,
                ownedAddonProducts,
                addonProducts,
                loading: false,
            });
        } catch (e) {
            console.error('Failed to load service:', e);
            if (e.i18n) {
                this.setState({ error: e.i18n });
            } else {
                this.setState({ error: 'Siden kunne ikke indlæses' });
            }
        }
    };

    getVariations = async serviceID => {
        const [archivedVariations, serviceVariations] = await Promise.all([
            this.getArchivedVariations(),
            this.getServiceVariations(serviceID),
        ]);

        return [...archivedVariations, ...serviceVariations];
    };

    getArchivedVariations = async () => {
        const archivedVariations = await getArchivedAppEngineVariations(this.productID);

        return archivedVariations.map(({ variation, year }) => {
            return {
                type: variation,
                year: year,
                status: productStatuses.LOCKED,
                isArchived: true,
            };
        });
    }; 

    getServiceVariations = async serviceID => {
        // try fetch variations from service
        const variations = [];
        try {
            const service = await getServiceByID(serviceID);
            variations.push(...service.variations);
        } catch(e) {
            if (e.status !== 200) {
                throw e;
            }
        }

        // enrich variations w/ user status
        // TODO: server side task
        return Promise.all(variations.map(async variation => {
            // TODO: variation must contain label
            const status = await this.getUserStatus(variation.modelId, variation.label || variationLabels.regular.id);
            return {
                ...variation,
                userStatus: status,
            };
        }));
    };

    getTaxSystemStatus = async (modelId, label, tag) => {
        try {
            const taxSystemStatus = await getFact(modelId, label, tag);
            if(taxSystemStatus.value.enumString.length > 0){
                return "taxSystemSelected";
            }
        } catch (e) {
            return userStatusesEnum.NOT_STARTED;
        }
    }
    getUserStatus = async (modelId, label) => {
        try {
            const status = await getFact(modelId, label, 'STATUS');
            return extractValue(status.value);
        } catch (e) {
            return userStatusesEnum.NOT_STARTED;
        }
    };

    render () {
        const { error, loading, product, taxYears, ownedAddonProducts, addonProducts } = this.state;
        if (error) {
            return <ProductPage>
                <Segment>
                    <Message
                        content={error}
                        icon='warning'
                        color='orange'
                    />
                </Segment>
            </ProductPage>;
        }

        if (loading) {
            return <ProductPage
                subHeader={
                    <PageHeader
                        content='Indlæser...'
                        loading
                    />
                }
                children={<Loader inline='centered' active size='huge' />}
            />;
        }

        return (
            <AppLauncher
                product={product}
                taxYears={taxYears}
                ownedAddonProducts={ownedAddonProducts}
                addonProducts={addonProducts}
                reloadRelatedOwnedAddonProducts={this.reloadRelatedOwnedAddonProducts}
            />
        );
    }
}

export default compose(
    withRouter,
    withErrorBoundary,
    withUserData,
)(ProductEngineAppLauncher);
