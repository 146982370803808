import React, { useState } from 'react';
import ColoredText from 'design/atoms/ColoredText';

const ReminderButton = ({signeeId, signeeStatus, handleSendReminder}) => {
    const [lockRemind, setLockRemind] = useState(false)

    const internalHandleReminder = (signeeId) => {
        handleSendReminder(signeeId);
        return setLockRemind(true)
    }

    return <ColoredText
            disabled={lockRemind || signeeStatus === "Pending"}
            onClick={(_) => internalHandleReminder(signeeId) }
            content='Send påmindelse'
            link
            underlined={false}
            iconPosition='left'
            icon='redo'
        />
}

export default ReminderButton;
