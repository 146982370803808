import debounce from 'lodash.debounce';
import set from 'lodash.set';
import React from 'react';
import { Checkbox, Dropdown, Form, Input} from 'semantic-ui-react';
import { documentComponentDataTypes } from '@digital-revisor/node-report-editor-model';
import TextareaAutoResize from './TextareaAutoResize';

const OverridesForm = ({ formValues, documentOverrideConfiguration, onChange }) => {
    const renderFormField = ({ editorID, propName, alignment, text, dataType, choices, value }) => {
        const updateFormValue = value => {
            const configurationCopy = { ...documentOverrideConfiguration };
            const propPath = [alignment, editorID, propName].join('.');
            set(configurationCopy, propPath, value);
            onChange(configurationCopy);
        };

        switch (dataType) {
            case documentComponentDataTypes.enum: {
                return (
                    <Form.Field>
                        <label>{text}</label>
                        <Dropdown
                            selection
                            search
                            options={choices.map(({ id, texts }) => ({
                                text: texts['da-DK'],
                                value: id,
                            }))}
                            onChange={(_, { value }) => updateFormValue(value)}
                            defaultValue={value}
                        />
                    </Form.Field>
                );
            }

            case documentComponentDataTypes.boolean: {
                return (
                    <Form.Field>
                        <Checkbox
                            label={text}
                            onChange={(_, { checked }) => updateFormValue(checked)}
                            defaultChecked={value}
                        />
                    </Form.Field>
                );
            }
    
            case documentComponentDataTypes.string: {
                return (
                    <Form.Field>
                        <label>{text}</label>
                        <TextareaAutoResize
                            style={{ resize: 'vertical' }}
                            onChange={e => updateFormValue(e.target.value)}
                            defaultValue={value}
                        />
                    </Form.Field>
                );
            }
    
            case documentComponentDataTypes.number: {
                return (
                    <Form.Field>
                        <label>{text}</label>
                        <Input
                            type='number'
                            defaultValue={value}
                            onChange={debounce((_, { value }) => updateFormValue(Number(value)), 500)}
                        />
                    </Form.Field>
                );
            }

            default: {
                return null;
            }
        }
    };

    return (
        <Form>
            {formValues.map(renderFormField)}
        </Form>
    );
};

export default OverridesForm;