import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import styles from './CardForm.module.scss';

function CardForm ({ disabled, fields, onChange, className }) {
    const [status, setStatus] = useState({
        cardNumber: { complete: false },
        cardExpiry: { complete: false },
        cardCvc: { complete: false },
    });

    const allFieldsComplete = status => {
        return Object.values(status).every(s => s.complete);
    };
    
    const getError = status => {
        const firstError = Object.values(status).find(s => s.error);

        // returns error message if any error found
        return firstError?.error;
    };

    const onCardElementChange = ({ elementType, complete, error }) => {
        status[elementType] = {
            complete,
            error: error?.message || null,
        };

        if (onChange) {
            const filled = allFieldsComplete(status);
            const error = getError(status);
            const ready = filled && !error;
            onChange({
                filled,
                error,
                ready,
            });
        }
        
        setStatus({ ...status });
    };

    const cardElementOptions = {
        placeholder: '',
        disabled,
    };

    return (
        <Form className={className}>
            <Form.Field>
                <span>
                    Kortnummer
                </span>
                <CardNumberElement
                    className={styles.cardField}
                    onChange={onCardElementChange}
                    options={{
                        showIcon: true,
                        ...cardElementOptions,
                    }}
                />
            </Form.Field>
            <Form.Group widths={2}>
                <Form.Field>
                    <span>
                        Udløbsdato
                    </span>
                    <CardExpiryElement
                        className={styles.cardField}
                        onChange={onCardElementChange}
                        options={cardElementOptions}
                    />
                </Form.Field>
                <Form.Field>
                    <span>
                        CVC
                    </span>
                    <CardCvcElement
                        className={styles.cardField}
                        onChange={onCardElementChange}
                        options={cardElementOptions}
                    />
                </Form.Field>
            </Form.Group>
            {fields}
        </Form>
    );
}

export default CardForm;