import React, { useState } from 'react';
import {
    Popup,
    Icon,
    Confirm,
    Button,
    Message,
    Loader,
	Modal,
} from 'semantic-ui-react';

import { toast } from 'react-toastify';
import * as payment from 'http/payment.js';
import { getSubscriptionsOverview } from 'http/payment';
import { formatDate } from 'util/format/DateTime';
import { formatNumber } from 'util/format/Number';
import { withRootUserData } from 'util/withUserData';
import getProducts from 'util/getProducts';
import ColoredText from 'design/atoms/ColoredText';
import { useBreakpoints } from 'design/atoms/Media';

import SubscriptionPackageSelector from 'design/atoms/SubscriptionPackageSelector/SubscriptionPackageSelector';
import ProfileTable from './ProfileTable';

const ChangePackageModal = ({ subscription, products, onChangeTier, onClose }) => {
	const [selectedTier, setSelectedTier] = useState(subscription.selectedPackageTier);

	const associatedProduct = products.find(product => product.id === subscription.productID);

	const choiceIsValid = selectedTier !== subscription.selectedPackageTier;

	const { tiers } = associatedProduct.subscriptionPackageSet;

	const selectedTierIdx = tiers.findIndex(tier => tier.id === selectedTier);
	const subscriptionTierIdx = tiers.findIndex(tier => tier.id === subscription.selectedPackageTier);
	const isUpgrade = selectedTierIdx > subscriptionTierIdx;

	return (
		<Modal
			open
			closeIcon
			onClose={onClose}
			size='large'
		>
			<Modal.Header><Icon name='box' /> Skift pakke</Modal.Header>
			<Modal.Content>
				<div style={{ marginBottom: '1em' }}>
					<SubscriptionPackageSelector
						onPackageSelected={(_, id) => setSelectedTier(id)}
						packageSet={associatedProduct.subscriptionPackageSet}
						chosenPackage={selectedTier}
						isChangingPackage={true}
						vat={true}
					/>
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button
					primary
					content={isUpgrade ? 'Bekræft opgradering' : 'Bekræft skift'}
					icon={isUpgrade ? 'level up' : 'exchange'}
					disabled={!choiceIsValid}
					onClick={async () => {
						onClose();
						onChangeTier(associatedProduct.id, selectedTier);
					}}
				/>
			</Modal.Actions>
		</Modal>
	);
};

const SubscriptionOptions = ({ onUnsubscribe }) => {
	const [open, setOpen] = useState(false);
	const breakpoints = useBreakpoints();

	return (
		<Popup
			basic
			on='click'
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			trigger={<Icon name='ellipsis horizontal' link />}
			position={breakpoints.select({
				'eq mobile': 'top right',
				'default': 'top center',
			})}
			content={(
				<ColoredText
					link
					underlined={false}
					iconPosition='left'
					content='Afmeld'
					icon='x'
					onClick={() => {
						onUnsubscribe();
						setOpen(false);
					}}
				/>
			)}
		/>
	);
};

class Subscriptions extends React.Component {
    state = {
		cardInfoModalActive: false,
		working: true,
		idxOfSubscriptionBeingCancelled: -1,
		idxOfSubscriptionBeingUpdated: -1,
        subscriptions: [],
		products: [],
	};

    componentDidMount = async () => {
        await this.doFetchSubscriptions();
		const products = await getProducts();
        this.setState({
            working: false,
			products,
        });
    };

    doFetchSubscriptions = async () => {
        const subscriptions = await getSubscriptionsOverview();
        this.setState({
            subscriptions,
        });
    };

	unsubscribe = async productID => {
		this.setState({ working: true });

		try {
			await payment.cancelSubscription(productID);
			await this.doFetchSubscriptions();
		} catch (e) {
			toast.error('Kunne ikke afmelde abonnementet. Prøv igen eller kontakt supporten');
		}
		
		this.setState({ working: false });
	};
	
	changePackageTier = async (productID, newTier) => {
		this.setState({ working: true });
		
		try {
			await payment.updateSubscriptionPackageTier(productID, newTier);
			await this.doFetchSubscriptions();
			toast.success('Abonnementspakken blev skiftet');
		} catch {
			toast.error('Kunne ikke skifte abonnementspakke');
		}

		this.setState({ working: false });
	};

	getSubscriptionRows = () => {
		const { subscriptions } = this.state;

		return subscriptions.map((sub, subIdx) => {
			const {
				productID,
				productName,
				productIcon,
				price,
				nextRenewal,
				nextTaxYear,
				selectedPackageTier,
			} = sub;

			
			const basePrice = formatNumber(price * 1.25);
			const subPeriodEnd = formatDate(nextRenewal);
			
			const product = this.state.products.find(product => product.id === productID);

			let changeTierLink;

			if (product.subscriptionPackageSet) {
				const lastTier = [...product.subscriptionPackageSet.tiers].pop();
				const hasChosenTheLastTier = selectedPackageTier === lastTier.id;

				const handleChangePackageClicked = () => this.setState({ idxOfSubscriptionBeingUpdated: subIdx });

				if (hasChosenTheLastTier) {
					changeTierLink = (
						<ColoredText
							link
							underlined={false}
							content='Skift'
							icon='exchange'
							iconPosition='left'
							onClick={handleChangePackageClicked}
						/>
					);
				} else {
					changeTierLink = (
						<Button
							primary
							size='tiny'
							content='Opgradér'
							icon='level up'
							style={{ padding: '0.5em 1em' }}
							onClick={handleChangePackageClicked}
						/>
					);
				}
			}

			const productNameJSX = <>
				<Icon name={productIcon} color='green' />
				{productName} {changeTierLink}
			</>;

			const basePriceJSX = (
				<Popup
					trigger={<span>{basePrice},-</span>}
					position='top center'
					content='Pris inkl. moms'
				/>
			);

			return {
				key: productID,
				cells: [
					productNameJSX,
					basePriceJSX,
					subPeriodEnd,
					nextTaxYear,
				],
			};
		});
	};

	renderUnsubscribeConfirm = () => {
		const { idxOfSubscriptionBeingCancelled } = this.state;

		if (idxOfSubscriptionBeingCancelled === -1) {
			return null;
		}

		const subscription = this.state.subscriptions[idxOfSubscriptionBeingCancelled];
		const content = `Er du sikker på at du vil opsige dit abonnement for ${subscription.productName}?`;

		const confirm = <Button basic>Opsig</Button>;
		const cancel  = <Button color='black'>Annuller</Button>;

		return (
			<Confirm
				open
				header='Afmeld'
				confirmButton={confirm}
				cancelButton={cancel}
				content={content}
				onConfirm={async () => {
					await this.unsubscribe(subscription.productID);
					this.setState({ idxOfSubscriptionBeingCancelled: -1 });
				}}
				onCancel={() => this.setState({ idxOfSubscriptionBeingCancelled: -1 })}
			/>
		);
	};

	renderChangePackageModal = () => {
		const { idxOfSubscriptionBeingUpdated } = this.state;
		if (idxOfSubscriptionBeingUpdated === -1) {
			return null;
		}

		const subscription = this.state.subscriptions[idxOfSubscriptionBeingUpdated];

		return (
			<ChangePackageModal
				subscription={subscription}
				products={this.state.products}
				onClose={() => this.setState({ idxOfSubscriptionBeingUpdated: -1 })}
				onChangeTier={this.changePackageTier}
			/>
		);
	};

	renderMissingPaymentMessage = () => {
		const { subscriptions } = this.state;

		const anyUnpaid = subscriptions.some(sub => !sub.paid);

		if (!anyUnpaid) {
			return null;
		}

		return (
			<Message
				header='Betaling mangler'
				content='Ved opdatering af kortoplysninger forsøger vi automatisk at trække penge for abonnementer med manglende betaling.'
				color='yellow'
				icon='info circle'
			/>
		);
	};

    render = () => {
        const { working, subscriptions } = this.state;

        if (working) {
            return <Loader inline='centered' active />;
        }

		if (subscriptions.length === 0) {
			return <div>
                Du har ingen aktive abonnementer på Digital Revisor
            </div>;
        }

		return (
			<div>
				{this.renderMissingPaymentMessage()}
				{this.renderUnsubscribeConfirm()}
				{this.renderChangePackageModal()}
				<ProfileTable
					header={[
						'Pakke',
						'Pris',
						'Fornyes d.',
						'Næste skatteår',
					]}
					body={this.getSubscriptionRows()}
					actions={[
						subIdx => {
							return (
								<SubscriptionOptions
									onUnsubscribe={() => this.setState({ idxOfSubscriptionBeingCancelled: subIdx })}
								/>
							);
						},
					]}
				/>
			</div>
		);

    }
}

export default withRootUserData(Subscriptions);
