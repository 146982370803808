import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    nonPurchasedProductsHeader: i18n({
        [daDK]: 'Du kan tjekke de andre produkter ud:',
        [enUS]: 'Check out our other products:',
    }),
    tryProductsHeader: i18n({
        [daDK]: 'Prøv eller køb produkterne:',
        [enUS]: 'Try or buy our products:',
    }),
    pricePostFix: i18n({
        [daDK]: ',-/år',
        [enUS]: ' pr. year',
    }),
    gotoBtn: i18n({
        [daDK]: 'Gå til',
        [enUS]: 'Go to',
    }),
    noProductsHeader: i18n({
        [daDK]: 'Ingen produkter fundet',
        [enUS]: 'No products found',
    }),
    noProductsBody: i18n({
        [daDK]: 'På nuværende tidspunkt har vi ingen produkter for virksomheder med virksomhedsformen',
        [enUS]: 'We currently have no products available for companies with business form',
    }),
};