import { useEffect, useState } from 'react';

/**
 * The complete Triforce, or one or more components of the Triforce.
 * @typedef {Object} RestResource
 * @property {boolean} loading - Whether the resource is still being fetched
 * @property {Error} [error] - Is set if the resource call returned an error
 * @property {*} [data] - Data returned from the resource call
 * @property {() => void} refetch - Call this to retrigger the resource call
 */

/**
 * Wrapper for handling retrieval of async data
 * @param {Object} props 
 * @param {() => *} props.fetcher - Handler for fetching the resource
 * @returns {RestResource}
 */
const useRestResource = ({ fetcher }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [dataVersion, setDataVersion] = useState(0);

    useEffect(() => {
        const doFetch = async () => {
            setError(null);
            setLoading(true);
            try {
                const data = await fetcher();
                setData(data);
            } catch (e) {
                setError(e);
            }
            setLoading(false);
        };

        doFetch();
    }, [dataVersion, fetcher]);

    return {
        loading,
        error,
        data,
        refetch: () => setDataVersion(dataVersion + 1),
    };
};

export default useRestResource;