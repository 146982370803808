import React, { Component } from 'react'
import { Message, Form, Input, Button } from 'semantic-ui-react';
import { registerCredentials } from 'http/ofinda';

import styles from './connectors.module.scss';

class OfindaConnector extends Component {
    state = { failed: false, working: false };

    setCredentialValue = credProp => {
        return (_, { value }) => {
            this.setState({ [credProp]: value });
        };
    };

    displayErrorMessage = () => {
        const { failed } = this.state;
        if (failed) {
            return <Message negative>
                <Message.Header>Der opstod en fejl</Message.Header>
                <p>Kontroller oplysningerne</p>
            </Message>;
        }
    };

    registerERP = async () => {
        const { companyNo, email, password } = this.state;
        if (!companyNo || !email || !password) {
            return;
        }

        this.setState({ working: true });

        try {
            await registerCredentials(companyNo, email, password);
            this.props.onRegister();
        } catch {
            this.setState({ failed: true, working: false })
        }
    };

    renderCredsForm = () => {
        return <Form>
            <p>Indtast dit regnskab's firma nr., din email og dit kodeord (vi gemmer ikke nogen af disse oplysninger, kun den resulterende API nøgle).</p>
            <p> </p>
            <p>Firma nummeret kan ses øverst til højre på skærmen i Ofinda, for eksempel Firma: 12345.</p>
            <Form.Field>
                <label>Firma Nummer</label>
                <Input
                    placeholder='Indtast Firma Nummer'
                    onChange={this.setCredentialValue('companyNo')}
                    disabled={this.state.working}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                />
            </Form.Field>
            <Form.Field>
                <label>Email</label>
                <Input
                    placeholder='Indtast Email'
                    onChange={this.setCredentialValue('email')}
                    disabled={this.state.working}
                />
            </Form.Field>
            <Form.Field>
                <label>Password</label>
                <Input
                    placeholder='Indtast password'
                    onChange={this.setCredentialValue('password')}
                    disabled={this.state.working}
                    type='password'
                />
            </Form.Field>
        </Form>
    };

    render = () => {
        return (
            <div>
                {this.renderCredsForm()}
                <div className={styles.actionsContainer}>
                    <Button
                        onClick={this.registerERP}
                        disabled={this.state.working}
                        loading={this.state.working}
                        content='Forbind'
                        primary
                    />
                </div>
                {this.displayErrorMessage()}
            </div>
        );
    };
}

export default OfindaConnector;