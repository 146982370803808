import serverErrors from 'i18n/serverErrors';

export class DRError extends Error {
    constructor (message, i18n, status, data = undefined) {
        super(message);
        this.i18n = serverErrors[i18n];
        this.i18nKey = i18n;
        this.status = status;
        this.data = data;
    }
}

class DRResponse {
    /**
     * @returns {boolean} T/F
     */
    wasSuccessful () {
        return this.success;
    }

    /**
     * @returns {string} the message associated with the response
     */
    getMessage () {
        return this.message || this.msg || this.statusText;
    }

    /**
     * @returns {number} the status code of the HTTP response
     */
    getStatusCode () {
        return this.status;
    }

    /**
     * @returns {any} the data associated with the response
     */
    getData () {
        return this.data;
    }

    /**
     * @returns {string|undefined} i18n message key
     */
    getI18n () {
        return this.i18n;
    }
}

export function unmarshalResponse (data) {
    return Object.assign(new DRResponse(), data);
}

export default DRResponse;