import React from 'react';
import { Dropdown, Icon, Popup } from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';

const RoleLabel = ({ id, title, icon, description, color, editable, entityRoles, onChange }) => {
    const iconJSX = (
        <Icon
            name={icon}
            color={color}
        />
    );

    const canEdit = editable && entityRoles.some(role => role.id === id);

    if (canEdit) {
        return (
            <div>
                {iconJSX}
                <Dropdown
                    defaultValue={id}
                    options={entityRoles.map(role => ({ text: role.title, value: role.id }))}
                    onChange={(_, { value }) => onChange(value)}
                />
            </div>
        );
    }

    return (
        <Popup
            content={description}
            position='top center'
            trigger={
                <ColoredText
                    icon={iconJSX}
                    content={title}
                    iconPosition='left'
                />
            }
        />
    );
};

export default RoleLabel;
