import { makeQueryParams } from 'util/QueryParams';
import { HTTPClient } from 'network/fetch';

const client = new HTTPClient({ prefix: 'credits' });

export function getBalance() {
    return client.getResource('credits-box/balance');
}

export function getCreditsBox() {
    return client.getResource('credits-box');
}

export function getCreditsPriceSteps() {
    return client.getResource('price-steps');
}

export function getCreditTransactionEvents(pageIndex = 0, pageSize = 10) {
    const queryParams = makeQueryParams({
        pageIndex,
        pageSize,
    });

    return client.getResource('transaction-events' + queryParams);
}

export function countCreditTransactionEvents() {
    return client.getResource('transaction-events/count');
}

export function getCreditsExpirationDate() {
    return client.getResource('expiration-date-from-now');
}