import React from 'react';
import { Dropdown, Grid, Header } from 'semantic-ui-react';
import { monthNamesDK } from '../../../util/format/DateTime';

function range (from, to) {
    const out = [];
    for (let i = from; i <= to; i++) {
        out.push(i);
    }
    return out;
}

function FilterOptions ({ year, month, onYearChange, onMonthChange, minYear, loading }) {
    const currentYear = new Date().getFullYear();
    return <>
        <Header size='large' style={{ textAlign: 'center' }}>
            Historik for {monthNamesDK[month - 1]} {year}
        </Header>
        <Grid stackable columns={2} textAlign='center'>
            <Grid.Row>
                <Grid.Column>
                    <span>Måned: </span>
                    <Dropdown
                        selection
                        disabled={loading}
                        defaultValue={month}
                        placeholder='Måned'
                        options={monthNamesDK.map((m, i) => ({
                            text: m, value: i + 1,
                        }))}
                        onChange={(_, { value }) => onMonthChange(value)}
                    />
                </Grid.Column>
                <Grid.Column>
                    <span>År: </span>
                    <Dropdown
                        selection
                        disabled={loading}
                        defaultValue={year}
                        placeholder='Årstal'
                        options={range(minYear, currentYear).map(year => ({
                            text: year,
                            value: year,
                        }))}
                        onChange={(_, { value }) => onYearChange(value)}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </>;
}

export default FilterOptions;