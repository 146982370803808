import React, { useLayoutEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import Stepper from 'design/atoms/Stepper';
import ColoredText from 'design/atoms/ColoredText';

const PaymentStepper = ({ containerID, selectedIndex, onStepSelected, disabled, paymentDone, steps = [] }) => {
    const [stepperWidth, setStepperWidth] = useState(0);

    useLayoutEffect(() => {
        const widthUpdator = () => {
            const productPageContainer = document.getElementById(containerID);
            const productWrapperWidth = productPageContainer?.clientWidth;
            const pageWidth = document.body.clientWidth;
            const ratio = productWrapperWidth / pageWidth;
            const stepperWidth = ratio * 100;
            setStepperWidth(stepperWidth);
        }; 

        widthUpdator();

        window.addEventListener('resize', widthUpdator);
        return () => {
            window.removeEventListener('resize', widthUpdator);
        };
    }, [containerID]);

    const renderSingleStep = ({ icon, title, isSelected, isCompleted, onClick }) => {
        const iconToShow = (
            (paymentDone || isCompleted)
                ? <Icon name='check circle' color='green' />
                : <Icon name={icon} color={isSelected ? 'green' :'black'} />
        );

        const titleToShow = (
            <ColoredText
                bold
                content={title}
                color={(paymentDone || isCompleted) ? 'green' : 'black'}
            />
        );

        return {
            icon: iconToShow,
            title: titleToShow,
            locked: false,
            isCompleted,
            isSelected,
            bgColorCompleted: '#edf9f1',
            bgColorSelected: '#d3f1de',
            borderColor: '#46C476',
            onSelected: onClick,
        };
    };

    return (
        <div style={{ 
            borderBottom: '1px solid lightgray',
            pointerEvents: disabled ? 'none' : undefined,
        }}>
            <Stepper
                selectedStepIndex={selectedIndex}
                offset={100 - stepperWidth}
                height={50}
                steps={steps.map((step, stepIdx) => {
                    return renderSingleStep({
                        ...step,
                        isCompleted: selectedIndex > stepIdx,
                        isSelected: selectedIndex === stepIdx,
                        onClick: () => {
                            if (disabled) return;
                            if (step.disabled) return;
                            onStepSelected(stepIdx)
                        },
                    });
                })}
            />
        </div>
    );
};

export default PaymentStepper;