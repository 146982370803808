import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader, Message } from 'semantic-ui-react';
import { ACCOUNTANT_COURSE_PRODUCT_ID } from 'config/config';
import { getAddonProductByID, getAddonProductPurchases } from 'http/payment';
import { getEntityLogins } from 'http/accounts';
import { useRootUser } from 'util/useUser';
import { getParam } from 'util/QueryParams';
import ColoredText from 'design/atoms/ColoredText';

import { CartItem, ShoppingCartSegment } from '../shared/ShoppingCart';
import usePaymentState from '../shared/usePaymentState';
import PaymentShell from '../shared/PaymentShell';
import PaymentPanels from '../shared/PaymentPanels';
import PaymentOverview from '../shared/PaymentOverview';
import HighFive from '../shared/HighFive';
import useDeferredRedirect from 'util/useDeferredRedirect';

const AccountantCoursePaymentFlow = () => {
    const [loading, setLoading] = useState(true);
    const [loginsWithoutAccess, setLoginsWithoutAccess] = useState([]);
    const [courseProduct, setCourseProduct] = useState(null);
    const [selectedLoginIds, setSelectedLoginIds] = useState([]);
    const [selectedStepIdx, setSelectedStepIdx] = useState(0);
    const history = useHistory();
    const user = useRootUser();

    const userIsAuthorizedToPurchase = user.isEntityAdmin();

    useEffect(() => {
        if (!userIsAuthorizedToPurchase) {
            return;
        }

        const fetchData = async () => {
            const [
                entityLogins,
                purchases,
                courseProduct,
            ] = await Promise.all([
                getEntityLogins(),
                getAddonProductPurchases(ACCOUNTANT_COURSE_PRODUCT_ID),
                getAddonProductByID(ACCOUNTANT_COURSE_PRODUCT_ID),
            ]);
            
            const loginsWithoutAccess = entityLogins.filter(login => {
                const hasPurchased = purchases.some(purchase => {
                    return purchase.data.courseAttendeeLoginID === login.id;
                });

                return !hasPurchased;
            });

            setCourseProduct(courseProduct);
            setLoginsWithoutAccess(loginsWithoutAccess);
            setLoading(false);
        };

        fetchData();
    }, [history, userIsAuthorizedToPurchase]);

    const orderLines = selectedLoginIds.map(loginID => {
        const login = loginsWithoutAccess.find(entity => entity.id === loginID);
        return {
            description: login.email,
            price: courseProduct.price,
            onClear: () => toggleSelectionByID(login.id),
            metadata: {
                addonProductPurchase: {
                    productID: ACCOUNTANT_COURSE_PRODUCT_ID,
                    data: {
                        courseAttendeeLoginID: login.id,
                    },
                },
            },
        };
    });

    const paymentState = usePaymentState({
        orderLines,
    });

    useDeferredRedirect(paymentState.paymentDone, getParam('redirect') || '/');

    const toggleSelectionByID = id => {
        const selectedLoginsCopy = [...selectedLoginIds];

        if (selectedLoginsCopy.includes(id)) {
            selectedLoginsCopy.splice(selectedLoginsCopy.indexOf(id), 1)
        } else {
            selectedLoginsCopy.push(id);
        }

        setSelectedLoginIds(selectedLoginsCopy);
    };

    const renderBasketSegment = ({ title, icon, logins, inBasket }) => {
        return (
            <ShoppingCartSegment title={title} icon={icon}>
                {logins.map(({ id, email }) => {
                    const label = (
                        <span>
                            Kursusadgang
                            <ColoredText color='grey'>&nbsp;∙&nbsp;</ColoredText>
                            <b>{email}</b>
                        </span>
                    );

                    const description = 'Giver adgang til Digital Revisors opstartskursus';

                    return (
                        <CartItem
                            icon='user'
                            id={id}
                            name={label}
                            description={description}
                            chosen={inBasket}
                            onChoose={() => toggleSelectionByID(id)}
                            price={courseProduct.price}
                        />
                    );
                })}

                {logins.length === 0 && (
                    <i>Der er ikke flere kursister at købe adgang til</i>
                )}
            </ShoppingCartSegment>
        );
    };

    const renderAvailableCourseMembers = () => {
        if (loading) {
            return (
                <Loader
                    inline='centered'
                    active
                />
            );
        }

        return (
            <div>
                {renderBasketSegment({
                    title: 'Vælg kursister at købe adgang til',
                    icon: 'users',
                    inBasket: false,
                    logins: loginsWithoutAccess.filter(login => {
                        return !selectedLoginIds.includes(login.id);
                    }),
                })}
            </div>
        );
    };
    
    const steps = [
        {
            title: 'Vælg kursister',
            icon: 'users',
            hideCoupon: false,
            renderContent: renderAvailableCourseMembers,
            renderCallToAction: () => (
                <Button
                    primary
                    fluid
                    disabled={selectedLoginIds.length === 0}
                    content='Forsæt til betaling'
                    icon='arrow right'
                    labelPosition='right'
                    onClick={() => setSelectedStepIdx(selectedStepIdx + 1)}
                />
            ),
        },
        {
            title: 'Betaling',
            icon: 'credit card outline',
            disabled: selectedLoginIds.length === 0,
            hideCoupon: true,
            renderContent: () => paymentState.renderPaymentMethod(),
            renderCallToAction: () => (
                <div>
                    {paymentState.renderPayButton(paymentState.performPaymentRequest)}
                    {paymentState.renderError()}
                </div>
            ),
        },
    ];

    const renderContent = () => {
        if (!userIsAuthorizedToPurchase) {
            return (
                <Message>
                    <Icon name='info circle' />
                    Du har ikke rettighed til at administrere adgange til kurset.
                    Kontakt en administrator af {user.displayName} for at få adgang.
                </Message>
            );
        }

        const selectedStep = steps[selectedStepIdx];

        if (paymentState.paymentDone) {
            return <HighFive />;
        }

        return (
            <PaymentPanels
                leftPanel={selectedStep.renderContent()}
                rightPanel={
                    <PaymentOverview
                        initialCoupon={paymentState.couponCode}
                        onCouponChange={paymentState.setCoupon}
                        orderLines={orderLines}
                        hideCouponField={selectedStep.hideCoupon}
                        extraContent={selectedStep.renderCallToAction()}
                    />
                }
            />
        );
    };

    return (
        <PaymentShell
            paymentDone={paymentState.paymentDone}
            title='Køb kursusadgang'
            selectedStepIdx={selectedStepIdx}
            disabled={paymentState.working}
            onSelectedStepChange={setSelectedStepIdx}
            steps={steps}
            children={renderContent()}
        />
    );
};

export default AccountantCoursePaymentFlow;