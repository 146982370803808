import React, { useEffect, useState } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { getParam } from 'util/QueryParams';
import { unsetParam } from 'util/QueryParams';
import { verifyEmail } from 'http/accounts';
import { refreshTokens } from 'network/fetch/refreshTokens';
import ProductPage from 'design/molecules/ProductPage';
import ColoredText from 'design/atoms/ColoredText';
import FormBox from 'design/atoms/FormBox';

const CODE_PARAM = 'code';

const VerifyEmail = () => {
    const [verifying, setVerifying] = useState(true);
    const [error, setError] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const verificationCode = getParam(CODE_PARAM);
        const confirmCode = async () => {    
            // verify code provided
            if (!verificationCode) {
                setError(true);
                return;
            }
    
            // perform email validation
            try {
                await verifyEmail(verificationCode);
                await refreshTokens();
                unsetParam(CODE_PARAM);
            } catch {
                setError(true);
            }
        };

        confirmCode()
            .then(() => setVerifying(false));
    }, []);

    const renderContent = () => {
        if (verifying) {
            return (
                <center>
                    <Loader
                        inline='centered'
                        size='huge'
                        active
                    />
                    <p>Verificerer email...</p>
                </center>
            );
        }

        let icon;
        let color;
        let header;
        let content;

        let linkText;
        let linkHref;

        if (error) {
            icon = 'warning circle';
            color = 'grey';
            header = 'Vi kunne ikke verificere din e-mail';
            content = <span>
                Har du fået tilsendt flere forskellige verificeringsemails fra os,
                bedes du klikke på linket fra den seneste mail. <br />
                Alternativt kan du få tilsendt et nyt verificeringslink fra dine profilindstillinger.
            </span>;

            linkText = 'Gå til indstillinger';
            linkHref = '/indstillinger';
        } else {
            icon = 'check circle';
            color = 'green';
            header = 'E-mail verificeret';
            content = <span>Din mailadresse er nu blevet verificeret!</span>;

            linkText = 'Gå til forsiden';
            linkHref = '/';
        }

        return (
            <div>
                <Message
                    icon={icon}
                    color={color}
                    header={header}
                    content={content}
                />
                <center>
                    <ColoredText
                        icon='arrow right'
                        content={linkText}
                        onClick={() => history.push(linkHref)}
                        link
                    />
                </center>
            </div>
        );
    };

    return (
        <ProductPage>
            <FormBox>
                {renderContent()}
            </FormBox>
        </ProductPage>
    );
};

export default VerifyEmail;