/*
    See copies of this module here:
        - admin: "src/pages/AccountsDetail/ProductStatesBusiness.js"
        - product-engine: "model/product_states.go"
        - rest-api: "src/util/productStatuses.js"
*/
export const statusesMap = {
    // this is the default state
    // (there are no such fact maps in mongo, because there is no fact mapo yet at this point)
    not_started: { // need tooptip? - icon appears for enduser if tooltip is added to object
        id: 'not_started',
        displayText: 'Ikke påbegyndt',
        color: 'grey',
        icon: 'exclamation circle',
        step: 0,
    },
    started: { // need tooptip?
        id: 'started',
        displayText: 'Påbegyndt',
        color: 'yellow',
        icon: 'cog',
        step: 1,
    },

    erp_data_fetched: {
        id: 'erp_data_fetched',
        displayText: 'Bogføringsdata hentet',
        color: 'yellow',
        icon: 'calculator',
        tooltip: 'Vi har importeret din kontoplan til Digital Revisor',
        step: 2,
    },
    signature_flow: {
        id: 'signature_flow',
        displayText: 'Underskrives',
        color: 'yellow',
        icon: 'pencil alternate',
        tooltip: 'Rapporten underskrives af virksomhedens interessenter',
        step: 3,
        deprecated: true,
    },
    visma_signature_flow: {
        id: 'visma_signature_flow',
        displayText: 'Underskrives',
        color: 'yellow',
        icon: 'pencil alternate',
        tooltip: 'Rapporten underskrives af virksomhedens interessenter',
        step: 3,
        deprecated: true,
    },
    esignatur_flow: {
        id: 'esignatur_flow',
        displayText: 'Underskrives',
        color: 'yellow',
        icon: 'pencil alternate',
        tooltip: 'Rapporten underskrives af virksomhedens interessenter',
        step: 3,
    },
    // historical. do not use anymore
    // set when user clicks "Afslut". Used to disable the "Send to signature" button
    completed: { // need tooptip?
        id: 'completed',
        displayText: 'Fuldført',
        color: 'green',
        icon: 'check',
        step: 4,
        deprecated: true,
    },
    // horrible bad id... but signifies that digital signing flow is done.
    // product-engine handler_penneo_done.go markerer produktet som "done" når det kommer tilbage fra underskrift
    // Same for Visma
    done: { // need tooltip?
        id: 'done',
        displayText: 'Underskrevet',
        color: 'green',
        icon: 'check',
        step: 4,
    },
    reported: {
        id: 'reported',
        displayText: 'Indberettet',
        color: 'green',
        icon: 'flag checkered',
        tooltip: 'Årsrapporten er afsluttet og indberettet',
        step: 5,
    },  
};

export const userStatusesEnum = {
    NOT_STARTED: statusesMap.not_started.id,
    STARTED: statusesMap.started.id,
    COMPLETED: statusesMap.completed.id,
    DONE: statusesMap.done.id,
    ERP_DATA_FETCHED: statusesMap.erp_data_fetched.id,
    SIGNATURE_FLOW: statusesMap.signature_flow.id,
    VISMA_SIGNATURE_FLOW: statusesMap.visma_signature_flow.id,
    ESIGNATUR_FLOW: statusesMap.esignatur_flow.id,
    REPORTED: statusesMap.reported.id,
};

export function translateStatus (statusID) {
    return statusesMap[statusID] || null;
}
