import React, { Component } from 'react';
import { Icon, Popup, Transition } from 'semantic-ui-react';
import styles from './styles.module.scss';

class LoadIndicator extends Component {
    render () {
        const { loading, windup } = this.props;
        const spinner = (
            <Icon
                name='circle notch'
                size='huge'
                loading={true}
                color='orange'
            />
        );
        const popup = (
            <Popup
                trigger={spinner}
                content='Arbejder...'
                position='top center'
                inverted={false}
            /> 
        );
        return (
            <Transition.Group animation='fade' duration={windup || 500}>
                {
                    loading && (
                        <div className={`${styles.loaderContainer} ${styles.thousand}`}>
                            { popup }
                        </div>
                    )
                }
            </Transition.Group>
        );
    }
}

export default LoadIndicator;