import React, { useEffect, useState } from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import { subscribe, unsubscribe } from 'util/pubsub';

import { Media } from '../Media';
import ColoredText from '../ColoredText';
import FoldableSegment from '../FoldableSegment';

const FieldGroup = ({
    children,
    title,
    topic,
    id,
    onClick,
    collapsible,
    unwrapped,
    completedQuestions,
    totalQuestions,
    icon,
    progress,
    error,
    defaultOpen = false
}) => {
    const [open, setOpen] = useState(collapsible ? defaultOpen : true);

    useEffect(() => {
        const subTopics = [];
        id && subTopics.push(id);
        topic && subTopics.push(topic);

        const subscriptions = subTopics.map((topic) => {
            return subscribe(topic, open => {
                setOpen(open);
            });
        });

        return () => {
            subscriptions.forEach(unsubscribe);
        };
    }, [topic, id]);

    const hasProgress = () => {
        return typeof progress === 'number';
    };

    let completionIndicator;

    if (hasProgress()) {
        completionIndicator = totalQuestions && (
            <Media gte='tablet'>
                <ColoredText
                    content={`${completedQuestions}/${totalQuestions}`}
                />
            </Media>
        );
    }

    if (icon) {
        completionIndicator = (
            <span>
                <Icon name={icon} color='grey' />
            </span>
        );
    }

    const isOpen = !collapsible || open;

    const content = (
        unwrapped
            ? (
                <Segment.Group
                    children={children}
                    style={{ border: 'none', boxShadow: 'none'}}
                />
            )
            : children
    );

    return (
        <FoldableSegment
            onClick={onClick}
            title={title}
            progress={progress}
            rightContent={completionIndicator}
            error={error}
            defaultOpen={isOpen}
            collapsible={collapsible}
            unwrapped={unwrapped}
            children={content}
            isOpen={open}
            setIsOpen={setOpen}
        />
    );
};

export default FieldGroup;
