import { downloadPublishment, getPDFPublishments } from 'http/cvr';
import React, { useEffect, useState } from 'react';
import { Dropdown, Transition } from 'semantic-ui-react';
import { formatDate } from 'util/format/DateTime';
import useUser from 'util/useUser';

const PublishmentsViewer = () => {
    const [publishments, setPublishments] = useState([]);
    const [selectedPublishmentIdx, setSelectedPublishmentIdx] = useState(-1);
    const [downloadedPublishment, setDownloadedPublishment] = useState(null);
    
    const user = useUser();

    useEffect(() => {
        if (!user.isAdmin()) return;
        if (!user.cvr) return;

        const fetchPublishments = async () => {
            const rawPublishments = await getPDFPublishments(user.cvr);
            
            const formattedPublishments = rawPublishments.map(publishment => {
                return {
                    start: new Date(publishment.period.startDato),
                    end: new Date(publishment.period.slutDato),
                    url: publishment.documentUrl,
                };
            });

            formattedPublishments.sort((pubA, pubB) => {
                return pubB.start.getTime() - pubA.start.getTime();
            });

            setPublishments(formattedPublishments);
        };

        fetchPublishments();
    }, [user]);

    useEffect(() => {
        if (publishments.length === 0) return setDownloadedPublishment(null);
        if (selectedPublishmentIdx === -1) return setDownloadedPublishment(null);

        const doDownloadPublishment = async () => {
            const { url } = publishments[selectedPublishmentIdx];
            const { document } = await downloadPublishment(url);
            setDownloadedPublishment(document);
        };

        doDownloadPublishment();

    }, [selectedPublishmentIdx, publishments]);

    const renderDropdown = () => (
        <Dropdown
            selection
            search
            placeholder='Vælg offentliggørelse'
            onChange={(_, { value }) => {
                const chosenPublishmentIdx = publishments.findIndex(p => p.url === value);
                setSelectedPublishmentIdx(chosenPublishmentIdx);
            }}
            options={[
                { text: 'Ingen', value: '' },
                ...publishments.map(({ start, end, url }) => {
                    const period = `${formatDate(start)} - ${formatDate(end)}`;
                    return {
                        text: period,
                        value: url,
                    };
                }),
            ]}
        />
    );

    const renderPublishment = () => {
        if (!downloadedPublishment) return null;

        return (
            <object
                data={`data:application/pdf;base64,${downloadedPublishment}`}
                children='PDF'
                style={{
                    marginTop: '1em',
                    width: '100%',
                    height: '600px',
                }}
            />
        );
    };

    return (
        <Transition visible={publishments.length > 0} animation='fade down'>
            <div style={{ textAlign: 'center', marginBottom: '1em' }}>
                {renderDropdown()}
                {renderPublishment()}
            </div>
        </Transition>
    );
};

export default PublishmentsViewer;