import React, { useEffect, useState } from 'react';
import { Loader, Message, Segment } from 'semantic-ui-react';
import { acceptEntityInvitation } from 'http/accounts';
import { refreshTokens } from 'network/fetch/refreshTokens';
import SegmentHeader from 'design/atoms/SegmentHeader';

// people invited to paricipate in certain entities
// wo do confirmation from this page
const InvitationConfirmer = ({ token }) => {
    const [working, setWorking] = useState(true);
    const [err, setErr] = useState(false);
    const [displayName, setDisplayName] = useState(null);

    useEffect(() => {
        if (!token) {
            return;
        }

        const handleTokenChanged = async () => {
            try {
                const { entityDisplayName } = await acceptEntityInvitation(token);
                setDisplayName(entityDisplayName);
                await refreshTokens();
            } catch {
                setErr(true);
            }
            setWorking(false);
        };
        handleTokenChanged();
    }, [token]);

    const renderContent = () => {
        if (working) {
            return <Loader inline='centered' active />;
        }

        if (err) {
            return (
                <Message
                    icon='warning circle'
                    content='Invitationen kunne ikke accepteres'
                />
            );
        }

        return (
            <Message
                positive
                header='Invitation accepteret'
                content={`Du kan nu få adgang til ${displayName} gennem menubjælken`}
            />
        );
    };

    return (
        <Segment>
            <SegmentHeader content='Invitation' icon='mail' />
            {renderContent()}
        </Segment>
    );
};

export default InvitationConfirmer;