import React from 'react';

export default class CvrOverview extends React.Component {
    constructor (props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount () {
        this.fetchData();
    }

    fetchData () {
        const { onChange, tag } = this.props;
        onChange(tag, {
            number: new Date().valueOf(),
        });
    }

    render () {
        return null;
    }
}
