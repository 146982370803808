import React, { useEffect, useRef, useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { publish, subscribe, unsubscribe } from 'util/pubsub';

import styles from './ContextMenu.module.scss';

const CTX_MENU_OPENED = 'CTX_MENU_OPENED';

const ContextMenu = ({ children, options, header }) => {
    const [open, setOpen] = useState(false);
    const [[left, top], setPosition] = useState([0, 0]);
    const myID = useRef(Math.random());

    const disabled = !options || options.length === 0;

    useEffect(() => {
        const subscription = subscribe(CTX_MENU_OPENED, oid => {
            if (oid !== myID) setOpen(false);
        });

        return () => unsubscribe(subscription);
    }, [myID]);

    const doSetOpen = (e) => {
        e.preventDefault();
        
        if (disabled) return;

        setPosition([e.clientX, e.clientY]);
        setOpen(true);
        publish(CTX_MENU_OPENED, myID);
    };

    const renderMenu = () => {
        if (!open) {
            return null;
        }

        const menuContent = (
            <div style={{ display: 'flex', gap: '0.25em', flexDirection: 'column' }}>
                {header && (
                    <div>
                        <strong>{header}</strong>
                    </div>
                )}

                {options.map(({ text, icon, onSelect }) => (
                    <div
                        className={styles.item}
                        children={<span>{icon && <Icon name={icon} />}&nbsp;{text}</span>}
                        onClick={() => {
                            setOpen(false);
                            onSelect();
                        }}
                    />
                ))}
            </div>
        );

        const trigger = (
            <div
                style={{
                    position: 'fixed',
                    left,
                    top,
                }}
            />
        );

        return (
            <Popup
                open
                basic
                hideOnScroll
                onContextMenu={e => e.preventDefault()}
                onClose={() => setOpen(false)}
                content={menuContent}
                trigger={trigger}
                offset={[-2, -12]}
                style={{ padding: '0.75em' }}
                position='bottom left'
                on='click'
            />
        );
    };

    return (
        <>
            {children(doSetOpen)}
            {renderMenu()}
        </>
    );
};

export default ContextMenu;
