import React, { useState } from 'react';
import { Grid, Icon, Segment } from 'semantic-ui-react';
import { Media } from 'design/atoms/Media';
import ColoredText from '../../../../../atoms/ColoredText';

import styles from './CheckAccordion.module.scss';
import { compileMarkdown } from 'util/markdown';

const sccssColor = 'green';
const sccssIcon  = 'check circle';
const sccssTitle = 'Godkendt';

// "Tjekliste" detail view
//
// disabled: Will render "Godkendt" state without color. Otherwise no changes
const CheckAccordion = ({ list, msgOnFail, failHeader, icon, color, openUnsuccessfulChecks: ouc, disabled=false }) => {
    // const [ open, setOpen ] = useState(!!ouc && !success);
    const [ openPanels, setOpenPanels ] = useState(list.map(li => !!ouc && !li.success));

    const panelClicked = i => {
        const newState = [ ...openPanels ];
        newState[i] = !newState[i];
        setOpenPanels(newState);
    };

    const panels = list.map(({ title, messages, success }, i) => {
        const colorToUse = success ? sccssColor : color;
        const iconToUse  = success ? sccssIcon  : icon; 
        const titleText  = success ? sccssTitle : msgOnFail;
        
        const isActive = openPanels[i];
        const titleIcon  = isActive ? 'caret down' : 'caret right';
        const titleCname = isActive ? styles.enabledTitle : styles.disabledTitle;

        const titlec = <Grid columns={2} onClick={() => panelClicked(i)}>
            <Grid.Column width={12}>
                <strong className={titleCname}>
                    <Icon name={titleIcon} />
                    {title}
                </strong>
            </Grid.Column>
            <Grid.Column width={4} textAlign='right'>
                <Media gte='tablet'>
                    <ColoredText
                        disabled={disabled}
                        color={colorToUse}
                        content={<span>{titleText} <Icon name={iconToUse} /></span>}
                    />
                </Media>
                <Media lt='tablet'>
                    <Icon name={iconToUse} color={colorToUse} />
                </Media>
            </Grid.Column>
        </Grid>;

        let messageList;
        if (success) {
            messageList = <span>Tjekket bestod vores kvalitetskontrol</span>;
        } else {
            messageList = <Segment.Group>
                {messages.map(m => {
                    return (
                        <Segment key={m}>
                            <Icon name={iconToUse} color={colorToUse} />
                            <b>{failHeader}</b>
                            {compileMarkdown(m)}
                        </Segment>
                    );
                })}
            </Segment.Group>;
        }

        return <Segment
            className={styles.accordionItem}
            key={`${title}${messages.join()}${success}`}
            content={<>
                {titlec}
                {isActive && <Segment basic>{messageList}</Segment>}
            </>}
        />
    });

    return <Segment.Group children={panels} />;
};

export default CheckAccordion;
