import cn from 'classnames';
import React, { useState } from 'react';
import { Container, Loader, Segment } from 'semantic-ui-react';
import { getParam } from 'util/QueryParams';
import { background } from 'styles/colors';
import PageHeader from 'design/atoms/PageHeader';
import Menu from '../Menu';

import styles from './ProductPage.module.scss';

const ShellContext = React.createContext({ hidden: false, hiddenFooter: false, bgColor: background });

const fromQuery = (qparam, def) => {
	const pval = getParam(qparam);
	return pval || def;
};

export const ShellProvider = ({ children }) => {
	const [hidden, setHidden] = useState(fromQuery('hideshell', false));
	const [bgColor, setBgColor] = useState(fromQuery('bgcolor', background));

	return (
		<ShellContext.Provider value={{
			hidden,
			setHidden,

			bgColor,
			setBgColor,
		}}>
			{children}
		</ShellContext.Provider>
	);
};

export const useShell = () => React.useContext(ShellContext);

function ProductPage ({ loading, children, className, style, noContainer, short, subHeader, backgroundColor }) {
	const { hidden, bgColor } = useShell();

	const wrapperStyle = {
		background: backgroundColor || bgColor,
	};
	
	if (hidden) {
		const className = cn(styles.pageWrapper, styles.portal);
		return <div className={className} style={wrapperStyle}>
			{children}
		</div>;
	}

	const renderContent = () => {
		if (loading) {
			return (
				<Segment basic textAlign='center'>
					<Loader
						size='massive'
						inline
						active
					/>
				</Segment>
			);
		}

		if (noContainer) {
			return children;
		}

		return (
			<>
				{ !short && <br /> }
					<Container className={className} style={style}>
						{children}
					</Container>
				{ !short && <br /> }
			</>
		);
	};

	return (
		<div className={styles.pageWrapper} style={wrapperStyle}>
			<div className={styles.pageContent}>
				<div id='product-page__header' style={{ position: 'sticky', top: '0', zIndex: 20 }}>
					<Menu />
					{subHeader}
				</div>
				
				{renderContent()}
			</div>
		</div>
	);
}

export function ProductPageWithSubHeader({ subHeader, children }) {
	return (
		<ProductPage subHeader={<PageHeader content={subHeader} />}>
			{children}
		</ProductPage>
	);
}

export default ProductPage;
