import React from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Icon, Segment } from 'semantic-ui-react';

import { IS_PRODUCTION } from 'config/config';
import i18n from 'i18n/pages/SomethingWentWrong';
import ProductPage from 'design/molecules/ProductPage';
import ColoredText from 'design/atoms/ColoredText';



function SomethingWentWrong ({ history }) {
    const goBack = () => history.goBack();
    const goHome = () => history.push('/');

    const header = (
        IS_PRODUCTION ?
        i18n.sorry :
        '"Hvad har de nu lavet?"'
    );

    return <ProductPage>
        <Segment textAlign='center'>
            <Header icon>
                <Icon name='bug' />
                {header}
            </Header>
            <p>{i18n.unexpectedError}</p>
            <p>
                {i18n.options.youCanTryTo} <ColoredText link onClick={goBack}>{i18n.options.gotoPreviousPage}</ColoredText>
                <span> {i18n.options.or}</span> <ColoredText link onClick={goHome}>{i18n.options.gotoFrontpage}</ColoredText>.
            </p>
        </Segment>
    </ProductPage>;
}

export default withRouter(SomethingWentWrong);