/**
 * The following RegularTawkTo component is meant to be used by logged in users
 */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { reportWarn } from 'http/api';
import withUserData from 'util/withUserData';
import * as tawk from 'util/tawkTo';

// pathname throttle timeout
let reportPathnameTimeout = null;

const RegularTawkTo = ({ userData }) => {
    const [initialized, setInitialized] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (initialized) {
            tawk.showWidget();
        }
    }, [initialized]);

    useEffect(() => {
        window.clearTimeout(reportPathnameTimeout);
        reportPathnameTimeout = window.setTimeout(async () => {
            try {
                await tawk.initWidget(userData);
                await tawk.setImpersonationURL(userData, location.pathname);
            } catch (e) {
                reportWarn('failed to set tawk.to impersonation path', {
                    error: e.message,
                });
            }
        }, 1000);
    }, [location.pathname, userData]);

    useEffect(() => {
        // ensure that access token has been fetched
        if (userData?.tokenType !== 'access') {
            return;
        }

        (async () => {
            try {
                await tawk.initWidget(userData); 
                await tawk.setUserAttributes(userData);
                setInitialized(true);
            } catch (e) {
                reportWarn('failed tawk.to operation', {
                    error: e.message,
                });
            }
        })();
    }, [userData]);

    return null;
};

export default withUserData(RegularTawkTo);
