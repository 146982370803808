export const styles = {
    mainContainer: {
        'font-family': 'Inter',
        'width': '94%',
        'margin': '0 auto',
    },
    mainHeader: {
        'text-align': 'center',
    },
    subHeader: {
        'text-align': 'center',
        'color': '#8c8888',
    },
    left: {
        'float': 'left',
    },
    right: {
        'float': 'right',
    },
    valueRow: {
        'padding-bottom': '10px',
    },
    logo: {
        width: '150px',
    },
};