import React           		from 'react';
import PropTypes 			from 'prop-types';
import { formatISODate }    from 'util/format/DateTime';
import DatePicker           from 'design/atoms/DatePicker';
import Form        		    from 'design/atoms/Form';
import FileAttachmentWidget from 'design/molecules/FileAttachmentWidget';
import Tooltip         		from 'design/atoms/Tooltip';

class DateWidget extends React.Component {
    static propTypes = {
        date: PropTypes.number,
		label: PropTypes.string,
		error: PropTypes.string,
		minDate: PropTypes.number,
		maxDate: PropTypes.number,
        isLocked: PropTypes.bool,
		tooltip: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.array,
        ]),
    };

    static defaultProps = {
		error: null,
		minDate: 0,
	};

	handleChange = date => {
		const { onChange, supply } = this.props;
		onChange(supply, { date: formatISODate(date) });
	};

	giveFocus = () => {
        if (!this.props.isLocked) {
            this.datePickerField.setOpen(true);
        }
	};

	getTooltip = () => {
		const data = this.props.tooltip;
		if (data) {
			return (<Tooltip data={data} />);
		} else {
			return null;
		}
	};

	renderDatePicker = () => {
		let { defaultValue, minDate, maxDate, isLocked } = this.props;
		minDate = minDate || 0;
		maxDate = maxDate || new Date(new Date().getFullYear() + 10, 11, 31);

		const min = new Date(minDate);
		const max = new Date(maxDate);
		let value;
		if (defaultValue) {
			value = new Date(defaultValue);
		}
		return <DatePicker
			minDate={min}
			maxDate={max}
			onChange={this.handleChange}
			value={value}
			disabled={isLocked}
			leftAttachment={<FileAttachmentWidget {...this.props} />}
		/>;
	};

	render () {
		const { label, tooltip, youtubeVideo, error } = this.props;
		return (
			<Form.Row>
				<Form.LabelField
					label={label}
					tooltip={tooltip}
					youtubeVideo={youtubeVideo}
				/>
				<Form.Field error={error} textAlign='right'>
					{this.renderDatePicker()}
				</Form.Field>
			</Form.Row>
		);
	}
}

export default DateWidget;
