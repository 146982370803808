import i18n from '../../../../i18n';
import lc from '../../../../languageCodes';

const { daDK, enUS } = lc;

export default {
    lastYear: i18n({
        [daDK]: 'sidste år',
        [enUS]: 'last year',
    }),
};