import React from 'react';
import { formatNumber } from 'util/format/Number';
import waves1 from 'images/waves1.svg';
import waves2 from 'images/waves2.svg';
import { moms } from 'util/moms';
import Package from './Package';
import { useBreakpoints } from '../Media';

const backgroundImages = [
    waves1,
    waves2,
];

const SubscriptionPackageSelector = ({ packageSet, onPackageSelected, chosenPackage, vat, isChangingPackage = false }) => {
    const breakpoints = useBreakpoints();

    const idxOfChosenPackage = packageSet.tiers.findIndex(tier => tier.id === chosenPackage);

    const packages = packageSet.tiers.map(({ id, title, semanticIcon, price, description, lockedFeatures }, packageIdx) => {
        const priceToDisplay = vat ? moms(price) : price;
        return (
            <Package
                key={id}
                title={title}
                isSelected={chosenPackage === id}
                icon={semanticIcon}
                description={description}
                features={packageSet.features}
                lockedFeatures={lockedFeatures}
                isChangingPackage={isChangingPackage}
                isUpgrade={packageIdx > idxOfChosenPackage}
                bgImage={backgroundImages[packageIdx % backgroundImages.length]}
                price={formatNumber(priceToDisplay)}
                onChoose={() => onPackageSelected(packageIdx, id)}
            />
        );
    });

    return breakpoints.select({
        'gte computer': (
            <div
                style={{
                    display: 'flex',
                    borderRadius: '0.5em',
                    justifyContent: 'center',
                    gap: '6em',
                }}
                children={packages}
            />
        ),
        'default': (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '0.5em',
                    alignItems: 'center',
                    gap: '3em',
                }}
                children={[...packages].reverse()}
            />
        ),
    });
};

export default SubscriptionPackageSelector;