import React, { useRef, useState, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';

const AutoHeight = ({ children, ...props }) => {
    const ref = useRef(null);
    const [height, setHeight] = useState('auto');

    useEffect(() => {
        const element = ref.current;
        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight + 0.5);
        });
        resizeObserver.observe(element);
        return () => resizeObserver.disconnect();
    }, []);

    return (
        <AnimateHeight
            {...props}
            contentRef={ref}
            height={height}
            contentClassName="auto-content"
            disableDisplayNone
        >
            {children}
        </AnimateHeight>
    );
};

export default AutoHeight;
