import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Header, Form, Checkbox, FormGroup, Button, Divider } from 'semantic-ui-react';

/* Here we write out the Google Form 
     In the future we can make this more dynamic by fetching the form from the backend
*/
export const renderForm = () => {
    let formInfo = [];
    let formInfoItem;

    formInfo = [
        {
            text: 'Privat',
            value: 'Privat',
            url: 'https://docs.google.com/forms/d/e/1FAIpQLSe1IPdZ16es8pOIUJ62NXJ9VF47Tkicsv5xo3ohk5GF0514Cg/formResponse',
            entryPoints: [
                {
                    entryPort: '1258929015',
                    question: "Hvorfor afmelder du dig nyhedsbrevet?",
                },
            ]
        },
    ];

    formInfoItem = formInfo.find(item => item.value === 'Privat');

    if (formInfoItem) {
        return (
            <Form action={formInfoItem.url}>
                {formInfoItem.entryPoints.map((entryPoint, index) => (
                    <Form.Group key={index} label={`${entryPoint.entryPort}`} name={`entry.${entryPoint.entryPort}`} grouped>
                        <Header as={'h3'}>{entryPoint.question}</Header>
                        <FormGroup grouped>
                            <Form.Field
                                control={Checkbox}
                                style={{ margin: '.5em 0' }}
                                label='I sender mails for ofte'
                                name={`entry.${entryPoint.entryPort}`}
                                value={'I sender mails for ofte'}
                            />
                            <Form.Field
                                control={Checkbox}
                                style={{ margin: '.5em 0' }}
                                label='Indholdet er uinteressant eller irrelevant'
                                name={`entry.${entryPoint.entryPort}`}
                                value={'Indholdet er uinteressant eller irrelevant'}
                            />
                            <Form.Field
                                control={Checkbox}
                                style={{ margin: '.5em 0' }}
                                label='Jeg har aldrig tilmeldt mig nyhedmails fra jer'
                                name={`entry.${entryPoint.entryPort}`}
                                value={'Jeg har aldrig tilmeldt mig nyhedmails fra jer'}
                            />
                            <Form.Field
                                control={Checkbox}
                                style={{ margin: '.5em 0' }}
                                label='Jeg er ikke længere interesseret'
                                name={`entry.${entryPoint.entryPort}`}
                                value={'Jeg er ikke længere interesseret'}
                            />
                        </FormGroup>
                        <Divider hidden/>
                    </Form.Group>
                ))}
                <Button floated='left' color='grey' type='submit'>Send</Button>
                <Link to='indstillinger/profil/kontodata'>
                    <Button
                        floated='right'
                        color='green'
                        verticalAlign='bottom'
                    >
                        <Icon name='envelope' />
                        Tilmeld nyhedsbrev
                    </Button>
                </Link>
            </Form>
        );
    }
};
