import React                    from 'react';
import { Icon, Label, Popup }   from 'semantic-ui-react';
import { forceUpdate }          from 'http/esignatur'

// states where the sign flow isn't configurable
// where the user has to manually unlock the flow to make changes
export const isFlowLockedEsignatur = status => {
    return [
        "Active",
        "Started",
        "Created",
        "Completed",
        "FinalizationPending", // Just incase status is messed up
        "Campagne started",
    ].includes(status);
};

const flowStatus = ({ status }) => {
    let icon;
    let tooltip;
    let text;

    switch (status) {  
        case "Failed":
            icon = <Icon color="red" name='warning sign' />;
            tooltip = "Error occurred.";
            text = "Fejlet";
            break ;
        case "Created":
            icon = <Icon color="grey" name='clock' />;
            tooltip = "Signing is started";
            text = "Oprettet";
            break ;
        case "Active":
            icon = <Icon color="grey" name='paper plane' />;
            tooltip = "Signing reached its signing start date and sent notifications to recipients.";
            text = "I gang";
            break ;
        case "Signed":
            icon = <Icon color="green" name='check' />;
            tooltip = "Finished distribution. Signing is completed.";
            text = "Underskrevet";
            break ;
        case "Expired":
            icon = <Icon color="yellow" name='clock' />;
            tooltip = "Reached selected signing template expiration date";
            text = "Udløbet";
            break ;
        case "Stopped":
            icon = <Icon color="yellow" name='x' />;
            tooltip = "Stopped by user";
            text = "Stoppet";
            break ;
        case "Sampagne started":
            icon = <Icon color="grey" name='question' />;
            tooltip = "Related to Campaigns";
            text = "Kampagne startet";
            break ;
        case "Rejected":
            icon = <Icon color="yellow" name='warning sign' />;
            tooltip = "Recipient rejected to sign document";
            text = "Afvist";
            break ;
        case "Cancelled":
            icon = <Icon color="yellow" name='warning sign' />;
            tooltip = "Processen er annulleret";
            text = "Annulleret";
            break ;    
        default:
            icon = <Icon color="grey" name='question' />;
            tooltip = "Ukendt status: " + status;
    }

    const label = <Label>
        {icon} {text}
    </Label>
    return <Popup trigger={label} content={tooltip} inverted />;
}

export default flowStatus;

// Used to determine field group progress
export const isFlowDoneEsignatur = (status) => {
    if (status === "Completed") {
        return true;
    }
    return false;
}

// Callback for LockStatus "Lås op"-button.
// Overwrites flowStatus and all signee.txStatus in "docGenFact"
// and writes to server.
export const resetSignflowEsignatur = async (docGenFact, fieldDataChanged) => {
    try {
        if (!docGenFact) return ;
        if (!docGenFact.value) return ;
        if (!docGenFact.value.doc_gen_data) return ;
        if (!docGenFact.value.doc_gen_data.esignaturSignFlow) return ;
        // This forces a reload of doc-gen which leads to a needed status & lock update
        const esignaturSignFlowId = docGenFact.value.doc_gen_data.esignaturSignFlow;
        docGenFact.value.doc_gen_data.esignaturSignFlow = ""
        await fieldDataChanged(docGenFact.id, { doc_gen_data: docGenFact.value.doc_gen_data });
        docGenFact.value.doc_gen_data.esignaturSignFlow = esignaturSignFlowId
        await fieldDataChanged(docGenFact.id, { doc_gen_data: docGenFact.value.doc_gen_data });
    } catch (err) {
        console.error("Reset signflow error:", err);
    }
}

export const forceUpdateEsignatur = async (docGenFact) => {
    try {
        if (!docGenFact) return ;
        if (!docGenFact.value) return ;
        if (!docGenFact.value.doc_gen_data) return ;
        if (!docGenFact.value.doc_gen_data.esignaturSignFlow) return ;
        if (docGenFact.value.doc_gen_data.signedPdf !== "") return;
        // This forces a reload of doc-gen which leads to a needed status & lock update
        const esignaturSignFlowId = docGenFact.value.doc_gen_data.esignaturSignFlow;
        await forceUpdate(esignaturSignFlowId)
    } catch (err) {
        console.error("Reset signflow error:", err);
    }
}
