import React, { useEffect } from 'react';
import ImpersonationSearchMolecule from 'design/molecules/ImpersonationSearch';
import { Header, Segment } from 'semantic-ui-react';
import { useRootUser } from 'util/useUser';
import { useHistory } from 'react-router-dom';
import ProductPage from 'design/molecules/ProductPage';

const ImpersonationSearch = () => {
    const user = useRootUser();
    const history = useHistory();

    useEffect(() => {
        if (!user.isAdmin()) {
            history.push('/');
        }
    }, [user, history]);

    return (
        <ProductPage>
            <Segment>
                <Header icon='spy' content='Impersonering' />
                <ImpersonationSearchMolecule />
            </Segment>
        </ProductPage>
    );
};

export default ImpersonationSearch;