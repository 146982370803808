import React, { Component } from 'react';
import SimpleLabel from '../../../../pages/Product/components/SimpleLabel/';

const keyLabelMap = {
    accountingCompany: 'Revisorvirksomhed',
    accountingPeriodStartDate: 'Regnskabsperiode start',
    accountingPeriodEndDate: 'Regnskabsperiode slut',
    boardChairman: 'Bestyrelsesformand',
    businessForm: 'Virksomhedsform',
    companyName: 'Virksomhedsnavn',
    cvr: 'CVR-nr',
    directorName: 'Adm. Direktør',
    endDate: 'Ophørsdato',
    home: 'Hjemsted',
    postalCode: 'Postnr.',
    city: 'By',
    startDate: 'Startdato',
    houseNumber: 'Husnummer',
    road: 'Vejnavn',
};

export default class CvrOverview extends Component {
    constructor (props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount () {
        const data = this.props.value;
        if (!data) {
            this.fetchData();
        }
    }

    fetchData () {
        const { onChange, tag } = this.props;
        onChange(tag, {
            number: new Date().valueOf(),
        });
    }

    render () {
        const data = this.props.value;

        if (!data) {
            return (
                <div>
                    Henter CVR data...
                </div>
            );
        }

        const renderField = (keyToShow) => {
            const label = keyLabelMap[keyToShow];
            const value = data[keyToShow];
            if (!value) {
                return null;
            }

            return (
                <SimpleLabel label={label} value={value} />
            );
        };

        return (
            <div>
                {renderField('cvr')}
                {renderField('businessForm')}
                {renderField('accountingPeriodStartDate')}
                {renderField('accountingPeriodEndDate')}
                {renderField('startDate')}
                {renderField('endDate')}
                {renderField('companyName')}
                {renderField('road')}
                {renderField('houseNumber')}
                {renderField('postalCode')}
                {renderField('city')}
                {renderField('home')}
                {renderField('boardChairman')}
                {renderField('directorName')}
                {renderField('accountingCompany')}
            </div>
        );
    }
}
