import { HTTPClient } from '../network/fetch';

const client = new HTTPClient({ prefix: 'xena/api' });

export function registerCredentials(fiscalId, apiKey) {
    return client.postResource('register', {
        fiscalId,
        apiKey,
    });
}

export function extractPrefillValues(token) {
    return client.getResource(`prefill/${token}`);
}