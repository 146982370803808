import { getResource } from '../network/fetch';

const prefix = 'financial-dates';

export function getReportingPeriodsByTaxYears (taxYears = []) {
    const joinedYears = taxYears.join(',');
    return getResource(`${prefix}/reporting-period/taxyears/${joinedYears}`);
}

export function getReportingPeriodPair(taxYear, label) {
    return getResource(`${prefix}/reporting-period/pair/year/${taxYear}/label/${label}`);
}

export function getReportingPeriodChain() {
    return getResource(`${prefix}/reporting-period`);
}