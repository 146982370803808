import React, { Component, createRef } from 'react';
import { base64ToImage } from '../../../util/files';

import styles from './ImageCropper.module.scss';

const MAX_COLLISION_DEVIATION = 8;
const CORNERS = [
    [-1, -1, 'nw'],
    [1, -1, 'ne'],
    [1, 1, 'se'],
    [-1, 1, 'sw'],
];

const clamp = (v, l, h) => {
    if (v < l) {
        return l;
    }
    if (v > h) {
        return h;
    }
    return v;
};

const getInitialState = () => ({
    x: 0,
    y: 0,
    width: 64,
    height: 64,
    offsetx: 0,
    offsety: 0,
    image: null,
    dragging: false,
    corner: undefined,
    draggedCorner: undefined,
    lineDashOffset: 0,
});

class ImageCropper extends Component {
    state = getInitialState();
    mousex = 0;
    mousey = 0;
    canvasRef = createRef();
    interval = undefined;

    componentDidMount = () => {
        window.addEventListener('mouseup',   this.mouseUp, false);
        window.addEventListener('mousedown', this.mouseDown, false);
        window.addEventListener('mousemove', this.mouseMoved, false);
        this.interval = window.setInterval(this.animationTick, 1000 / 60);
        this.updateCanvas();
    };

    componentWillUnmount = () => {
        window.removeEventListener('mouseup',   this.mouseUp, false);
        window.removeEventListener('mousedown', this.mouseDown, false);
        window.removeEventListener('mousemove', this.mouseMoved, false);
        this.interval && window.clearInterval(this.interval);
    };

    componentDidUpdate = (props) => {
        if (!this.props.image && props.image) {
            this.onImageUnloaded();
        } else if (this.props.image && !props.image) {
            this.onImageLoaded(this.props.image);
        }
    };

    almostEqual = (a, b, maxDiff) => {
        return Math.abs(a - b) <= maxDiff;
    };

    scaleImage = (scaleFn, image) => {
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        const { width, height } = scaleFn();
        tempCanvas.width = width;
        tempCanvas.height = height;
        tempCtx.drawImage(image, 0, 0, width, height);
        return tempCanvas.toDataURL('image/png');
    };

    scaleImageByHeight = (image, desiredHeight) => {
        return this.scaleImage(() => {
            const ratio = image.height / desiredHeight;
            const newWidth = image.width / ratio;
            return {
                width: newWidth,
                height: desiredHeight,
            };
        }, image);
    };

    scaleImageByWidth = (image, desiredWidth) => {
        return this.scaleImage(() => {
            const ratio = image.width / desiredWidth;
            const newHeight = image.height / ratio;
            return {
                width: desiredWidth,
                height: newHeight,
            };
        }, image);
    };

    setError = error => {
        const { onError } = this.props;
        onError && onError(error);
    };

    reportDimensionsChanged = dimensions => {
        const { onDimensionsChanged } = this.props;
        if (!onDimensionsChanged) {
            return;
        }
        onDimensionsChanged(dimensions);
    };

    onImageLoaded = async image => {
        const { minImageHeight, maxImageWidth } = this.props;
        if (image.height < minImageHeight) {
            return this.setError(`Billedet skal være mindst ${minImageHeight} pixels højt`);
        }

        if (image.width > maxImageWidth) {
            const scaledImage = this.scaleImageByWidth(image, maxImageWidth);
            image = await base64ToImage(scaledImage);
        }
        // Calculate default bounding box position & size
        const width = Math.floor(image.width * 0.75);
        const height = Math.floor(image.height * 0.75);
        const x = Math.floor((image.width - width) / 2);
        const y = Math.floor((image.height - height) / 2);
        
        const dimensions = {
            width,
            height,
            x,
            y,
        };

        this.setState({
            image,
            ...dimensions,
        }, () => {
            this.getCroppedImage();
            this.setError('');
            this.reportDimensionsChanged(dimensions);
        });
    };

    onImageUnloaded = () => {
        this.setState({ ...getInitialState() });
    };

    canvasMounted = () => !!this.canvasRef.current;

    getCanvasDimensions = () => {
        let width = 0;
        let height = 0;
        if (this.canvasMounted()) {
            const canvas = this.canvasRef.current;
            width = canvas.width;
            height = canvas.height;
        }
        return { width, height };
    };

    ensureMounted = handler => {
        return (...args) => {
            const { disabled, error } = this.props;
            this.canvasMounted() && !error && !disabled && handler(...args);
        };
    };

    /**
     * @returns {CanvasRenderingContext2D}
     */
    getCtx = () => {
        const cref = this.canvasRef.current;
        return cref && cref.getContext('2d');
    };

    animationTick = this.ensureMounted(() => {
        let { lineDashOffset } = this.state;

        lineDashOffset -= 0.10;

        this.setState({
            lineDashOffset,
        }, this.updateCanvas);
    });

    createCornerBoundingBox = () => {
        const { x, y, width, height, draggedCorner } = this.state;
        const cdim = this.getCanvasDimensions();

        // Find the corner points of the current bounding box
        const hw = width / 2;
        const hh = height / 2;
        const mx = x + hw;
        const my = y + hh;
        const points = [];
        for (let [ cx, cy, cd ] of CORNERS) {
            const nx = mx + (hw * cx);
            const ny = my + (hh * cy);
            points.push([ nx, ny, cd ]);
        }

        // Find index of the point to drag
        const pidx = points.findIndex(p => p[2] === draggedCorner[2]);

        // Find index of adjacent points
        const cwpidx = (pidx + 1) % 4; // Clockwise point index
        const ccpidx = (pidx + 3) % 4; // Counter-clockwise point index

        // Move the dragged point to the position of the mouse
        points[pidx][0] = clamp(this.mousex, 0, cdim.width);
        points[pidx][1] = clamp(this.mousey, 0, cdim.height);

        // Move cc & cw points accordingly
        const scc = pidx % 2;
        const scw = (pidx + 1) % 2;

        // Only move the x- or y-coordinate of the adjacent points
        points[ccpidx][scc] = points[pidx][scc];
        points[cwpidx][scw] = points[pidx][scw];

        // Find top-left & bottom-right points
        let tl = points[0];
        let br = points[0];
        for (let i = 1; i < points.length; i++) {
            const p = points[i];
            const sum = p[0] + p[1];
            if (sum < tl[0] + tl[1]) {
                tl = p;
            } else if (sum > br[0] + br[1]) {
                br = p;
            }
        }

        // Calculate output
        const nx = Math.round(tl[0]);      // x
        const ny = Math.round(tl[1]);      // y
        const nw = Math.round(br[0] - nx); // width
        const nh = Math.round(br[1] - ny); // height

        return {
            x: nx,
            y: ny,
            width: nw,
            height: nh,
        };
    };

    createTranslatedBoundingBox = () => {
        const { x, y, width, height, offsetx, offsety } = this.state;
        const cdim = this.getCanvasDimensions();
        const ox = Math.abs(x - offsetx);
        const oy = Math.abs(y - offsety);
        const cx = Math.min(Math.max(this.mousex - ox, 0), cdim.width - width);
        const cy = Math.min(Math.max(this.mousey - oy, 0), cdim.height - height);
        return {
            x: cx,
            y: cy,
            width,
            height,
        };
    };

    createBoundingBox = () => {
        if (this.state.draggedCorner) {
            return this.createCornerBoundingBox();
        }
        return this.createTranslatedBoundingBox();
    };

    useDashedLineStyle = () => {
        const ctx = this.getCtx();
        ctx.fillStyle = 'white';
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 2;
        ctx.setLineDash([4, 4]);
        ctx.globalCompositeOperation = 'difference';
        ctx.lineDashOffset = this.state.lineDashOffset;
    };

    updateCanvas = () => {
        const { image } = this.state;
        if (!image) {
            return;
        }

        const {
            x,
            y,
            width,
            height,
            dragging,
        } = this.state;

        this.clear();
        this.drawImage(image, 0, 0);
        this.useDashedLineStyle();

        if (dragging) {
            // Draw temporary bounding box
            const bb = this.createBoundingBox();
            this.drawRectOutline(bb.x, bb.y, bb.width, bb.height);
            this.drawTransparentBorder(bb.x, bb.y, bb.width, bb.height);
        } else {
            // Draw the actual bounding box
            this.drawRectOutline(x, y, width, height);
            this.drawTransparentBorder(x, y, width, height);
            for (let corner of CORNERS) {
                this.drawResizeIndicartor(corner);
            }
        }
    };

    clear = () => {
        const { image } = this.state;
        const ctx = this.getCtx();
        ctx.globalCompositeOperation = 'source-over';
        ctx.clearRect(0, 0, image.width, image.height);
    };

    drawImage = (image, x, y) => {
        const ctx = this.getCtx();
        ctx.drawImage(image, x, y);
    };

    drawLine = (x1, y1, x2, y2) => {
        const ctx = this.getCtx();
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
    };

    drawTransparentBorder = (x, y, w, h) => {
        const ctx = this.getCtx();
        const cdim = this.getCanvasDimensions();
        ctx.globalCompositeOperation = 'source-over';
        ctx.globalAlpha = 0.5;
        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, cdim.width, y);
        ctx.fillRect(0, y, x, h);
        ctx.fillRect(x + w, y, cdim.width - (w + x), h);
        ctx.fillRect(0, y + h, cdim.width, cdim.height);
        ctx.globalAlpha = 1;
    };

    drawRectOutline = (x, y, w, h) => {
        this.drawLine(x, y, x + w, y);
        this.drawLine(x + w, y, x + w, y + h);
        this.drawLine(x + w, y + h, x, y + h);
        this.drawLine(x, y + h, x, y);
    };

    drawResizeIndicartor = ([ cx, cy ]) => {
        const { x, y, width: w, height: h } = this.state;
        const ctx = this.getCtx();
        ctx.globalCompositeOperation = 'source-over';
        ctx.fillStyle = 'white';
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.setLineDash([]);
        const MCD = MAX_COLLISION_DEVIATION;
        const HMCD = MCD / 2;
        const hw = w / 2;
        const hh = h / 2;
        const mx = x + hw;
        const my = y + hh;
        const nx = mx + (hw * cx);
        const ny = my + (hh * cy);
        const indicatorArgs = [ nx - HMCD, ny - HMCD, MCD, MCD ];
        this.drawRectOutline(...indicatorArgs)
        ctx.fillRect(...indicatorArgs);
    };

    almostColliding = (x1, y1, x2, y2) => (
        this.almostEqual(x1, x2, MAX_COLLISION_DEVIATION) &&
        this.almostEqual(y1, y2, MAX_COLLISION_DEVIATION)
    );

    getCroppedImage = async () => {
        const ctx = this.getCtx();
        const { x, y, image, width, height } = this.state;
        const {
            onCrop,
            minImageHeight,
            heightWidthRatioThreshold,
        } = this.props;

        if (width === 0) {
            return onCrop && onCrop(undefined);
        }

        if (height < minImageHeight) {
            return onCrop && onCrop(undefined);
        }

        // Ensure healthy image dimensions
        let tempCanvasHeight = height;
        let imageOffsetY = 0;
        const heightWidthRatio = height / width;
        if (heightWidthRatio < heightWidthRatioThreshold) {
            tempCanvasHeight = width * heightWidthRatioThreshold;
            imageOffsetY = (tempCanvasHeight / 2) - height / 2; 
        }
        
        // Create a temporary canvas
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = width;
        tempCanvas.height = tempCanvasHeight;

        // Redraw the main canvas without the crop UI
        this.clear();
        this.drawImage(image, 0, 0);

        // Put image data from main canvas to temp canvas
        tempCtx.putImageData(ctx.getImageData(x, y, width, height), 0, imageOffsetY);

        // Get the image as png
        const data = tempCanvas.toDataURL('image/png');

        // Redraw the crop UI
        this.updateCanvas();

        // Downscale the image and pass it to parent components
        // const asImg = await base64ToImage(data);
        // const resized = this.scaleImageByHeight(asImg, outputImageHeight);

        onCrop && onCrop(data);
    };

    getCursor = () => {
        const { corner } = this.state;
        if (corner) {
            const direction = corner[2];
            return `${direction}-resize`;
        }
    };

    mouseMoved = this.ensureMounted(e => {
        const { current } = this.canvasRef;
        const { left, top } = current.getBoundingClientRect();
        const { x, y, width, height } = this.state;
        const { clientX, clientY } = e;
        const mousex = clientX - left;
        const mousey = clientY - top;
        this.mousex = mousex;
        this.mousey = mousey;
        // Checks if the mouse is at one of the corners of the bounding box
        const hw = width / 2;
        const hh = height / 2;
        const mx = x + hw;
        const my = y + hh;
        let corner;
        for (let c of CORNERS) {
            const [ cx, cy ] = c;
            const nx = mx + (hw * cx);
            const ny = my + (hh * cy);
            const isColliding = this.almostColliding(mousex, mousey, nx, ny);
            if (isColliding) {
                corner = c;
                break;
            }
        }
        this.setState({ corner });
    });

    mouseDown = this.ensureMounted(() => {
        const { x, y, width: w, height: h, corner } = this.state;
        const { mousex: mx, mousey: my } = this;
        const insideBoundingBox = (
            mx >= x &&
            mx <= x + w &&
            my >= y &&
            my <= y + h
        );
        if (insideBoundingBox || corner) {
            document.body.classList.add(styles.noselect);
            this.setState({
                offsetx: mx,
                offsety: my,
                dragging: true,
                draggedCorner: corner,
            });
        }
    });

    mouseUp = this.ensureMounted(() => {
        if (!this.state.dragging) {
            return;
        }
        document.body.classList.remove(styles.noselect);
        const boundingBox = this.createBoundingBox();
        this.reportDimensionsChanged(boundingBox);
        this.setState({
            ...boundingBox,
            dragging: false,
            draggedCorner: undefined,
        }, this.getCroppedImage);
    });

    render = () => {
        const { className, disabled, error } = this.props;
        const { image } = this.state;
        if (!image || error) {
            return null;
        }
        return <canvas
            ref={this.canvasRef}
            className={className}
            width={image.width}
            height={image.height}
            style={{
                cursor: this.getCursor(),
                opacity: disabled ? 0.5 : 1,
            }}
        />;
    };
}

ImageCropper.defaultProps = {
    minImageHeight: 150,
    maxImageWidth: Math.floor(window.screen.width * 0.80),
    heightWidthRatioThreshold: 0.30,
};

export default ImageCropper;