import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';
import { useCreditsContext } from 'design/molecules/CreditsProvider';
import CreditsIcon from 'design/atoms/CreditsIcon';

const HighFive = ({ isCreditsPurchase }) => {
    const { credits } = useCreditsContext();
    
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Segment compact textAlign='center'>
                <Header icon color='green'>
                    <Icon name='check circle' />
                    Din betaling er modtaget
                </Header>
                {isCreditsPurchase && (
                    <p>Din nye balance er nu: <strong>{credits} <CreditsIcon /></strong></p>
                )}
                <p>
                    Vi har sendt en faktura afsted til dig på mail.
                </p>
                <p>
                    Du vil blive omdirigeret om få sekunder...
                </p>
            </Segment>
        </div>
    );
};

export default HighFive;