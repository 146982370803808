import React from 'react';

import Checklist from 'design/pages/Product/FieldTypes/FactWidgets/checklist';
import withUserData from 'util/withUserData';

const HintNodeData = props => {
    const {
        hint,
        hasPaid,
        factsBeingUpdated,
        productMetadata,
        productData,
        userData,
    } = props;
    const { nodeDataType } = hint.data;

    const renderContent = () => {
        let content = null;
        switch(nodeDataType) {
            case 'Checklist': {
                const { id: checklistFactID } = (Object.values(productMetadata.facts)).find(f => f.dataType === 'checklist');
                const checklistFact = productData.values[checklistFactID];

                if(checklistFact) {
                    content = <Checklist
                        value={checklistFact.value.checklist}
                        hasPayed={hasPaid}    
                        values={productData.values}
                        userData={userData}
                        productMetadata={productMetadata}
                        factsBeingUpdated={factsBeingUpdated}
                    />;
                }
            }
        }

        return content;
    };

    return renderContent();
};

export default withUserData(HintNodeData);
