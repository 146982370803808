import React from 'react';
import { Image } from 'semantic-ui-react';
import styles from './SecurePaymentMessage.module.scss';

import stripeImg from '../../../images/PoweredByStripe.svg';
import mastercardImg from '../../../images/MastercardLogo.svg';
import visaImg from '../../../images/VisaLogo.svg';

const sizes = {
    small: 20,
    medium: 35,
    large: 50,
};

/**
 * @param {Object} props
 * @param {'small'|'medium'|'large'} props.size 
 * @returns 
 */
const SecurePaymentMessage = ({ size }) => {
    const sizepx = sizes[size] || sizes.medium;
    const style = { height: sizepx };
    return <>
        <Image
            alt='stripe'
            src={stripeImg}
            className={styles.cardIcon}
            style={style}
        />
        <Image
            alt='visa'
            src={visaImg}
            className={styles.cardIcon}
            style={style}
        />
        <Image
            alt='mastercard'
            src={mastercardImg}
            className={styles.cardIcon}
            style={style}
        />
    </>; 
};

export default SecurePaymentMessage;