import React, { useRef, useState } from 'react';
import { Divider, Icon, Loader } from 'semantic-ui-react';
import { formatDate, formatDateShort } from 'util/format/DateTime';
import useRestResource from 'util/useRestResource';
import loadEmojis from 'util/emojis';
import ColoredText from 'design/atoms/ColoredText';

import styles from './ChatHistoryViewer.module.scss';

const ChatMessage = ({ emojis, _id, message, link, agentName, timestamp }) => {
    const pad = num => {
        if (num <= 9) {
            return '0' + num;
        }

        return num;
    };

    const injectEmojis = message => {
        return message.replace(/:[a-z0-9_]+?:/gi, match => {
            const emojiID = match.slice(1, -1);
            return emojis[emojiID];
        });
    };

    const stamp = new Date(timestamp);
    const isAgent = !!agentName;
    const classNames = [
        styles.message,
        (
            isAgent ? 
            styles.agentMessage :
            styles.clientMessage
        ),
    ];

    const hh = pad(stamp.getHours());
    const mm = pad(stamp.getMinutes());
    const timeOfMessage = `${hh}:${mm}`;
    const tooltip = `Sendt d. ${formatDate(stamp)} kl. ${timeOfMessage}`;

    const now = new Date();
    const stampFromToday = (
        now.getDate() === stamp.getDate() &&
        now.getMonth() === stamp.getMonth() &&
        now.getFullYear() === stamp.getFullYear()
    );

    const timestampContent = (
        stampFromToday ?
        timeOfMessage :
        `${formatDateShort(stamp)} ${timeOfMessage}`
    );

    return (
        <div className={styles.messageContainer} title={tooltip} key={_id}>
            <div className={classNames.join(' ')}>
                {/* agent name */}
                {
                    isAgent &&
                    <div>
                        <b>
                            <Icon name='user' /> {agentName}
                        </b>
                    </div>
                }

                {/* regular message */}
                {
                    message &&
                    injectEmojis(message)
                }

                {/* attachment */}
                {
                    link &&
                    <ColoredText
                        newWindow
                        link={link}
                        icon='attach'
                        iconPosition={isAgent ? 'left' : 'right'}
                        content={link.split('/').at(-1)}
                    />
                }

                {/* timestamp */}
                <div
                    children={timestampContent}
                    className={styles.timestamp}
                    style={{ textAlign: isAgent ? 'left' : 'right' }}
                />
            </div>
        </div>
    );
};

const ChatHistoryViewer = ({ chatEntries }) => {
    const PAGE_SIZE = 10;

    const containerRef = useRef();
    const [msgCount, setMsgCount] = useState(PAGE_SIZE);
    const { data: emojis, loading } = useRestResource({
        fetcher: loadEmojis,
    });

    const loadMoreMessages = () => {
        setMsgCount(msgCount + PAGE_SIZE);
    };

    if (loading) {
        return <Loader inline='centered' active />;
    }

    const messages = [];
    for (let i = 0; i < Math.min(chatEntries.length, msgCount); i++) {
        const entry = chatEntries.at(-1 - i);
        const prevEntry = chatEntries.at(-2 - i);

        // add current message
        const messageJSX = (
            <ChatMessage
                emojis={emojis}
                {...entry}
            />
        );
        messages.push(messageJSX);

        // add divider if the date has changed between current & previous message
        if (prevEntry) {
            const edate = new Date(entry.timestamp);
            const ndate = new Date(prevEntry.timestamp);
            if (edate.getDate() !== ndate.getDate()) {
                messages.push(
                    <Divider horizontal content={formatDate(edate)} />
                );
            }
        }
    }

    return (
        <div className={styles.container} ref={containerRef}>
            {messages}
            <div className={styles.center}>
                {
                    msgCount < chatEntries.length && 
                    <ColoredText
                        link
                        content='Indlæs tidligere beskeder...'
                        icon='arrow up'
                        iconPosition='left'
                        onClick={loadMoreMessages}
                    />
                }
            </div>
        </div>
    );
};

export default ChatHistoryViewer;