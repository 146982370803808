import React, { Component } from 'react';
import { Card, Icon, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import withUserData from 'util/withUserData';
import createProductHomeLink from 'util/createProductHomeLink';
import styles from './ProductsDisplayer.module.scss';

function ProductCard ({ history, icon, header, description, buttonText, link }) {
    const cardContent = <>
        <Card.Content>
            <Icon color='green' className={icon} size='massive' />
            <Card.Header
                content={header}
                className={styles.cardTitle}
            />
            <Card.Description>
                {description}
            </Card.Description>
        </Card.Content>
        <Card.Content extra>
            <Button
                content={buttonText}
                primary
            />
        </Card.Content>
    </>;
    return <Card
        onClick={() => history.push(link)}
        className={styles.card}
        children={cardContent}
        color='green'
        link
    />;
}


class ProductsDisplayer extends Component {
    renderProductCard = product => {
        const {
            id,
            semanticIcon,
            name,
            purchaseDescription,
            buyTryBtn,
            bought,
        } = product;

        const buttonText = (
            bought ?
            `Gå til ${name}` :
            buyTryBtn
        );

        return <ProductCard
            key={id}
            history={this.props.history}
            icon={semanticIcon}
            header={name}
            description={purchaseDescription}
            buttonText={buttonText}
            link={createProductHomeLink(product)}
        />;
    };

    render = () => {
        const { products } = this.props;

        if (!products || products.length === 0) {
            return null;
        }

        return <Card.Group textAlign='center' centered>
            {products.map(this.renderProductCard)}
        </Card.Group>;
    };
}

export default withUserData(withRouter(ProductsDisplayer));