import React, { Component } from 'react';
import cn from 'classnames';
import { Form as SemForm, Header, Divider, Icon, Loader, Segment } from 'semantic-ui-react';
import FormLabel from '../FormLabel';
import ErrorMessage from '../ErrorMessage';
import styles from './Form.module.scss';

class FormRowDivider extends Component {
    render () {
        const { title, icon } = this.props;
        return (
            <Form.Row>
                <Form.Field style={{ width: '100%' }}>
                    <Divider horizontal={title || icon}>
                        {(title || icon) &&
                            <Header as='h3'>
                                {icon && <Icon name={icon} />}
                                {title}
                            </Header>
                        }
                    </Divider>
                </Form.Field>
            </Form.Row>
        );
    }
}

class FormLabelField extends Component {    
    render () {
        const { className, label, tooltip, youtubeVideo, generalVideoTooltip, generalTooltip } = this.props;
        return (
            <SemForm.Field> 
                <FormLabel 
                    style={className}  
                    label={label}
                    tooltip={tooltip}
                    youtubeVideo={youtubeVideo}
                    generalVideoTooltip={generalVideoTooltip}
                    generalTooltip={generalTooltip}
                />
            </SemForm.Field>
        );
    }
}

class FormField extends Component {
    render () {
        const { className, error, textAlign, children, blurred, direction, ...otherProps } = this.props;
        const cns = [ className ];
        if (blurred) {
            cns.push(styles.blurred);
        }
        return (
            <SemForm.Field {...otherProps}>
                <div className={cn(...cns)} style={{ textAlign }}>
                    {children}
                    <ErrorMessage visible>{error}</ErrorMessage>
                </div>
            </SemForm.Field>
        );
    }
}

class FormRow extends Component {
    render () {
        const { blurred } = this.props;
        const cns = [];
        if (blurred) {
            cns.push(styles.blurred);
        }

        if (this.props.direction) {
            return(
                <SemForm.Group widths={this.props.children.length} className={cn(...cns)} style={{display:'flex', flexDirection:'column', gap:'15px'}}>
                {this.props.children}
                </SemForm.Group>
            )
        }
        else{
            return (
                <SemForm.Group widths={this.props.children.length} className={cn(...cns)} >
                    {this.props.children}
                </SemForm.Group>
            );
        }
    }
}

class Form extends Component {
    renderLoader = () => {
        return <Loader
            active
            inline
            size='massive'
            className={`centered ${styles.loader}`}
        />;
    };

    render () {
        const { disabled, loading, children } = this.props;

        if (loading) {
            return <Segment basic>
                {this.renderLoader()}
            </Segment>;
        }

        const form = (
            <SemForm>
                {children}
            </SemForm>
        );

        return (
            <Segment
                basic
                className={styles.formWrapper}
                disabled={disabled}
                children={form}
                style={{
                    padding: 0,
                    margin: 0,
                }}
            />
        );
    }
}

Form.Row = FormRow;
Form.RowDivider = FormRowDivider;
Form.Field = FormField;
Form.LabelField = FormLabelField;

export default Form;
