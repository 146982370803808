import React, { useState } from 'react'
import { Message, Button, Input } from 'semantic-ui-react';
import { registerToken } from 'http/billy'

import styles from './connectors.module.scss';

const BillyConnector = ({ onRegister }) => {
    const [apiKey, setApiKey] = useState('');
    const [error, setError] = useState(false);
    const [working, setWorking] = useState(false);

    const onSubmitKey = async () => {
        if (!apiKey) {
            return;
        }

        setError(false);
        setWorking(true);

        try {
            await registerToken(apiKey);
            onRegister();
        } catch {
            setError(true);
            setWorking(false);
        }
    };

    const renderError = () => {
        if (!error) {
            return null;
        }

        return (
            <Message negative>
                <Message.Header>Der opstod en fejl</Message.Header>
                <p>Sandsynligvis et problem med den indtastede API-nøgle</p>
            </Message>
        );
    };

    return (
        <div>
            <div>
                <p>For at kunne forbinde med Billy skal vi bruge en API nøgle fra dig.<br/>
                Det er gratis at gøre på Billy og skal gøres for den bruger,
                du vælger, vi skal hente data fra.</p>
                <p>
                    <a
                        className={styles.externalLink}
                        href='https://www.billy.dk/support/article/saadan-opretter-du-en-api-noegle-adgangsnoegle/'
                        target='_blank'
                        rel='noopener noreferrer'
                        children='Klik her'
                    />
                    &nbsp;for at se hvordan man opretter en API nøgle.
                </p>

                <Input
                    label='API nøgle'
                    id='apikey'
                    placeholder='Eks. 56d4e654d65c6a5s4d6andsa8f79a46'
                    disabled={working}
                    defaultValue={apiKey}
                    onChange={e => setApiKey(e.target.value)}
                    onKeyDown={e => e.keyCode === 13 && onSubmitKey()}
                    fluid
                    autoFocus
                />
                <div className={styles.actionsContainer}>
                    <Button
                        primary
                        content='Forbind'
                        onClick={onSubmitKey}
                        disabled={working}
                        loading={working}
                    />
                </div>
            </div>

            {renderError()}
        </div>
    );
};

export default BillyConnector;