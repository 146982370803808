class HintWrapper {
    /**
     * Constructs a new HintWrapper.
     * 
     * @param {*} hint Hint of the HintWrapper
     */
    constructor(hint, source) {
        this.hint = hint;
        this.source = source;
    }
}

export default HintWrapper;
