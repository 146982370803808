import React, { useState, useEffect } from 'react'
import { Message, Button, Input, Grid, Dropdown, Progress } from 'semantic-ui-react';
import { searchCompanies, registerLoginCredentials } from 'http/uniconta';

import styles from './connectors.module.scss';

const UnicontaConnector = ({ onRegister }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [working, setWorking] = useState(false);
    const [companies, setCompanies] = useState(null);
    const [chosenCompany, setChosenCompany] = useState(null);
    const [session, setSession] = useState(null);
    const [countdown, setCountdown] = useState(45000); 


    // ==== Countdown timer ==================================================
    useEffect(() => {
        let interval;
        if (working && countdown > 0) {
            interval = setInterval(() => {
                setCountdown(countdown - 10);
            }, 10);
        } else if (countdown === 0) {
            clearInterval(interval);
            // Handle countdown completion here
        }
        return () => clearInterval(interval);
    }, [working, countdown]);

    const formatTime = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60000);
        const seconds = ((milliseconds % 45000) / 1000).toFixed(0);
        return `${minutes}:${seconds}`;
    };

    const progressBarPercent = ((countdown / 45000) * 100).toFixed(2).replace();
    // =======================================================================

    const doCompanyLogin = async (companyID) => {
        setWorking(true);
        await registerLoginCredentials(username, password, companyID, session);
        onRegister && onRegister();
    };

    const onUnicontaLogin = async () => {
        if (!username || !password) {
            return;
        }

        setError(false);
        setWorking(true);
        
        let companiesResult; // used to hold the result of the returned companies. should be an array

        try {
            // Attempt to retrieve companies and session data
            const result = await searchCompanies(username, password);
        
            // ===========================================================================================
            // Saveing session is only done with two factor to preserve the login.
            // If session is not surplied it means ywofactor login was not used
            // ===========================================================================================
            if (result && result.companies && Array.isArray(result.companies)) { // Check if 'companies' and 'sess' are present.
                console.log("Using session");
                if (result.companies.length > 0) { companiesResult = result.companies; } 
                else { throw new Error('No companies found'); }
                
                // Check for session existence and length
                if (result.sess && typeof result.sess === 'string' && result.sess.length > 10) { 
                    setSession(result.sess);
                } 
                else { throw new Error('Invalid or missing session data'); }

            } else if (result && Array.isArray(result)) { // Check for just companies being surplied
                console.log("Not using session");
                if (result.length > 0) {
                    companiesResult = result;
                } 
                else { throw new Error('No companies found'); }
            } else {
                // Unknown response structure
                throw new Error('Unexpected response structure');
            }
            // ===========================================================================================


        } catch (error) {
            // Handle any errors thrown above or connection issues
            setError(true);
            setWorking(false);
            console.error(error.message); // Log the error message for debugging
            return;
        }

        // 0 companies => error
        if (companiesResult.length === 0) {
            setError(true);
            setWorking(false);
            return;
        }

        // >1 company => render company choice
        if (companiesResult.length > 1) {
            setCompanies(companiesResult);
            setWorking(false);
            return;
        }

        // 1 company found => ready to connect
        await doCompanyLogin(companiesResult[0].companyId);
    };

    const renderLoginForm = () => (
        <Grid columns={1}>
            <Grid.Column>
            <div className={styles.progressBarWrapper}>
                    <div>
                        {working && countdown > 0 ? (
                            <span>Afventer svar fra Uniconta: {formatTime(countdown)}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        ) : (
                            <span>Indtast dine loginoplysninger fra Uniconta:</span>
                        )}
                    </div>
                    {working && countdown > 0 && (
                        <Progress
                            className={styles.progressBar}
                            percent={progressBarPercent}
                            indicating
                            progress
                            color="green"
                            size="small"
                            style={{ flex: 1 }}
                        />
                    )}
                </div>
                    
            </Grid.Column>
            <Grid.Column>
                <b>Uniconta Brugernavn</b>
                <Input
                    id='email'
                    onChange={(_, { value }) => setUsername(value)}
                    onKeyDown={e => e.keyCode === 13 && onUnicontaLogin()}
                    defaultValue={username}
                    disabled={working}
                    fluid
                />
            </Grid.Column>
            <Grid.Column>
                <b>Uniconta Password</b>
                <Input
                    onChange={(_, { value }) => setPassword(value)}
                    onKeyDown={e => e.keyCode === 13 && onUnicontaLogin()}
                    defaultValue={password}
                    disabled={working}
                    type='password'
                    fluid
                />
            </Grid.Column>
            <Grid.Column textAlign='right'>
                <Button
                    primary
                    content='Log ind'
                    icon='sign in'
                    disabled={working}
                    onClick={onUnicontaLogin}
                />
            </Grid.Column>
            {
                error &&
                <Grid.Column>
                    <Message
                        negative
                        content='Kunne ikke logge ind'
                    />
                </Grid.Column>
            }
        </Grid>
    );

    const renderCompanySelect = () => {
        return (
            <div>
                <p>Vælg det firma som du vil forbinde med Digital Revisor:</p>
                <Dropdown
                    fluid
                    selection
                    search
                    options={companies.map(({ companyId, companyName }) => ({
                        text: companyName,
                        value: companyId,
                    }))}
                    onChange={(_, { value }) => setChosenCompany(value)}
                />
                <div className={styles.actionsContainer}>
                    <Button
                        primary
                        content='Forbind'
                        disabled={!chosenCompany}
                        onClick={() => doCompanyLogin(chosenCompany)}
                    />
                </div>
            </div>
        );
    };

    if (companies) {
        return renderCompanySelect();
    }

    return renderLoginForm();
};

export default UnicontaConnector;