import JWTDecode from 'jwt-decode';
import userSessionStore from 'util/userSessionStore';
import fetchImpersonationToken from '../../network/fetch/fetchImpersonationToken';
import history from '../browserHistory';
import {
    clearImpersonationToken,
    hasImpersonationToken,
    getImpersonationToken,
} from '../token';

const IMP_ID = 'impersonationID';

export function setImpersonationID(idOfImpersonatedUser) {
    userSessionStore.set(IMP_ID, idOfImpersonatedUser);
}

export function removeImpersonationID() {
    userSessionStore.remove(IMP_ID);
}

export async function beginImpersonation(idOfImpersonatedUser) {
    await fetchImpersonationToken(idOfImpersonatedUser);
    setImpersonationID(idOfImpersonatedUser);
}

export function getImpersonatedID() {
    return userSessionStore.get(IMP_ID);
}

export function getImpersonatedEmail() {
    const tokenUser = JWTDecode(getImpersonationToken())
    return tokenUser.email;
}

export function isImpersonating() {
    return !!getImpersonatedID() && hasImpersonationToken();
}

/**
 * Stop the current impersonation session
 * @param {string} [href] - Optional redirect after ended session
 */
export function stopImpersonation(href) {
    userSessionStore.remove(IMP_ID);
    clearImpersonationToken();
    if (href) {
        window.scrollTo(0, 0);
        history.push(href);
    }
}
