import React from 'react';
import { Popup } from 'semantic-ui-react';
import RadioOption from 'design/atoms/RadioOption';

const Option = ({ text, tooltip, disabled, disabledTooltip, onClick, active }) => {
    const renderedOption = (
        <div
            onClick={() => !disabled && onClick()}
            style={{
                opacity: disabled ? 0.65 : 1,
                background: active ? 'white' : undefined,
                outline: active ? '1px solid lightgray' : undefined,
                padding: '0.5em 0.75em',
                borderRadius: '14px',
                alignItems: 'center',
                display: 'flex',
                cursor: (
                    disabled
                    ? 'not-allowed'
                    : !active
                    ? 'pointer'
                    : undefined
                ),
            }}
        >
            <RadioOption
                checked={active}
                disabled={disabled}
                style={{ marginRight: '1em' }}
            />
            <div>
                {text}
                <br />
                <span style={{ fontSize: '85%', opacity: 0.75 }}>
                    {tooltip}
                </span>
            </div>
        </div>
    );

    if (disabled && disabledTooltip) {
        return (
            <Popup
                content={disabledTooltip}
                trigger={renderedOption}
                position='top center'
            />
        );
    }

    return renderedOption;
};

const MultipleChoice = ({ options, onChange, value, direction }) => {

    if(direction){
        return (
            <div
                style={{
                    background: '#edeef0',
                    borderRadius: '14px',
                    padding: '0.5em 0.5em',
                    textAlign: 'left',
                    border: '1px solid lightgray',
                    display:  'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                }}

                children={options.map(option => (
                    <Option

                        direction={direction}    
                        {...option}
                        active={value === option.value}
                        onClick={() => onChange(option.value)}
                    />
                ))}
            />
        );
    }
    else{
        return (
            <div
                style={{
                    background: '#edeef0',
                    borderRadius: '14px',
                    padding: '0.5em 0.5em',
                    textAlign: 'left',
                    border: '1px solid lightgray',
                }}
                children={options.map(option => (
                    <Option
                        {...option}
                        active={value === option.value}
                        onClick={() => onChange(option.value)}
                    />
                ))}
            />
        );
    }
}

export default MultipleChoice;
