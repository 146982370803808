class HintSectionAnalysis {
    constructor() {
        this.sectionHint = null;
        this.fieldHints = [];
    }

    setSectionHint(sectionHint) {
        this.sectionHint = sectionHint;
    }

    addFieldHint(fieldHint) {
        this.fieldHints.push(fieldHint);
    }

    // --------------------------------------------------

    containsSectionHints() {
        return this.sectionHint !== null;
    }

    containsFieldHints() {
        return this.fieldHints.length > 0;
    }

    containsFieldHintsOfType(type) {
        const fieldHints = this.fieldHints.filter(fieldHint => {
            return fieldHint.type === type;
        });
        return fieldHints.length > 0;
    }
}

export default HintSectionAnalysis;
