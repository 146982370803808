import React, { Component, createRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Segment, Icon, Loader, Message, Transition, Grid } from 'semantic-ui-react';
import { prepareDownloadLink, deleteFile, uploadFile } from '../../../http/file-storage';
import { updateLogo, deleteLogo } from '../../../http/accounts';
import { readAsImage, base64ToFile, compareFiles, isFile } from '../../../util/files';
import withUserData from '../../../util/withUserData';
import ImageCropper from '../../atoms/ImageCropper';
import FileuploadButton from '../../atoms/FileuploadButton';
import ColoredText from '../../atoms/ColoredText';

import styles from './UploadLogo.module.scss';

const acceptedTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg'];

export const CurrentLogo = ({ userData }) => {
    const [loading, setLoading] = useState(false);
    const [src, setSrc] = useState('');

    const logo = userData?.logo;

    useEffect(() => {
        const fetchLogo = async () => {
            if (logo) {
                const link = await prepareDownloadLink(logo);
                setSrc(link);
            } else {
                setSrc('');
            }
            setLoading(false);
        };

        setLoading(true);
        fetchLogo();
    }, [logo]); 

    return loading ? (
        <>
            <Loader active inline />
            <p>Henter nuværende logo...</p>
        </>
    ) : <img src={src} alt='logo' style={{ maxWidth: '500px', maxHeight: '500px' }} />;
};

class UploadLogo extends Component {
    state = {
        image: null,
        cropped: null,
        error: null,
        cropError: null,
        working: false,
    };
    uploadRef = createRef();

    componentDidUpdate = async prevProps => {
        const currentFile = this.props.preselectedFile;
        const prevFile = prevProps.preselectedFile;
        if (!compareFiles(prevFile, currentFile)) {
            this.onLogoSelected(currentFile);
        }
    };

    toast = (kind, ...args) => {
        if (!this.props.hideToast) {
            toast[kind](...args);
        }
    };

    resetState = cb => {
        this.setState({
            image: null,
            cropped: null,
            error: null,
            working: false,
        }, () => typeof cb === 'function' && cb());
    };

    onLogoSelected = async file => {
        if (!isFile(file)) {
            return;
        }
        const image = await readAsImage(file);
        this.setState({ image });
    };

    onImageCropped = cropped => {
        this.setState({ cropped });
    };

    onError = error => {
        this.setState({ error });
    };

    onDimensionsChanged = ({ height }) => {
        if (height < 150) {
            this.setState({ cropError: `Udsnittet skal være mindst 150 pixels højt. Nuværende højde: ${height}px` });
        } else {
            this.setState({ cropError: null });
        }
    };

    saveLogo = async () => {
        const { cropped } = this.state;
        const { userData, onLogoChanged } = this.props;
        this.setState({ working: true });
        try {
            // Convert b64 to file
            const file = await base64ToFile(cropped, 'logo.png', 'image/png');
            // Create form data and append the file
            const formData = new FormData();
            formData.append('file', file);
            // Perform request
            if (userData.logo) {
                await deleteFile(userData.logo);
            }
            const logoFileID = await uploadFile(file);
            // Save file ID reference on user
            await updateLogo(logoFileID);
            this.resetState(() => {
                onLogoChanged && onLogoChanged();
                this.toast('success', 'Dit logo blev opdateret');
            });
        } catch (e) {
            console.error('Error saving logo: ' + e.message);
            this.toast('error', 'Der opstod en fejl og logoet blev derfor ikke gemt');
        }
    };

    removeLogo = async () => {
        const { userData, onLogoChanged } = this.props;
        this.setState({ working: true });
        await deleteFile(userData.logo);
        await deleteLogo();
        this.resetState(() => {
            onLogoChanged && onLogoChanged();
            this.toast('success', 'Dit logo blev fjernet');
        });
    };

    renderCurrentLogo = () => {
        const { image } = this.state;
        const { userData } = this.props;
        if (image) {
            return;
        }

        if (!userData.logo) {
            return <>
                <Icon
                    onClick={() => this.uploadRef.current.clickUpload()}
                    name='image outline'
                    size='huge'
                    link
                />
                <br />
                <i>Intet logo valgt</i>
            </>;
        }
        return <CurrentLogo userData={userData} />;
    };

    renderFileuploadButton = () => {
        const { userData } = this.props;
        const txt = userData.logo ? 'Skift' : 'Vælg';
        const ico = userData.logo ? 'exchange' : 'upload cloud';
        const col = userData.logo ? 'grey' : 'green';
        return <FileuploadButton
            ref={this.uploadRef}
            onChange={this.onLogoSelected}
            accept={acceptedTypes}
            disabled={this.state.working}
            trigger={
                <ColoredText
                    underlined={false}
                    icon={ico}
                    color={col}
                    disabled={this.state.working}
                    content={`${txt} logo`}
                    iconPosition='left'
                    padded
                    link
                />
            }
        />;
    };

    renderRemoveLogo = () => {
        const { userData } = this.props;
        if (!userData.logo) {
            return;
        }
        return <ColoredText
            underlined={false}
            onClick={this.removeLogo}
            disabled={this.state.working}
            loading={this.state.working}
            content='Fjern logo'
            iconPosition='left'
            color='grey'
            icon='x'
            padded
            link
        />;
    };

    renderLogoOptions = () => {
        const { image } = this.state;
        if (image) {
            return <>
                <Grid columns={2} textAlign='center'>
                    <Grid.Column>{this.renderSaveButton()}</Grid.Column>
                    <Grid.Column>{this.renderGoBack()}</Grid.Column>
                </Grid>
                {this.renderCropError()}
            </>;
        }
        return <>
            <br />
            {this.renderFileuploadButton()}
            <br />
            {this.renderRemoveLogo()}
        </>;
    };

    renderImageCropper = () => {
        const { image, error, working } = this.state;
        return <ImageCropper
            image={image}
            error={error}
            onCrop={this.onImageCropped}
            onError={this.onError}
            onDimensionsChanged={this.onDimensionsChanged}
            disabled={working}
            className={styles.cropper}
        />;
    };

    renderError = () => {
        const { error } = this.state;
        if (!error) {
            return;
        }
        return <Message
            content={error}
            compact
            color='red'
        />;
    };

    renderSaveButton = () => {
        const { error, cropError, working, cropped } = this.state;
        if (error) {
            return;
        }

        return <ColoredText
            underlined={false}
            iconPosition='left'
            content='Gem logo'
            icon='save'
            color='green'
            onClick={this.saveLogo}
            loading={working}
            disabled={working || !cropped || cropError}
            link
        />;
    };

    renderCropError = () => {
        const { cropError } = this.state;
        return <Transition.Group>
            { cropError && <Message>{cropError}</Message> }
        </Transition.Group>;
    };

    renderGoBack = () => {
        const { error, working } = this.state;
        const txt = error ? 'Tilbage' : 'Annuller';
        const ico = error ? 'arrow left' : 'x';
        return <ColoredText
            underlined={false}
            onClick={this.resetState}
            disabled={working}
            content={txt}
            icon={ico}
            color='grey'
            iconPosition='left'
            link
        />;
    };

    render = () => {
        return <Segment textAlign={this.props.alignment || 'center'} basic>
            <div>
                {this.renderCurrentLogo()}
                {this.renderImageCropper()}
                {this.renderError()}
                {this.renderLogoOptions()}
            </div>
        </Segment>;
    };
}

export default withUserData(UploadLogo);