import { Button, Icon, Image } from 'semantic-ui-react';
import { useRootUser } from 'util/useUser';
import { useEffect, useState } from 'react';
import { getFileData } from 'http/file-storage';
import Logo from 'images/digital-revisor/digital-revisor-big-logo-transparent.svg';
import styles from './Menu.module.scss';

const logoCache = {};

const MenuLogo = ({ onClick }) => {
    const [customLogo, setCustomLogo] = useState(null);

    const user = useRootUser();
    const isExternalModelBuilder = user.isExternalModelBuilder();
    const uid = user.id;
    const logo = user.logo;

    useEffect(() => {
        if (!isExternalModelBuilder) return;
        if (!uid) return;
        if (!logo) return;

        const downloadAndSetLogo = async () => {
            logoCache[logo] ||= getFileData(logo, true);
            const { data } = await logoCache[logo];
            setCustomLogo(`data:image/png;base64,${data}`);
        };

        downloadAndSetLogo();
    }, [uid, logo, isExternalModelBuilder]);

    const isLoadingCustomLogo = isExternalModelBuilder && logo && !customLogo;
    if (isLoadingCustomLogo) {
        return (
            <Button
                icon={<Icon name='spinner' inverted loading />}
                style={{ background: 'none' }}
                onClick={onClick}
            />
        );
    }

    return (
        <Image
            src={customLogo || Logo}
            className={styles.logo}
            onClick={onClick}
        />
    );
};

export default MenuLogo;