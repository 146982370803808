import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import i18n from 'i18n/pages/Product/components/UiElementSegment';
import withUserData from 'util/withUserData'
import { compileMarkdown } from 'util/markdown';
import { describeYoutubeVideo } from 'http/youtube';
import { TooltipVideoModal } from 'design/atoms/Tooltip';
import ColoredText from 'design/atoms/ColoredText';
import FieldGroup from '../FieldGroup';

class UiElementSegment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: !!props?.segment?.youtubeID,
			youtubeVideo: null,
		};
	};

	componentDidMount = async () => {
		const { youtubeID } = this.props.segment;
		if (!youtubeID) {
			return;
		}

		const youtubeVideo = await describeYoutubeVideo(youtubeID);

		// HACK !!!
		// Locks videos when the product isn't bought (for boligudlejning && test models only)
		// Should be configurable in the model
		// See: https://gitlab.com/Digital-Revisor/model-editor/-/issues/294
		const { pathname } = window.location;
		if (pathname.startsWith('/product') || pathname.includes('boligudlejning')) {
			youtubeVideo.locked = !this.props.hasPayed;
		}

		this.setState({
			youtubeVideo,
			loading: false,
		});
	};

	renderLink = () => {
		const { link, buttonText } = this.props.segment;
		if (!link) {
			return null;
		}

		return (
			<ColoredText
				link={link}
				newWindow
				content={buttonText || i18n.linkBtn}
				icon='right arrow'
				iconPosition='left'
				color='green'
			/>
		);
	};

	renderYoutube = () => {
		const trigger = (
			<ColoredText
				content={this.props.segment.header}
				underlined={false}
				iconPosition='left'
				icon='play circle'
				color='blue'
				link
			/>
		);
			
		const { youtubeVideo } = this.state;

		if (!youtubeVideo) {
			return trigger;
		}

		return (
			<TooltipVideoModal
				youtubeVideo={youtubeVideo}
				trigger={trigger}
			/>
		);
	};

	renderLinkAction = () => {
		const linkAction = this.renderLink();

		if (!linkAction) {
			return null;
		}

		return (
			<Segment>
				{linkAction}
			</Segment>
		)
	};

	render = () => {
		const { header, text, youtubeID, icon } = this.props.segment || {};

		if (youtubeID) {
			return this.renderYoutube();
		}

		return (
			<FieldGroup
				unwrapped
				collapsible
				defaultOpen
				title={header}
				icon={icon || 'info circle'}
				children={
					<>
						{text && (
							<Segment>
								{compileMarkdown(text)}
							</Segment>
						)}
						{this.renderLinkAction()}
					</>
				}
			/>
		);
	}
}

export default withUserData(UiElementSegment);
