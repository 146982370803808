import { API_LOCATION } from '../config/config';
import { HTTPClient } from '../network/fetch';

const client = new HTTPClient({ prefix: 'report-editor' });

class ReportEditorClient {
    executeTemplate({ productID, productLabel, yearReportDataNodeID, variables, overrideConfiguration, logoID, isInternal, language, isFirstYear, reportTemplateID, reportTemplateVersion }) {
        return client.postResource(`/exported-templates/execute-product-engine-template`, {
            productID,
            productLabel,
            yearReportDataNodeID,
            variables, 
            overrideConfiguration,
            logoID,
            isInternal,
            language,
            isFirstYear,
            reportTemplateID,
            reportTemplateVersion,
        });
    }

    getTemplateMetadata(templateID, templateVersion) {
        return client.getResource(`/exported-templates/${templateID}/${templateVersion}/metadata`);
    }

    getTemplateBlocks(templateID, templateVersion) {
        return client.getResource(`/exported-templates/${templateID}/${templateVersion}/blocks`);
    }

    getUserDefinedTheme = () => {
        return client.getResource(`/user-defined-theme`);
    };

    putUserDefinedTheme = (updatedTheme) => {
        return client.putResource(`/user-defined-theme`, updatedTheme);
    };

    getAssetURL = (assetID) => {
        return `${API_LOCATION}report-editor/assets/${assetID}/data`;
    };
}


export default new ReportEditorClient();