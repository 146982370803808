import { isIE, isEdge } from './browserKind';
import scrollbarWidth from './scrollbarWidth';

/**
 * Checks if the provided drag event is dragged outside the browser window
 * @param {*} e - Drag event
 */
export function isDraggedOutsideScreen (e) {
    if (isIE() || isEdge()) {
        const { clientX, clientY } = e;
        const width = window.innerWidth - scrollbarWidth();
        const height = window.innerHeight;
        return (
            clientX < 0 ||
            clientX >= width ||
            clientY < 0 ||
            clientY >= height
        );
    } else {
        // Other browsers
        const rtarget = e.relatedTarget;
        if (!rtarget || rtarget.tagName === 'HTML') {
            return true;
        }
    }   
}