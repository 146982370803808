import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Popup, Transition } from 'semantic-ui-react';
import { getClientsWithAuditorAssistance, copyClientAuditorAssistance } from 'http/productEngine';
import { isImpersonating } from 'util/impersonation';

const ClientDataTransferer = ({ productID, productLabel, reloadProductData, isLocked }) => {
    const [impersonating] = useState(isImpersonating());
    const [completedClients, setCompletedClients] = useState([]);
    const [chosenClient, setChosenClient] = useState(null);
    const [working, setWorking] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        if (!impersonating) {
            return;
        }

        getClientsWithAuditorAssistance(productID)
            .then(clients => {
                setCompletedClients(clients);
                setLoading(false);
            });
    }, [impersonating, productID]);

    const doTransferClientData = async () => {
        setWorking(true);

        try {
            const fullClient = completedClients.find(client => client.id === chosenClient);
            await copyClientAuditorAssistance(
                fullClient.id,
                fullClient.newestModel,
                fullClient.label,
                productID,
                productLabel,
            );
            await reloadProductData();
            toast.success(
                <span>
                    <Icon name='check circle' color='white' />
                    Revisionspåtegning indlæst
                </span>,
            );
            setOpen(false);
        } catch {
            toast.error('Der opstod en fejl');
        }

        setWorking(false);
    };

    if (!impersonating || isLocked) {
        return null;
    }

    return <Transition visible={!loading && completedClients.length > 0} animation='fade up'>
        <div style={{ textAlign: 'center' }}>
            <Popup
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                on='click'
                position='top center'
                trigger={
                    <Button
                        content='Hent tidligere revisionspåtegning'
                        icon='exchange'
                        primary={!open}
                        basic
                    />
                }
                content={
                    <>
                        <p>Vælg en klient at hente revisionspåtegning fra:</p>
                        <Dropdown
                            style={{ minWidth: '300px' }}
                            options={completedClients.map(({ id, name }) => ({
                                text: name,
                                value: id,
                            }))}
                            fluid
                            selection
                            placeholder='Vælg klient'
                            search={completedClients.length >= 5}
                            defaultValue={chosenClient}
                            disabled={working}
                            onChange={(_, { value }) => setChosenClient(value)}
                        />
                        <br />
                        <Button
                            disabled={working || !chosenClient}
                            icon={
                                <Icon
                                    name={working ? 'spinner' : 'exchange'}
                                    loading={working}
                                />
                            }
                            content='Hent revisionspåtegning'
                            primary
                            fluid
                            onClick={doTransferClientData}
                        />
                    </>
                }
            />
        </div>
    </Transition>
};

export default ClientDataTransferer;