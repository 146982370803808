import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    fetchingData: i18n({
        [daDK]: 'Indlæser dine data',
        [enUS]: 'Fetching your data',
    }),
    weAreFetchingAccounts: i18n({
        [daDK]: 'Vi henter tal fra dit bogføringssystem',
        [enUS]: "We're fetching your account plan",
    }),
    weAreConvertingAccountPlanToYearReport: i18n({
        [daDK]: 'Vi omdanner din bogføring til årsrapporten',
        [enUS]: "We're converting your account plan to year report data",
    }),
    weAreRevisingYourAccountPlanAndYearReport: i18n({
        [daDK]: 'Vi gennemgår dine tal og kvalitetssikrer årsrapporten',
        [enUS]: "We're performing quality assurance on your account plan and year report",
    }),
    error: i18n({
        [daDK]: 'Fejl',
        [enUS]: 'Error',
    }),
    chosenAccountingSystem: i18n({
        [daDK]: 'Valgt bogføringssystem',
        [enUS]: 'Chosen accounting system',
    }),
    changeAccountingSystem: i18n({
        [daDK]: 'Skift bogføringssystem',
        [enUS]: 'Change accounting system',
    }),
    fetchAccountPlan: i18n({
        [daDK]: 'Hent tal',
        [enUS]: 'Fetch account plan',
    }),
    updateAccountPlan: i18n({
        [daDK]: 'Opdater tal',
        [enUS]: 'Update account plan',
    }),
    lastFetch: i18n({
        [daDK]: 'Data sidst opdateret d.',
        [enUS]: 'Last updated at',
    }),
    noERPSelected: i18n({
        [daDK]: 'Du er endnu ikke koblet op til et bogføringssystem',
        [enUS]: 'You are not connected to an accounting system yet',
    }),
    reconnect: i18n({
        [daDK]: 'Genforbind',
        [enUS]: 'Reconnect',
    }),

    /* Manual quality assurance (Eng and i18 key = google translate) */
    manualQualityControl: i18n({
        [daDK]: 'Manuel kvalitetskontrol',
        [enUS]: 'Manual quality control',
    }),
    weReviewAndApproveTheFollowingInYourAnnualReport: i18n({
        [daDK]: 'Vi gennemgår og godkender følgende i din årsrapport',
        [enUS]: 'We review and approve the following in your annual report',
    }),
    thatChartOfAccountsAndFiguresHaveComeCorrectlyFromBookkeeping: i18n({
        [daDK]: 'At kontoplan og tal er kommet korrekt fra bogføring',
        [enUS]: 'That chart of accounts and figures have come correctly from bookkeeping',
    }),
    thatTheChartOfAccountsCanBeConvertedIntoACorrectlyPreparedAnnualReport: i18n({
        [daDK]: 'At kontoplan kan omdannes til korrekt opstillet årsrapport',
        [enUS]: 'That the chart of accounts can be converted into a correctly prepared annual report',
    }),
    properHandlingOfTaxDeductions: i18n({
        [daDK]: 'Korrekt håndtering af skattefradrag',
        [enUS]: 'Proper handling of tax deductions',
    }),
    thatAnnualReportAgreesAndBalances: i18n({
        [daDK]: 'At årsrapport stemmer og balancerer',
        [enUS]: 'That annual report agrees and balances',
    }),
    youJustContinueTheProcessAtDigitalRevisorAnswerQuestionsAndLookThroughTheAnnualReport: i18n({
        [daDK]: 'Du fortsætter blot processen på Digital Revisor, svarer på spørgsmål og kigger årsrapporten igennem.',
        [enUS]: 'You just continue the process at Digital Revisor, answer questions and look through the annual report.',
    }),
    weWillSendAnEmailWhenTheAnnualReportHasBeenReviewedAndIsReadyForSignatureAndSubmission: i18n({
        [daDK]: 'Vi sender en mail, når årsrapporten er gennemgået og klar til underskrift og indsendelse.',
        [enUS]: 'We will send an email when the annual report has been reviewed and is ready for signature and submission.',
    }),
    weReviewItWithin24Hours: i18n({
        [daDK]: 'Vi gennemgår den indenfor 24 timer.',
        [enUS]: 'We review it within 24 hours.',
    }),

    /* Error messages */
    billy_invalid_amount: i18n({
        [daDK]: 'Modtog en ugyldig saldo fra Billy',
        [enUS]: 'Got an unsupported account from Billy: Invalid amount',
    }),
    billy_invalid_account_number: i18n({
        [daDK]: 'Modtog et ugyldigt kontonummer fra Billy',
        [enUS]: 'Got an unsupported account from Billy: Invalid account number',
    }),
    billy_unmatched_account: i18n({
        [daDK]: 'Modtog posteringer fra Billy som ikke tilhører nogen kendt konto',
        [enUS]: 'Got an unmatched account from Billy',
    }),
    billy_missing_account_no: i18n({
        [daDK]: 'Modtog en konto uden kontonummer fra Billy',
        [enUS]: 'Got an unsupported account from Billy: No account number',
    }),
    billy_unhandled_paging: i18n({
        [daDK]: 'Billy: Denne størrelse kontoplan understøttes ikke endnu. Kontakt supporten.',
        [enUS]: 'Billy: Paging is not supported yet',
    }),
    dinero_rate_limiting: i18n({
        [daDK]: 'Dinero svarer ikke lige nu. Prøv igen senere',
        [enUS]: 'Dinero is temporarily not responding. Try again later',
    }),
    dinero_not_pro: i18n({
        [daDK]: 'Dinero svarer: "Ikke Pro abonnement". Kontakt Dineros support for hjælp til at tænde for "API adgang" til dine bogføringsdata',
        [enUS]: 'Dinero says: "Not Pro" membership. Please contant Dinero support and have them enable API access for your account',
    }),
    dinero_login_failed: i18n({
        [daDK]: 'Vi kunne ikke forbinde til din konto hos Dinero. Kontroller venligst din API-nøgle, og tryk på "skift bogføringssystem" og forbind med Dinero igen.',
        [enUS]: 'Dinero login not allowed. Please check your API key',
    }),
    dinero_invalid_apikeys: i18n({
        [daDK]: 'Dinero API melder at din API nøgle er udløbet. Vær venlig at trykke på "skift bogføringssystem" og forbind med Dinero igen.',
        [enUS]: 'Dinero API says your API key is expired. Please press "change ERP system" and connect with Dinero again',
    }),
    dinero_decrepit_apikeys: i18n({
        [daDK]: 'Dinero understøtter ikke længere API nøgler. Vær venlig at trykke på "skift bogføringssystem" og log på Dinero.',
        [enUS]: 'Dinero no longer support API keys. Please press "change ERP system" and choose login on Dinero',
    }),
    dinero_other_error: i18n({
        [daDK]: 'Dinero API melder en fejl. Kontakt supporten',
        [enUS]: 'Dinero API responds with an error. Please contact support',
    }),
    oauth_other_error: i18n({
        [daDK]: 'Dinero API melder en fejl angående login. Kontakt supporten',
        [enUS]: 'Dinero API responds with an error in regards to login. Please contact support',
    }),
    oauth_invalid_grant: i18n({
        [daDK]: 'Før du kan hente tal skal du genforbinde til Dinero.',
        [enUS]: 'You have to reconnect with Dinero to fetch figures.',
    }),
    uniconta_invalid_session: i18n({
        [daDK]: 'Du skal genetablere forbindelsen til Uniconta.',
        [enUS]: 'You have to reconnect with Uniconta to fetch figures.',
    }),
    accountplans_no_response: i18n({
        [daDK]: 'Intern fejl: En Digital Revisor service svarer ikke. Kontakt supporten.',
        [enUS]: 'Internal error: No response from ML service',
    }),
    webfinance_rate_limiting: i18n({
        [daDK]: 'WebFinance svarer ikke lige nu. Prøv igen senere',
        [enUS]: 'WebFinance is temporarily not responding. Try again later',
    }),
    webfinance_login_error: i18n({
        [daDK]: 'Fejl ved login. Tjek venligst om du indtastede logininformationerne korrekt. Kontakt supporten hvis problemet ikke bliver løst.',
        [enUS]: 'WebFinance API responds with an error in regards to the login info provided.',
    }),
    webfinance_other_error: i18n({
        [daDK]: 'WebFinance melder en fejl. Kontakt supporten',
        [enUS]: 'WebFinance API responds with an error. Please contact support',
    }),
    xena_rate_limiting: i18n({
        [daDK]: 'Xena svarer ikke lige nu. Prøv igen senere',
        [enUS]: 'Xena is temporarily not responding. Try again later',
    }),
    xena_account_no_error: i18n({
        [daDK]: 'Modtog en konto uden kontonummer fra Xena. Sørg for, at alle konti har et gyldigt kontonummer og prøv igen. Konti uden kontonummer: ',
        [enUS]: 'Received an account without an accountnumber from Xena. Make sure that every account has an valid accountnumber and try again. The account in question:',
    }),
    xena_other_error: i18n({
        [daDK]: 'Xena API melder en fejl. Kontakt supporten',
        [enUS]: 'Xena API responds with an error. Please contact support',
    }),
    businesscentral_rate_limiting: i18n({
        [daDK]: 'Business Central svarer ikke lige nu. Prøv igen senere',
        [enUS]: 'Business Central is temporarily not responding. Try again later',
    }), 
    businesscentral_category_error: i18n({
        [daDK]: 'En konto i din kontoplan på Business Central mangler at blive tildelt en konto-kategori.',
        [enUS]: 'An account in your Chart of Accounts in Business Cental is missing a category.',
    }),
    businesscentral_other_error: i18n({
        [daDK]: 'Business Central API melder en fejl. Kontakt supporten',
        [enUS]: 'Business Central API responds with an error. Please contact support',
    }),
    ofinda_rate_limiting: i18n({
        [daDK]: 'Ofinda svarer ikke lige nu. Prøv igen senere',
        [enUS]: 'Ofinda is temporarily not responding. Try again later',
    }), 
    ofinda_category_error: i18n({
        [daDK]: 'En konto i din kontoplan på Ofinda mangler at blive tildelt en konto-kategori.',
        [enUS]: 'An account in your Chart of Accounts in Ofinda is missing a category.',
    }),
    ofinda_other_error: i18n({
        [daDK]: 'Ofinda API melder en fejl. Kontakt supporten',
        [enUS]: 'Ofinda API responds with an error. Please contact support',
    }),
    different_accounts_error: i18n({
        [daDK]: 'Vi har fundet følgende ens kontonumre med forskellige kontotyper',
        [enUS]: 'Accountplan without headers not supported',
    }),
    missing_accounts_error: i18n({
        [daDK]: 'Følgende mangler i det ene regnskabsår',
        [enUS]: 'Accountplan without headers not supported',
    }),
    erp_no_headers: i18n({
        [daDK]: 'Kontoplaner uden overskrifter understøttes ikke',
        [enUS]: 'Accountplan without headers not supported',
    }),
    erp_no_initial_header: i18n({
        [daDK]: 'Kontoplaner som ikke starter med en overskrift understøttes ikke',
        [enUS]: 'Accountplan without an initial header is not supported',
    }),
    erp_no_accounts: i18n({
        [daDK]: 'Kontoplan ikke fundet. Har du bogført for dette skatteår?',
        [enUS]: 'Accountplan not found',
    }),
    erp_duplicates: i18n({
        [daDK]: 'Flere konti i kontoplanen med samme kontonummer understøttes ikke',
        [enUS]: 'Multiple accounts with same account number is not supported',
    }),
    erp_not_allowed: i18n({
        [daDK]: 'Vi har ikke rettighed til at hente tal fra dit bogføringssystem. Du kan prøve at forbinde til dit økonomisystem forfra. Har du problemer, bedes du kontakte os i supporten.',
        [enUS]: 'We do not have the rights to fetch the account plan from your bookkeeping system. Please try reconnecting to the ERP system, and try again. Contact us through the support chat if you keep experiencing errors.',
    }),
    reporting_period_error: i18n({
        [daDK]: 'Din virksomheds første skatteår ligger efter produktets skatteår',
        [enUS]: 'The first tax year of your company is after the tax year of the product',
    }),
    defaultErpErrorMsg: i18n({
        [daDK]: 'Kunne ikke hente bogføringsdata',
        [enUS]: 'Could not fetch accounting data',
    }),
};
