/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Divider, Icon, List, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import i18n from 'i18n/pages/ArchiveDetail';
import styles from './ArchiveDetailStep.module.scss';
import { Media } from 'design/atoms/Media'
import { prepareDownloadLink } from '../../../http/file-storage';
import { DISPOSITION } from '../../molecules/FileAttachmentWidget/FileItem';

class ArchiveDetailStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  createLabel(field, isMobile) {
    return (
      <div className={isMobile ? '' : styles.label}>
        {isMobile ? <b>{field.label}</b> : field.label}
      </div>
    );
  }

  createValue(field, isMobile) {
    const value = field.value ? field.value : "";
    const suffix = field.suffix ? ` ${field.suffix}` : "";
    return (
      <div className={isMobile ? '' : styles.value}>
        {`${value}${suffix}`}
      </div>
    );
  }

  async prepareDownloadLink(fileId, disposition) {
    try {
			this.downloadElm.href = await prepareDownloadLink(fileId, disposition);
      this.downloadElm.click();
		} catch (err) {
			console.error("Error", err);
		}
	}

  createAttachments(field, isMobile) {
    const attachments = field.files;
    if(attachments.length === 0) {
      return null;
    }

    const seeAttachmentsLabel = (
      <div className={isMobile ? styles['see-attachments-label-mobile'] : styles['see-attachments-label']}>
        <Icon disabled name='paperclip' />
        <p>{i18n.seeAttachments}</p>
      </div>
    );
    const jsxAttachments = (
      <List>
        {
          attachments.map(att =>
            <List.Item>
              <List.Content className={styles.attachment}>
                <a target="_blank" rel="noopener noreferrer" ref={a => this.downloadElm = a}></a>
                <Link><Icon name='eye' onClick={() => this.prepareDownloadLink(att.fileID, DISPOSITION.INLINE)} /></Link>
                <Link><Icon name='cloud download' onClick={() => this.prepareDownloadLink(att.fileID, DISPOSITION.ATTACHMENT)} /></Link>
                <span>{` ${att.name}`}</span>
              </List.Content>
            </List.Item>,
          )
        }
      </List>
    );
    return (
      <Popup position='top right' trigger={seeAttachmentsLabel} on='click' pinned>
        <Popup.Content>
          {jsxAttachments}
        </Popup.Content>
      </Popup>
    );
  }

  renderRow(field) {
    if(field.isDivider) {
      return <>
        <Media lt='tablet'>
          <Divider className={styles['divider']} />
        </Media>
        <Media gte='tablet'>
          <Divider className={styles['divider-shortened']} />
        </Media>
      </>;
    } else {
      return <>
        <Media lt='tablet'>
          <li key={field.id} className={field.value ? styles['row-mobile'] : styles['row-mobile-no-value']}>
            {this.createLabel(field, true)}
            {this.createValue(field, true)}
            {this.createAttachments(field, true)}
          </li>
        </Media>
        <Media gte='tablet'>
          <li key={field.id} className={styles.row}>
            {this.createLabel(field, false)}
            {this.createValue(field, false)}
            {this.createAttachments(field, false)}
          </li>
        </Media>
      </>;
    }
  }

  render() {
    return (
      <div className={styles.step}>
        <h3><b className={styles['step-title']}>{this.props.step.title}</b></h3>
        <ul>
          { this.props.step.fields.map(field => this.renderRow(field)) }
        </ul>
      </div>
    );
  }
}

export default ArchiveDetailStep;
