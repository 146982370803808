import React from 'react';
import { getParam } from 'util/QueryParams';
import { Container, Header, Segment } from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';

const Bye = () => {
    const segment = getParam('segment');

    const questionarieID = (
        segment === 'privat' ?
        '1FAIpQLScYUxPrImmMsIg5vrFUhJftXbTGN9Bauvsr6oWzKi7DOplQ3A' :
        '1FAIpQLScaMfiBiXujR3cH7oafO_V9Q5EIo6JXsSF41NPwmdPDNFLc3Q'
    );

    const questionarieLink = `https://docs.google.com/forms/d/e/${questionarieID}/viewform?usp=sf_link`;

    return (
        <Container>
            <br />
            <Segment>
                <Header>Din konto blev slettet</Header>
                <p>
                    Vi har sendt en mail til dig, der bekræfter sletningen af din konto.
                </p>
                <p>
                    Hvis du har brug for vores ydelse igen i fremtiden, kan du altid oprette en ny konto.
                </p>
                <p>
                    Vil du hjælpe os med at blive klogere på, hvorfor du har slettet din konto,
                    så vi kan forbedre vores service? Vi bliver meget glade,
                    hvis du vil svare på et enkelt spørgsmål i dette&nbsp;
                    <a href={questionarieLink}>
                        <ColoredText color='green' link>spørgeskema</ColoredText>
                    </a>.
                </p>
            </Segment>
        </Container>
    );
};

export default Bye;