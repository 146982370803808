import React from 'react';
import { Grid, Icon, Segment } from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';

import {
    SigningStateFailed,
    // SigningStateCreated,
    // SigningStateStarted,
    SigningStateCompleted,
    SigningStateExpired,
    SigningStateStopped,
    // SigningStateCampaignStarted,
    SigningStateRejected, 
    SigningStateStarted,
    FlowStatusToLabel,
    FlowStatusDescription, 
    SigningStateAdminCancelled} from './FlowStatus';
import RefreshLink from './RefreshLink';

// FlowHeader
// Viser status på et Visma Addo flow
// Altså en samlet status for alle underskrivere i et flow
//
export default ({ flowStatus, reloadProductData }) => {
    if (flowStatus === 0) {
        return null; // no status box until we initial the flow with visma
    }

    let icon;
    let color;
    if (flowStatus === SigningStateFailed) {
        // attrs = { error: true };
        icon = 'warning circle';
        color = 'red';
    }
    if (flowStatus === SigningStateCompleted) {
        // attrs = { positive: true };
        icon = 'check circle';
        color = 'green';
    }
    if (flowStatus === SigningStateStarted) {
        // attrs = { color: 'blue' };
        icon = 'paper plane';
        color = 'blue';
    }
    if (flowStatus === SigningStateExpired || flowStatus === SigningStateStopped || flowStatus === SigningStateRejected || flowStatus === SigningStateAdminCancelled) {
        // attrs = { color: 'yellow' }; // "warning" virker ikke
        icon = 'warning circle';
        color = 'yellow';
    }
    
    return <Segment>
        <Grid columns={2}>
            <Grid.Column width={12}>
                <b>
                    Status:
                    &nbsp;
                    <ColoredText color={color}>
                        {FlowStatusToLabel(flowStatus)}
                    </ColoredText>
                </b>
                &nbsp;
                { icon && <Icon name={icon} color={color} /> }
                <br />
                { FlowStatusDescription(flowStatus) }
            </Grid.Column>
            <Grid.Column width={4} verticalAlign='middle'>
                <RefreshLink reloadProductData={reloadProductData} />
            </Grid.Column>
        </Grid>
    </Segment>;
}

export const LegacyFlowStatus = ({done, ongoing}) => {
    if (done) return <div><Icon name="pencil" /> &nbsp; Underskriftsproces afsluttet</div>
    if (ongoing) return <div><Icon name="pencil" /> &nbsp; Underskriftsproces i gang</div>
    return null;
}