import React      from 'react';
import Tooltip    from 'design/atoms/Tooltip';
import Form       from 'design/atoms/Form';

class UiElementHeader extends React.Component {
	getTooltip () {
		const { tooltip, youtubeVideo } = this.props;
		if (tooltip || youtubeVideo) {
			return <Tooltip data={tooltip} youtubeVideo={youtubeVideo} />;
		} else {
			return null;
		}
	}

	render () {
        const { label } = this.props;

		// render tooltip
		const tooltipJSX = this.getTooltip();

		// prepare header
		let headerElement;

		if (label || tooltipJSX) {
			headerElement = (
				<strong>
					{label}
					<span>
						{tooltipJSX}
					</span>
				</strong>
			);
		}

		return (
            <Form.Row>
				<Form.Field>
					{headerElement}
				</Form.Field>
			</Form.Row>
		);
	}
}

export default UiElementHeader;
