import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useBreakpoints } from 'design/atoms/Media';

const PaymentPanels = ({ leftPanel, rightPanel }) => {
    const breakpoints = useBreakpoints();

    return breakpoints.select({
        'gte computer': (
            <Grid stackable>
                <Grid.Row columns={2}>
                    <Grid.Column width={9}>
                        {leftPanel}
                    </Grid.Column>
                    <Grid.Column width={7}>
                        {rightPanel}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        ),
        
        'default': (
            <>
                {leftPanel}
                {rightPanel}
            </>
        ),
    });
};

export default PaymentPanels;