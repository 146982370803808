import React, { useEffect, useState } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import { getCustomerInvoices, getInvoicePdf } from 'http/economic';
import { formatDate } from 'util/format/DateTime';
import { downloadBase64 } from 'util/downloadBlob';
import { formatPrice } from 'util/format/Price';
import ProfileTable from './ProfileTable';

const InvoicesBox = () => {
    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [activeInvoice, setActiveInvoice] = useState(null);

    const doLoadInvoices = async () => {
        const invoices = await getCustomerInvoices();
        setLoading(false);
        setInvoices(invoices);
    };

    const downloadInvoice = async invoiceIdx => {
        const { id } = invoices[invoiceIdx];
        
        // flag selected invoice row as active
        setActiveInvoice(invoiceIdx);

        // fetch PDF blob
        const pdf = await getInvoicePdf(id);

        // trigger download
        downloadBase64('application/pdf', pdf, '');

        // reset active invoice
        setActiveInvoice(null);
    };

    useEffect(() => {
        doLoadInvoices();
    }, []);


    if (loading) {
        return <Loader
            size='big'
            inline='centered'
            active
        />;
    }

    if (invoices.length === 0) {
        return <p>Der er ingen fakturaer at vise</p>;
    }

    const success = true;

    return (
        <ProfileTable
            header={[
                'Dato',
                'Beløb',
                'Status',
            ]}
            body={invoices.map(({ id, date, grossAmount }, i) => ({
                key: id,
                error: !success,
                cells: [
                    <>{formatDate(date)}</>,
                    <>{formatPrice(grossAmount)} kr.</>,
                    <>
                        <Icon
                            name={success ? 'check' : 'x'}
                            color={success ? 'green' : 'red'}
                        />
                        {success ? 'Godkendt' : 'Fejlet'}
                    </>,
                ],
            }))}

            actions={[
                {
                    label: 'Download',
                    icon: 'download',
                    loading: idx => idx === activeInvoice,
                    disabled: idx => idx === activeInvoice,
                    onClick: downloadInvoice,
                },
            ]}
        />
    );
};

export default InvoicesBox;
