import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

const EditIcon = ({ text, onClick, ...props }) => {
    return (
        <Popup
            key={Math.random()}
            content="Rediger postering"
            position='top center'
            trigger={<Icon name='external square alternate' color='black' onClick={onClick} link {...props} />}
        />
    );
};
export default EditIcon;
