import { useEffect, useState } from 'react';
import { ACCOUNTANT_HELP_CALENDLY_BOOKING_URL } from 'config/config';
import loadScript from 'util/loadScript';
import { getCurrentLogin } from 'http/accounts';
import useRestResource from 'util/useRestResource';
import { useRootUser } from 'util/useUser';

const CalendlyBookingPopup = ({ triggerRenderer, onEventScheduled }) => {
    const [loadingScript, setLoadingScript] = useState(true);
    const [gotScriptError, setGotScriptError] = useState(false);

    const user = useRootUser();

    const loginResource = useRestResource({
        fetcher: getCurrentLogin,
    });

    useEffect(() => {
        loadScript('https://assets.calendly.com/assets/external/widget.js')
            .catch(() => setGotScriptError(true))
            .finally(() => setLoadingScript(false));
    }, []);

    useEffect(() => {
        if (!onEventScheduled) {
            return;
        }

        if (loadingScript || gotScriptError) {
            return;
        }

        const messageHandler = function (e) {
            if (e.origin !== 'https://calendly.com') return;
            if (e?.data?.event !== 'calendly.event_scheduled') return;
            onEventScheduled(e.data.payload);
        };

        window.addEventListener('message', messageHandler);
        return () => window.removeEventListener('message', messageHandler);
    }, [onEventScheduled, loadingScript, gotScriptError]);

    const loading = loadingScript || loginResource.loading;
    const gotError = gotScriptError || loginResource.error;

    const onClick = () => {
        if (loading || gotError) {
            return;
        }

        let phoneNumber = loginResource.data.phone.trim().replace(/\s/g, '');
        if (!/^\+\d{2}/.test(phoneNumber)) {
            phoneNumber = `+45${phoneNumber}`;
        }

        const now = new Date();
        const queryParams = `?back=1&month=${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;

        window.Calendly.initPopupWidget({
            url: ACCOUNTANT_HELP_CALENDLY_BOOKING_URL + queryParams,
            prefill: {
                name: user.displayName,
                email: loginResource.data.loginEmail,
                customAnswers: {
                    a1: phoneNumber,
                },
            },
        })
    };

    return triggerRenderer({ loading, gotError, onClick });
};

export default CalendlyBookingPopup;