export const parseSIE = (rawSIE, startDate, endDate) => {
    const result = rawSIE.split(/\n+/);
    let fromDate = startDate.replaceAll("-", "")
    let toDate = endDate.replaceAll("-", "")
    var accountList = [];
    var yearList = [];
    var balanceList = [];

    for (let row of result) {
        row = row.replaceAll('', 'oe')
        row = row.replaceAll('', 'aa')
        row = row.replaceAll('', 'ae')
        row = row.replaceAll('', 'e')
        row = row.replaceAll('', 'Oe')
        row = row.replaceAll('', 'Aa')
        row = row.replaceAll('', 'Ae')
        const titleList = row.split(/"/)
        const rowArray = row.split(" ")
        if (rowArray[0] === "#KONTO") {
            accountList.push([rowArray[1], titleList[1]])
        } else if (rowArray[0] === "#RAR") {
            yearList.push([rowArray[1], rowArray[2], rowArray[3]])
        }else if (rowArray[0] === "#UB") {
            balanceList.push([rowArray[2], "Balance", rowArray[3].replaceAll('\r', ""), rowArray[1]])
        } else if (rowArray[0] === "#RES") {
            balanceList.push([rowArray[2], "Drift", rowArray[3].replaceAll('\r', ""), rowArray[1]])
        }
    }

    let yearId = 0;
    let addLastYear = false;
    for (let year of yearList) {
        if (yearId === parseInt(year[0])) {
            if (parseInt(year[1]) === parseInt(fromDate) && parseInt(year[2]) === parseInt(toDate)) {
                yearId = parseInt(year[0]);
            } else {
                return ["", "årsfejl"]
            }
        } else if (parseInt(year[0]) === -1) {
            addLastYear = true;
        }
    }

    let finaceAccounts = []
    let finaceAccountHeader = {
        number: 0, 
        accountType: "Overskrift",
        amount: 0.0,
        amountLastYear: 0,
        text: "Kontoplan",
        vatcode: "none",
    }

    finaceAccounts.push(finaceAccountHeader)
    for (let row of balanceList) {         
        for (let account of accountList) {
            if (parseInt(row[0]) === parseInt(account[0]) && parseInt(row[3]) === yearId) {
                let finaceAccount = {
                    number: parseInt(row[0]), 
                    accountType: row[1],
                    amount: parseFloat(row[2]),
                    amountLastYear: 0,
                    text: account[1],
                    vatcode: "none",
                }
                finaceAccounts.push(finaceAccount);
                break
            }
        } 
    }

    if (addLastYear) {
        for (let row of balanceList) {
            let accountExists = false 
            if (parseInt(row[3]) === -1) {
                for (let account of finaceAccounts) {
                    if (parseInt(row[0]) === account.number) {
                        account.amountLastYear = parseFloat(row[2])
                        accountExists = true
                        break
                    }
                }
                if (!accountExists) {
                    for (let account of accountList) {
                        if (parseInt(row[0]) === parseInt(account[0])) {
                            let finaceAccount = {
                                number: parseInt(row[0]), 
                                accountType: row[1],
                                amount: 0,
                                amountLastYear: parseFloat(row[2]),
                                text: account[1],
                                vatcode: "none",
                            }
                            finaceAccounts.push(finaceAccount);
                            break
                        }
                    }
                } 
            }    
        }
    } 
    return [finaceAccounts, ""];
};