import React from 'react';
import { List } from 'semantic-ui-react';
import papaparse from 'papaparse';

// utility used for loose string comparison
// that return the "essence" of a string
// 
// for instance the strings "konto plan  " and "  Kontoplan" 
// will be reduced to the same string ("kontoplan")
const essence = str => {
    return (
        str
        .toLowerCase()
        .replaceAll('æ', 'ae')
        .replaceAll('ø', 'oe')
        .replaceAll('å', 'aa')
        .split('')
        .filter(c => /[a-z]/.test(c))
        .join('')
    )
};

// supported account types
const AC_DRIFT = 'Drift';
const AC_BALANCE = 'Balance';
const AC_OVERSKRIFT = 'Overskrift';
const AC_SUM = 'Sum';

// supported VAT-codes
const VATCODE_NONE = 'none';
const VATODE_SALG = 'salg';
const VATODE_KOEB = 'køb';
const VATODE_REP = 'rep';

const DRIFT_BALANCE = [AC_DRIFT, AC_BALANCE];
const VALID_ACCOUNT_TYPES = [...DRIFT_BALANCE, AC_OVERSKRIFT, AC_SUM];
const VALID_VATCODES = [VATCODE_NONE, VATODE_SALG, VATODE_KOEB, VATODE_REP];

// maps from internal property name => expected csv column name
const inputNames = {
    number:         'Kontonummer',
    text:           'Kontonavn',
    amount:         'Beløb i år',
    amountLastYear: 'Beløb sidste år',
    accountType:    'Kontotype',
    vatcode:        'Momskode',
};

// utility for creating validation results
const validationResult = (valid, message, data) => ({ valid, message, data });
const validationFailure = message => validationResult(false, message);
const validationSuccess = data => validationResult(true, null, data);

const isDriftBalanceRow = row => {
    return DRIFT_BALANCE.map(essence).includes(essence(row[essence(inputNames.accountType)]));
};

const remove1000sep = value => {
    const countChars = (string, char) => {
        return [...string].filter(c => c === char).length;
    };

    const commaCount = countChars(value, ',');
    const dotCount = countChars(value, '.');

    // validate one comma
    if (commaCount !== 1) {
        return value;
    }

    // validate number has dots
    if (dotCount === 0) {
        return value;
    }

    // validate that dot(s) comes before comma
    if (value.indexOf(',') < value.lastIndexOf('.')) {
        return value;
    }

    return value.replace(/\./g, '');
};

const formatDecimal = (value) => {
    // in case of comma as decimal seperator, replace it
    const sanitized = remove1000sep(value).replaceAll(',', '.');
    return Number(sanitized);
};

// data validators
const isValidPositveInteger = value => {
    if (!/^-?[0-9]+$/.test(value)) {
        return validationFailure(`"${value}" er ikke et validt heltal`);
    }

    if (Number(value) < 0) {
        return validationFailure(`Negativ talværdi "${value}" godtages ikke`);
    }

    return validationSuccess();
};

const isValidDecimal = (value, row) => {
    // only do validation on "Drift" && "Balance" accounts
    if (!isDriftBalanceRow(row)) {
        return validationSuccess();
    }

    value = remove1000sep(value);

    // both possible seperator chars are not allowed in the number
    if (['.', ','].every(sep => value.includes(sep))) {
        return validationFailure('Både punktum og komma må ikke indgå i tallet på en gang. Vælg en af delene som decimaltegn.');
    }

    
    const asNumber = formatDecimal(value);
    if (isNaN(asNumber)) {
        return validationFailure(`"${value}" er ikke validt tal`);
    }

    return validationSuccess();
};

const enumValidator = (kind, enumValues, onlyDriftBalance) => {
    const pvals = new Set(enumValues.map(essence));
    return (value, row) => {
        if (onlyDriftBalance && !isDriftBalanceRow(row)) {
            return validationSuccess();
        }

        if (!pvals.has(essence(value))) {
            return validationFailure(`"${value}" er ikke en valid ${kind}. Brug en af følgende: ${enumValues.join(', ')}`);
        }

        return validationSuccess();
    };
};

const enumFormatter = (enumValues, fallback) => {
    return value => {
        return enumValues.find(v => essence(v) === essence(value)) || fallback;
    };
};

const formatDriftBalanceDecimals = (decimal, row) => {
    if (isDriftBalanceRow(row)) {
        return formatDecimal(decimal);
    }

    // if not drift/balance, return 0
    return 0;
};

// List of all required columns of the CSV-file
//
// a "required column" can have the following properties:
// inputName      => expected column name in CSV-file
// nullable       => function that takes the current row, and determines if the column value is nullable
// unique         => whether the value of the column must be unique
// valueValidator => function that takes a value from the column and validates it
// formatter      => function that takes a value from the column and formats it for internal usage
// description    => description of column content (for the guide)
export const requiredColumns = [
    {
        inputName: inputNames.number,
        unique: true,
        valueValidator: isValidPositveInteger,
        formatter: value => Number(value),
        description: (
            <span>
                Kontonummeret skal være et heltal, og hver linje i kontoplanen skal have tildelt kontonummer.
                To linjer i kontoplanen må ikke have det samme kontonummer.
            </span>
        ),
    },
    {
        inputName: inputNames.text,
        description: (
            <span>
                Her indsættes det navn, der passer til det givne kontonummer
            </span>
        ),
    },
    {
        inputName: inputNames.amount,
        nullable: row => !isDriftBalanceRow(row),
        valueValidator: isValidDecimal,
        formatter: formatDriftBalanceDecimals,
        description: (
            <span>
                Her skal beløbene for det indeværende skatteår indsættes,
                så det stemmer overfor den givende konto.
                Beløbet må ikke indeholde tusindtalsseparator. Du kan
                bruge både punktum og komma som decimaltegn.
            </span>
        ),
    },
    {
        inputName: inputNames.amountLastYear,
        nullable: row => !isDriftBalanceRow(row),
        valueValidator: isValidDecimal,
        formatter: formatDriftBalanceDecimals,
        description: (
            <span>
                Her skal beløbene for det forhenværende skatteår indsættes,
                så det stemmer overfor den givende konto.
                Beløbet må ikke indeholde tusindtalsseparator. Du kan
                bruge både punktum og komma som decimaltegn.
            </span>
        ),
    },
    {
        inputName: inputNames.accountType,
        valueValidator: enumValidator('kontotype', VALID_ACCOUNT_TYPES),
        formatter: enumFormatter(VALID_ACCOUNT_TYPES),
        description: (
            <span>
                Her skal der tages stilling til inddeling af kontotypen.
                Hos Digital Revisor understøtter vi fire klassificeringer:
                <List bulleted>
                    {VALID_ACCOUNT_TYPES.map(accountType => {
                        const description = {
                            [AC_DRIFT]: 'Denne type skal tildeles hvis kontoen hører ind under virksomhedens driftsmæssige resultat.',
                            [AC_BALANCE]: 'Denne type skal tildeles hvis kontoen hører ind under virksomhedens balance regnskab, med hensyn til Passiver og Aktiver.',
                            [AC_SUM]: 'Skal tildeles på konti som er en summering af nogle ovenstående konti. F.eks. "Omsætning i alt", som er en summering af de forskellige omsætningskonti tilsammen.',
                            [AC_OVERSKRIFT]: 'Skal tildeles på konti, som er overskrifter. F.eks. "Omsætning"',
                        }[accountType];
                        return <List.Item>
                            <b>{accountType}:</b> {description}
                        </List.Item>;
                    })}
                </List>
            </span>
        ),
    },
    {
        inputName: inputNames.vatcode,
        nullable: row => !isDriftBalanceRow(row),
        valueValidator: enumValidator('momskode', VALID_VATCODES, true),
        formatter: enumFormatter(VALID_VATCODES, VATCODE_NONE),
        description: (
            <span>
                Her skal der tages stilling til inddeling af momskoder.
                Hos Digital Revisor understøtter vi fire klassificeringer:
                <List bulleted>
                    {VALID_VATCODES.map(vatcode => <List.Item>
                        <b>{vatcode}</b> {vatcode === VATODE_REP && '(repræsentation)'}
                    </List.Item>)}
                </List>
            </span>
        ),
    },
];

// create lookup table for required columns
const requiredColumnTable = {};
requiredColumns.forEach(column => {
    requiredColumnTable[essence(column.inputName)] = column;
});

export const parseCSV = rawCsv => {
    const result = papaparse.parse(rawCsv);
    if (result.errors.length > 0) {
        return validationFailure('Filen kunne ikke læses. Har du valgt en valid CSV-fil?');
    }

    // split into headers & rows
    const [headers, ...rows] = result.data;

     // look for replacement characters in the header
    if (headers.find(header => header.includes('�'))) {
        return validationFailure(
            'CSV-filens enkodning er ikke understøttet. ' +
            'Når du eksporterer filen fra dit regnskabsprogram ' +
            'skal du huske at specifere UTF-8 som enkodning',
        );
    }

    if (rows.length === 0) {
        return validationFailure('Valgte CSV-fil indeholder ingen rækker');
    }

    return validationSuccess({
        headers,
        rows,
    });
};

export const validateHeaders = headers => {
    // convert CSV headers to a set of simplified strings
    const headersSet = new Set(headers.map(essence));
    
    // run through each of the required columns
    // and check if the column is present in the header of the CSV file
    const missingColumns = [];
    for (let requiredColumn of requiredColumns) {
        const simplifiedInputName = essence(requiredColumn.inputName);
        if (!headersSet.has(simplifiedInputName)) {
            missingColumns.push(requiredColumn);
        }
    }

    // return validation error if any required columns are missing
    if (missingColumns.length > 0) {
        const plur = missingColumns.length !== 1;
        return validationFailure(
            <div>
                Følgende kolonne{plur && 'r'} er påkrævet men er ikke tilstede i den valgte CSV-fil:
                <br />
                <List bulleted>
                    {missingColumns.map(({ inputName }) => <List.Item>{inputName}</List.Item>)}
                </List>
                <p>
                    Sørg venligst for at kolonne{plur ? 'rne' : 'n'} findes i din CSV-fil,
                    og at kolonnenavne{plur ? 'ne' : 't'} er indtastet korrekt.
                </p>
            </div>,
        );
    }

    return validationSuccess();
};

export const sanitizeRows = ({ headers, rows }) => {
    const relevantIndices = [];
    for (let headerIdx in headers) {
        const header = essence(headers[headerIdx]);
        if (requiredColumnTable[header]) {
            relevantIndices.push(Number(headerIdx));
        }
    }

    const sanitizedRows = rows.map((row, rowIndex) => {
        const out = {};
        relevantIndices.forEach(idx => {
            const simplifiedHeader = essence(headers[idx]);
            out[simplifiedHeader] = row[idx]?.trim();
        });

        if (!Object.values(out).some(v => !!v)) {
            return null;
        } 

        out.rowNumber = rowIndex + 2;

        return out;
    });

    const filteredRows = sanitizedRows.filter(row => !!row);

    return filteredRows;
};

export const validateData = rows => {
    const validationErrors = [];
    const columnsSets = {};

    const seenValueBefore = (column, value) => {
        columnsSets[column] = columnsSets[column] || new Set();

        // save whether the value was found or not
        const hasValue = columnsSets[column].has(value);

        // update set
        columnsSets[column].add(value);

        return hasValue;
    };

    for (let rowIndex in rows) {
        const row = rows[rowIndex];
        const rowErrors = [];

        Object.entries(row).forEach(([propName, columnValue]) => {
            const currentColumn = requiredColumnTable[propName];
            if (!currentColumn) {
                return;
            }

            // wrapper for adding validation errors for current row/column
            const addValidationError = message => {
                rowErrors.push({
                    message,
                    columnName: currentColumn.inputName,
                });
            };

            if (columnValue.length === 0) {
                if (currentColumn.nullable && !currentColumn.nullable(row)) {
                    addValidationError('Cellen må ikke være tom');
                    return;
                }
            }

            if (currentColumn.unique && seenValueBefore(currentColumn, columnValue)) {
                addValidationError(`Værdien "${columnValue}" må ikke optræde mere end én gang i kolonnen`);
            }
            
            if (currentColumn.valueValidator) {
                const validationResult = currentColumn.valueValidator(columnValue, row);
                if (!validationResult.valid) {
                    addValidationError(validationResult.message);
                }
            }
        });

        if (rowErrors.length > 0) {
            validationErrors.push({
                rowNumber: row.rowNumber, // actual row number in CSV-file
                errors: rowErrors,
            });
        }
    }

    if (validationErrors.length > 0) {
        return {
            valid: false,
            validationErrors,
        };
    }

    return {
        valid: true,
    };
};

export const convertToInternalFormat = rows => {
    // maps from csv column name => internal name
    const ext2int = {};
    Object.entries(inputNames).forEach(([internalName, inputName]) => {
        ext2int[essence(inputName)] = internalName;
    });

    return rows.map(row => {
        const account = {};

        Object.entries(row).forEach(([propName, columnValue]) => {
            const currentColumn = requiredColumnTable[propName];
            if (!currentColumn) {
                return;
            }

            account[ext2int[propName]] = (
                currentColumn.formatter ?
                currentColumn.formatter(columnValue, row) :
                columnValue
            );
        });

        return account;
    });
};