import React from 'react';
import * as SemanticUI from 'semantic-ui-react';

const SemanticMarkdown = ({ markdown }) => {
    // parse JSON
    if (typeof markdown === 'string') {
        if (['{', '['].includes(markdown[0])) {
            // assume text is JSON
            markdown = JSON.parse(markdown);
        } else {
            // else wrap text in span element
            markdown = {
                kind: 'span',
                children: markdown,
            };
        }
    }

    // wrap in array
    if (!Array.isArray(markdown)) {
        markdown = [markdown];
    }

    // format markdown
    markdown = markdown.map(({ kind, children, props = {} }) => {
        // format children
        let inner;
        switch (typeof children) {
            case 'number':
            case 'string':
            case 'boolean':
                inner = children;
                break;
            case 'object':
                inner = <SemanticMarkdown markdown={children} />;
                break;
            default:
                inner = undefined;
                break;
        }

        // Decide element kind
        // - use semantic component, if match
        // - use HTML element
        let elementKind;

        if (SemanticUI[kind]) {
            elementKind = SemanticUI[kind];
        } else {
            elementKind = kind;
        }

        return React.createElement(elementKind, props, inner);
    });

    return <>{markdown}</>;
};

export default SemanticMarkdown;