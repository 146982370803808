import { HTTPClient } from "../network/fetch";

const client = new HTTPClient({ prefix: 'dynaccount' });

export function registerCredentials(apiID, apiKey, apiSecret) {
    return client.postResource('register', {
        apiID,
        apiKey,
        apiSecret,
    });
}

export function extractPrefillValues (token) {
    return client.getResource(`credentials/${token}/userdata`);
}