import React from 'react';
import { HintVideo } from '.';

import style from './HintVideosErp.module.scss'

const HintVideosErp = props => {
    const { hint, customHintProps } = props;

    const renderEmptyContainer = message => {
        return (
            <div className={style['empty-container']}>
                <p>{message}</p>
            </div>
        );
    };

    const renderContent = () => {
        if(!customHintProps.selectedErp) {
            return renderEmptyContainer(`Her vises hjælp videoer vedr. forbindelse til bogføringssystemer`);
        } else {
            const videos = hint.data.videos;
            const videoToShow = videos.find(video => video.id === customHintProps.selectedErp && Boolean(video.ytVideoID));
            if(!videoToShow) {
                return renderEmptyContainer(`Hjælp video vedr. dette ERP system eksisterer endnu ikke`);
            }

            const fakeProps = {
                hint: {
                    data: videoToShow
                }
            }

            return (
                <div key={customHintProps.selectedErp}>
                    <HintVideo {...fakeProps} />
                </div>
            );
        }
    };

    return renderContent();
};

export default HintVideosErp;
