import React from 'react';
import compose from 'lodash.flowright';
import { Message } from 'semantic-ui-react';
import withUserData from 'util/withUserData';
import { isAdmin, isAllowedToViewProduct, ownsTaxYear } from 'util/userMethods';
import { getProductByID } from 'util/getProducts';
import { getProduct, getServiceByID } from 'http/productEngine';
import ProductPage from 'design/molecules/ProductPage';
import { withErrorBoundary } from 'design/atoms/ErrorBoundary';
import Product from '../../../pages/Product';

class ProductError extends Error {}

class ProductDirector extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            errorMessage: '',

            modelId: '',

            stripeProduct: null,
            variation: null,
            service: null,
        };
    }

    getRequestedVariation = ({ variations }) => {
        const { type, year } = this.props.match.params;
        for (let variation of variations) {
            if (variation.type === type && variation.year === year) {
                return variation;
            }
        }
    };

    fetchStripeProduct = async () => {
        const { productID } = this.props.match.params;
        const stripeProduct = await getProductByID(productID);

        if (!isAllowedToViewProduct(this.props.userData, stripeProduct)) {
            throw new ProductError('Du har ikke rettigheder til at tilgå dette produkt');
        }

        return stripeProduct;
    };

    fetchService = async stripeProduct => {
        // fetch service
        const service = await getServiceByID(stripeProduct.serviceID);

        // find requested variation
        const variation = this.getRequestedVariation(service);

        return { service, variation };
    };

    fetchProduct = async service => {
        const product = await getProduct(this.getRequestedVariation(service).modelId);
        return product;
    };

    prepareProductData = ({ service, product, variation, stripeProduct }) => {
        const { productID } = this.props.match.params;
        const { userData } = this.props;

        // get user data and extract check for roles
        const admin = isAdmin(userData);

        const serviceHasPayment = !!service.stripeId;
        let productIsActive = false;
        let stripeId = service.stripeId;
        let requiresPayment = variation.requiresPayment;
        if (stripeId) {
            productIsActive = ownsTaxYear(userData, productID, variation.year);
        }
        const paymentURL = `/betaling/${productID}/${variation.year}`;

        if (!requiresPayment || !serviceHasPayment || productIsActive || admin) {
            this.setState({
                paymentURL,
                service,
                product,
                variation,
                stripeProduct,
                hasPayed: productIsActive,
                loading: false,
            });
        } else {
            // Access to this product has not been bought => Redirect non-admins to the service front page
            this.props.history.push(`/service/${productID}`);
        }
    };

    componentDidMount = async () => {
        try {
            const stripeProduct = await this.fetchStripeProduct();
            const { service, variation } = await this.fetchService(stripeProduct);
            const product = await this.fetchProduct(service);
            
            this.prepareProductData({ service, product, variation, stripeProduct });
        } catch(e) {
            let errorMessage = '';

            console.error('Failed to load ProductDirector resources', e);

            if (e instanceof ProductError) {
                errorMessage = e.message;
            }

            this.setState({
                errorMessage,
                error: true,
            });
        }
    };

    renderError = () => {
        return (
            <ProductPage>
                <Message
                    icon='warning triangle'
                    warning
                    content={this.state.errorMessage || 'Der opstod en fejl'}
                />
            </ProductPage>
        );
    };

    render = () => {
        const { error, loading, hasPayed, paymentURL, variation, product, stripeProduct } = this.state;
        const { productID, type, year, label } = this.props.match.params;

        if (error) {
            return this.renderError();
        }

        let pagePrefix = `/service/${productID}/${type}/${year}`;
        if (label) {
            pagePrefix += `/${label}`;
        }

        return (
            <Product
                loading={loading}
                error={error}
                modelId={variation && variation.modelId}
                {...this.props}
                label={label}
                pagePrefix={pagePrefix}
                landingLocation={`/service/${productID}`}
                isLocked={product && product.isLocked}
                hasPayed={hasPayed}
                paymentURL={paymentURL}
                stripeProduct={stripeProduct}
                product={product}
            />
        );
    };
}

export default compose(
    withUserData,
    withErrorBoundary,
)(ProductDirector);
