import style from './HintContainerBasic.module.scss';

const HintContainerBasic = props => {
    const { hintRender } = props;
    return <div className={style['container']}>
        {hintRender}
    </div>;
};

export default HintContainerBasic;
