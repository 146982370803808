import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Icon, Segment } from 'semantic-ui-react';
import useMeasure from 'util/useMeasure';

import styles from './FoldableSegment.module.scss';
import ProductElementInteractionData, { ProductElementInteractionDataTypes } from 'design/pages/Product/field_interactivity/ProductElementInteractionData';

const SegmentPanel = ({ children, onClick, isFirst, isLast, toInteractiveElement, interactionContext, isOpen, style = {} }) => {
    const segmentPanel= (
        <div
            onClick={onClick}
            children={children}
            className={styles.lastEleNoMarginBottom}
            style={{
                borderTopLeftRadius: isFirst && '4px',
                borderTopRightRadius: isFirst && '4px',
                borderBottomLeftRadius: isLast && '4px',
                borderBottomRightRadius: isLast && '4px',
                borderBottom: !isLast && '1px solid lightgray',
                ...style,
            }}
        />
    );

    if(toInteractiveElement) {
        const interactiveSegmentPanel = toInteractiveElement(
            new ProductElementInteractionData(ProductElementInteractionDataTypes.FOLDABLE_SEGMENT_PANEL, interactionContext, {
                isOpen
            }),
            segmentPanel
        );
        return interactiveSegmentPanel;
    } else {
        return segmentPanel;
    }
};

const Chevron = ({ open }) => {
    const iconAnimation = useSpring({
        transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
        config: { tension: 500, clamp: true, bounce: 0 },
    });

    return (
        <div style={{ display: 'inline-block', marginRight: '0.5em' }}>
            <animated.div style={{ ...iconAnimation, width: '16px' }}>
                <Icon name='chevron right' />
            </animated.div>
        </div>
    );
};

const Progress = ({ percentage, error }) => {
    const isDone = percentage === 100;

    let barColor;

    if (error) {
        barColor = 'red';
    } else if (isDone) {
        barColor = '#46C476';
    } else {
        barColor = '#FBBD08';
    }
    
    const animatedWidth = useSpring({ width: `${Math.max(4, percentage)}%` });

    const bar = (
        <animated.div
            style={{
                background: barColor,
                borderTopLeftRadius: '4px',
                borderTopRightRadius: isDone && '4px',
                height: '4px',
                ...animatedWidth,
            }}
        />
    );

    return (
        <SegmentPanel
            isFirst
            style={{ padding: 0, background: '#e1f7ea' }}
            children={bar}
        />
    );
};

const FoldableSegment = ({
    title,
    children,
    progress,
    rightContent,
    error,
    sticky,
    stickyOffset,
    onClick,
    collapsible,
    unwrapped,
    hideChevron,
    isOpen,
    setIsOpen,
    defaultOpen = false,
    style = {},
    toInteractiveElement,
    interactionContext
}) => {
    const [mounted, setMounted] = useState(false);
    const [currentlyMoving, setCurrentlyMoving] = useState(false);
    const [contentProps, { height, ready }] = useMeasure();

    useEffect(() => {
        if (!ready) {
            return;
        }
        setMounted(true);
    }, [ready]);

    const toggleOpen = () => {
        if (!collapsible) {
            return;
        }

        setIsOpen(!isOpen);

        setCurrentlyMoving(true);
    };

    const shouldAnimate = () => {
        if (!defaultOpen) {
            return true;
        }

        return mounted;
    };

    const animation = useSpring({
        height: isOpen ? height : 0,
        immediate: () => !shouldAnimate(),
        onRest: () => setCurrentlyMoving(false),
        config: {
            tension: 260,
            friction: 25,
            clamp: true,
        },
    });

    const hasProgress = typeof progress === 'number';

    const progressBar = hasProgress && <Progress percentage={progress} error={error} />

    const fullyOpen = isOpen && !currentlyMoving;
    const fullyClosed = !isOpen && !currentlyMoving;

    return (
        <Segment
            onClick={onClick}
            style={{
                borderRadius: '4px',
                marginBottom: '1rem',
                padding: 0,
            }}
        >
            <div style={{ ...(sticky ? { position: 'sticky', top: stickyOffset, zIndex: 1 } : {}) }}>
                {progressBar}
                <SegmentPanel
                    isFirst={!hasProgress}
                    isLast={fullyClosed}
                    onClick={toggleOpen}
                    style={{
                        background: 'rgb(250, 250, 250)',
                        cursor: collapsible && 'pointer',
                        padding: '0.5em',
                    }}
                    toInteractiveElement={toInteractiveElement}
                    interactionContext={interactionContext}
                    isOpen={isOpen}
                >
                    {collapsible && !hideChevron && <Chevron open={isOpen} />}
                    <strong>{title}</strong>
                    {rightContent && (
                        <div style={{ float: 'right' }}>{rightContent}</div>
                    )}
                </SegmentPanel>
            </div>

            <animated.div style={{ ...animation, overflow: fullyOpen ? 'visible' : 'hidden', ...style }}>
                <div {...contentProps}>
                    <SegmentPanel
                        children={children}
                        style={{ padding: !unwrapped && '1em' }}
                        isLast
                    />
                </div>
            </animated.div>
        </Segment>
    );
};

export default FoldableSegment;