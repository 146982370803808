import downloadBlob from 'util/downloadBlob';

const rawSIE = `#FLAGGA 0
#PROGRAM "Bokio" 1.0
#FORMAT PC8
#GEN 20220316
#SIETYP 4
#ORGNR 5590018619
#FNAMN "1B Caf, AB"
#RAR 0 20210101 20211231
#KPTYP BAS2014
#KONTO 1930 "Företagskonto / affärskonto"
#SRU 1930 7281
#KONTO 2620 "Utgående moms, 12 %"
#SRU 2620 7369
#KONTO 2640 "Ingående moms"
#SRU 2640 7369
#KONTO 3002 "Försäljning varor inom Sverige, 12 % moms"
#SRU 3002 7410
#KONTO 5610 "Personbilskostnader"
#SRU 5610 7513
#UB 0 1930 900.00
#UB 0 2620 -107.14
#UB 0 2640 20.00
#RES 0 3002 -892.86
#RES 0 5610 80.00
#VER "V" "1" 20210501 "Försäljning varor 12% moms" 20220225
{
	#TRANS 1930 {} 1000.00
	#TRANS 2620 {} -107.14
	#TRANS 3002 {} -892.86
}
#VER "V" "2" 20210805 "Biltvätt" 20220225
{
	#TRANS 1930 {} -100.00
	#TRANS 2640 {} 20.00
	#TRANS 5610 {} 80.00
}`;

const sieBlob = new Blob(['\ufeff', rawSIE]);

export const downloadExampleSIE = () => downloadBlob(sieBlob, 'eksempel.se');

export default sieBlob;