import React from 'react';
import { Message } from 'semantic-ui-react';
import withUserData from '../../../util/withUserData';
import { isSuspended } from '../../../util/userMethods';

function AccountSuspendedMessage ({ userData }) {
    if (!isSuspended(userData)) {
        return null; 
    }
    return <Message
        error
        icon='warning circle'
        content='Din konto er suspenderet grundet manglende betaling'
    />;
}

export default withUserData(AccountSuspendedMessage);