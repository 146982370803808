/* eslint-disable */
/* prettier-ignore */
(function () {
var $c=function (a) {
this.w=a||[];
};$c.prototype.set=function (a) {
this.w[a]=!0;
};$c.prototype.encode=function () {
for(var a=[],b=0;b<this.w.length;b++)this.w[b]&&(a[Math.floor(b/6)]^=1<<b%6);for(b=0;b<a.length;b++)a[b]="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_".charAt(a[b]||0);return a.join("")+"~";
};var vd=new $c;function J (a) {
vd.set(a);
}var Nd=function (a,b) {
var c=new $c(Dd(a));c.set(b);a.set(Gd,c.w);
},Td=function (a) {
a=Dd(a);a=new $c(a);for(var b=vd.w.slice(),c=0;c<a.w.length;c++)b[c]=b[c]||a.w[c];return(new $c(b)).encode();
},Dd=function (a) {
a=a.get(Gd);ka(a)||(a=[]);return a;
};var ea=function (a) {
return"function"==typeof a;
},ka=function (a) {
return"[object Array]"==Object.prototype.toString.call(Object(a));
},qa=function (a) {
return void 0!=a&&-1<(a.constructor+"").indexOf("String");
},D=function (a,b) {
return 0==a.indexOf(b);
},sa=function (a) {
return a?a.replace(/^[\s\xa0]+|[\s\xa0]+$/g,""):"";
},ga=function () {
for(var a=O.navigator.userAgent+(M.cookie?M.cookie:"")+(M.referrer?M.referrer:""),b=a.length,c=O.history.length;0<c;)a+=c--^b++;return[hd()^La(a)&2147483647,Math.round((new Date).getTime()/
1E3)].join(".");
},ta=function (a) {
var b=M.createElement("img");b.width=1;b.height=1;b.src=a;return b;
},ua=function () {},K=function (a) {
if(encodeURIComponent instanceof Function)return encodeURIComponent(a);J(28);return a;
},L=function (a,b,c,d) {
try{
a.addEventListener?a.addEventListener(b,c,!!d):a.attachEvent&&a.attachEvent("on"+b,c);
}catch(e) {
J(27);
}
},f=/^[\w\-:/.?=&%!]+$/,wa=function (a,b,c,d) {
a&&(c?(d="",b&&f.test(b)&&(d=' id="'+b+'"'),f.test(a)&&M.write("<script"+d+' src="'+a+'">\x3c/script>')):(c=M.createElement("script"),
c.type="text/javascript",c.async=!0,c.src=a,d&&(c.onload=d),b&&(c.id=b),a=M.getElementsByTagName("script")[0],a.parentNode.insertBefore(c,a)));
},Ud=function () {
return"https:"==M.location.protocol;
},E=function (a,b) {
return(a=a.match("(?:&|#|\\?)"+K(b).replace(/([.*+?^=!:${}()|\[\]\/\\])/g,"\\$1")+"=([^&#]*)"))&&2==a.length?a[1]:"";
},xa=function () {
var a=""+M.location.hostname;return 0==a.indexOf("www.")?a.substring(4):a;
},ya=function (a) {
var b=M.referrer;if(/^https?:\/\//i.test(b)) {
if(a)return b;a="//"+M.location.hostname;
var c=b.indexOf(a);if(5==c||6==c)if(a=b.charAt(c+a.length),"/"==a||"?"==a||""==a||":"==a)return;return b;
}
},za=function (a,b) {
if(1==b.length&&null!=b[0]&&"object"===typeof b[0])return b[0];for(var c={},d=Math.min(a.length+1,b.length),e=0;e<d;e++)if("object"===typeof b[e]) {
for(var g in b[e])b[e].hasOwnProperty(g)&&(c[g]=b[e][g]);break;
}else e<a.length&&(c[a[e]]=b[e]);return c;
};var ee=function () {
this.keys=[];this.values={};this.m={};
};ee.prototype.set=function (a,b,c) {
this.keys.push(a);c?this.m[":"+a]=b:this.values[":"+a]=b;
};ee.prototype.get=function (a) {
return this.m.hasOwnProperty(":"+a)?this.m[":"+a]:this.values[":"+a];
};ee.prototype.map=function (a) {
for(var b=0;b<this.keys.length;b++) {
var c=this.keys[b],d=this.get(c);d&&a(c,d);
}
};var O=window,M=document;var F=window,G=function (a) {
var b=F._gaUserPrefs;if(b&&b.ioo&&b.ioo()||a&&!0===F["ga-disable-"+a])return!0;try{
var c=F.external;if(c&&c._gaUserPrefs&&"oo"==c._gaUserPrefs)return!0;
}catch(d) {}return!1;
};var Ca=function (a) {
var b=[],c=M.cookie.split(";");a=new RegExp("^\\s*"+a+"=\\s*(.*?)\\s*$");for(var d=0;d<c.length;d++) {
var e=c[d].match(a);e&&b.push(e[1]);
}return b;
},zc=function (a,b,c,d,e,g) {
e=G(e)?!1:eb.test(M.location.hostname)||"/"==c&&vc.test(d)?!1:!0;if(!e)return!1;b&&1200<b.length&&(b=b.substring(0,1200),J(24));c=a+"="+b+"; path="+c+"; ";g&&(c+="expires="+(new Date((new Date).getTime()+g)).toGMTString()+"; ");d&&"none"!=d&&(c+="domain="+d+";");d=M.cookie;M.cookie=c;if(!(d=d!=M.cookie))a: {
a=
Ca(a);for(d=0;d<a.length;d++)if(b==a[d]) {
d=!0;break a;
}d=!1;
}return d;
},Cc=function (a) {
return K(a).replace(/\(/g,"%28").replace(/\)/g,"%29");
},vc=/^(www\.)?google(\.com?)?(\.[a-z]{2})?$/,eb=/(^|\.)doubleclick\.net$/i;var oc=function () {
return(Ba||Ud()?"https:":"http:")+"//www.google-analytics.com";
},Da=function (a) {
this.name="len";this.message=a+"-8192";
},ba=function (a,b,c) {
c=c||ua;if(2036>=b.length)wc(a,b,c);else if(8192>=b.length)x(a,b,c)||wd(a,b,c)||wc(a,b,c);else throw ge("len",b.length),new Da(b.length);
},wc=function (a,b,c) {
var d=ta(a+"?"+b);d.onload=d.onerror=function () {
d.onload=null;d.onerror=null;c();
};
},wd=function (a,b,c) {
var d=O.XMLHttpRequest;if(!d)return!1;var e=new d;if(!("withCredentials"in e))return!1;
a=a.replace(/^http:/,"https:");e.open("POST",a,!0);e.withCredentials=!0;e.setRequestHeader("Content-Type","text/plain");e.onreadystatechange=function () {
4==e.readyState&&(c(),e=null);
};e.send(b);return!0;
},x=function (a,b,c) {
return O.navigator.sendBeacon?O.navigator.sendBeacon(a,b)?(c(),!0):!1:!1;
},ge=function (a,b,c) {
1<=100*Math.random()||G("?")||(a=["t=error","_e="+a,"_v=j55","sr=1"],b&&a.push("_f="+b),c&&a.push("_m="+K(c.substring(0,100))),a.push("aip=1"),a.push("z="+hd()),wc(oc()+"/collect",a.join("&"),
ua));
};var h=function (a) {
var b=O.gaData=O.gaData||{};return b[a]=b[a]||{};
};var Ha=function () {
this.M=[];
};Ha.prototype.add=function (a) {
this.M.push(a);
};Ha.prototype.D=function (a) {
try{
for(var b=0;b<this.M.length;b++) {
var c=a.get(this.M[b]);c&&ea(c)&&c.call(O,a);
}
}catch(d) {}b=a.get(Ia);b!=ua&&ea(b)&&(a.set(Ia,ua,!0),setTimeout(b,10));
};function Ja (a) {
if(100!=a.get(Ka)&&La(P(a,Q))%1E4>=100*R(a,Ka))throw"abort";
}function Ma (a) {
if(G(P(a,Na)))throw"abort";
}function Oa () {
var a=M.location.protocol;if("http:"!=a&&"https:"!=a)throw"abort";
}
function Pa (a) {
try{
O.navigator.sendBeacon?J(42):O.XMLHttpRequest&&"withCredentials"in new O.XMLHttpRequest&&J(40);
}catch(c) {}a.set(ld,Td(a),!0);a.set(Ac,R(a,Ac)+1);var b=[];Qa.map(function (c,d) {
d.F&&(c=a.get(c),void 0!=c&&c!=d.defaultValue&&("boolean"==typeof c&&(c*=1),b.push(d.F+"="+K(""+c))));
});b.push("z="+Bd());a.set(Ra,b.join("&"),!0);
}
function Sa (a) {
var b=P(a,gd)||oc()+"/collect",c=P(a,fa);!c&&a.get(Vd)&&(c="beacon");if(c) {
var d=P(a,Ra),e=a.get(Ia),e=e||ua;"image"==c?wc(b,d,e):"xhr"==c&&wd(b,d,e)||"beacon"==c&&x(b,d,e)||ba(b,d,e);
}else ba(b,P(a,Ra),a.get(Ia));b=a.get(Na);b=h(b);c=b.hitcount;b.hitcount=c?c+1:1;b=a.get(Na);delete h(b).pending_experiments;a.set(Ia,ua,!0);
}
function Hc (a) {
(O.gaData=O.gaData||{}).expId&&a.set(Nc,(O.gaData=O.gaData||{}).expId);(O.gaData=O.gaData||{}).expVar&&a.set(Oc,(O.gaData=O.gaData||{}).expVar);var b=a.get(Na);if(b=h(b).pending_experiments) {
var c=[];for(d in b)b.hasOwnProperty(d)&&b[d]&&c.push(encodeURIComponent(d)+"."+encodeURIComponent(b[d]));var d=c.join("!");
}else d=void 0;d&&a.set(m,d,!0);
}function cd () {
if(O.navigator&&"preview"==O.navigator.loadPurpose)throw"abort";
}
function yd (a) {
var b=O.gaDevIds;ka(b)&&0!=b.length&&a.set("&did",b.join(","),!0);
}function vb (a) {
if(!a.get(Na))throw"abort";
};var hd=function () {
return Math.round(2147483647*Math.random());
},Bd=function () {
try{
var a=new Uint32Array(1);O.crypto.getRandomValues(a);return a[0]&2147483647;
}catch(b) {
return hd();
}
};function Ta (a) {
var b=R(a,Ua);500<=b&&J(15);var c=P(a,Va);if("transaction"!=c&&"item"!=c) {
var c=R(a,Wa),d=(new Date).getTime(),e=R(a,Xa);0==e&&a.set(Xa,d);e=Math.round(2*(d-e)/1E3);0<e&&(c=Math.min(c+e,20),a.set(Xa,d));if(0>=c)throw"abort";a.set(Wa,--c);
}a.set(Ua,++b);
};var Ya=function () {
this.data=new ee;
},Qa=new ee,Za=[];Ya.prototype.get=function (a) {
var b=$a(a),c=this.data.get(a);b&&void 0==c&&(c=ea(b.defaultValue)?b.defaultValue():b.defaultValue);return b&&b.Z?b.Z(this,a,c):c;
};var P=function (a,b) {
a=a.get(b);return void 0==a?"":""+a;
},R=function (a,b) {
a=a.get(b);return void 0==a||""===a?0:1*a;
};Ya.prototype.set=function (a,b,c) {
if(a)if("object"==typeof a)for(var d in a)a.hasOwnProperty(d)&&ab(this,d,a[d],c);else ab(this,a,b,c);
};
var ab=function (a,b,c,d) {
if(void 0!=c)switch(b) {
case Na:wb.test(c);
}var e=$a(b);e&&e.o?e.o(a,b,c,d):a.data.set(b,c,d);
},bb=function (a,b,c,d,e) {
this.name=a;this.F=b;this.Z=d;this.o=e;this.defaultValue=c;
},$a=function (a) {
var b=Qa.get(a);if(!b)for(var c=0;c<Za.length;c++) {
var d=Za[c],e=d[0].exec(a);if(e) {
b=d[1](e);Qa.set(b.name,b);break;
}
}return b;
},yc=function (a) {
var b;Qa.map(function (c,d) {
d.F==a&&(b=d);
});return b&&b.name;
},S=function (a,b,c,d,e) {
a=new bb(a,b,c,d,e);Qa.set(a.name,a);return a.name;
},cb=function (a,
b) {
Za.push([new RegExp("^"+a+"$"),b]);
},T=function (a,b,c) {
return S(a,b,c,void 0,db);
},db=function () {};var gb=qa(window.GoogleAnalyticsObject)&&sa(window.GoogleAnalyticsObject)||"ga",Ba=!1,hb=T("apiVersion","v"),ib=T("clientVersion","_v");S("anonymizeIp","aip");var jb=S("adSenseId","a"),Va=S("hitType","t"),Ia=S("hitCallback"),Ra=S("hitPayload");S("nonInteraction","ni");S("currencyCode","cu");S("dataSource","ds");var Vd=S("useBeacon",void 0,!1),fa=S("transport");S("sessionControl","sc","");S("sessionGroup","sg");S("queueTime","qt");var Ac=S("_s","_s");S("screenName","cd");
var kb=S("location","dl",""),lb=S("referrer","dr"),mb=S("page","dp","");S("hostname","dh");var nb=S("language","ul"),ob=S("encoding","de");S("title","dt",function () {
return M.title||void 0;
});cb("contentGroup([0-9]+)",function (a) {
return new bb(a[0],"cg"+a[1]);
});var pb=S("screenColors","sd"),qb=S("screenResolution","sr"),rb=S("viewportSize","vp"),sb=S("javaEnabled","je"),tb=S("flashVersion","fl");S("campaignId","ci");S("campaignName","cn");S("campaignSource","cs");S("campaignMedium","cm");
S("campaignKeyword","ck");S("campaignContent","cc");var ub=S("eventCategory","ec"),xb=S("eventAction","ea"),yb=S("eventLabel","el"),zb=S("eventValue","ev"),Bb=S("socialNetwork","sn"),Cb=S("socialAction","sa"),Db=S("socialTarget","st"),Eb=S("l1","plt"),Fb=S("l2","pdt"),Gb=S("l3","dns"),Hb=S("l4","rrt"),Ib=S("l5","srt"),Jb=S("l6","tcp"),Kb=S("l7","dit"),Lb=S("l8","clt"),Mb=S("timingCategory","utc"),Nb=S("timingVar","utv"),Ob=S("timingLabel","utl"),Pb=S("timingValue","utt");S("appName","an");
S("appVersion","av","");S("appId","aid","");S("appInstallerId","aiid","");S("exDescription","exd");S("exFatal","exf");var Nc=S("expId","xid"),Oc=S("expVar","xvar"),m=S("exp","exp"),Rc=S("_utma","_utma"),Sc=S("_utmz","_utmz"),Tc=S("_utmht","_utmht"),Ua=S("_hc",void 0,0),Xa=S("_ti",void 0,0),Wa=S("_to",void 0,20);cb("dimension([0-9]+)",function (a) {
return new bb(a[0],"cd"+a[1]);
});cb("metric([0-9]+)",function (a) {
return new bb(a[0],"cm"+a[1]);
});S("linkerParam",void 0,void 0,Bc,db);
var ld=S("usage","_u"),Gd=S("_um");S("forceSSL",void 0,void 0,function () {
return Ba;
},function (a,b,c) {
J(34);Ba=!!c;
});var ed=S("_j1","jid"),ia=S("_j2","gjid");cb("\\&(.*)",function (a) {
var b=new bb(a[0],a[1]),c=yc(a[0].substring(1));c&&(b.Z=function (a) {
return a.get(c);
},b.o=function (a,b,g,ca) {
a.set(c,g,ca);
},b.F=void 0);return b;
});
var Qb=T("_oot"),dd=S("previewTask"),Rb=S("checkProtocolTask"),md=S("validationTask"),Sb=S("checkStorageTask"),Uc=S("historyImportTask"),Tb=S("samplerTask"),Vb=S("_rlt"),Wb=S("buildHitTask"),Xb=S("sendHitTask"),Vc=S("ceTask"),zd=S("devIdTask"),Cd=S("timingTask"),Ld=S("displayFeaturesTask"),oa=S("customTask"),V=T("name"),Q=T("clientId","cid"),n=T("clientIdTime"),Ad=S("userId","uid"),Na=T("trackingId","tid"),U=T("cookieName",void 0,"_ga"),W=T("cookieDomain"),Yb=T("cookiePath",void 0,"/"),Zb=T("cookieExpires",
void 0,63072E3),$b=T("legacyCookieDomain"),Wc=T("legacyHistoryImport",void 0,!0),ac=T("storage",void 0,"cookie"),bc=T("allowLinker",void 0,!1),cc=T("allowAnchor",void 0,!0),Ka=T("sampleRate","sf",100),dc=T("siteSpeedSampleRate",void 0,1),ec=T("alwaysSendReferrer",void 0,!1),I=T("_gid","_gid"),ja=T("_ge"),la=T("_gcn"),gd=S("transportUrl"),Md=S("_r","_r");function X (a,b,c,d) {
b[a]=function () {
try{
return d&&J(d),c.apply(this,arguments);
}catch(e) {
throw ge("exc",a,e&&e.name),e;
}
};
};var Od=function (a,b,c) {
this.V=a;this.fa=b;this.$=!1;this.oa=c;this.ea=1;
},Ed=function (a,b,c) {
if(a.fa&&a.$)return 0;a.$=!0;if(b) {
if(a.oa&&R(b,a.oa))return R(b,a.oa);if(0==b.get(dc))return 0;
}if(0==a.V)return 0;void 0===c&&(c=Bd());return 0==c%a.V?Math.floor(c/a.V)%a.ea+1:0;
};function fc () {
var a,b;if((b=(b=O.navigator)?b.plugins:null)&&b.length)for(var c=0;c<b.length&&!a;c++) {
var d=b[c];-1<d.name.indexOf("Shockwave Flash")&&(a=d.description);
}if(!a)try{
var e=new ActiveXObject("ShockwaveFlash.ShockwaveFlash.7");a=e.GetVariable("$version");
}catch(g) {}if(!a)try{
e=new ActiveXObject("ShockwaveFlash.ShockwaveFlash.6"),a="WIN 6,0,21,0",e.AllowScriptAccess="always",a=e.GetVariable("$version");
}catch(g) {}if(!a)try{
e=new ActiveXObject("ShockwaveFlash.ShockwaveFlash"),a=e.GetVariable("$version");
}catch(g) {}a&&
(e=a.match(/[\d]+/g))&&3<=e.length&&(a=e[0]+"."+e[1]+" r"+e[2]);return a||void 0;
};var aa=function (a) {
var b=Math.min(R(a,dc),100);return La(P(a,Q))%100>=b?!1:!0;
},gc=function (a) {
var b={};if(Ec(b)||Fc(b)) {
var c=b[Eb];void 0==c||Infinity==c||isNaN(c)||(0<c?(Y(b,Gb),Y(b,Jb),Y(b,Ib),Y(b,Fb),Y(b,Hb),Y(b,Kb),Y(b,Lb),setTimeout(function () {
a(b);
},10)):L(O,"load",function () {
gc(a);
},!1));
}
},Ec=function (a) {
var b=O.performance||O.webkitPerformance,b=b&&b.timing;if(!b)return!1;var c=b.navigationStart;if(0==c)return!1;a[Eb]=b.loadEventStart-c;a[Gb]=b.domainLookupEnd-b.domainLookupStart;a[Jb]=b.connectEnd-
b.connectStart;a[Ib]=b.responseStart-b.requestStart;a[Fb]=b.responseEnd-b.responseStart;a[Hb]=b.fetchStart-c;a[Kb]=b.domInteractive-c;a[Lb]=b.domContentLoadedEventStart-c;return!0;
},Fc=function (a) {
if(O.top!=O)return!1;var b=O.external,c=b&&b.onloadT;b&&!b.isValidLoadTime&&(c=void 0);2147483648<c&&(c=void 0);0<c&&b.setPageReadyTime();if(void 0==c)return!1;a[Eb]=c;return!0;
},Y=function (a,b) {
var c=a[b];if(isNaN(c)||Infinity==c||0>c)a[b]=void 0;
},Fd=function (a) {
return function (b) {
if("pageview"==b.get(Va)&&
!a.I) {
a.I=!0;var c=aa(b);b=0<E(b.get(kb),"gclid").length;(c||b)&&gc(function (b) {
a.send(c?"timing":"adtiming",b);
});
}
};
};var hc=!1,mc=function (a) {
"cookie"==P(a,ac)&&(ma(a,Q,U),a.get(ja)&&ma(a,I,la,864E5));
},ma=function (a,b,c,d) {
var e=nd(a,b);if(e) {
c=P(a,c);b=kc(P(a,Yb));var g=lc(P(a,W));d=d||1E3*R(a,Zb);var ca=P(a,Na);if("auto"!=g)zc(c,e,b,g,ca,d)&&(hc=!0);else{
J(32);a: {
e=[];g=xa().split(".");if(4==g.length) {
var l=g[g.length-1];if(parseInt(l,10)==l) {
l=["none"];break a;
}
}for(l=g.length-2;0<=l;l--)e.push(g.slice(l).join("."));e.push("none");l=e;
}for(var k=0;k<l.length;k++)if(g=l[k],a.data.set(W,g),e=nd(a,Q),zc(c,e,b,g,ca,
d)) {
hc=!0;return;
}a.data.set(W,"auto");
}
}else a.get(ja)||J(54);
},nc=function (a) {
if("cookie"==P(a,ac)&&!hc&&(mc(a),!hc))throw"abort";
},Yc=function (a) {
if(a.get(Wc)) {
var b=P(a,W),c=P(a,$b)||xa(),d=Xc("__utma",c,b);d&&(J(19),a.set(Tc,(new Date).getTime(),!0),a.set(Rc,d.R),(b=Xc("__utmz",c,b))&&d.hash==b.hash&&a.set(Sc,b.R));
}
},nd=function (a,b) {
b=Cc(P(a,b));var c=lc(P(a,W)).split(".").length;a=jc(P(a,Yb));1<a&&(c+="-"+a);return b?["GA1",c,b].join("."):"";
},na=function (a,b) {
if(!b||1>b.length)J(12);else{
for(var c=
[],d=0;d<b.length;d++) {
var e=b[d];var g=e.split(".");var ca=g.shift();("GA1"==ca||"1"==ca)&&1<g.length?(e=g.shift().split("-"),1==e.length&&(e[1]="1"),e[0]*=1,e[1]*=1,g={H:e,s:g.join(".")}):g=/^amp-/.test(e)?{H:[0,0],s:e}:void 0;g&&c.push(g);
}if(1==c.length)return J(13),c[0].s;if(0==c.length)J(12);else{
J(14);b=lc(P(a,W)).split(".").length;c=Gc(c,b,0);if(1==c.length)return c[0].s;a=jc(P(a,Yb));c=Gc(c,a,1);1<c.length&&J(41);return c[0]&&c[0].s;
}
}
},Gc=function (a,b,c) {
for(var d=[],e=[],g,ca=0;ca<a.length;ca++) {
var l=
a[ca];l.H[c]==b?d.push(l):void 0==g||l.H[c]<g?(e=[l],g=l.H[c]):l.H[c]==g&&e.push(l);
}return 0<d.length?d:e;
},lc=function (a) {
return 0==a.indexOf(".")?a.substr(1):a;
},kc=function (a) {
if(!a)return"/";1<a.length&&a.lastIndexOf("/")==a.length-1&&(a=a.substr(0,a.length-1));0!=a.indexOf("/")&&(a="/"+a);return a;
},jc=function (a) {
a=kc(a);return"/"==a?1:a.split("/").length;
};function Xc (a,b,c) {
"none"==b&&(b="");var d=[],e=Ca(a);a="__utma"==a?6:2;for(var g=0;g<e.length;g++) {
var ca=(""+e[g]).split(".");ca.length>=a&&d.push({hash:ca[0],R:e[g],O:ca});
}if(0!=d.length)return 1==d.length?d[0]:Zc(b,d)||Zc(c,d)||Zc(null,d)||d[0];
}function Zc (a,b) {
if(null==a)var c=a=1;else c=La(a),a=La(D(a,".")?a.substring(1):"."+a);for(var d=0;d<b.length;d++)if(b[d].hash==c||b[d].hash==a)return b[d];
};var od=new RegExp(/^https?:\/\/([^\/:]+)/),pd=/(.*)([?&#])(?:_ga=[^&#]*)(?:&?)(.*)/;function Bc (a) {
var b=a.get(Q);if(a.get(ja)) {
var c=a.get(I);a=pa(c+b,0);return"_ga=2."+K(a+"."+c+"-"+b);
}a=pa(b,0);return"_ga=1."+K(a+"."+b);
}function Ic (a,b) {
var c=new Date,d=O.navigator,e=d.plugins||[];a=[a,d.userAgent,c.getTimezoneOffset(),c.getYear(),c.getDate(),c.getHours(),c.getMinutes()+b];for(b=0;b<e.length;++b)a.push(e[b].description);return La(a.join("."));
}
function pa (a,b) {
var c=new Date,d=O.navigator;return La([a,d.userAgent,d.language||"",c.getTimezoneOffset(),c.getYear(),c.getDate(),c.getHours(),c.getMinutes()+b].join("."));
}var Dc=function (a) {
J(48);this.target=a;this.T=!1;
};Dc.prototype.ca=function (a,b) {
if(a.tagName) {
if("a"==a.tagName.toLowerCase()) {
a.href&&(a.href=qd(this,a.href,b));return;
}if("form"==a.tagName.toLowerCase())return rd(this,a);
}if("string"==typeof a)return qd(this,a,b);
};
var qd=function (a,b,c) {
var d=pd.exec(b);d&&3<=d.length&&(b=d[1]+(d[3]?d[2]+d[3]:""));a=a.target.get("linkerParam");var e=b.indexOf("?"),d=b.indexOf("#");c?b+=(-1==d?"#":"&")+a:(c=-1==e?"?":"&",b=-1==d?b+(c+a):b.substring(0,d)+c+a+b.substring(d));return b=b.replace(/&+_ga=/,"&_ga=");
},rd=function (a,b) {
if(b&&b.action)if("get"==b.method.toLowerCase()) {
a=a.target.get("linkerParam").split("=")[1];for(var c=b.childNodes||[],d=0;d<c.length;d++)if("_ga"==c[d].name) {
c[d].setAttribute("value",a);return;
}c=M.createElement("input");
c.setAttribute("type","hidden");c.setAttribute("name","_ga");c.setAttribute("value",a);b.appendChild(c);
}else"post"==b.method.toLowerCase()&&(b.action=qd(a,b.action));
};
Dc.prototype.S=function (a,b,c) {
function d (c) {
try{
c=c||O.event;a: {
var d=c.target||c.srcElement;for(c=100;d&&0<c;) {
if(d.href&&d.nodeName.match(/^a(?:rea)?$/i)) {
var g=d;break a;
}d=d.parentNode;c--;
}g={};
}("http:"==g.protocol||"https:"==g.protocol)&&sd(a,g.hostname||"")&&g.href&&(g.href=qd(e,g.href,b));
}catch(k) {
J(26);
}
}var e=this;this.T||(this.T=!0,L(M,"mousedown",d,!1),L(M,"keyup",d,!1));c&&L(M,"submit",function (b) {
b=b||O.event;if((b=b.target||b.srcElement)&&b.action) {
var c=b.action.match(od);c&&sd(a,c[1])&&
rd(e,b);
}
});
};function sd (a,b) {
if(b==M.location.hostname)return!1;for(var c=0;c<a.length;c++)if(a[c]instanceof RegExp) {
if(a[c].test(b))return!0;
}else if(0<=b.indexOf(a[c]))return!0;return!1;
};var p=/^(GTM|OPT)-[A-Z0-9]+$/,q=/;_gaexp=[^;]*/g,r=/;((__utma=)|([^;=]+=GAX?\d+\.))[^;]*/g,Aa=/^https?:\/\/[\w\-.]+\.google.com(:\d+)?\/optimize\/opt-launch\.html\?.*$/,t=function (a) {
function b (a,b) {
b&&(c+="&"+a+"="+K(b));
}var c="https://www.google-analytics.com/gtm/js?id="+K(a.id);"dataLayer"!=a.B&&b("l",a.B);b("t",a.target);b("cid",a.clientId);b("cidt",a.ka);b("gac",a.la);b("aip",a.ia);a.sync&&b("m","sync");b("cycle",a.G);a.qa&&b("gclid",a.qa);Aa.test(M.referrer)&&b("cb",String(hd()));return c;
};var Jd=function (a,b,c) {
this.U=ed;this.aa=b;(b=c)||(b=(b=P(a,V))&&"t0"!=b?Wd.test(b)?"_gat_"+Cc(P(a,Na)):"_gat_"+Cc(b):"_gat");this.Y=b;
},Rd=function (a,b) {
var c=b.get(Wb);b.set(Wb,function (b) {
Pd(a,b,a.U);Pd(a,b,ia);var d=c(b);Qd(a,b);return d;
});var d=b.get(Xb);b.set(Xb,function (b) {
var c=d(b);Id(a,b);return c;
});
},Pd=function (a,b,c) {
b.get(c)||("1"==Ca(a.Y)[0]?b.set(c,"",!0):b.set(c,""+hd(),!0));
},Qd=function (a,b) {
b.get(a.U)&&zc(a.Y,"1",b.get(Yb),b.get(W),b.get(Na),6E4);
},Id=function (a,b) {
if(b.get(a.U)) {
var c=
new ee,d=function (a) {
$a(a).F&&c.set($a(a).F,b.get(a));
};d(hb);d(ib);d(Na);d(Q);d(Ad);d(a.U);d(ia);d(I);c.set($a(ld).F,Td(b));var e=a.aa;c.map(function (a,b) {
e+=K(a)+"=";e+=K(""+b)+"&";
});e+="z="+hd();ta(e);b.set(a.U,"",!0);
}
},Wd=/^gtm\d+$/;var fd=function (a,b) {
a=a.b;if(!a.get("dcLoaded")) {
Nd(a,29);b=b||{};var c;b[U]&&(c=Cc(b[U]));b=new Jd(a,"https://stats.g.doubleclick.net/r/collect?t=dc&aip=1&_r=3&",c);Rd(b,a);a.set("dcLoaded",!0);
}
};var Sd=function (a) {
if(!a.get("dcLoaded")&&"cookie"==a.get(ac)) {
Nd(a,51);var b=new Jd(a);Pd(b,a,b.U);Pd(b,a,ia);Qd(b,a);a.get(b.U)&&(a.set(Md,1,!0),a.set(gd,oc()+"/r/collect",!0));
}
};var Lc=function () {
var a=O.gaGlobal=O.gaGlobal||{};return a.hid=a.hid||hd();
};var ad,bd=function (a,b,c) {
if(!ad) {
var d=M.location.hash;var e=O.name,g=/^#?gaso=([^&]*)/;if(e=(d=(d=d&&d.match(g)||e&&e.match(g))?d[1]:Ca("GASO")[0]||"")&&d.match(/^(?:!([-0-9a-z.]{1,40})!)?([-.\w]{10,1200})$/i))zc("GASO",""+d,c,b,a,0),window._udo||(window._udo=b),window._utcp||(window._utcp=c),a=e[1],wa("https://www.google.com/analytics/web/inpage/pub/inpage.js?"+(a?"prefix="+a+"&":"")+hd(),"_gasojs");ad=!0;
}
};var H=function (a) {
return a?(1*a).toFixed(3):"0";
},da=function (a) {
var b=O.performance;if(b&&b.getEntriesByName) {
J(35);var c="https://www.google-analytics.com/analytics.js?wpid="+a;wa(c,void 0,void 0,function () {
try{
var d=1,e=b.getEntriesByName("https://www.google-analytics.com/analytics.js");e&&0!=e.length||(e=b.getEntriesByName("http://www.google-analytics.com/analytics.js"),d=0);var g=b.getEntriesByName(c);if(e&&1==e.length&&g&&1==g.length) {
J(37);var ca=e[0],l=g[0],k={tid:a,ad:H(ca.duration),bd:H(l.duration),
ar:H(ca.responseEnd-ca.requestStart),br:H(l.responseEnd-l.requestStart),an:H(ca.domainLookupEnd-ca.domainLookupStart),bn:H(l.domainLookupEnd-l.domainLookupStart),ac:H(ca.connectEnd-ca.connectStart),bc:H(l.connectEnd-l.connectStart),as:d},d=[];d.push("_v=j55");d.push("id=10");for(var w in k)k.hasOwnProperty(w)&&d.push(w+"="+K(k[w]));d.push("z="+hd());wc("https://www.google-analytics.com/u/d",d.join("&"),ua);
}
}catch(ha) {}
});
}
};var wb=/^(UA|YT|MO|GP)-(\d+)-(\d+)$/,pc=function (a) {
function b (a,b) {
d.b.data.set(a,b);
}function c (a,c) {
b(a,c);d.filters.add(a);
}var d=this;this.b=new Ya;this.filters=new Ha;b(V,a[V]);b(Na,sa(a[Na]));b(U,a[U]);b(W,a[W]||xa());b(Yb,a[Yb]);b(Zb,a[Zb]);b($b,a[$b]);b(Wc,a[Wc]);b(bc,a[bc]);b(cc,a[cc]);b(Ka,a[Ka]);b(dc,a[dc]);b(ec,a[ec]);b(ac,a[ac]);b(Ad,a[Ad]);b(n,a[n]);b(ja,a[ja]);b(hb,1);b(ib,"j55");c(Qb,Ma);c(oa,ua);c(dd,cd);c(Rb,Oa);c(md,vb);c(Sb,nc);c(Uc,Yc);c(Tb,Ja);c(Vb,Ta);c(Vc,Hc);c(zd,yd);c(Ld,
Sd);c(Wb,Pa);c(Xb,Sa);c(Cd,Fd(this));Jc(this.b,a[Q]);Kc(this.b);this.b.set(jb,Lc());bd(this.b.get(Na),this.b.get(W),this.b.get(Yb));this.ra=new Od(1E4,!0,"gaexp10");
},Jc=function (a,b) {
a.data.set(ja,a.get(ja)||1==Ed(new Od(1,!0),void 0,La(a.get(Q))));if(a.get(ja)) {
var c=P(a,U);a.data.set(la,"_ga"==c?"_gid":c+"_gid");
}if("cookie"==P(a,ac)) {
hc=!1;c=Ca(P(a,U));if(!(c=na(a,c))) {
var c=P(a,W),d=P(a,$b)||xa();c=Xc("__utma",d,c);void 0!=c?(J(10),c=c.O[1]+"."+c.O[2]):c=void 0;
}c&&(a.data.set(Q,c),c=Ca(P(a,la)),
(c=na(a,c))&&a.data.set(I,c),hc=!0);
}a:if(c=a.get(cc),c=E(M.location[c?"href":"search"],"_ga"))if(a.get(bc))if(d=c.indexOf("."),-1==d)J(22);else{
var e=c.substring(0,d),g=c.substring(d+1),d=g.indexOf("."),c=g.substring(0,d),g=g.substring(d+1);if("1"==e) {
if(d=g,c!=Ic(d,0)&&c!=Ic(d,-1)&&c!=Ic(d,-2)&&c!=pa(d,0)&&c!=pa(d,-1)&&c!=pa(d,-2)) {
J(23);break a;
}
}else if("2"==e) {
e=g.split("-",2);d=e[1];if(c!=Ic(e[0]+d,0)&&c!=Ic(e[0]+d,-1)&&c!=Ic(e[0]+d,-2)&&c!=pa(e[0]+d,0)&&c!=pa(e[0]+d,-1)&&c!=pa(e[0]+d,-2)) {
J(53);
break a;
}J(2);a.data.set(I,e[0]);
}else{
J(22);break a;
}J(11);a.data.set(Q,d);
}else J(21);b&&(J(9),a.data.set(Q,K(b)));a.get(Q)||((b=(b=O.gaGlobal&&O.gaGlobal.vid)&&-1!=b.search(/^(?:utma\.)?\d+\.\d+$/)?b:void 0)?(J(17),a.data.set(Q,b)):(J(8),a.data.set(Q,ga())));a.get(ja)&&!a.get(I)&&(J(3),a.data.set(I,ga()));mc(a);
},Kc=function (a) {
var b=O.navigator,c=O.screen,d=M.location;a.set(lb,ya(a.get(ec)));if(d) {
var e=d.pathname||"";"/"!=e.charAt(0)&&(J(31),e="/"+e);a.set(kb,d.protocol+"//"+d.hostname+e+d.search);
}c&&
a.set(qb,c.width+"x"+c.height);c&&a.set(pb,c.colorDepth+"-bit");var c=M.documentElement,g=(e=M.body)&&e.clientWidth&&e.clientHeight,ca=[];c&&c.clientWidth&&c.clientHeight&&("CSS1Compat"===M.compatMode||!g)?ca=[c.clientWidth,c.clientHeight]:g&&(ca=[e.clientWidth,e.clientHeight]);c=0>=ca[0]||0>=ca[1]?"":ca.join("x");a.set(rb,c);a.set(tb,fc());a.set(ob,M.characterSet||M.charset);a.set(sb,b&&"function"===typeof b.javaEnabled&&b.javaEnabled()||!1);a.set(nb,(b&&(b.language||b.browserLanguage)||"").toLowerCase());
if(d&&a.get(cc)&&(b=M.location.hash)) {
b=b.split(/[?&#]+/);d=[];for(c=0;c<b.length;++c)(D(b[c],"utm_id")||D(b[c],"utm_campaign")||D(b[c],"utm_source")||D(b[c],"utm_medium")||D(b[c],"utm_term")||D(b[c],"utm_content")||D(b[c],"gclid")||D(b[c],"dclid")||D(b[c],"gclsrc"))&&d.push(b[c]);0<d.length&&(b="#"+d.join("&"),a.set(kb,a.get(kb)+b));
}
};pc.prototype.get=function (a) {
return this.b.get(a);
};pc.prototype.set=function (a,b) {
this.b.set(a,b);
};
var qc={pageview:[mb],event:[ub,xb,yb,zb],social:[Bb,Cb,Db],timing:[Mb,Nb,Pb,Ob]};pc.prototype.send=function (a) {
if(!(1>arguments.length)) {
if("string"===typeof arguments[0]) {
var b=arguments[0];var c=[].slice.call(arguments,1);
}else b=arguments[0]&&arguments[0][Va],c=arguments;b&&(c=za(qc[b]||[],c),c[Va]=b,this.b.set(c,void 0,!0),this.filters.D(this.b),this.b.data.m={},Ed(this.ra,this.b)&&da(this.b.get(Na)));
}
};
pc.prototype.ma=function (a,b) {
var c=this;u(a,c,b)||(v(a,function () {
u(a,c,b);
}),y(String(c.get(V)),a,void 0,b,!0));
};var rc=function (a) {
if("prerender"==M.visibilityState)return!1;a();return!0;
},z=function (a) {
if(!rc(a)) {
J(16);var b=!1,c=function () {
if(!b&&rc(a)) {
b=!0;var d=c,e=M;e.removeEventListener?e.removeEventListener("visibilitychange",d,!1):e.detachEvent&&e.detachEvent("onvisibilitychange",d);
}
};L(M,"visibilitychange",c);
}
};var td=/^(?:(\w+)\.)?(?:(\w+):)?(\w+)$/,sc=function (a) {
if(ea(a[0]))this.u=a[0];else{
var b=td.exec(a[0]);null!=b&&4==b.length&&(this.c=b[1]||"t0",this.K=b[2]||"",this.C=b[3],this.a=[].slice.call(a,1),this.K||(this.A="create"==this.C,this.i="require"==this.C,this.g="provide"==this.C,this.ba="remove"==this.C),this.i&&(3<=this.a.length?(this.X=this.a[1],this.W=this.a[2]):this.a[1]&&(qa(this.a[1])?this.X=this.a[1]:this.W=this.a[1])));b=a[1];a=a[2];if(!this.C)throw"abort";if(this.i&&(!qa(b)||""==b))throw"abort";
if(this.g&&(!qa(b)||""==b||!ea(a)))throw"abort";if(ud(this.c)||ud(this.K))throw"abort";if(this.g&&"t0"!=this.c)throw"abort";
}
};function ud (a) {
return 0<=a.indexOf(".")||0<=a.indexOf(":");
};var Yd,Zd,$d,A;Yd=new ee;$d=new ee;A=new ee;Zd={ec:45,ecommerce:46,linkid:47};
var u=function (a,b,c) {
b==N||b.get(V);var d=Yd.get(a);if(!ea(d))return!1;b.plugins_=b.plugins_||new ee;if(b.plugins_.get(a))return!0;b.plugins_.set(a,new d(b,c||{}));return!0;
},y=function (a,b,c,d,e) {
if(!ea(Yd.get(b))&&!$d.get(b)) {
Zd.hasOwnProperty(b)&&J(Zd[b]);if(p.test(b)) {
J(52);a=N.j(a);if(!a)return!0;c=d||{};d={id:b,B:c.dataLayer||"dataLayer",ia:!!a.get("anonymizeIp"),sync:e,G:!1};a.get("&gtm")==b&&(d.G=!0);var g=String(a.get("name"));"t0"!=g&&(d.target=g);G(String(a.get("trackingId")))||(d.clientId=
String(a.get(Q)),d.ka=Number(a.get(n)),c=c.palindrome?r:q,c=(c=M.cookie.replace(/^|(; +)/g,";").match(c))?c.sort().join("").substring(1):void 0,d.la=c,d.qa=E(a.b.get(kb)||"","gclid"));a=d.B;c=(new Date).getTime();O[a]=O[a]||[];c={"gtm.start":c};e||(c.event="gtm.js");O[a].push(c);c=t(d);
}!c&&Zd.hasOwnProperty(b)?(J(39),c=b+".js"):J(43);c&&(c&&0<=c.indexOf("/")||(c=(Ba||Ud()?"https:":"http:")+"//www.google-analytics.com/plugins/ua/"+c),d=ae(c),a=d.protocol,c=M.location.protocol,("https:"==a||a==c||("http:"!=
a?0:"http:"==c))&&B(d)&&(wa(d.url,void 0,e),$d.set(b,!0)));
}
},v=function (a,b) {
var c=A.get(a)||[];c.push(b);A.set(a,c);
},C=function (a,b) {
Yd.set(a,b);b=A.get(a)||[];for(var c=0;c<b.length;c++)b[c]();A.set(a,[]);
},B=function (a) {
var b=ae(M.location.href);if(D(a.url,"https://www.google-analytics.com/gtm/js?id="))return!0;if(a.query||0<=a.url.indexOf("?")||0<=a.path.indexOf("://"))return!1;if(a.host==b.host&&a.port==b.port)return!0;b="http:"==a.protocol?80:443;return"www.google-analytics.com"==a.host&&(a.port||
b)==b&&D(a.path,"/plugins/")?!0:!1;
},ae=function (a) {
function b (a) {
var b=(a.hostname||"").split(":")[0].toLowerCase(),c=(a.protocol||"").toLowerCase(),c=1*a.port||("http:"==c?80:"https:"==c?443:"");a=a.pathname||"";D(a,"/")||(a="/"+a);return[b,""+c,a];
}var c=M.createElement("a");c.href=M.location.href;var d=(c.protocol||"").toLowerCase(),e=b(c),g=c.search||"",ca=d+"//"+e[0]+(e[1]?":"+e[1]:"");D(a,"//")?a=d+a:D(a,"/")?a=ca+a:!a||D(a,"?")?a=ca+e[2]+(a||g):0>a.split("/")[0].indexOf(":")&&(a=ca+e[2].substring(0,
e[2].lastIndexOf("/"))+"/"+a);c.href=a;d=b(c);return{protocol:(c.protocol||"").toLowerCase(),host:d[0],port:d[1],path:d[2],query:c.search||"",url:a||""};
};var Z={ga:function () {
Z.f=[];
}};Z.ga();Z.D=function (a) {
var b=Z.J.apply(Z,arguments),b=Z.f.concat(b);for(Z.f=[];0<b.length&&!Z.v(b[0])&&!(b.shift(),0<Z.f.length););Z.f=Z.f.concat(b);
};Z.J=function (a) {
for(var b,c=[],d=0;d<arguments.length;d++)try{
b=new sc(arguments[d]),b.g?C(b.a[0],b.a[1]):(b.i&&(b.ha=y(b.c,b.a[0],b.X,b.W)),c.push(b));
}catch(e) {}return c;
};
Z.v=function (a) {
try{
if(a.u)a.u.call(O,N.j("t0"));else{
var b=a.c==gb?N:N.j(a.c);if(a.A)"t0"!=a.c||N.create.apply(N,a.a);else if(a.ba)N.remove(a.c);else if(b)if(a.i) {
if(a.ha&&(a.ha=y(a.c,a.a[0],a.X,a.W)),!u(a.a[0],b,a.W))return!0;
}else if(a.K) {
var c=a.C,d=a.a,e=b.plugins_.get(a.K);e[c].apply(e,d);
}else b[a.C].apply(b,a.a);
}
}catch(g) {}
};var N=function (a) {
J(1);Z.D.apply(Z,[arguments]);
};N.h={};N.P=[];N.L=0;N.answer=42;var uc=[Na,W,V];N.create=function (a) {
var b=za(uc,[].slice.call(arguments));b[V]||(b[V]="t0");var c=""+b[V];if(N.h[c])return N.h[c];b=new pc(b);N.h[c]=b;N.P.push(b);return b;
};N.remove=function (a) {
for(var b=0;b<N.P.length;b++)if(N.P[b].get(V)==a) {
N.P.splice(b,1);N.h[a]=null;break;
}
};N.j=function (a) {
return N.h[a];
};N.getAll=function () {
return N.P.slice(0);
};
N.N=function () {
"ga"!=gb&&J(49);var a=O[gb];if(!a||42!=a.answer) {
N.L=a&&a.l;N.loaded=!0;var b=O[gb]=N;X("create",b,b.create);X("remove",b,b.remove);X("getByName",b,b.j,5);X("getAll",b,b.getAll,6);b=pc.prototype;X("get",b,b.get,7);X("set",b,b.set,4);X("send",b,b.send);X("requireSync",b,b.ma);b=Ya.prototype;X("get",b,b.get);X("set",b,b.set);if(!Ud()&&!Ba) {
a: {
for(var b=M.getElementsByTagName("script"),c=0;c<b.length&&100>c;c++) {
var d=b[c].src;if(d&&0==d.indexOf("https://www.google-analytics.com/analytics")) {
J(33);
b=!0;break a;
}
}b=!1;
}b&&(Ba=!0);
}Ud()||Ba||!Ed(new Od(1E4))||(J(36),Ba=!0);(O.gaplugins=O.gaplugins||{}).Linker=Dc;b=Dc.prototype;C("linker",Dc);X("decorate",b,b.ca,20);X("autoLink",b,b.S,25);C("displayfeatures",fd);C("adfeatures",fd);a=a&&a.q;ka(a)?Z.D.apply(N,a):J(50);
}
};N.da=function () {
for(var a=N.getAll(),b=0;b<a.length;b++)a[b].get(V);
};var ra=N.N,va=O[gb];va&&va.r?ra():z(ra);z(function () {
Z.D(["provide","render",ua]);
});function La (a) {
var b=1,c;if(a)for(b=0,c=a.length-1;0<=c;c--) {
var d=a.charCodeAt(c);b=(b<<6&268435455)+d+(d<<14);d=b&266338304;b=0!=d?b^d>>21:b;
}return b;
};
})(window);
