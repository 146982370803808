import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { Media } from 'design/atoms/Media';

const SigneeStateFailed = -1; // Error occurred.
export const SigneeStateCreated = 1; // When transaction is created, and signing start date is not due.
const SigneeStatePending = 2;  // Related to sequential signing. First document in sequence order is stated to Started as rest of them to Pending state.
export const SigneeStateStarted = 3;  // Signing start date is due and ready to sign document.
export const SigneeStateSigned = 4;  // Recipient signed document - others may not have done that.
const SigneeStateReadyForDistribution = 5;  // Start distributing transactions to recipients, and sender.
const SigneeStateCompleted = 6;  // Finished distribution. Signing is completed.
const SigneeStateExpired = 7;  // Reached signing expiration date.
const SigneeStateCanceled = 8;  // (API doc siger 5!)  Canceled by user.
const SigneeStateDataCollecting = 9; // Relates to data campaigns. After campaign is initiated but not yet started, waiting for merged documents
const SigneeStateRejected = 10; // Recipient rejected signing the document.

export const signeeHasSigned = status => {
    return [
        SigneeStateSigned,
        SigneeStateReadyForDistribution,
        SigneeStateCompleted,
    ].includes(status);
};

// SigneeStatus
// Status på en enkelt Visma Addo signee
export default ({ status }) => {
    const iconSize = "small";
    let icon;
    let tooltip;
    switch (status) {
        case SigneeStateFailed:
            icon = <Icon size={iconSize} color="red" name='warning sign' circular />;
            tooltip = "Underskrift fejlet. Kontakt supporten";
            break ;
        case SigneeStateCreated:
            icon = <Icon size={iconSize} color="grey" name='clock' inverted circular />;
            tooltip = "Underskriftsanmodning oprettet";
            break ;
        case SigneeStatePending:
            icon = <Icon size={iconSize} color="grey" name='clock' inverted circular />;
            tooltip = "Afventer andre underskrivere";
            break ;
        case SigneeStateStarted:
            icon = <Icon size={iconSize} color="blue" name='paper plane' inverted circular />;
            tooltip = "Anmodning afsendt. Klar til underskrift";
            break ;
        case SigneeStateSigned:
            icon = <Icon size={iconSize} color="green" name='check' inverted circular />;
            tooltip = "Underskrevet. Andre underskrifter mangler muligvis stadig";
            break ;
        case SigneeStateReadyForDistribution:
            icon = <Icon size={iconSize} color="grey" name='check' inverted circular />;
            tooltip = "Underskrevet. Dokumentet er på vej til underskriverne";
            break ;
        case SigneeStateCompleted:
            icon = <Icon size={iconSize} color="green" name='check' inverted circular />;
            tooltip = "Alle har skrevet under";
            break ;
        case SigneeStateExpired:
            icon = <Icon size={iconSize} color="yellow" name='warning sign' inverted circular />;
            tooltip = "Udløbet. Underskriften har overskrevet sin udløbsdato";
            break ;
        case SigneeStateCanceled:
            icon = <Icon size={iconSize} color="yellow" name='x' circular />;
            tooltip = "Underskriftsflow annulleret";
            break ;
        case SigneeStateDataCollecting:
            icon = <Icon size={iconSize} color="grey" name='clock' inverted circular />;
            tooltip = "Venter på dokumenter";
            break ;
        case SigneeStateRejected:
            icon = <Icon size={iconSize} color="yellow" name='x' inverted circular />;
            tooltip = "Underskriveren har afvist at skrive under";
            break ;
        case 0:
            return null;
        default:
            icon = <Icon size={iconSize} color="grey" name='question' inverted circular />;
            tooltip = "Ukendt status" + (!!status ? ": "+status : "");
    }

    const renderPopup = (position) => (
        <Popup
            position={position}
            trigger={icon}
            content={tooltip}
            inverted
        />
    );

    return (
        <>
            <Media gte='computer'>
                {renderPopup('top center')}
            </Media>
            <Media lt='computer'>
                {renderPopup()}
            </Media>
        </>
    );
}
