import IteratorTypes from "./IteratorTypes";
import TreeDepthFirstIterator from "./TreeDepthFirstIterator";

function createIterator(iteratorType, treeNode) {
    let iterator = null;
    switch(iteratorType) {
        case IteratorTypes.DEPTH_FIRST: {
            iterator = new TreeDepthFirstIterator(treeNode);
        }
    }

    return iterator;
}

function iterate(treeNode, iteratorType, visitNode) {
    const iterator = createIterator(iteratorType, treeNode);
    while(iterator.hasNext()) {
        const node = iterator.next();
        visitNode(node);
    }
}

export {
    IteratorTypes,
    createIterator,
    iterate
};
