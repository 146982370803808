import React, { isValidElement } from 'react';
import { Icon, Segment } from 'semantic-ui-react';

import styles from './SegmentMenuItem.module.scss';

function SegmentMenuItem({ active, onClick, content, icon, error }) {
    const className = (
        active ?
        styles.activePdfMenuItem :
        styles.inactivePdfMenuItem
    );

    const renderIcon = () => {
        return (
            isValidElement(icon) ?
            icon :
            <Icon
                name={icon}
                color={active ? 'green' : undefined}
            />
        );
    };

    const renderContent = () => {
        if (error) {
            return <Icon name='warning sign' color='red' />;
        }

        return (
            <span>
                {content}
                {
                    icon &&
                    <>
                        &nbsp;
                        {renderIcon()}
                    </>
                }
            </span>
        );
    };

    return (
        <Segment
            disabled={!!error}
            onClick={onClick}
            className={className}
            content={renderContent()}
            textAlign='center'
            style={{ cursor: active || error ? 'initial' : 'pointer' }}
        />
    );
}

export default SegmentMenuItem;