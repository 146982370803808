/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react';
import { Form, Icon, Label, Search } from 'semantic-ui-react';
import { searchCompanies } from 'http/cvr';
import debouce from 'lodash.debounce';
import ColoredText from 'design/atoms/ColoredText';

const CVRInput = ({ onChange, cvr, error: parentError }) => {
    const [query, setQuery] = useState('');
    const [error, setError] = useState(false);
    const [searching, setSearching] = useState(false);
    const [selectionMade, setSelectionMade] = useState(false);
    const [nextSearch, setNextSearch] = useState(null);
    const [lastSearch, setLastSearch] = useState(null);
    const [results, setResults] = useState(null);

    useEffect(() => {
        if (!query) {
            return;
        }

        setSelectionMade(false);
        setError(false);
        onChange && onChange({ valid: false })
        const trimmed = query.trim();
        if (trimmed.length <= 2) {
            // query is not long enough to trigger search
            setResults(null);
            return;
        }

        setNextSearch(trimmed);
    }, [query]);

    useEffect(() => {
        if (searching) {
            return;
        }

        if (!nextSearch) {
            return;
        }

        if (lastSearch && lastSearch === nextSearch) {
            return;
        }

        // do search
        const doSearch = async () => {
            const query = nextSearch;
            setSearching(true);
            setLastSearch(query);
            setNextSearch(null);
            try {
                const result = await searchCompanies(query);
                setResults(result);
            } catch {
                setError(true);
            };
            setSearching(false);
        };

        doSearch();
    }, [nextSearch, searching]);

    const renderIcon = () => {
        if (error || parentError) {
            return <Icon name='warning circle' color='red' />;
        }

        if (selectionMade || cvr) {
            return <Icon name='check' color='green' />;
        }

        if (searching) {
            return undefined;
        }

        return <Icon name='search' />;
    };

    const onResultSelected = (_, data) => {
        setSelectionMade(true);
        onChange && onChange({
            valid: true,
            cvr: data.result.value,
        });
    };

    const onInputChange = (_, { value }) => {
        setQuery(value);
    };

    return (
        <>
            <Form.Field>
                <label>Virksomhedens navn eller CVR-nummer:</label>
                <Search
                    autoFocus
                    disabled={cvr}
                    value={cvr}
                    loading={searching}
                    placeholder='Søg efter virksomheder...'
                    noResultsMessage='Ingen virksomheder fundet'
                    onSearchChange={debouce(onInputChange, 500)}
                    onResultSelect={onResultSelected}
                    icon={renderIcon()}
                    results={results?.map(({ name, cvr, road, houseNumber, zipcode, ziparea, isDissolved }) => ({
                        key: cvr,
                        title: name,
                        description: `${cvr} ∙ ${road} ${houseNumber}, ${zipcode} ${ziparea}`,
                        value: cvr,
                        price: isDissolved && <ColoredText
                            style={{ marginTop: '10px' }}
                            color='grey'
                            icon='warning circle'
                            content='Opløst'
                        />
                    }))}
                    showNoResults={!!results}
                    fluid
                />
                {
                    parentError &&
                    <Label pointing='above' content={parentError} color='red' basic />
                }
            </Form.Field>
        </>
    );
};

export default CVRInput;