import React from 'react';
import { ShoppingCartSegment } from "./ShoppingCart";

export const SubscriptionPackageUpdateDescription = ({ fromTier, toTier }) => {
    return (
        <ShoppingCartSegment title='Dit abonnement opgraderes' icon='level up'>
            Ved køb opgraderes dit abonnement
            fra <b style={{ whiteSpace: 'nowrap' }}>{fromTier}</b>{' '}
            til <b style={{ whiteSpace: 'nowrap' }}>{toTier}</b>.
        </ShoppingCartSegment>
    );
};

const getSubscriptionPackageUpgradeInfo = (product, currentPackageTier, desiredPackageTier) => {
    if (!currentPackageTier) return null;
    if (!desiredPackageTier) return null;

    const tiers = product.subscriptionPackageSet.tiers || [];

    const currentSubcriptionTierIndex = tiers.findIndex(tier => {
        return tier.id === currentPackageTier;
    });

    const desiredTierIdx = tiers.findIndex(tier => {
        return tier.id === desiredPackageTier;
    });

    const isUpgrade = desiredTierIdx > currentSubcriptionTierIndex;

    if (!isUpgrade) {
        return null;
    }
    
    return {
        prevTier: tiers[currentSubcriptionTierIndex],
        nextTier: tiers[desiredTierIdx],
    };
};

export default getSubscriptionPackageUpgradeInfo;