import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';

const DeleteIcon = ({ text, onDelete, ...props }) => {
    return (
        <Popup
            key={Math.random()}
            on='click'
            position='top center'
            trigger={<Icon name='trash' color='red' link {...props} />}
            content={
                <ColoredText
                    bold
                    link
                    onClick={onDelete}
                    content={text}
                    underlined={false}
                    color='red'
                />
            }
        />
    );
};

export default DeleteIcon;
