import { HTTPClient } from '../network/fetch';
import { withLocalCacheAsync } from 'util/localCache';

const cvrClient = new HTTPClient({ prefix: 'cvr' });

export function getAddressInfo(cvr) {
    return cvrClient.getResource(`${cvr}/address`);
}

export const searchCompanies = withLocalCacheAsync((cvrOrName) => {
    //cvrOrName = encodeURIComponent(cvrOrName);
    return cvrClient.getResource(`search?query=${cvrOrName}`);
});

export const getPDFPublishments = withLocalCacheAsync(cvr => {
    return cvrClient.getResource(`/${cvr}/publishments/pdf`);
});

export const downloadPublishment = withLocalCacheAsync(publishmentURL => {
    return cvrClient.getResource(`/publishments/download?publishment=${encodeURIComponent(publishmentURL)}`);
});