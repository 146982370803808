import React, { useCallback, useState } from 'react';
import { Loader, Message, Pagination, Table } from 'semantic-ui-react';
import { countCreditTransactionEvents, getCreditTransactionEvents, getCreditsBox } from 'http/credits';
import useRestResource from 'util/useRestResource';
import { formatDate, formatDateTime } from 'util/format/DateTime';
import CreditsIcon from 'design/atoms/CreditsIcon';
import { useCreditsContext } from 'design/molecules/CreditsProvider';

const CreditsBalanceInfo = () => {
    const balanceResource = useCreditsContext();
    const creditsBoxResource = useRestResource({
        fetcher: getCreditsBox,
    });

    if (balanceResource.loading || creditsBoxResource.loading) {
        return <Loader inline='centered' active />;
    }

    const error = balanceResource.error || creditsBoxResource.error;
    if (error) {
        return <Message error content='Kunne ikke indlæse credits balance information' />;
    }

    const expirationDate = new Date(creditsBoxResource.data.expirationDate);
    const hasExpiration = !isNaN(expirationDate.valueOf()) && expirationDate > 0;
    
    return (
        <>
            <div>
                Din nuværende balance er <strong><CreditsIcon orange /> {balanceResource.credits}</strong>
                {hasExpiration && balanceResource.credits > 0 && <span>, der udløber d. {formatDate(expirationDate)}</span>}.
            </div>
        </>
    );
};

const PAGE_SIZE = 10;
const COL_SPAN = 3;

const CreditTransactions = () => {
    const [pageIndex, setPageIndex] = useState(0);

    const countResource = useRestResource({ fetcher: countCreditTransactionEvents });

    const creditsFetcher = useCallback(async () => {
        return getCreditTransactionEvents(pageIndex, PAGE_SIZE);
    }, [pageIndex]);

    const eventsResource = useRestResource({ fetcher: creditsFetcher });

    const amountOfTransactions = countResource.data;
    const totalPages = Math.ceil(amountOfTransactions / PAGE_SIZE);

    const renderSingleRow = content => {
        return (
            <Table.Row>
                <Table.Cell colSpan={COL_SPAN}>
                    {content}
                </Table.Cell>
            </Table.Row>
        );
    };
    
    const renderTableBody = () => {
        const isLoading = eventsResource.loading || countResource.loading;
        if (isLoading) {
            return renderSingleRow(<Loader active inline='centered' />);
        }
        
        const errorOccured = eventsResource.error || countResource.error;
        if (errorOccured) {
            return renderSingleRow(<Message content='Der opstod en fejl' error />);
        }
        
        const currentTransactions = eventsResource.data;
        return (
            currentTransactions.map(({ timestamp, comment, balanceChange, productName, taxYear, clientName }) => {
                const text = [productName, taxYear, clientName, comment].filter(x => x).join(' ∙ ');

                return (
                    <Table.Row key={timestamp}>
                        <Table.Cell>{formatDateTime(timestamp)}</Table.Cell>
                        <Table.Cell>{text}</Table.Cell>
                        <Table.Cell textAlign='right'>
                            {balanceChange > 0 && '+'}{balanceChange}
                            {' '}<CreditsIcon />
                        </Table.Cell>
                    </Table.Row>
                );
            })
        );
    };

    const transactionsTable = (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Tidspunkt</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Balanceændring</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {renderTableBody()}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan={COL_SPAN} textAlign='center'>
                        {!countResource.loading && (
                            <Pagination
                                activePage={pageIndex + 1}
                                totalPages={totalPages}
                                disabled={totalPages <= 1}
                                onPageChange={(_, { activePage }) => setPageIndex(activePage - 1)}
                            />
                        )}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );

    return (
        <>
            <CreditsBalanceInfo />
            {transactionsTable}
        </>
    );
};

export default CreditTransactions;