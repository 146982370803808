import React from 'react';
import { CardNumberElement, ElementsConsumer } from '@stripe/react-stripe-js';

export const getCardToken = (stripe, elements, fromElement = CardNumberElement) => {
    const cardElement = elements.getElement(fromElement);
    return stripe.createToken(cardElement);
};

export const withStripe = Component => {
    return props => (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <Component
                    stripe={stripe}
                    elements={elements}
                    {...props}
                />
            )}
        </ElementsConsumer>
    );
};