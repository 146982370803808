// corrosponds to labels from by financial-dates
// => in model/period.go
const variationLabels = {
    regular: {
        id: 'regular',
        description: 'Regnskabsperiode',
    },
    conversion: {
        id: 'conversion',
        description: 'Omlægningsperiode',
    },
};

export default variationLabels;