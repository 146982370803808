import React, { Component } from 'react';
import { useHistory } from 'react-router';
import handleComponentError from 'util/handleComponentError';

class ErrorBoundary extends Component {
    componentDidCatch = (error, errorInfo) => {
        handleComponentError({
            error,
            errorInfo,
            history: this.props.history,
        });
    };

    render = () => {
        return this.props.children;
    };
}

export const withErrorBoundary = Wrapped => {
    return props => {
        const history = useHistory();
        return (
            <ErrorBoundary history={history}>
                <Wrapped {...props} />
            </ErrorBoundary>
        );
    };
};

export default ErrorBoundary;