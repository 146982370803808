
import { ECONOMIC_AUTH_LOCATION } from 'config/config';
import { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import QueryParams from 'util/QueryParams';

// when connecting to e-conomic locally or on dev
// the app will perform a redirect to prod, targeting this component
const DevAndLocalEconomicProxy = () => {
    const [error, setError] = useState('');

    useEffect(() => {
        const { redirectUrl } = QueryParams();

        if (!redirectUrl) {
            setError('No redirect URL provided');
            return;
        }

        window.sessionStorage.afterEconomicIntegrationRedirectPath = redirectUrl;
        window.location.href = ECONOMIC_AUTH_LOCATION;
    }, []);

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Loader inline='centered' active size='huge' />
    );
};

export default DevAndLocalEconomicProxy;