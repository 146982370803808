import React, { useEffect, useState } from 'react';
import { Container, Header, Loader, Segment } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import { withErrorBoundary } from 'design/atoms/ErrorBoundary';
import { beginImpersonation } from 'util/impersonation';
import { useRootUser } from 'util/useUser';
import AuthHelper from 'util/AuthHelper';
import ColoredText from 'design/atoms/ColoredText';
import QueryParams from 'util/QueryParams';

const ImpersonationDirector = () => {
    const [error, setError] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const user = useRootUser();
    const { impuid, redirect } = QueryParams();

    useEffect(() => {
        if (!impuid) {
            history.push('/');
            return;
        }

        beginImpersonation(impuid)
            .then(() => history.push(redirect || '/'))
            .catch(() => setError(true))
    }, [impuid, location, history, redirect]);
    
    const onLogoutClicked = () => {
        const redirect = location.pathname + location.search;
        AuthHelper.deAuthenticate(redirect);
    };

    const renderContent = () => {
        const adminOrAccountant = user.isAdmin() || user.isAccountant();
        if (!adminOrAccountant) {
            return (
                <>
                    <Header
                        icon='info circle'
                        content='Manglende administratorrettigheder'
                    />
                    <p>
                        Din konto har ikke de fornødne rettigheder til at foretage impersonering på Digital Revisor.
                    </p>
                    <p>
                        <ColoredText
                            link
                            color='green'
                            content='Klik her'
                            icon='logout'
                            iconPosition='left'
                            onClick={onLogoutClicked}
                        /> for at logge ind på en anden konto.
                    </p>
                </>
            );
        }

        if (error) {
            return (
                <>
                    <Header
                        icon='warning circle'
                        content='Der opstod en fejl'
                    />
                    <p>
                        Du kan <ColoredText
                            link
                            color='green'
                            content='klikke her'
                            onClick={() => history.push('/')}
                        /> for at gå til forsiden.
                    </p>
                </>
            );
        }

        return <div style={{ textAlign: 'center' }}>
            <Loader active inline size='large' />
            <div>Arbejder...</div>
        </div>;
    };

    return (
        <Container>
            <br />
            <Segment>
                {renderContent()}
            </Segment>
        </Container>
    );
};

export default withErrorBoundary(ImpersonationDirector);