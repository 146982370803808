import React from 'react';
import UpdateCardForm from 'design/molecules/UpdateCardForm';
import useUser from 'util/useUser';

const Card = ({ fetchSubscriptions }) => {
    const user = useUser();

    return <UpdateCardForm
        alwaysShow={user.isAccountant()}
        subscriptions={[] /* @todo: let the component itself do this */}
        fetchSubscriptions={fetchSubscriptions /* @todo: let the component itself do this */}
    />;
};

export default Card;