import React from 'react';
import { formatDate } from 'util/format/DateTime'
import SimpleLabel from '../SimpleLabel';

const SimpleDate = ({ value, ...otherProps }) => {
    return (
        <SimpleLabel
            {...otherProps}
            value={(
                value ?
                formatDate(value) :
                'Ikke angivet'
            )}
        />
    );
};

export default SimpleDate;