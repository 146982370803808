import { HTTPClient } from 'network/fetch';
import { withLocalCacheAsync } from 'util/localCache';

const client = new HTTPClient({ prefix: 'youtube' });

/**
 * Get Youtube video metadata
 * @param {string} id
 */
export const describeYoutubeVideo = withLocalCacheAsync(function (id) {
    return client.getResource(`/videos/${id}`);
});