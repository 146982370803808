import JWTDecode from 'jwt-decode';
import userSessionStore from 'util/userSessionStore';
import { publish } from '../pubsub';

const ACCESS_TOKEN        = 'accessToken';
const IMPERSONATION_TOKEN = 'impersonationToken';
const REFRESH_TOKEN       = 'token';
const CHOSEN_USER         = 'chosenUser';

// Topics
export const ACCESS_TOKEN_CHANGED        = 'ACCESS_TOKEN_CHANGED';
export const IMPERSONATION_TOKEN_CHANGED = 'IMPERSONATION_TOKEN_CHANGED';

export function isTokenExpired(jwtToken) {
    const decoded = JWTDecode(jwtToken);
    const expEpoch = (decoded.exp - 10) * 1000;
    return Date.now() >= expEpoch;
}

function isTokenTypeExpired (tokenType) {
    return isTokenExpired(userSessionStore.get(tokenType));
}

function tokenExists (tokenType) {
    return userSessionStore.has(tokenType);
}

function setToken (tokenType, value) {
    userSessionStore.set(tokenType, value);
}

export function clearAccessToken () {
    userSessionStore.remove(ACCESS_TOKEN);
    publish(ACCESS_TOKEN, null);
}

export function clearRefreshToken () {
    localStorage.removeItem(REFRESH_TOKEN);
    userSessionStore.remove(REFRESH_TOKEN);
}

export function clearImpersonationToken () {
    userSessionStore.remove(IMPERSONATION_TOKEN);
    publish(IMPERSONATION_TOKEN_CHANGED, null);
}

export function setAccessToken (value) {
    setToken(ACCESS_TOKEN, value);
    publish(ACCESS_TOKEN_CHANGED, value);
}

export function setRefreshToken (value) {
    localStorage[REFRESH_TOKEN] = value;
    setToken(REFRESH_TOKEN, value);
}

export function setImpersonationToken (value) {
    setToken(IMPERSONATION_TOKEN, value);
    publish(IMPERSONATION_TOKEN_CHANGED, value);
}

export function isAccessTokenExpired () {
    return isTokenTypeExpired(ACCESS_TOKEN);
}

export function isRefreshTokenExpired () {
    return isTokenTypeExpired(REFRESH_TOKEN);
}

export function isImpersonationTokenExpired () {
    return isTokenTypeExpired(IMPERSONATION_TOKEN);
}

export function hasRefreshToken () {
    return tokenExists(REFRESH_TOKEN);
}

export function hasAccessToken () {
    return tokenExists(ACCESS_TOKEN);
}

export function hasImpersonationToken () {
    return tokenExists(IMPERSONATION_TOKEN);
}

export function getRefreshToken () {
    return userSessionStore.get(REFRESH_TOKEN);
}

export function getAccessToken () {
    return userSessionStore.get(ACCESS_TOKEN);
}

export function getImpersonationToken () {
    return userSessionStore.get(IMPERSONATION_TOKEN);
}

export function clearAllTokens () {
    clearImpersonationToken();
    clearAccessToken();
    clearRefreshToken();
    clearChosenUser();
}

export function decodeAccessToken () {
    const accessToken = getAccessToken();
    if (!accessToken) {
        return null;
    }
    return JWTDecode(accessToken);
}

export function decodeImpersonationToken () {
    const impersonationToken = getImpersonationToken();
    if (!impersonationToken) {
        return null;
    }
    return JWTDecode(impersonationToken);
}

/**
 * Returns a token, if present. Hierarchy:
 * - Impersonation token
 * - Access token
 * - Refresh token
 */
export function getRelevantToken () {
    return (
        userSessionStore.get(IMPERSONATION_TOKEN) ||
        userSessionStore.get(ACCESS_TOKEN) ||
        userSessionStore.get(REFRESH_TOKEN)
    );
}

/**
 * Determines if it's necessary to validate tokens. Returns true if any token is present in storage.
 */
export function hasAnyToken () {
    return !!getRelevantToken();
}

export function setChosenUser(uid) {
    if (!uid) {
        return;
    }
    
    userSessionStore.set(CHOSEN_USER, uid);
}

export function getChosenUser() {
    return userSessionStore.get(CHOSEN_USER) || '';
}

export function clearChosenUser() {
    userSessionStore.remove(CHOSEN_USER);
}