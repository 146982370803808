import { postResource, getResource, putResource } from '../network/fetch';
import { getImpersonatedEmail } from '../util/impersonation';

const prefix = 'e-mail';

// @todo: who should receive this mail?
export function sendAccounplantApprovalEmail ({ productLink, note, email, displayName }) {
    return postResource(`${prefix}/approval`, {
        userEmail: email || getImpersonatedEmail(),
        displayName,
        productLink,
        note,
    }, {
        token: 'accessToken',
    });
}

export function getEmailNotifications(loginID) {
    return getResource(`${prefix}/notifications/${loginID}`)
    .then(notifications => {
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

        return notifications
            .filter(notification => new Date(notification.timestamp) >= sixMonthsAgo)
            .slice(0, 5);
    });
}

export function flagEmailNotificationsAsSeen(notificationIds = []) {
    return putResource(`${prefix}/notifications/seen`, notificationIds);
}

export function getEmailNotificationsAsSeen(notificationIds = []) {
    return getResource(`${prefix}/notifications/seen`, notificationIds);
}