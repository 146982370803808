export const revcodesDrift = new Map([
    [ "none", "none" ],
    [ "AfskrivningerAndreAnlaegDriftsmaterielInventar", "Afskrivninger andre anlæg, driftsmateriel og inventar" ],
    [ "AfskrivningerErhvervedeImmaterielleAktiver", "Afskrivninger erhvervede immaterielle aktiver" ],
    [ "AfskrivningerFaerdiggjorteUdviklingprojekter", "Afskrivninger færdiggjorte udviklingprojekter" ],
    [ "AfskrivningerGoodwill", "Afskrivninger goodwill" ],
    [ "AfskrivningerGrundeBygninger", "Afskrivninger grunde og bygninger" ],
    [ "AfskrivningerIndretningLejedeLokaler", "Afskrivninger indretning af lejede lokaler" ],
    [ "AfskrivningerProduktionsanlaegMaskiner", "Afskrivninger produktionsanlæg maskiner" ],
    [ "AfskrivningerUdviklingprojekter", "Afskrivninger udviklingprojekter" ],
    [ "AfskrivningerSkibe", "Afskrivninger skibe" ],
    [ "ATP", "ATP" ],
    [ "Dagsvaerdiregulering", "Dagsværdiregulering" ],
    [ "EjFradragsberettigedeOmkostninger", "Ej fradragsberettigede omkostninger" ],
    [ "Laaneomkostninger_mv", "Låneomkostninger m.v." ],
    [ "TabGevinstAnlaegsaktiver", "Tab/gevinst anlægsaktiver" ],
    [ "BoederogAndreGebyrer", "Bøder og andre gebyrer" ],
    [ "EjFradragRenter", "Ej fradragsberettigede renter" ],
    [ "EjSkatAssocieredeVirk", "Ej skattepligtige kapitalandele i associerede virksomheder" ], // kun B
    [ "EjSkatTilknyttedeVirk", "Ej skattepligtige kapitalandele i tilknyttede virksomheder" ], // kun B
    [ "FremmedArbejder", "Fremmed arbejder" ], // kun A
    [ "Laaneomkostninger", "Låneomkostninger" ],
    [ "NedskrivningOmsaetningsaktiver", "Nedskrivning omsætningsaktiver" ],
    [ "EjSkattepligtigeRenter", "Ej skattepligtige renter" ], 
    [ "Repraesentation", "Repræsentation" ],
    [ "RepraesentationSalgsfremmendeUdgifter", "Repræsentation salgsfremmende udgifter" ],
    [ "SalgsfremmendeUdgifter", "Salgsfremmende udgifter" ],
    [ "Smaaanskaffelser", "Småanskaffelser" ],
    [ "TabPaaDebitorerHensat", "Tab på debitorer hensat" ], // kun A
    [ "TabPaaDebitorerKonstateret", "Tab på debitorer konstateret" ],
    [ "TilgangPaaProjekterUnderUdviklingIAaret", "Tilgang på projekter under udvikling i året" ],
    [ "OevrigeEjSkattepligtigeIndtaegter" , "Øvrige ej skattepligtige indtægter" ],
    [ "SkattemaessigReguleringAfInvesteringsejendomme", "Skattemæssig regulering af investeringsejendomme" ],
    [ "SygedagpengeforsikringIndehaver", "Sygedagpengeforsikring indehaver" ],
    [ "SkattepligtigIndkomstAfPSAndele", "Skattepligtig indkomst af P/S andele" ],
]);

export const revcodesBalance = new Map([
    [ "none", "none"],
    [ "Mellemregning", "Mellemregning" ], // kun A
    [ "Moms", "Moms" ], // kun A
    [ "VirksomhedensKontingentTilArbejdsgiver", "Virksomhedens kontingent til arbejdsgiver" ], // kun A
    [ "IgangvaerendeArbejde", "Igangværende arbejde" ],
    [ "Periodeafgraensningsposter", "Periodeafgrænsningsposter" ],
    [ "HensatTilTabPaaDebitorer", "Tbf. af debitorhensættelse i udskudt skat" ],
    [ "AnskaffelsessumForEjendomme", "Anskaffelsessum for ejendomme (VSO/KAO)" ],
    [ "LeasingDepositum", "Regulering leasing depositum" ],
    [ "AndreRegUdskudtSkat", "Andre regulering til udskudt skat" ],
]);

const DRIFT = "Drift";
const BALANCE = "Balance";

/**
 * Returns the account type corresponding to "revcode".
 * Eg. "EjFradragRenter" => "Drift".
 * Returns "Other" if the revcode is unknown.
 * @param {*} revcode - A "revisorkode" (aka "skattekode"). Example: "EjFradragRenter"
 */
export const getExpectedAccountType = (revcode) => {
    if (revcodesDrift.has(revcode)) return DRIFT;
    if (revcodesBalance.has(revcode)) return BALANCE;

    return 'Other';
}

/**
 * Checks whether "revcode" corresponds to the expected account type
 * @param {*} revcode - A "revisorkode" (aka "skattekode"). Example: "EjFradragRenter"
 * @param {*} expectedAccountType - Either "Drift" or "Balance"
 */
export const doesMatchAccountType = (revcode, expectedAccountType) => {
    if (!expectedAccountType) return true;

    if (expectedAccountType === BALANCE && revcodesBalance.has(revcode)) return true;

    if (expectedAccountType === DRIFT && revcodesDrift.has(revcode)) return true;

    return false;
}

/**
 * Lookup the human readable name for "revcode".
 * Eg. "EjFradragRenter" => "Ej fradragsberettigede renter".
 * Returns "EjFradragRenter*" if the revcode is unknown (to signal a problem).
 * @param {*} revcode - "revisorkode"
 */
export const revcodeTranslation = (revcode) => {
    if (revcodesDrift.has(revcode)) {
        return revcodesDrift.get(revcode);
    }
    if (revcodesBalance.has(revcode)) {
        return revcodesBalance.get(revcode);
    }
    console.error(`Unknown revcode: "${revcode}"`)
    // return a un-translated revcode, with a visible signal
    return `${revcode}*`
}
