const makeUserSessionStore = () => {
    const sessionStorageKey = 'userSessionStore';
    
    let store = JSON.parse(sessionStorage.getItem(sessionStorageKey) || '{}');

    const save = () => {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(store));
    };

    return Object.freeze({
        get(key) {
            return store[key];
        },
        has(key) {
            return !!store[key];
        },
        remove(key) {
            delete store[key];
            save();
        },
        set(key, value) {
            store[key] = value;
            save();
        },
    });
};

const userSessionStore = makeUserSessionStore();

if (!userSessionStore.has('token') && localStorage.token) {
    userSessionStore.set('token', localStorage.token);
}

export default userSessionStore;