import React, { Component } from 'react';
import { Input as SemInput } from 'semantic-ui-react';

class Input extends Component {
    onChange = (_, { value }) => this.props.onChange(value);

    render () {
        const { leftAttachment: la, rightAttachment: ra, ...props } = this.props;
        if (!la && !ra) {
            return <SemInput {...props} />;
        }
        const labelPosition = ra ? 'right' : 'left';
        return (
            <SemInput labelPosition={labelPosition} {...props} onChange={this.onChange}>
                { !!la && la }
                <input />
                { !!ra && ra }
            </SemInput>
        );
    }
}

export default Input;