import i18n from './i18n';
import lc from './languageCodes';

const { daDK, enUS } = lc;

export default {
    CVR_DOWN: i18n({
        [daDK]: 'Kan ikke hente CVR-data, da Virk.dk er nede. Prøv igen senere.',
        [enUS]: 'Can not fetch CVR-data as Virk is down. Try again later.',
    }),
};
