import React     from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Message, Button } from 'semantic-ui-react';

import styles from './UploadArea.module.scss';

class UploadArea extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            uploadErrors: [],
        };
    }

	static propTypes = {
        uploadFile: PropTypes.func.isRequired,
        saving: PropTypes.bool,
	};

    static defaultProps = {
		saving: false,
	};

    blockDefault (e) {
        e.preventDefault();
        e.stopPropagation();
    }

    toggleIsDragging (state) {
        this.setState({dragging: state});
    }

    handleUpload (e) {
        e.preventDefault();
        const inputElem = this.refs.fileInput;
        if (e.type === 'change' && !inputElem.files[0]) {
            return null; // Do nothing if no file has been selected
        }
        let fileToUpload = {};

        if (e.type === 'drop') {
            fileToUpload = e.dataTransfer.files[0];
        } else if (e.type === 'change') {
            fileToUpload = inputElem.files[0];
        } else {
            throw new Error('No file to upload');
        }

        let filters = [
            {
                error: (e.type === 'change' && !inputElem.value),
                msg: 'Du har ikke valgt en fil',
            },
            {
                error: (!fileToUpload.type.match(/(png|jpg|jpeg|pdf)/i)),
                msg: 'Du kan kun uploade filer af typerne JPG, PNG eller PDF',
            },
            {
                error: (fileToUpload.size > 4000000),
                msg: 'Filen skal fylde mindre end 4MB',
            },
        ];
        const errors = filters.filter((filter) => {
            return filter.error;
        });

        if (errors.length) {
            this.setState({ uploadErrors: errors });
            inputElem.value = '';
        } else {
            //No errors, continue
            this.props.uploadFile(fileToUpload)
                .then( () => this.setState({uploadErrors: []}) )
                .catch( (err) => this.setState({uploadErrors: [{ msg: err+"" }]}) );
        }
    }

    renderErrorMsg () {
        const { uploadErrors } = this.state;
        if (uploadErrors === null || uploadErrors.length === 0) {
            return null;
        }

        const list = uploadErrors.map(e => e.msg);
        return <Message error header='Fejl' list={list} />;
    }


    render () {
        const { saving } = this.props;
        const DADCapable = 'draggable' in document.createElement('span');
        const headerTxt = DADCapable ? 'Træk fil hertil' : 'Upload bilag her';
        const subHeader = DADCapable ? <Header.Subheader>eller</Header.Subheader> : null;

        return (<React.Fragment>
            <Segment placeholder
                textAlign='center'
                className={styles.uploadArea}
                onDrop={ this.handleUpload.bind(this) }
                onDrag={this.blockDefault}
                onDragStart={this.blockDefault}
                onDragOver={this.blockDefault}
                onDragEnd={this.blockDefault}
            >
                <Header>
                    { headerTxt }
                    { subHeader }
                </Header>

                <input className={ `${styles.fileInput}` } type="file" ref="fileInput" onChange={ this.handleUpload.bind(this) }/>

                <Button
                    primary
                    content='Vælg fil'
                    loading={saving}
                    onClick={() => this.refs.fileInput.click()}
                />
            </Segment>

            { this.renderErrorMsg() }
            </React.Fragment>
        );
    }
}
export default UploadArea;
