import React, { Component } from 'react';
import { Segment, Message, Header, Image, Label, Icon } from 'semantic-ui-react';
import get from 'lodash.get';

import { hasRole, isAdmin } from 'util/userMethods';
import erpSystems from 'util/erpSystems';
import withUserData from 'util/withUserData';
import { getPaymentLogs, countCustomerConversions, getERPDeal, getPaymentRefundLogs } from 'http/payment';
import { countErpUsers } from 'http/accounts';

import ProductPage from '../ProductPage'
import LogTable from './LogTable';
import FilterOptions from './FilterOptions';

const initialState = () => {
    const now = new Date();
    return {
        loading: true,
        fetching: false,
        unauthorized: false,
        deal: null,
        logs: [],
        userCount: -1,
        error: false,
        year: now.getFullYear(),
        month: now.getMonth() + 1,
        totalUserCount: -1,
        totalConversions: -1,
    };
};

class PaymentLogsView extends Component {
    state = initialState();

    componentDidMount = async () => {
        const mountRoutine = async () => {
            await this.fetchDeal();

            if (!this.isAuthorized()) {
                this.setState({
                    unauthorized: true,
                });
                return;
            }

            try {
                await this.fetchRequiredData();
            } catch {
                this.setState({ error: true });
            }
        };

        await mountRoutine();
        this.setState({ loading: false });
    };

    fetchDeal = () => new Promise(async resolve => {
        const deal = await getERPDeal(this.props.erp);
        this.setState({ deal }, resolve);
    });

    isAuthorized = () => {
        const { userData } = this.props;
        const { deal } = this.state;

        // admins are always allowed
        if (isAdmin(userData)) {
            return true;
        }

        // check if user has the required staff role if deal active
        for (let arole of get(deal, 'authRoles', [])) {
            if (hasRole(userData, arole)) {
                return true;
            }
        }

        // user is not authorized
        return false;
    };

    fetchRequiredData = async (mounted = false) => {
        const promises = [
            this.fetchLogs(),
            this.countUsers(),
        ];
        if (!mounted) {
            promises.push(this.countTotalUsers());
            promises.push(this.countTotalConversions());
        }
        await Promise.all(promises);
    };

    countUsers = async () => {
        const { year, month } = this.state;
        const userCount = await countErpUsers(this.props.erp, {
            year,
            month,
        });
        this.setState({ userCount });
    };

    countTotalUsers = async () => {
        const totalUserCount = await countErpUsers(this.props.erp);
        this.setState({ totalUserCount });
    };

    countTotalConversions = async () => {
        const totalConversions = await countCustomerConversions({
            erp: this.props.erp,
        });
        this.setState({ totalConversions });
    };

    fetchLogs = async () => {
        const { year, month } = this.state;

        const query = {
            erp: this.props.erp,
            year,
            month,
        };

        // Payment log entries and refunds come from the same collection.
        // We fetch them in two different calls as the year/month of purchase
        // can differ from the year/month of refund
        const [logs, refunds] = await Promise.all([
            getPaymentLogs(query),
            getPaymentRefundLogs(query),
        ]);

        const formatEntry = (entry, isRefund) => {
            return {
                cvr: entry.cvr,
                name: entry.displayName,
                transactionDate: isRefund ? entry.refundTimestamp : entry.timestamp,
                isRefund,
                amount: isRefund ? -entry.refundAmount : entry.price,
            };
        };

        const formattedLogs = [
            ...logs.map(entry => formatEntry(entry, false)),
            ...refunds.map(entry => formatEntry(entry, true)),
        ];

        formattedLogs.sort((a, b) => {
            return new Date(a.transactionDate) - new Date(b.transactionDate);
        });

        this.setState({ logs: formattedLogs });
    };

    setAndRefetch = newState => {
        this.setState({ ...newState }, async () => {
            this.setState({ fetching: true });
            await this.fetchRequiredData(true);
            this.setState({ fetching: false });
        });
    };
    
    renderLogTable = () => {
        const { logs, loading, fetching, userCount, year, month } = this.state;
        return !loading && <LogTable
            userCount={userCount}
            logs={logs}
            loading={fetching}
            year={year}
            month={month}
        />;
    };

    renderTotalStats = () => {
        const { totalUserCount, totalConversions } = this.state;
        let result;
        result = totalConversions / totalUserCount * 100;
        result = isNaN(result) ? '-' : <u>{result.toFixed(2)} %</u>;
        return <Segment textAlign='center' padded stacked>
            <Header size='large'>Samlet statistik</Header>
            <Label.Group size='large'>
                <Label>
                    <Icon name='user' />
                    Brugere i alt: <u>{totalUserCount}</u>
                </Label>
                <Label>
                    <Icon name='money' />
                    Kunder i alt: <u>{totalConversions}</u>
                </Label>
                <Label>
                    <Icon name='line graph' />
                    Konverteringsrate: {result}
                </Label>
            </Label.Group>
        </Segment>;
    };

    renderLogo = () => {
        const erpSystem = erpSystems[this.props.erp];
        if (!erpSystem) {
            return null;
        }

        return <Image size='medium' src={erpSystem.logo} centered />;
    };

    renderOptions = () => {
        const { year, month, fetching, deal } = this.state;

        let minYear;
        if (deal) {
            minYear = new Date(deal.dealDate).getFullYear();
        } else {
            minYear = 2017;
        }

        return <FilterOptions
            year={year}
            month={month}
            loading={fetching}
            onMonthChange={month => this.setAndRefetch({ month })}
            onYearChange={year => this.setAndRefetch({ year })}
            minYear={minYear}
        />;
    };

    renderUnauthorized = () => {
        return <Message
            icon='hand paper'
            header='Adgang nægtet'
            content='Du har ikke adgang til at se denne side'
            error
        />;
    };

    renderError = () => {
        const { error } = this.state;

        let content;
        if (typeof error === 'string') {
            content = error;
        } else {
            content = 'Der gik noget galt da vi forsøgte at hente dine oversigtsdata';
        }
        
        return <Message
            icon='warning sign'
            header='Der opstod en fejl'
            content={content}
            error
        />;
    };

    renderContent = () => {
        const { unauthorized, error } = this.state;
        if (unauthorized) {
            return this.renderUnauthorized();
        }
        if (error) {
            return this.renderError();
        }
        return <>
            {this.renderLogo()}
            {this.renderTotalStats()}
            <Segment padded stacked>
                {this.renderOptions()}
                {this.renderLogTable()}
            </Segment>
        </>;
    };

    render = () => {
        return <ProductPage loading={this.state.loading}>
            {this.renderContent()}
        </ProductPage>;
    };
}

export default withUserData(PaymentLogsView);