import React, { Component } from 'react';
import { Label, Transition } from 'semantic-ui-react';

class ErrorMessage extends Component {
    render () {
        const msg = this.props.children;
        const visible = this.props.visible;
        if (!visible) {
            return null;
        }
        const errLabel = (
            <Label
                basic
                color='red'
                icon='info circle'
                pointing='above'
                size='small'
                ref={r => this.label = r}
                content={msg}
            />
        );
        return (
            <>
                <Transition.Group animation='fade right' duration={300}>
                    { msg && errLabel}
                </Transition.Group>
            </>
        );
    }
}

export default ErrorMessage;
