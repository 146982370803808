import React, { useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import copyToClipboard from 'util/copyToClipboard';

// Button that copies the text in prop "text" to clipboard.
//
// Inspiration:
// https://stackoverflow.com/a/42844911
function CopyToClipboard({ text, copyMessage='Kopieret!', trigger=<Button icon='copy outline' /> }) {
    const [copySuccess, setCopySuccess] = useState(null);
    const [popupTimeout, setPopupTimeout] = useState(null);

    const handleOpen = () => {
        copyToClipboard(text);
        setCopySuccess(copyMessage);
        setPopupTimeout( setTimeout(() => {
            setCopySuccess('');
        }, 2000));
    }
    
    const handleClose = () => {
        setCopySuccess(null);
        if (popupTimeout) clearTimeout(popupTimeout);
        setPopupTimeout(null);
    }

    return (
        <Popup
            on='click'
            position='top center'
            trigger={trigger}
            content={copySuccess}
            open={!!copySuccess}
            onClose={handleClose}
            onOpen={handleOpen}
        />
    );
}

export default CopyToClipboard;