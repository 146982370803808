let _iFrame;
function getIFrame () {
    // Create the iFrame if it doesn't exist
    if (!_iFrame) {
        const frame = document.createElement('iframe');
        frame.style.display = 'none';
        document.body.appendChild(frame);
        const frameWindow = frame.contentWindow || frame.contentDocument.document || frame.contentDocument;
        _iFrame = frameWindow;
    }
    // Reset it
    _iFrame.document.body.innerText = '';
    return _iFrame;
}

export function triggerPrint (markup) {
    // Get the print iFrame
    const iFrame = getIFrame();

    // Write the markup to the iFrame
    iFrame.document.body.appendChild(markup);

    // Signal the frame to print
    iFrame.print();
}
