// Reverse lexicographic order
const variationComparator = (a, b) => {
    if (a.type > b.type) return -1;
    if (a.type < b.type) return 1;
    return 0;
};

function groupTaxYears (variations) {
    const taxYearMap = {};
    for (let variation of variations) {
        const { year } = variation;
        if (!taxYearMap[year]) {
            taxYearMap[year] = [];
        }
        taxYearMap[year].push(variation);
    }

    const taxYearList = Object.keys(taxYearMap).map(taxYear => {
        const year = Number(taxYear);
        const variations = taxYearMap[taxYear].sort(variationComparator);
        const isTeaser = variations.every(v => v.teaser);

        let startDate;
        let endDate;
        if (variations.length === 1) {
            const variation = variations[0];
            startDate = variation.startDate;
            endDate = variation.endDate;
        }
        
        return {
            year,
            variations,
            isTeaser,
            startDate,
            endDate,
        };
    });
    taxYearList.sort((a, b) => b.year - a.year);
    return taxYearList;
}

export default groupTaxYears;