import { HTTPClient } from '../network/fetch';

const client = new HTTPClient({ prefix: 'esignatur' });

export function healthz() {
    return client.get(`healthz/esignatur`);
}

export function fetchFlow(flowId) {
    return client.getResource(`flow/${flowId}`);
}

export function forceUpdate(flowId){
    return client.getResource(`flow/force-update/${flowId}`)
}

export function sendReminder(flowId, signeeId){
    return client.getResource(`remind/${flowId}/${signeeId}`)
}

export function changeEmail(flowId, signeeId, signeeEmail, newSigneeEmail){
    return client.postResource(`changeEmail`, {flowId: flowId, signeeId: signeeId, signeeEmail: signeeEmail, newSigneeEmail: newSigneeEmail})
}

export function getSignedDocument(flowId, documentID) {
    return client.getResource(`flow/${flowId}/signed-documents/${documentID}`);
}

export function getAttachment(flowId, documentID) {
    return client.getResource(`flow/${flowId}/attachments/${documentID}`);
}