import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { Media } from 'design/atoms/Media';

const SigneeStateFailed = "Error"; // Error occurred.
export const SigneeStateCreated = "Created"; // When transaction is created, and signing start date is not due.
const SigneeStatePending = "Pending";  // Related to sequential signing. First document in sequence order is stated to Started as rest of them to Pending state.
export const SigneeStateStarted = "Active";  // Signing start date is due and ready to sign document.
export const SigneeStateSigned = "Signed";  // Recipient signed document - others may not have done that.
const SigneeStateCompleted = "Completed";  // Finished distribution. Signing is completed.
const SigneeStateExpired = "Expired";  // Reached signing expiration date.
const SigneeStateCanceled = "Cancelled";  // (API doc siger 5!)  Canceled by user.
const SigneeStateRejected = "Rejected"; // Recipient rejected signing the document.
export const SigneeDuplicateStarted = "DuplicateActive";  // Signing start date is due and ready to sign document.

export const signeeHasSigned = status => {
    return [
        SigneeStateSigned,
        SigneeStateCompleted,
        SigneeDuplicateStarted,
    ].includes(status);
};

// SigneeStatus
// Status på en enkelt Esignatur signee
export default ({ status }) => {
    const iconSize = "small";
    let icon;
    let tooltip;

    switch (status) {
        case SigneeStateFailed:
            icon = <Icon size={iconSize} color="red" name='warning sign' circular />;
            tooltip = "Underskrift fejlet. Kontakt supporten";
            break ;
        case SigneeStateCreated:
            icon = <Icon size={iconSize} color="grey" name='clock' inverted circular />;
            tooltip = "Underskriftsanmodning oprettet";
            break ;
        case SigneeStatePending:
            icon = <Icon size={iconSize} color="grey" name='clock' inverted circular />;
            tooltip = "Afventer andre underskrivere";
            break ;
        case SigneeStateStarted:
            icon = <Icon size={iconSize} color="blue" name='paper plane' inverted circular />;
            tooltip = "Anmodning afsendt. Klar til underskrift";
            break ;
        case SigneeStateSigned:
            icon = <Icon size={iconSize} color="green" name='check' inverted circular />;
            tooltip = "Underskrevet. Andre underskrifter mangler muligvis stadig";
            break ;
        case SigneeStateCompleted:
            icon = <Icon size={iconSize} color="green" name='check' inverted circular />;
            tooltip = "Alle har skrevet under";
            break ;
        case SigneeStateExpired:
            icon = <Icon size={iconSize} color="yellow" name='warning sign' inverted circular />;
            tooltip = "Udløbet. Underskriften har overskrevet sin udløbsdato";
            break ;
        case SigneeStateCanceled:
            icon = <Icon size={iconSize} color="yellow" name='x' circular />;
            tooltip = "Underskriftsflow annulleret";
            break ;
        case SigneeStateRejected:
            icon = <Icon size={iconSize} color="yellow" name='x' inverted circular />;
            tooltip = "Underskriveren har afvist at skrive under";
            break ;
        case SigneeDuplicateStarted:
            icon = <Icon size={iconSize} color="green" name='checkmark' inverted circular />;
            tooltip = "Underskrives senere som en anden rolle";
            break ;
        case "":
            return null;
        default:
            icon = <Icon size={iconSize} color="grey" name='question' inverted circular />;
            tooltip = "Ukendt status" + (!!status ? ": "+status : "");
    }

    const renderPopup = (position) => (
        <Popup
            position={position}
            trigger={icon}
            content={tooltip}
            inverted
        />
    );

    return (
        <>
            <Media gte='computer'>
                {renderPopup('top center')}
            </Media>
            <Media lt='computer'>
                {renderPopup()}
            </Media>
        </>
    );
}