import React from 'react';
import Product from '../../../pages/Product';
import { hasQueryParam } from '../../../../util/QueryParams';
import { getProduct } from 'http/productEngine';
import { Loader } from 'semantic-ui-react';
import { withErrorBoundary } from 'design/atoms/ErrorBoundary';

class DevDirector extends React.Component {
    state = {
        loading: true,
        product: {},
    };

    componentDidMount = async () => {
        const productID = this.props.match.params.product;
        const product = await getProduct(productID);
        this.setState({ loading: false, product });
    };

    render () {
        const { product, loading } = this.state;
        if (loading) {
            return <span>
                <br />
                <Loader inline='centered' active size='huge' inverted />
            </span>;
        }

        const { params } = this.props.match;
        const { label } = params;
        
        let pagePrefix = `/product/${params.product}`;
        if (label) {
            pagePrefix += `/${label}`;
        }

        let hasPaid = true;
        if (hasQueryParam('notpaid')) {
            hasPaid = false;
        }

        return (
            <Product
                modelId={params.product}
                label={label}
                product={product}
                {...this.props}
                pagePrefix={pagePrefix}
                hasPayed={hasPaid}
                landingLocation='/'
                paymentURL='/'
                year={product.year}
                isLocked={product.isLocked}
                testmode
            />
        );
    }
}

export default withErrorBoundary(DevDirector);