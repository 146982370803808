import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Icon, List, Message, Segment } from 'semantic-ui-react';
import CopyToClipboard from 'design/atoms/CopyToClipboard';
import { useRootUser } from 'util/useUser';
import { ButtonWithPriceTag } from './components/TaxYearNew';
import { STARTER_HELP_ID, starterHelpOrderLineItem } from 'design/pages/Payment/PaymentFlowStarterHelp';

const INFO_MAIL = 'info@digitalrevisor.nu';

const StarterHelp = () => {
    const user = useRootUser();
    const hasAccessToStarterHelp = user.purchases.includes(STARTER_HELP_ID);
    const purchaseRedirectURL = `${window.location.pathname}#${STARTER_HELP_ID}`;

    return (
        <Segment padded>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1em' }}>
                <div style={{ flex: 1 }}>
                    <Header content='Opstartshjælp' icon='rocket'  />
                </div>
                {!hasAccessToStarterHelp && (
                    <div>
                        <Link to={`/betaling/opstartshjaelp?redirect=${encodeURIComponent(purchaseRedirectURL)}`}>
                            <ButtonWithPriceTag content='Køb opstartshjælp' vat={false} price={starterHelpOrderLineItem.price} />
                        </Link>
                    </div>
                )}
            </div>
            {
                hasAccessToStarterHelp &&
                <Message
                    color='green'
                    header='Du har købt adgang til opstartshjælpen'
                    icon='check circle'
                />
            }
            <p>
                Opstartshjælpen er til dig, der har brug for at overføre
                sidste års bogføringsdata. Det er et krav, at årsrapporten
                indeholder tallene fra sidste års regnskabsperiode, så der
                kan dannes et sammenligningsgrundlag.
            </p>
            <p>
                Hvis du ikke har tallene, kan vi hjælpe dig med at skrive
                tallene ind, så det kommer til at stå korrekt i din årsrapport.
            </p>
            {
                !hasAccessToStarterHelp &&
                <div>
                    <p>
                        Du får hjælp til at:
                    </p>
                    <List bulleted>
                        <List.Item>
                            Sammenholde sidste års bogføringstal med den indberettede årsrapport
                        </List.Item>
                        <List.Item>
                            Korrigere sammenligningstal i sidste års bogføring
                        </List.Item>
                        <List.Item>
                            Kontrollere sammenligningstal i årsrapport på Digital Revisor
                        </List.Item>
                    </List>
                </div>
            }
            <Header size='small'>
                Sådan er processen for opstartshjælpen:
            </Header>
            <List ordered>
                <List.Item>
                    Køb adgang til opstartshjælp og skriv til os i chatten
                </List.Item>
                <List.Item>
                    Giv Digital Revisor adgang til dit bogføringssystem
                    {hasAccessToStarterHelp && ' på følgende måde:'}
                    {
                        hasAccessToStarterHelp &&
                        <List.List>
                            <List.Item>Navigér til dit bogføringssystem</List.Item>
                            <List.Item>Gå til indstillinger og vælg "Tilføj revisor"</List.Item>
                            <List.Item>
                                Indtast Digital Revisors mailadresse:&nbsp;
                                <b>{INFO_MAIL}</b>&nbsp;
                                <CopyToClipboard
                                    text={INFO_MAIL}
                                    trigger={
                                        <Icon
                                            link
                                            name='copy'
                                            style={{ display: 'inline' }}
                                        />
                                    }
                                />
                            </List.Item>
                            <List.Item>Digital Revisor har nu adgang til dit bogføringssystem</List.Item>
                        </List.List>
                    }
                </List.Item>
                <List.Item>
                    Digital Revisor indskriver og kontrollerer dine tal
                    og sender dig en besked, når vi er færdige.
                </List.Item>
            </List>
        </Segment>
    );
};

export default StarterHelp;