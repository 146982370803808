function validateCvr(input) {
    const inputType = typeof input;
    let cvr = null;

    if (!input) {
        return 'Indtast venligst et CVR nummer';
    }

    if (inputType === 'number') {
        cvr = input.toString();
    } else if (inputType === 'string') {
        cvr = input;
    } else {
        return 'Intern fejl: CVR er hverken tal eller streng';
    }

    let len = cvr.length,
        i = 1,
        res = 0;

    if (len !== 8) {
        return 'Indtast venligst 8 tal i CVR nummer';
    }

    // internal test cvrs are prefixed with 900000
    if (cvr.startsWith('900000')) return;

    while (len > 0) {
        len--;
        res += i * parseInt(cvr[len], 10);
        i++;
        if (i > 7) {
            i = 2;
        }
    }
    if (res % 11 === 0) return false;
    else return 'Indtast venligst et gyldigt CVR nummer';
}

export default validateCvr;
