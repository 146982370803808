const countries = [
    {
        name: 'Danmark',
        language: {
            name: 'Dansk',
            code: 'da-DK'
        },
        flagCode: 'dk',
    },
    {
        name: 'Storbritannien',
        language: {
            name: 'Engelsk',
            code: 'en-GB'
        },
        flagCode: 'gb',
    }
];

export function getFlagCode(languageCode) {
    let country = countries.find(x => x.language.code === languageCode);
    if(!country) {
        return '';
    } else {
        return country.flagCode;
    }
}

export default Object.freeze(countries);
