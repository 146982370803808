import React, { Component } from 'react'
import { Message, Form, Input, Button } from 'semantic-ui-react';
import { registerCredentials } from 'http/dynaccount';

import styles from './connectors.module.scss';

class DynaccountConnector extends Component {
    state = { failed: false, working: false };

    setCredentialValue = credProp => {
        return (_, { value }) => {
            this.setState({ [credProp]: value });
        };
    };

    displayErrorMessage = () => {
        const { failed } = this.state;
        if (failed) {
            return <Message negative>
                <Message.Header>Der opstod en fejl</Message.Header>
                <p>Kontroller login-oplysningerne</p>
            </Message>;
        }
    };

    registerERP = async () => {
        const { apiID, apiKey, apiSecret } = this.state;
        if (!apiID || !apiKey || !apiSecret) {
            return;
        }

        this.setState({ working: true });

        try {
            await registerCredentials(apiID, apiKey, apiSecret);
            this.props.onRegister();
        } catch {
            this.setState({ failed: true, working: false })
        }
    };

    renderCredsForm = () => {
        return <Form>
            <Form.Field>
                <label>API ID</label>
                <Input
                    placeholder='Indtast API ID'
                    onChange={this.setCredentialValue('apiID')}
                    disabled={this.state.working}
                />
            </Form.Field>
            <Form.Field>
                <label>API-nøgle</label>
                <Input
                    placeholder='Indtast API-nøgle'
                    onChange={this.setCredentialValue('apiKey')}
                    disabled={this.state.working}
                />
            </Form.Field>
            <Form.Field>
                <label>API-secret</label>
                <Input
                    placeholder='Indtast API-secret'
                    onChange={this.setCredentialValue('apiSecret')}
                    disabled={this.state.working}
                />
            </Form.Field>
        </Form>
    };

    render = () => {
        return (
            <div>
                {this.renderCredsForm()}
                <div className={styles.actionsContainer}>
                    <Button
                        onClick={this.registerERP}
                        disabled={this.state.working}
                        loading={this.state.working}
                        content='Forbind'
                        primary
                    />
                </div>
                {this.displayErrorMessage()}
            </div>
        );
    };
}

export default DynaccountConnector;