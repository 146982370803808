import googleTagManager from 'util/GoogleTagManager';

/**
 * Prepares a purchase event to be consumed by GTM
 */
const sendGTMPurchaseEvent = ({ transactionID, orderLines, totalPrice, vat }) => {
    const event = {
        currency: 'DKK',
        value: totalPrice,
        tax: vat,
        shipping: 0,
        transaction_id: transactionID,
        items: orderLines.map(({ description, metadata, price }) => {
            return {
                item_name: description,
                item_id: metadata.productPurchase.id,
                price: price,
                quantity: '1',
                item_brand: 'Digitalrevisor',
            };
        }),
    };

    googleTagManager.pushPurchaseEvent(event);
};

export default sendGTMPurchaseEvent;