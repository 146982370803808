import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Header, Loader, Segment } from 'semantic-ui-react';
import { describeInvitation } from 'http/accounts';
import { formatDateTime } from 'util/format/DateTime';
import { hasAccessToken } from 'util/token';
import { makeQueryParams } from 'util/QueryParams';
import ColoredText from 'design/atoms/ColoredText';
import InvitationConfirmer from 'design/molecules/InvitationConfirmer';
import ProductPage from 'design/molecules/ProductPage';

// users land here when they've clicked on an invitation link from an e-mail
const InvitationBroker = () => {
    const history = useHistory();
    const params = useParams();
    const [error, setError] = useState(null);
    const [done, setDone] = useState(false);

    useEffect(() => {
        const doDescribeInvitation = async () => {
            const { token } = params;

            if (!token) {
                history.push('/');
                return;
            }

            let invitation;

            // check if invitation exists
            try {
                invitation = await describeInvitation(token);
            } catch {
                setError({
                    header: 'Invitation kunne ikke findes',
                    body: 'Invitationen kunne ikke findes, da den eventuelt er udløbet eller trukket tilbage af afsenderen.',
                });
                return;
            }

            // confirm not expired
            if (invitation.isExpired) {
                setError({
                    header: 'Invitation udløbet',
                    body: `Den invitation du forsøgte at acceptere udløb d. ${formatDateTime(invitation.expirationDate)}`,
                });
                return;
            }

            // if this point is reached, the invitation is valid!

            // if logged in, render acceptance component
            if (hasAccessToken()) {
                setDone(true);
                return;
            }
            
            // send to register page w/ prefill email
            history.push(`/register/${invitation.segment}${makeQueryParams({
                invitationToken: token,
                email: invitation.email,
                segment: invitation.segment,
                displayName: invitation.displayName,
            })}`);
        };

        doDescribeInvitation();
    }, [params.token, history, params]);

    const renderShell = content => {
        if (hasAccessToken()) {
            return <ProductPage>{content}</ProductPage>;
        }

        return <Container>
            <br />
            {content}
        </Container>;
    };


    if (error) {
        return renderShell(
            <Segment>
                <Header>{error.header}</Header>
                <p>{error.body}</p>
                <ColoredText link='/' content='Gå til forsiden' />
            </Segment>
        );
    }

    if (!done) {
        return renderShell(
            <Segment basic textAlign='center'>
                <Loader inline='centered' active />
                Arbejder...
            </Segment>
        );
    }

    return renderShell(<InvitationConfirmer token={params.token} />);
};

export default InvitationBroker;