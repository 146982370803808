export function $ (...args) {
    let elem, props, children;
    elem = args[0];
    if (args.length === 1) {
        props = {};
        children = [];
    } else if (args.length === 2) {
        props = {};
        children = args[1];
    } else {
        props = args[1];
        children = args[2];
    }
    if (children === undefined || children === null) {
        children = '';
    }
    if (typeof children === 'string') {
        props.innerText = children;
        children = [];
    }
    return renderElement(elem, props, children);
}

function renderElement (elem, props, children) {
    const ele = document.createElement(elem);
    applyProps(ele, props);
    for (let child of children) {
        ele.appendChild(child);
    }
    return ele;
}

function applyProps (ele, props) {
    for (let [ key, value ] of Object.entries(props)) {
        if (typeof value === 'object') {
            applyProps(ele[key], value);
        } else {
            ele[key] = value;
        } 
    }
}