import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import set from 'lodash.set';
import { Button, Form, Icon, Input, Label, List, Segment, Table, Transition, Popup } from 'semantic-ui-react';

import withUserData from 'util/withUserData';
import { isAccountant, isAdmin } from 'util/userMethods';
import { userStatusesEnum } from 'design/molecules/AppLauncher/userStatuses';
import { isFlowLocked, SigningStateCompleted } from './FlowStatus';
import SigneeStatus, { signeeHasSigned, SigneeStateStarted } from './SigneeStatus';
import SigningMedia, { Email, SMS } from './SigningMedia';
import FlowHeader, { LegacyFlowStatus } from './FlowHeader';

import styles from './VismaSignature.module.scss';
import SegmentMenuItem from 'design/atoms/SegmentMenuItem';
import ColoredText from 'design/atoms/ColoredText';

const VismaSignature = ({ error, disabled, onSubmit, internalPdfId, productStatus, signFlow, internalSignFlow, reloadProductData, userData }) => {
    const fieldsDisabled = disabled;
    const [activeFlowIdx, setActiveFlowIdx] = useState(0);
    const [notificationMedia, setNotificationMedia] = useState();               // forsendelsesmedie
    const [includeInternalReport, setIncludeInternalReport] = useState(false);  // medtag intern rapport til underskrift
    const [signees, setSignees] = useState([]);                                 // er den lokale kopi af signFlow.signees. Kan ændre sig (ved user input). Ved klik på knappen gemmes signFlow til mongo
    const [hasNonUniqueSignees, setHasNonUniqueSignees] = useState(true);       // all signess must be unique. Blocks "Sign"-button
    const [hasDuplicateEmails, setHasDuplicateEmails] = useState(true);         // flag duplicate email/phone
    const [attachedSections, setAttachedSections] = useState(new Set());        // attach section print PDFs to the sign flow
    const [invalidEmails, setInvalidEmails] = useState(new Set());
    const [badEmails, setBadEmails] = useState(new Set());

    const flows = [
        {
            name: 'Årsrapport',
            flow: signFlow,
        },
        {
            name: 'Intern årsrapport',
            flow: internalSignFlow,
        },
    ];

    const activeFlow = flows[activeFlowIdx].flow;

    // TODO: model builder chooses which sections can be attached?
    const attachableSections = [
        { name: 'Skat', slug: 'skat' },
        { name: 'Indberetning', slug: 'taxq' },
    ];

    useEffect(() => {
        if (!activeFlow || !activeFlow.signees) return ;

        setSignees(activeFlow.signees);
        setNotificationMedia(activeFlow.notificationMedia);
        setIncludeInternalReport(activeFlow.includeInternalReport);
        setAttachedSections(new Set(activeFlow.attachedSections));
    }, [activeFlow]);

    // counts number of occurances
    const occurancesReducer = (accu, cur) => ({ ...accu, [cur]: (accu[cur] || 0) + 1 });

    // uniqueness:
    // all (email, name, and title)-tuples must be unique
    useEffect(() => {
        const occurrences = signees
            .map(signee => `${signee.name}#${signee.title}#${signee.email}`)
            .reduce(occurancesReducer, {});
        const hasNonUniques = Object.values(occurrences).some(count => count > 1);
        setHasNonUniqueSignees(hasNonUniques);
    }, [signees]);

    // duplicates:
    // look for emails (or phone numbers, as applicable) that has multiple occurrences
    useEffect(() => {
        const occurrences = signees
            .map(signee => signee[notificationMedia === SMS ? "phone" : "email"])
            .filter(emailOrSms => emailOrSms !== "")
            .reduce(occurancesReducer, {});
        const hasDuplicates = Object.values(occurrences).some(count => count > 1)
        setHasDuplicateEmails(hasDuplicates);
    }, [signees, notificationMedia]);

    // invalid emails:
    // can be found in the flow after sign flow validation
    useEffect(() => {
        const invalidEmails = new Set(activeFlow?.validationResult?.invalidEmails);
        
        setInvalidEmails(invalidEmails);
    }, [activeFlow]);

    // bad emails:
    // looks for emails of signees that doesn't support TLS
    useEffect(() => {
        const badEmails = new Set();
        signees.forEach(signee => {
            invalidEmails.has(signee.email) && badEmails.add(signee.email);
        });
        setBadEmails(badEmails);
    }, [signees, invalidEmails]);


    const handleNotificationMediaChange = (_, data) => {
        if (data.value === "SMS") {
            setNotificationMedia(SMS);
        } else {
            setNotificationMedia(Email);
        }
    };

    // Handles onChange events from form fields.
    // See React "controlled components"
    // React does handle nested state well. Therefore we do a deep object copy via json.
    // Args:
    // - stateObj: Eg. signees
    // - setterFun: Eg. setSignees()
    // - path1: Eg. "[0]"
    // - path2: Eg. "email"
    // - value: New value of the nested state property (eg. "signees[0].email")
    const handleChange = (stateObj, setterFun, path1="") => {
        return (path2, value) => {
            const path = `${path1}${path2}`
            let newObj = JSON.parse(JSON.stringify(stateObj));
            set(newObj, path, value);
            setterFun(newObj);
        }
    }

    // Medsend intern årsrapport tin underskrift
    const renderInternalReportOptions = () => {
        return internalPdfId && <Form.Group inline>
            { renderFormLabel('Send også intern årsrapport til underskrift?') }
            <Form.Checkbox
                label='Ja'
                checked={includeInternalReport}
                disabled={disabled}
                onChange={ (_, target) => setIncludeInternalReport(target.checked)}
            />
        </Form.Group>;
    };

    const renderFormLabel = (text) => {
        return (
            <label style={{ opacity: fieldsDisabled ? 0.25 : 1 }}>
                {text}
            </label>
        );
    };

    // Medsend print af andre sektioner
    // Kun Rådgivere og admins
    const renderPrintOptions = () => {
        if (!isAccountant(userData) && !isAdmin(userData)) {
            return null;
        }

        return <Form.Group inline>
            { renderFormLabel('Medsend print af andre sektioner') }
            {attachableSections.map(({ name, slug }) =>
                <Form.Checkbox
                    label={name}
                    checked={attachedSections.has(slug)}
                    disabled={disabled}
                    onChange={(_, { checked }) => {
                        const newChoices = new Set([...attachedSections]);

                        // add/remove slug
                        checked ? newChoices.add(slug) : newChoices.delete(slug);

                        setAttachedSections(newChoices);
                    }}
                />,
            )}
        </Form.Group>;
    }

    const renderSignees = () => {
        if (!signees || signees.length === 0) return ;

        // map of signee-obj => UI compoennt (with change handler)
        const m = signees.map((signee, idx) => {
            return {
                signee, 
                uiComp: renderSigneeRow(signee, idx, handleChange(signees, setSignees, `[${idx}]`)),
            };
        });

        // table 
        const groups = [
            <span>Disse personer skal skrive under først</span>,
            <span>Dernæst skal revisoren skrive under</span>,
            <span>
                Dirigenten skriver under til sidst
                {
                    includeInternalReport &&
                    !isFlowLocked(activeFlow?.flowStatus) &&
                    ', dog ikke på den interne årsrapport'
                }
            </span>,
        ];

        return (
            groups
            .map((groupHeader, seqidx) => {
                // find all signees by sequence number
                const sequenceNum = seqidx + 1;
                return {
                    signees: m.filter(obj => obj.signee.sequenceNumber === sequenceNum),
                    header: groupHeader,
                };
            })
            .filter(group => group.signees.length > 0)
            .map((group, groupIdx) => {
                const groupNum = groupIdx + 1;
                const signeeTxStatuses = group.signees.map(({ signee }) => signee.txStatus);

                const groupIsDone = signeeTxStatuses.every(signeeHasSigned);
                const groupIsActive = signeeTxStatuses.some(txStatus => txStatus === SigneeStateStarted);

                return (
                    <SigneeGroup
                        number={groupNum}
                        header={group.header}
                        active={groupIsActive}
                        done={groupIsDone}
                        children={group.signees.map(signee => signee.uiComp)}
                    />
                );
            })
        );
    }


    const renderSigneeRow = (signee, idx, changeHandler) => {
        return <Table.Row key={`${signee.title}-${idx}`} >
            <Table.Cell collapsing>
                <SigneeStatus status={signee.txStatus} />
            </Table.Cell>
            <Table.Cell>
            <Form.Group widths='equal'>
                { renderName(signee.name, signee.title, changeHandler) }
                { renderPhone(signee.phone, changeHandler) }
                { renderEmail(signee.email, changeHandler) }
                { renderRadioButtons(signee.signingMethod, changeHandler) }
            </Form.Group>
            </Table.Cell>
        </Table.Row>;
    }

    const renderName = (nameValue, title, changeHandler) => {
        return <SigneeField label={title || "Navn"}>
            <Input
                fluid required type="text" autoComplete="new-password"
                placeholder="Navn"
                disabled={fieldsDisabled}
                value={nameValue}                
                onChange={ (_, { value }) => changeHandler("name", value) }
                />
        </SigneeField>;
    }

    const renderEmail = (emailValue, changeHandler) => {
        return <SigneeField label={ notificationMedia === SMS ? "Email til kvittering" : "Email"}>
            <Input
                fluid
                required
                type="email"
                autoComplete="new-password"
                placeholder="eks: mail@test.com"
                disabled={fieldsDisabled}
                value={emailValue}
                onChange={ (_, { value }) => changeHandler("email", value) }
                icon={
                    invalidEmails.has(emailValue) &&
                    <Icon name={'warning circle'} color='orange' />
                }
            />
        </SigneeField>;
    }

    const renderPhone = (phoneValue, changeHandler) => {
        if (notificationMedia !== SMS) {
            return;
        }
        return <SigneeField label="Telefon">
            <Input
                fluid type="tel" autoComplete="new-password"
                placeholder="eks: 22334455"
                disabled={fieldsDisabled}
                value={phoneValue}
                onChange={ (_, { value }) => changeHandler("phone", value) }
                labelPosition='left' >
                <Label >+45</Label>
                <input />
            </Input>
        </SigneeField>;
    }

    const renderRadioButtons = (signingMethod, changeHandler) => {
        return <SigneeField label="Underskrivningsmetode">
            <Form.Radio
                label='NemID/MitID'
                value={1}
                checked={signingMethod === 1}
                disabled={fieldsDisabled}
                onChange={ (_, { value }) => changeHandler("signingMethod", value) }
            />
            <Form.Radio
                label='Touch'
                value={2}
                checked={signingMethod === 2}
                disabled={fieldsDisabled}
                onChange={ (_, { value }) => changeHandler("signingMethod", value) }
            />
        </SigneeField>;
    }


    // display warnings (max one, with this precedence);
    // - signee uniqueness
    // - email duplicates
    const warnings = useMemo(() => {
        if (hasNonUniqueSignees) {
            return <Segment secondary>
                <Icon name="warning" color="red" className={styles.numberLabel} />
                Alle underskrivere skal være unikke
            </Segment>;
        }

        if (badEmails.size > 0) {
            const plu = badEmails.size !== 1;
            return <Segment color='red'>
                <Icon name='warning circle' color='red' />
                Følgende e-mail{plu && 's'} er ikke sik{plu ? 're' : 'ker'}:
                <List bulleted>
                    {[...badEmails].map(email => (
                        <List.Item>{email}</List.Item>
                    ))}
                </List>
                <div>
                    Vælg venligst {plu ? 'nogle andre' : 'en anden'} e-mailadresse{plu && 'r'}
                    {' '}
                    og prøv igen.
                </div>
            </Segment>
        }

        if (hasDuplicateEmails) {
            const media = notificationMedia === SMS ? "det samme telefonnummer" : "den samme email"
            const msg = `Du har angivet ${media} flere steder, hvilket blot betyder at denne person skal underskrive flere gange`;
            return <Segment secondary>
                <strong>OBS -</strong>
                &nbsp;
                {msg}
            </Segment>;
        }

        // no warnings
        return null;

    }, [hasDuplicateEmails, hasNonUniqueSignees, notificationMedia, badEmails]);

    const renderButton = () => {
        const buttonIsDisabled = (
            disabled ||
            notificationMedia === undefined ||
            isInputInvalid ||
            hasNonUniqueSignees ||
            signees.length === 0 ||
            badEmails.size > 0
        );

        const sendToSigningButton = (
            <Button
                primary
                onClick={onBtnCLick}
                disabled={buttonIsDisabled}
                content='Send til underskrift'
                icon='signup'
            />
        );

        /*
            TODO: Kan error overhovedet eksistere?
            Bør vi vise fejl f.eks. fra CVR fetch eller vise hvorfor knappen er disabled?
        */
        const errorPopup = (
            <Popup
                trigger={
                    <div style={{ display: 'inline-block' }}>
                        {sendToSigningButton}
                    </div>
                }
            >
                <span className={styles.error}>{error}</span>
            </Popup>
        );

        return (
            <Segment basic textAlign='right' className="buttonSegment">
                { !error ? sendToSigningButton : errorPopup }
            </Segment>
        );
    };

    const renderSignatureMenu = () => {
        if (!fieldsDisabled) {
            return null;
        }

        if (!activeFlow) {
            return null;
        }

        if (activeFlow.flowStatus === 0) {
            return null;
        }

        const activeFlows = flows.filter(f => f.flow);

        // show menu if two or more flows
        if (activeFlows.length < 2) {
            return null;
        }
        
        return (
            <Segment.Group horizontal>
                {activeFlows.map(({ name, flow, ...op }, flowIdx) => {
                    const isDone = flow.flowStatus === SigningStateCompleted;

                    // signee stats
                    const totalSignees = flow.signees.length;
                    const signedSignees = flow.signees.reduce((acc, cur) => {
                        return acc += signeeHasSigned(cur.txStatus);
                    }, 0);
                    
                    const color = (
                        isDone ?
                        'green' :
                        'blue'
                    );

                    const content = (
                        <>
                            <span>
                                {name}
                                <span>
                                    &nbsp;
                                    <Icon
                                        name={isDone ? 'check circle' : 'paper plane'}
                                        color={color}
                                    />
                                    <ColoredText color={color}>
                                        {signedSignees} / {totalSignees}
                                    </ColoredText>
                                </span>
                            </span>
                        </>
                    );

                    return <SegmentMenuItem
                        key={flowIdx}
                        content={content}
                        active={flowIdx === activeFlowIdx}
                        onClick={() => setActiveFlowIdx(flowIdx)}
                    />;
                })}
            </Segment.Group>
        );
    };

    const onBtnCLick = async () => {
        const signeesCopy = JSON.parse(JSON.stringify(signees)); // deep copy

        // removes phone number from signees, if e-mail is chosen as shipping media
        omitPhoneIfMediaIsEmail(signeesCopy);

        // trims spaces on values from input fields
        sanitizeSignees(signeesCopy);

        onSubmit({
            signees: signeesCopy,
            attachedSections: [...attachedSections],
            notificationMedia,
            includeInternalReport,
            attachedDocuments: [],
        });
    };

    const sanitizeSignees = signeesArray => {
        signeesArray.forEach(signee => {
            signee.email = signee.email?.trim();
            signee.name = signee.name?.trim();
        });
    };

    const omitPhoneIfMediaIsEmail = signeesArray => {
        try {
            if (notificationMedia !== Email) {
                return ;
            }
            for (let i=0; i<signeesArray.length; i++) {
                delete signeesArray[i].phone;
            }    
        } catch (err) {
            console.error("Omit phone if media is email failed:", err);
        }
    }

    const isInputInvalid = useMemo(() => {
        try {
            if (notificationMedia === undefined || notificationMedia === null) {
                return true;
            }
            // check emails (not empty, regex)
            const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
            const emailInvalid = email => !email || !emailRegex.test(email);
            const foundInvalidEmails = signees
                .map(signee => signee.email)
                .filter(emailInvalid)
                .length > 0;
            if (foundInvalidEmails) return true;

            if (notificationMedia === Email) {
                return false;
            }
            // check phone numbers (not empty, all integers)
            const phoneRegex = /^\d{8}$/;
            const phoneInvalid = phone => !phone || !phoneRegex.test(phone);
            const foundInvalidPhoneNumbers = signees
                .map(signee => signee.phone)
                .filter(phoneInvalid)
                .length > 0;
            return foundInvalidPhoneNumbers;

        } catch (err) {
            console.error("Calc form validity failed:", err);
        }
        return false;
    }, [signees, notificationMedia]);


    if (!activeFlow) {
        if (productStatus === userStatusesEnum.SIGNATURE_FLOW || productStatus === userStatusesEnum.VISMA_SIGNATURE_FLOW) {
            return <LegacyFlowStatus ongoing />;
        }
        if (productStatus === userStatusesEnum.DONE || productStatus === userStatusesEnum.COMPLETED) {
            return <LegacyFlowStatus done />;
        }
        console.error("No signflow");
        return null;
    }

    return <div className={styles.left}>
        <Segment.Group>
            { renderSignatureMenu() }
            <FlowHeader flowStatus={activeFlow.flowStatus} reloadProductData={reloadProductData} />
            {
                !isFlowLocked(activeFlow?.flowStatus) &&
                <Segment>
                    <SigningMedia
                        label={renderFormLabel('Forsendelsesmedie')}
                        notificationMedia={notificationMedia}
                        onChange={handleNotificationMediaChange}
                        disabled={fieldsDisabled}
                    />
                    { renderPrintOptions() }
                    { renderInternalReportOptions() }
                </Segment>
            }
            { renderSignees() }
            {
                <Transition.Group animation='fade down' duration={400}>
                    {warnings}
                </Transition.Group>
            }
        </Segment.Group>
        { renderButton() }
    </div>;
}

VismaSignature.propTypes = {
    error: PropTypes.string,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    pdfId: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    productStatus: PropTypes.string,
    signFlow: PropTypes.shape({
        flowStatus: PropTypes.number,
        signees: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string,
            sequenceNumber: PropTypes.number.isRequired,
            signingMethod: PropTypes.number.isRequired,
            txStatus: PropTypes.number,
        })),
    }),
    reloadProductData: PropTypes.func.isRequired,
};
 

const SigneeGroup = ({ number, active, done, header, children }) => {
    return <>
        <Segment secondary className={active ? styles.active : ""}>
            { !!number && number + ". " }
            {header}
            { active && !done && <Icon name='ellipsis horizontal' className={styles.signeeGroupIcon} /> }
            { done && <Icon color="green" name='check' className={styles.signeeGroupIcon} /> }
        </Segment>
        <Segment>
            <Table basic='very' unstackable compact='very'>
            <Table.Body>
                { children }
            </Table.Body>
            </Table>
        </Segment>
    </>;
}

const SigneeField = ({label, children}) => {
    return <Form.Field>
        <label>{label}</label>
        {children}
    </Form.Field>;
}


export default compose(
    withUserData,
    withRouter,
)(VismaSignature);
