import crc32 from './crc32';

const localCache = {};

const defaultKeyFn = (...args) => JSON.stringify(args);

const fnCacheKey = fn => {
    const cacheKey = crc32(fn.toString());
    localCache[cacheKey] = localCache[cacheKey] || {};
    return cacheKey;
};

export function withLocalCache (fn, keyFn = defaultKeyFn) {
    const cacheKey = fnCacheKey(fn);
    return function (...args) {
        const argsChecksum = crc32(keyFn(...args));
        const lookup = localCache[cacheKey][argsChecksum];
        if (lookup !== undefined) {
            return lookup;
        }
        const result = fn(...args);
        localCache[cacheKey][argsChecksum] = result;
        return result;
    };
}

export function withLocalCacheAsync (fn, keyFn = defaultKeyFn) {
    const cacheKey = fnCacheKey(fn);
    return async function (...args) {
        const argsChecksum = crc32(keyFn(...args));
        const lookup = localCache[cacheKey][argsChecksum];
        if (lookup !== undefined) {
            return lookup;
        }
        const result = await fn(...args);
        localCache[cacheKey][argsChecksum] = result;
        return result;
    };
}

window.getLocalCache = () => {
    return localCache;
};