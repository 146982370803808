import React from 'react';
import { Segment} from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';
import { downloadExampleSIE } from './exampleSIE';

const ToolDescription = () => {
    return (
        <Segment>
            <p>
                Her kan du importere din kontoplan på Digital Revisor. Vi kræver at den kontoplan du ønsker at importere er af filformatet SIE.
            </p>
            <p>
                Vi ønsker dog også at din SIE-fil er af Type 1, 2, 3 and 4E.        
            </p>
            <p>
            <ColoredText
                content='Download eksempel'
                iconPosition='left'
                icon='download'
                color='green'
                onClick={() => downloadExampleSIE()}
                link
            />
            &nbsp;
            på SIE-fil som er klar til at blive importeret
            </p>
        </Segment>
    );
};

export default ToolDescription;