import React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import set from 'lodash.set';
import { Segment, Icon, Header, Loader, Message } from 'semantic-ui-react';
import { runActionRPC } from 'http/productEngine';
import { downloadText } from 'util/downloadBlob';
import { isAdmin, isAccountant, isAkademi } from 'util/userMethods';
import { FLOW_CONTEXT_TAG, STATUS_FACT_ID } from 'util/FactMapUtil';
import { isManualReviewNeeded } from 'util/ManualReview.js';
import withUserData from 'util/withUserData';
import ColoredText from 'design/atoms/ColoredText';
import PDFViewer from 'design/molecules/PDFViewer';
import VismaSignature from 'design/molecules/VismaSignature';
import Esignatur from 'design/molecules/Esignatur';
import { DocGenOptionName, DocGenVariantIXBRL, DocGenVariantPDF, DocGenVariantSignflow, TypeFlowContext } from 'design/pages/Product/FieldTypes/Fact';
import { statusesMap, userStatusesEnum } from 'design/molecules/AppLauncher/userStatuses';
import FieldGroup from '../../../components/FieldGroup';
import IXBRLViewer from './IXBRLViewer';

class DocumentGenerator extends React.Component {
    static propTypes = {
        isLocked: PropTypes.bool,
        value: PropTypes.shape({
            pdf: PropTypes.string.isRequired,
            filename: PropTypes.string,
            signFlow: PropTypes.object,
        }),
    };

    hasPDF = () => !!this.getRelevantPDF(this.props.value);

    getRelevantPDF = value => value?.signedPdf || value?.pdf;

    getRelevantInternalPDF = value => value?.signedInternalPdf || value?.internalPdf;

    getHash = value => value?.inputHash;

    signingBtnEnabled = (status) => {
        switch (status) {
            case userStatusesEnum.SIGNATURE_FLOW:
            case userStatusesEnum.VISMA_SIGNATURE_FLOW:
            case userStatusesEnum.ESIGNATUR_FLOW:
            case userStatusesEnum.COMPLETED:
            case userStatusesEnum.DONE:
            case userStatusesEnum.REPORTED:
                return false
            default:
               return true;
        }
    };

    userIsAdminOrAccountant = () => {
        const { userData } = this.props;

        return isAccountant(userData) || isAdmin(userData);
    };

    showInternalPDF = () => {    
        if (!this.getRelevantInternalPDF(this.props.value)) {
            return false;
        }

        return this.userIsAdminOrAccountant();
    };

    onSendToSigning = async ({ signees, notificationMedia, includeInternalReport, attachedSections, attachedDocuments, attachedSecondaryDocumentIndices }) => {
        // run doc-gen action w/ "startSignatureFlow" flag
        await this.props.runAction({
            startSignatureFlow: true,
            flowOptions: {
                signees,
                notificationMedia,
                includeInternalReport,
                attachedSections,
                attachedDocuments,
                attachedSecondaryDocumentIndices,
            },
        });
    };

    getFlowContextFact = () => {
        const { values } = this.props;
        return Object.values(values).find(x => x.tag === FLOW_CONTEXT_TAG); 
    };

    isFlowContextNodePresent = () => {
        const { productMetadata } = this.props;
        return Object.values(productMetadata.facts).find(x => x.dataType === TypeFlowContext);
    };

    updateFlowContext = (overwrite) => {
        if (!overwrite) {
            return;
        }

        const { onChange } = this.props;
        const flowContextNode = this.getFlowContextFact();
        if(!flowContextNode) {
            return;
        }

        const updatedFlowContext = { ...(flowContextNode.value || {}) };
        if (overwrite instanceof Function) {
            overwrite(updatedFlowContext);
        } else {
            for (const [propertyPath, value] of Object.entries(overwrite)) {
                set(updatedFlowContext, propertyPath, value);
            }
        }

        onChange(flowContextNode.id, updatedFlowContext, { run: true });
    };

    getAssemblyDateFactID = () => {
        return this.props.options?.assemblyDateOverwriteFactID;
    };

    canOverwriteAssemblyDate = () => {
        return !!this.getAssemblyDateFactID();
    };

    getAssemblyDate = () => {
        const { values } = this.props;
        const dateLookup = values[this.getAssemblyDateFactID()];
        return dateLookup?.value?.date;
    };

    getYearReportData = () => {
        const { values, options, productMetadata } = this.props;
        const { facts, actions } = productMetadata;
        const { yearReportTriggerFact } = options;
        
        try {
            const triggerFact = facts[yearReportTriggerFact];
            const [triggerFactSupply] = triggerFact.supply;
            const yearReportDataAction = actions[triggerFactSupply];
            const yearReportDataFact = facts[yearReportDataAction.supply];
            return {
                id: yearReportDataFact.id,
                data: values[yearReportDataFact.id].value.yearReportData,
            };
        } catch {
            return null;
        }
    };

    downloadXBRLFiles = async () => {
        const { productMetadata, id, productId, productLabel, userData } = this.props;

        const productFact = productMetadata.facts[id];
        const xbrlActionID = productFact.supply.find(supplyID => {
            const action = productMetadata.actions[supplyID];
            if (!action) {
                return false;
            }

            return action.func === 'XBRLReport';
        });

        if (!xbrlActionID) {
            toast.error('XBRL-dokumenter kunne ikke genereres');
            return;
        }

        const xbrlFiles = await runActionRPC(productId, productLabel, xbrlActionID, 'generateXBRLDocuments');

        const fileFriendlyUserName = [...userData.displayName].filter(char => {
            const lowerCase = char.toLowerCase();
            const isChar = lowerCase >= 'a' && lowerCase <= 'z';
            const isDigit = lowerCase >= '0' && lowerCase <= '9';
            const isDanishVowel = ['æ', 'ø', 'å'].includes(lowerCase);
            return isChar || isDigit || isDanishVowel;
        }).join('');

        downloadText(`${fileFriendlyUserName}_xbrl.xml`, 'application/xml', xbrlFiles.xbrl);

        if (xbrlFiles.supplementaryXbrl) {
            downloadText(`${fileFriendlyUserName}_supplerende_xbrl.xml`, 'application/xml', xbrlFiles.supplementaryXbrl);
        }
    };

    canReportBeSetAsSigned = () => {
        const { values } = this.props;

        const statusValue = values?.STATUS?.value?.string;
        const status = statusesMap[statusValue];
        if (!status) {
            return false;
        }

        const canSign = status.step < statusesMap.esignatur_flow.step;
        if (!canSign) {
            return false;
        }

        return true;
    };

    setReportAsSigned = async () => {
        if (!this.canReportBeSetAsSigned()) {
            return;
        }

        await this.props.onChange(
            STATUS_FACT_ID,
            { string: userStatusesEnum.DONE },
            { shouldArtificiallySetSignFlowAsDone: true},
        );
    };

    setAssemblyDate = async date => {
        const { onChange } = this.props;
        const factID = this.getAssemblyDateFactID();
        if (!factID) {
            return;
        }

        const isodate = (
            new Date(date + (1000 * 60 * 60 * 2))
            .toISOString()
            .substring(0, 10)
        );
        
        // update assembly date overwite fact...
        await onChange(factID, {
            date: isodate,
        });

        // ...and trigger PDF regeneration
        this.regenerateDocument();
    };

    regenerateDocument = (updateContext = {}) => {
        const { onChange, options } = this.props;
        if (options && options.yearReportTriggerFact) {
            const timeOfUpdate = { number: Date.now() };
            onChange(options.yearReportTriggerFact, timeOfUpdate, { run: true, ...updateContext });
        }
    };

    renderEsignatur = () => {
        const {
            isLocked,
            isTeaser,
            isRunning,
            productId,
            productStatus,
            value,
            values,
            hasPayed,
            reloadProductData,
            userData : user,
        } = this.props;
        
        if (!hasPayed) {
            return null;
        }

        if (!value) {
            return (
                <span>
                    Din årsrapport er ikke klar til at blive underskrevet
                </span>
            );
        }


        if (isTeaser) {
            return (
                <span>
                    Indberetningsperioden er ikke startet endnu, og du kan derfor ikke sende din årsrapport til underskrift
                </span>
            );
        };

        const guards = [
            {
                acceptanceCondition: () => !isRunning,
            },
            {
                acceptanceCondition: () => !isLocked,
            },
            {
                acceptanceCondition: () => value && value.isReportable,
                errorMsg: `Kvalitetskontrollen indeholder uløste fejl`,
            },
            {
                acceptanceCondition: () => !isManualReviewNeeded(values),
                errorMsg: `Din kontoplan er ikke blevet godkendt endnu`,
            },
            {
                acceptanceCondition: () => !isAkademi(user),
                errorMsg: `Akademi bruger er ikke tilladt at underskrive rapporten`,
            },
            {
                acceptanceCondition: () => !this.getFlowContextFact()?.value?.forceWatermark,
                errorMsg: `Slet vandmærket før du sender til underskrift`,
            },
            {
                acceptanceCondition: () => {
                    const status = (values || {})[STATUS_FACT_ID]?.value?.string;
                    return this.signingBtnEnabled(status)
                },
            },
        ];

        const failedGuards = guards.filter(guard => !guard.acceptanceCondition());
        const sendToSigningDisabled = failedGuards.length > 0; 
        const failedGuard = sendToSigningDisabled ? failedGuards[0] : null; // If some guards failed, take the first one that failed
        const signInternalReport = this.showInternalPDF();

        let defaultSendInternalReportToSigning = false;
        let defaultSendTaxbookletToSigning = false;
        if (isAccountant(user)) {
            Object.values(this.props.values).forEach(fact => {
                if (fact.tag === 'TypeOfAuditorAssistance_Intern' && fact.value.enumString && fact.value.enumString !== 'Intet') {
                    defaultSendInternalReportToSigning = true;
                }

                if (fact.tag === 'TypeOfAuditorAssistance_Skat' && fact.value.enumString && fact.value.enumString !== 'Intet') {
                    defaultSendTaxbookletToSigning = true;
                }
            });
        }

        return (
            <Esignatur
                disabled={sendToSigningDisabled} 
                error={failedGuard?.errorMsg}
                onSubmit={this.onSendToSigning}
                pdfId={this.getRelevantPDF(value)}
                internalPdfId={signInternalReport && this.getRelevantInternalPDF(value)}
                productId={productId}
                productStatus={productStatus}
                esignaturSignFlowId={value?.esignaturSignFlow}
                secondaryDocuments={value?.secondaryDocuments || []}
                reloadProductData={reloadProductData}
                defaultSendInternalReportToSigning={defaultSendInternalReportToSigning}
                defaultSendTaxbookletToSigning={defaultSendTaxbookletToSigning}
            />
        );
    };

    renderSignature = () => {
        const {
            isLocked,
            isTeaser,
            isRunning,
            productId,
            productStatus,
            value,
            values,
            hasPayed,
            paymentURL,
            reloadProductData,
            userData: user,
        } = this.props;

        if (!hasPayed) {
            return (
                <span>
                    Ved køb af produktet kan du sende årsrapporten til underskrift.
                    <br />
                    <p>
                        <ColoredText
                            link
                            color='green'
                            content='Køb fuld adgang'
                            icon='unlock'
                            iconPosition='left'
                            onClick={() => this.props.history.push(paymentURL)}
                        />
                    </p>
                </span>
            );
        }

        if (!value) {
            return (
                <span>
                    Din årsrapport er ikke klar til at blive underskrevet
                </span>
            );
        }

        if (isTeaser) {
            return (
                <span>
                    Indberetningsperioden er ikke startet endnu, og du kan derfor ikke sende din årsrapport til underskrift
                </span>
            );
        };        

        const guards = [
            {
                acceptanceCondition: () => !isRunning,
            },
            {
                acceptanceCondition: () => !isLocked,
            },
            {
                acceptanceCondition: () => value && value.isReportable,
                errorMsg: `Kvalitetskontrollen indeholder uløste fejl`,
            },
            {
                acceptanceCondition: () => !isManualReviewNeeded(values),
                errorMsg: `Din kontoplan er ikke blevet godkendt endnu`,
            },
            {
                acceptanceCondition: () => !isAkademi(user),
                errorMsg: `Akademi bruger er ikke tilladt at underskrive rapporten`,
            },
            {
                acceptanceCondition: () => !this.getFlowContextFact()?.value?.forceWatermark,
                errorMsg: `Slet vandmærket før du sender til underskrift`,
            },
            {
                acceptanceCondition: () => {
                    const status = (values || {})[STATUS_FACT_ID]?.value?.string;
                    return this.signingBtnEnabled(status)
                },
            },
        ];

        const failedGuards = guards.filter(guard => !guard.acceptanceCondition());
        const sendToSigningDisabled = failedGuards.length > 0; 
        const failedGuard = sendToSigningDisabled ? failedGuards[0] : null; // If some guards failed, take the first one that failed

        const signInternalReport = this.showInternalPDF();
        return (
            <VismaSignature
                disabled={sendToSigningDisabled} 
                error={failedGuard?.errorMsg}
                onSubmit={this.onSendToSigning}
                pdfId={this.getRelevantPDF(value)}
                internalPdfId={signInternalReport && this.getRelevantInternalPDF(value)}
                productId={productId}
                productStatus={productStatus}
                signFlow={value?.signFlow}
                internalSignFlow={value?.internalSignFlow}
                reloadProductData={reloadProductData}
            />
        );
    };

    renderPaymentReminder = () => {
        const { hasPayed, paymentURL, userData } = this.props;

        // not relevant if admin
        if (isAdmin(userData)) {
            return null;
        }

        // not relevant if paid
        if (hasPayed) {
            return null;
        }

        return (
            <Segment
                content={
                    <p>
                        Nedenfor ser du et udkast til din årsrapport, hvor balancetallene er låst.
                        Ved køb af produktet låser vi op for tallene og skaber en indberetningsklar årsrapport til dig,
                        som du kan underskrive digitalt.
                        <br />
                        <ColoredText
                            link
                            icon='unlock'
                            iconPosition='left'
                            color='green'
                            content='Lås op for årsrapport nu'
                            onClick={() => this.props.history.push(paymentURL)}
                        />
                    </p>
                }
            />
        );
    };

    renderContent = () => {
        const { value, isLocked, hasPayed, updating, userData: user } = this.props;

        if (updating && !this.hasPDF()) {
            return (
                <Segment textAlign='center'>
                    <Loader inline active size='huge' />
                    <br />
                    Genererer PDF...
                </Segment>
            );
        }

        if (!this.hasPDF()) {
            return (
                <Segment textAlign='center'>
                    <Icon
                        color='grey'
                        name='file pdf outline'
                        size='huge'
                    />
                    <Header
                        color='grey'
                        content='Intet dokument'
                    />
                    Der mangler noget data før årsrapporten kan dannes. Har du udfyldt alle felter?
                </Segment>
            );
        }

        const yearReportData = this.getYearReportData();

        return (
            <>
                {this.renderPaymentReminder()}
                <PDFViewer
                    productID={this.props.productId}
                    productLabel={this.props.productLabel}
                    pdfId={this.getRelevantPDF(value)}
                    primaryLanguage={this.props.value?.primaryLanguage}
                    secondaryDocuments={this.props.value?.secondaryDocuments}
                    internalPdfId={this.getRelevantInternalPDF(value)}
                    showInternalPDF={this.showInternalPDF()}
                    showSecondaryDocuments={this.userIsAdminOrAccountant()}
                    primaryPdfDocumentComponentsFileID={value.primaryPdfDocumentComponentsFileID}
                    internalPdfDocumentComponentsFileID={value.internalPdfDocumentComponentsFileID}
                    pdfHash={this.getHash(value)}
                    taxYear={this.props.taxYear}
                    updating={updating}
                    isLocked={isLocked}
                    regeneratePDFDocument={this.regenerateDocument}
                    isFlowContextNodePresent={this.isFlowContextNodePresent()}
                    flowContext={this.getFlowContextFact()?.value}
                    updateFlowContext={this.updateFlowContext}
                    canOverwriteAssemblyDate={this.canOverwriteAssemblyDate()}
                    assemblyDate={this.getAssemblyDate()}
                    yearReportDataNodeID={yearReportData.id}
                    yearReportData={yearReportData.data}
                    setAssemblyDate={this.setAssemblyDate}
                    downloadDisabled={!hasPayed || isAkademi(user)}
                    downloadDisabledMessage={!hasPayed ? 'Køb produktet for at downloade den fulde PDF' : null}
                    date={this.props.value.updatedAt}
                    isFirstYear={this.props.currentPeriod?.isFirstYear}
                    downloadXBRLFiles={this.downloadXBRLFiles}
                    canReportBeSetAsSigned={this.canReportBeSetAsSigned}
                    setReportAsSigned={this.setReportAsSigned}
                />
            </>
        );
    };

    render() {
        const { value, isRunning, fieldOptions, updating, userData } = this.props;
        const widgetVariant = fieldOptions[DocGenOptionName];

        switch (widgetVariant) {
            case DocGenVariantPDF: {
                return (
                    <FieldGroup title='Årsrapport' collapsible defaultOpen={true} unwrapped>
                        {this.renderContent()}
                    </FieldGroup>
                );
            }

            case DocGenVariantIXBRL: {
                return (
                    <IXBRLViewer
                        yearReportData={this.getYearReportData()}
                        regenerateDocument={this.regenerateDocument}
                        {...this.props}
                    />
                );
            }

            case DocGenVariantSignflow: {
                if (updating && !isRunning) {
                    return null;
                }
    
                if (value) {
                    if (value?.signFlow && value?.signFlow?.flowStatus) {
                        if (value?.signFlow?.flowStatus === 2 || value?.signFlow?.flowStatus === 3 ) {
                            return this.renderSignature();  
                        }
                    }
                }
                return this.renderEsignatur();
            }

            default: {
                return (
                    <Segment color='red'>
                        <Icon name='warning sign' color='red' /> <b>Der opstod en fejl</b>
                        {userData.isAdmin() && <span>:&nbsp;Du har ikke valgt en doc-gen type i modellen</span>}
                    </Segment>
                );
            }
        }
    }
}

export default compose(
    withUserData,
    withRouter,
)(DocumentGenerator);
