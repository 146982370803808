export function formatNumber(num) {
    if (isNaN(num)) {
        return num;
    }
    try {
        num = Math.trunc(num);
        const digits = (num + '').split('').reverse();
        let res = '';
        for (var i = 0; i < digits.length; ++i) {
            res = digits[i] + res;
            if (i % 3 === 2 && i < digits.length - 1) {
                res = "." + res;
            }
        }
        if (res.startsWith('-.')) {
            res = res.replace('-.', '-')
        }
        return res;
    } catch (err) {
        return num;
    }
}

export function formatDecimalNumber(num) {
    if (isNaN(num)) {
        return num;
    }
    try {
        const parts = num.toString().split('.');
        const integerPart = parts[0];
        let decimalPart = parts.length > 1 ? ',' + parts[1] : ',00';
        
        if (decimalPart.length === 2) {
            decimalPart += '0';
        } else if (decimalPart.length > 3) {
            decimalPart = ',' + decimalPart.slice(1, 3);
        }

        const digits = integerPart.split('').reverse();
        let res = '';
        for (let i = 0; i < digits.length; ++i) {
            res = digits[i] + res;
            if (i % 3 === 2 && i < digits.length - 1) {
                res = "." + res;
            }
        }
        if (res.startsWith('-.')) {
            res = res.replace('-.', '-');
        }
        return res + decimalPart;
    } catch (err) {
        return num;
    }
}