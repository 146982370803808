import React from 'react';
import { Segment } from 'semantic-ui-react';
import { useBreakpoints } from 'design/atoms/Media';

const ProductSegment = ({ children }) => {
    const breakpoints = useBreakpoints();

    return (
        <Segment
            children={children}
            {...breakpoints.select({
                'default': {},
                'eq mobile': {
                    basic: true,
                    style: { padding: 0 },
                },
            })}
        />
    );
};

export default ProductSegment;