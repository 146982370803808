import React from 'react';

import { HintTypes } from "./HintTypes";
import { HintBasic, HintNodeData, HintVideo, HintVideosErp } from "./hints";

import style from './Hint.module.scss';

const Hint = props => {
    const {
        hint,
        hasPaid,
        factsBeingUpdated,
        productMetadata,
        productData,
        sectionFieldWrappers,
        customHintProps
    } = props;

    const renderHintComponent = () => {
        let HintComponent = null;
        switch(hint.type) {
            case HintTypes.BASIC: {
                HintComponent = HintBasic;
                break;
            }
            case HintTypes.NODE_DATA: {
                HintComponent = HintNodeData;
                break;
            }
            case HintTypes.VIDEO: {
                HintComponent = HintVideo;
                break;
            }
            case HintTypes.VIDEOS_ERP: {
                HintComponent = HintVideosErp;
                break;
            }
            default: {}
        }

        return <HintComponent
            hint={hint}
            hasPaid={hasPaid}
            factsBeingUpdated={factsBeingUpdated}
            productMetadata={productMetadata}
            productData={productData}
            sectionFieldWrappers={sectionFieldWrappers}
            customHintProps={customHintProps}
        />
    };

    // ==================================================
    
    return (
        <div className={style.hint} >
            {renderHintComponent()}
        </div>
    );
};

export default Hint;
