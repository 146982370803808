import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';
import * as pubsub from './pubsub';

const PUBSUB_TOPIC = 'SYSTEM_UPDATE_STATUS';

let isSystemUpdateOngoing = false;

export function setStatus(newValue) {
    isSystemUpdateOngoing = newValue;
    pubsub.publish(PUBSUB_TOPIC, newValue);
}

export function getStatus() {
    return isSystemUpdateOngoing;
}

export function subscribe(callback) {
    return pubsub.subscribe(PUBSUB_TOPIC, callback);
}

export function unsubscribe(subscriptionToken) {
    pubsub.unsubscribe(subscriptionToken);
}

export function renderMessage() {
    return (
        <Container>
            <br />
            <Segment>
                <Header icon='cog' content='Siden er under opdatering' />
                <p>
                    Siden er desværre ikke tilgængelig lige nu da vi er ved at installere opdateringer.
                    Vend tilbage om lidt!
                </p>
            </Segment>
        </Container>
    );
}
