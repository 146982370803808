import { documentComponentTypes } from '@digital-revisor/node-report-editor-model';
// import styles from './ReportEditor.module.scss';

const getMarginAsStyleProps = ([left, top, right, bottom]) => {
    return {
        marginLeft:   left   || 0,
        marginTop:    top    || 0,
        marginRight:  right  || 0,
        marginBottom: bottom || 0,
    };
};

const makeElement = (tag, { children, style, ...other } = {}) => {
    const out = {
        tag,
        style: {},
        children: [],
    };

    Object.entries(style || {}).forEach(([key, val]) => {
        out.style[key] = val;
    })

    Object.entries(other).forEach(([key, val]) => {
        out[key] = val;
    });

    if (children) {
        if (!Array.isArray(children)) {
            children = [children];
        }

        for (let child of children) {
            child && out.children.push(child);
        }
    }

    return out;
};

const convertSection = ({ data }) => {
    if (data.hidden) {
        return makeElement('div', {
            style: {
                fontStyle: 'italic',
            },
            innerText: 'Sektionen er skjult og vil ikke indgå i den endelige rapport',
        });
    }

    return makeElement('div', {
        children: data.children.map(element => convertDocumentComponentToHTML(element)),
    });
};

const convertImage = ({ data }) => {
    if (!data.imageData) {
        return makeElement('div');
    }
    const height = data.height ? `${data.height}px` : '150px';
    return makeElement('div', {
        // overrideOptions,
        // className: overrideOptions ? styles.editable : null,
        style: {
            textAlign: data.position,
            position: 'relative',
            height,
        },
        children: [makeElement('img', {
            src: data.imageData,
            style: {
                height,
            },
        })],
    });
};

const convertContainer = ({ data }) => {
    return makeElement('div', {
        style: {
            fontWeight: data.bold ? 'bold' : 'inherit', 
            fontSize: data.fontSize || 12,
            textAlign: data.textAlign,
            ...getMarginAsStyleProps(data.margin),
        },
        children: data.children.map(element => convertDocumentComponentToHTML(element)),
    });
};

const convertText = ({ data }) => {
    const rawText = (
        typeof data.text === 'object'
            ? data.text?.text
            : data.text
    );
    
    const byNewline = rawText?.split('\n') || [];
    const compiledText = [];
    byNewline.forEach((line, idx) => {
        const isLast = idx === byNewline.length - 1;

        compiledText.push(makeElement('span', {
            innerText: line,
        }));

        if (!isLast) {
            compiledText.push(makeElement('br'));
        }
    });
    
    return makeElement('div', {
        children: makeElement('span', {
            children: compiledText,
            style: {
                position: 'relative',
            },
        }),
        // className: (overrideOptions?.hasOverrideOptions) ? styles.editable : undefined,
        // title: (overrideOptions?.hasOverrideOptions) ? 'Redigér' : undefined,
        style: {
            fontWeight: data.bold ? 'bold' : 'inherit', 
            fontSize: data.fontSize || 12,
            textAlign: data.textAlign,
            ...getMarginAsStyleProps(data.margin),
            ...(rawText.trim().length === 0 && rawText.length !== 0 ? { whiteSpace: 'pre' } : {}),
        },
    });
};

const convertDivider = ({ data }) => {
    return makeElement('div', {
        style: {
            ...getMarginAsStyleProps(data.margin),
        },
        children: makeElement('div', {
            style: {
                height: '1px',
                background: data.color,
            },
        }),
    });
};

const convertTableOfContents = () => {
    return makeElement('div', {
        style: {
            fontStyle: 'italic',
        },
        innerText: 'Indholdsfortegnelsen opdateres når du gemmer rapporten...',
    });
};

const convertGrid = ({ data }) => {
    const widths = data.widths || [];
    if (widths.length === 0) {
        const widthPct = 100 / data.columnsPerRow;
        while (widths.length < data.columnsPerRow) {
            widths.push(widthPct + '%');
        }
    }

    return makeElement('div', {
        style: {
            display: 'flex',
            alignItems: 'top',
            flexWrap: 'wrap',
        },
        children: data.columns.map((element, idx) => makeElement('div', {
            style: {
                width: widths[idx % data.columnsPerRow],
            },
            children: convertDocumentComponentToHTML(element),
        })),
    });
};

const convertDocumentComponentToHTML = (element) => {
    if (!element) {
        return;
    }

    const elementConverter = {
        [documentComponentTypes.section.id]:         convertSection,
        [documentComponentTypes.image.id]:           convertImage,
        [documentComponentTypes.container.id]:       convertContainer,
        [documentComponentTypes.text.id]:            convertText,
        [documentComponentTypes.divider.id]:         convertDivider,
        [documentComponentTypes.tableOfContents.id]: convertTableOfContents,
        [documentComponentTypes.grid.id]:            convertGrid,
    }[element.kind];

    if (!elementConverter) {
        return;
    }

    return {
        ...elementConverter(element),
        element,
    };
};

const createMarkdownFromSection = section => {
    const WIDTH_A4 = 595.28;
    const HEIGHT_A4 = 841.89;

    return makeElement('div', {
        style: {
            width: `${WIDTH_A4}px`,
            height: `${HEIGHT_A4}px`,
            overflowY: 'overlay',
        },
        children: makeElement('div', {
            style: getMarginAsStyleProps([60, 60, 60, 60]),
            children: convertDocumentComponentToHTML(section),
        }),
    });
};

export default createMarkdownFromSection;