import React from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import { publish } from 'util/pubsub';

import styles from './UiElementGroupToggler.module.scss';

export const TOGGLE_ALL_FIELD_GROUPS = 'TOGGLE_ALL_FIELD_GROUPS';

const ToggleChoice = ({ icon, content, onClick }) => {
    return (
        <Segment
            textAlign='center'
            style={{ padding: '0.5em' }}
            className={styles.item}
            onClick={onClick}
        >
            <Icon name={icon} />
            {content}
        </Segment>
    );
};

function GroupToggler() {
    return (
        <Segment.Group horizontal>
            <ToggleChoice
                icon='folder open'
                content='Fold alle ud'
                onClick={() => publish(TOGGLE_ALL_FIELD_GROUPS, true)}
            />
            <ToggleChoice
                icon='folder'
                content='Luk alle'
                onClick={() => publish(TOGGLE_ALL_FIELD_GROUPS, false)}
            />
        </Segment.Group>
    );
}

export default GroupToggler;
