import downloadBlob from 'util/downloadBlob';

const rawCSV = `Kontonummer;Kontonavn;Beløb i år;Beløb sidste år;Kontotype;Momskode
1010;Salg af varer/ydelser m/moms;-6.534.600,00;-5.300.000,00;Drift;None
1020;Salg af varer til udland;0;0;Drift;None
1021;Salg af ydelser til udland;0;0;Drift;None
1025;Salg af varer til EU;0;0;Drift;None
1026;Salg af ydelser til EU;0;0;Drift;None
1030;Salg til kunder u/moms;0;0;Drift;None
1031;HS tilskud;0;0;Drift;None
1040;Salg, Sweetdeal;0;0;Drift;None
1090;Igangværende arbejder, ultimo;-50.000,00;0;Drift;None
1310;Varekøb m/moms;165.000,00;124.825,00;Drift;None
1320;Direkte omkostninger varer u/moms indenfor EU;2.000.000,00;1.800.000,00;Drift;None
1321;Direkte omkostninger ydelser u/moms indenfor EU;0;0;Drift;None
1325;Direkte omkostninger u/moms 3. lande;0;0;Drift;None
1326;Lønninger, afsluttede projekter (projekt);0;0;Drift;None
1327;Omkostninger, afsluttede projekter (projekt);0;0;Drift;None
1330;Vareforbrug (lagermodul);0;0;Drift;None
1340;Fremmed assistance;0;0;Drift;None
1360;Viderefaktureret p-afgifter;3.000,00;3.000,00;Drift;None
1370;Varekøb;0;0;Drift;None
2210;Lønninger;1.000.000,00;1.000.000,00;Drift;None
2211;Feriepenge & SH;80.000,00;80.000,00;Drift;None
2212;Sygedagpenge mv.;80.000,00;80.000,00;Drift;None
2213;B-honorar;0;0;Drift;None
2214;Personalegoder og multimedier;-32.000,00;-32.000,00;Drift;None
2215;Pensioner, arbejdsgiver;200.000,00;200.000,00;Drift;None
2218;Lønrefusion;-80.000,00;-80.000,00;Drift;None
2222;AER (Samlet betaling);60.000,00;60.000,00;Drift;None
2223;ATP;58.000,00;30.000,00;Drift;None
2224;DA-barsel;0;0;Drift;None
2225;Sygedagpengeforsikring;14.000,00;14.000,00;Drift;None
2230;KM-penge;26.700,00;26.700,00;Drift;None
2241;Personaleudgifter;55.000,00;55.550,00;Drift;None
2242;Projektførte lønninger (projekt);0;0;Drift;None
2243;Kursusudgifter;0;0;Drift;None
2250;Arbejdstøj;14.000,00;14.690,00;Drift;None
2260;Personalemøde;0;0;Drift;None
2280;Feriepengeforpligtelse, reg.;10.000,00;25.000,00;Drift;None
2750;Restaurationsbesøg;0;0;Drift;None
2754;Gaver og blomster;1.200,00;1.200,00;Drift;None
2770;Rejseudgifter;0;0;Drift;None
2775;Rejseudgifter m/moms, parkering;0;0;Drift;None
2800;Annoncer og reklame;63.700,00;63.700,00;Drift;None
2805;Sponsorater;33.600,00;33.600,00;Drift;None
2811;Fragt m/moms;0;0;Drift;None
3010;Brændstof, personbil;3.000,00;2.100,00;Drift;None
3020;Bilforsikring, personbil;3.500,00;3.500,00;Drift;None
3030;Vægtafgift, personbil;3.200,00;2.740,00;Drift;None
3040;Reparation/vedligeholdelse, personbil;60.000,00;60.000,00;Drift;None
3110;Brændstof, varebiler;88.000,00;80.000,00;Drift;None
3120;Bilforsikring, varebiler;12.000,00;12.000,00;Drift;None
3130;Vægtafgift, varebiler;61.000,00;61.000,00;Drift;None
3140;Reparation/vedligeholdelse, varebiler;107.000,00;107.000,00;Drift;None
3150;Leasingydelse, varebiler;200.000,00;200.000,00;Drift;None
3410;Husleje m/moms;111.000,00;101.040,00;Drift;None
3411;Husleje u/moms;0;0;Drift;None
3412;Varme;0;0;Drift;None
3420;El, vand og gas;37.000,00;27.000,00;Drift;None
3430;Vedligeholdelse og rengøring;30.000,00;29.000,00;Drift;None
3450;Forsikringer (lokale);0;0;Drift;None
3460;Alarm m/moms;14.000,00;14.450,00;Drift;None
3600;Kontorartikler og tryksager;13.000,00;13.000,00;Drift;None
3601;Avishold;0;0;Drift;None
3604;Edb-udgifter / software;47.000,00;43.000,00;Drift;None
3610;Rep./vedligeholdelse af inventar;35.000,00;39.900,00;Drift;None
3617;Mindre anskaffelser;45.000,00;44.000,00;Drift;None
3620;Telefon;13.000,00;12.000,00;Drift;None
3621;Internetforbindelse;0;0;Drift;None
3628;Porto og gebyrer;3.000,00;3.925,00;Drift;None
3640;Revisor;22.000,00;22.950,00;Drift;None
3645;Advokat;166.000,00;166.000,00;Drift;None
3650;Forsikringer;89.000,00;89.000,00;Drift;None
3660;Faglitteratur m/moms;0;0;Drift;None
3661;Faglitteratur u/moms;0;0;Drift;None
3662;Kontingenter m/moms;47.000,00;49.950,00;Drift;None
3663;Kontingenter u/moms;500;750;Drift;None
3664;Web-hotel og domænenavne;200;221;Drift;None
3670;Leje/leasing af driftsmidler;2.500,00;2.500,00;Drift;None
3720;Tab på debitorer, hensat;15.000,00;30.000,00;Drift;None
3721;Tab på debitorer, konstateret;10.000,00;0;Drift;None
3770;Kassedifferencer mv.;0;0;Drift;None
3780;Ej fradragsberettigede omkostninger;2.500,00;0;Drift;None
3910;Afskrivning, indretning;25.000,00;25.000,00;Drift;None
3940;Afskrivning, driftsmidler og inventar;80.000,00;80.000,00;Drift;None
3950;Afskrivning, personbil;0;0;Drift;None
3970;Tab/avance ved salg af driftsmidler;-35.000,00;0;Drift;None
4310;Renteindtægt, bank;0;0;Drift;None
4320;Renter, mellemregning;9.000,00;9.419,00;Drift;None
4360;Renteindtægt, debitorer;0;0;Drift;None
4365;Rykkergebyr, debitorer;-9.000,00;-9.300,00;Drift;None
4380;Valutakursdifference, debitorer gevinst;0;0;Drift;None
4381;Valutakursdifference, kreditorer gevinst;0;0;Drift;None
4410;Renteudgift, bank;7.000,00;7.065,00;Drift;None
4420;Renter Jyske Finans;0;0;Drift;None
4430;Rente AL Finans;0;0;Drift;None
4440;Rente Santander;4.000,00;4.000,00;Drift;None
4450;Låneomkostninger;5.200,00;5.225,00;Drift;None
4451;Garantiprovisioner;0;0;Drift;None
4460;Renteudgift, kreditorer;2.300,00;2.300,00;Drift;None
4465;Renteudgift, Told & Skat;1.000,00;1.000,00;Drift;None
4480;Valutakursdifference, debitorer tab;0;0;Drift;None
4481;Valutakursdifference, kreditorer tab;0;0;Drift;None
4610;Ekstraordinære indtægter m/moms;0;0;Drift;None
4620;Resultat af kapitalandele;15.000,00;-120.000,00;Drift;None
4630;Ekstraordinære udgifter m/moms;0;0;Drift;None
4640;Ekstraordinære udgifter u/moms;0;0;Drift;None
4810;Skat af årets resultat;337.942,00;73.722,00;Drift;None
4820;Regulering udskudt skat;74.675,00;202.525,00;Drift;None
4971;Ekstraordinære udgifter;0;0;Drift;None
4972;Ekstraordinære udgifter;0;0;Drift;None
4973;Extraordinære udgifter;0;0;Drift;None
4974;Extraordinære udgifter;0;0;Drift;None
5111;Indretning, anskaffelse primo;156.000,00;156.000,00;Balance;None
5112;Indretning, årets tilgang;0;0;Balance;None
5113;Indretning, årets afgang;0;0;Balance;None
5116;Indretning, afskrivning primo;-87.400,00;-62.400,00;Balance;None
5117;Indretning, årets afskrivninger;-25.000,00;-25.000,00;Balance;None
5221;Driftsmidler, anskaffelse primo;1.142.889,00;879.889,00;Balance;None
5222;Driftsmidler, årets tilgang;0;263.000,00;Balance;None
5223;Driftsmidler, årets afgang;0;0;Balance;None
5226;Driftsmidler, afskrivning primo;-178.419,00;-98.419,00;Balance;None
5227;Driftsmidler, årets afskrivninger;-80.000,00;-80.000,00;Balance;None
5228;Driftsmidler, afgang afskrivninger;0;0;Balance;None
5231;Tilknyttet, anskaffelse primo;40.000,00;0;Balance;None
5232;Tilknyttet, årets tilgang;0;40.000,00;Balance;None
5233;Tilknyttet, årets afgang;0;0;Balance;None
5236;Tilknyttet, overført res. primo;120.000,00;0;Balance;None
5237;Tilknyttet, årets resultat;-15.000,00;120.000,00;Balance;None
5312;Huslejedepositum m/moms;0;0;Balance;None
5313;Huslejedepositum u/moms;0;0;Balance;None
5322;Leasingdepositum;0;0;Balance;None
5520;Varelager;0;0;Balance;None
5600;Debitorer;345.000,00;340.000,00;Balance;None
5605;Hensat til tab på tilgodehavender;-75.000,00;-60.000,00;Balance;None
5610;Debitorer, HS Tilskud;0;0;Balance;None
5650;Andre tilgodehavender;44.500,00;49.148,00;Balance;None
5651;Tilgode sambeskatning;0;0;Balance;None
5654;Igangværende arbejder - aktiviteter (projekt);100.000,00;50.000,00;Balance;None
5655;Igangværende arbejder - omkostninger (projekt);0;0;Balance;None
5660;Forudbetalte poster;23.750,00;23.750,00;Balance;None
5665;Personaletilgodehavender;0;0;Balance;None
5666;Mellemregning 1;-15.000,00;-50.000,00;Balance;None
5670;Mellemregning 2;0;0;Balance;None
5675;Mellemregning 3;0;0;Balance;None
5810;Kassebeholdning;0;0;Balance;None
5811;Opsparing til moms;0;0;Balance;None
5820;Bankkonto;1.220.000,00;-15.000,00;Balance;None
5821;Andre tilgodehavender;51.000,00;51.000,00;Balance;None
5825;Andre tilgodehavender;0;0;Balance;None
5830;Bankkonto, XX;0;0;Balance;None
6100;Egenkapital;;;Overskrift;
6110;Registreret kapital;-40.000,00;-40.000,00;Balance;None
6111;Overkurs ved emission;0;0;Balance;None
6115;Foreslået udbytte;-180.000,00;-60.000,00;Balance;None
6120;Reserve for udvikling, primo;0;0;Balance;None
6121;Reserve for indre værdi;-105.000,00;-120.000,00;Balance;None
6130;Overført resultat tidligere år;-202.819,00;-96.066,00;Balance;None
6145;Ekstraordinært udbytte;90.000,00;0;Balance;None
6310;Hensættelse til udskudt skat;-209.675,00;-202.525,00;Balance;None
6610;Anden gæld;-2;0;Balance;None
6620;Anden gæld;-1;0;Balance;None
6630;Anden gæld;0;0;Balance;None
6690;Anden gæld;0;0;Balance;None
6750;Kassekredit;-40.500,00;-46.113,00;Balance;None
6800;Kreditorer;-28.000,00;-28.000,00;Balance;None
6830;Skyldige omkostninger;-12.000,00;-12.000,00;Balance;None
6835;Skyldige medarbejderudlæg;0;0;Balance;None
6850;Skyldig selskabsskat;-337.942,00;-73.722,00;Balance;None
6851;Skyldig sambeskatning;0;0;Balance;None
6860;Mellemregning med anpartshaver;0;0;Balance;None
6901;Skyldig moms, primo;-358.789,00;-278.754,00;Balance;None
6902;Udgående (salg) moms;-1.378.650,00;-1.325.000,00;Balance;None
6903;Indgående (køb) moms;1.021.122,00;734.965,00;Balance;None
6906;Erhvervelsesmoms (køb i udland);0;0;Balance;None
6907;Erhvervelsesmoms (modkonto);0;0;Balance;None
6910;Olieafgift;0;0;Balance;None
6911;Elafgift;0;0;Balance;None
6914;CO2-afgift;0;0;Balance;None
6917;Betalt moms;342.819,00;510.000,00;Balance;None
6920;Skyldig A-skat;0;0;Balance;None
6921;Skyldig ATP;-10.000,00;-10.000,00;Balance;None
6922;Skyldig pension;-38.000,00;-38.000,00;Balance;None
6923;Skyldige feriepenge & SH;-150.000,00;-150.000,00;Balance;None
6924;Skyldig DA-barsel;0;0;Balance;None
6930;Skyldig AM-bidrag;0;0;Balance;None
6940;Skyldige lønninger;0;0;Balance;None
6945;Andre skyldige løn poster;0;0;Balance;None
6946;Hensat feriepengeforpligtelse;-85.000,00;-75.000,00;Balance;None
9900;Analyse/fejlkonto;0;0;Balance;None`;

const csvBlob = new Blob(['\ufeff', rawCSV]);

export const downloadExampleCSV = () => downloadBlob(csvBlob, 'eksempel.csv');

export default csvBlob;