import { HTTPClient } from '../network/fetch';

const client = new HTTPClient({ prefix: 'internal-tasks' });

export function getHoldingTask(taxYear) {
    return client.getResource(`tasks/static/holding/${taxYear}`);
}

export function holdingTaskExists(taxYear) {
    return client.getResource(`tasks/static/holding/${taxYear}/exists`);
}

export function createHoldingTask(taxYear, files, link) {
    return client.postResource(`tasks/static/holding/${taxYear}`, {
        files,
        link,
    });
}

export function setHoldingFiles(taxYear, files = []) {
    return client.putResource(`tasks/static/holding/${taxYear}/files`, files);
}