import React from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './Stepper.module.scss';

const Stepper = ({ steps, height, offset, selectedStepIndex, vicinity = -1, showPointers = true, showIcons = true }) => {
    const getLeftmostStepIndex = () => (vicinity === -1) ? 0 : Math.max(0, selectedStepIndex - vicinity);
    const getRightmostStepIndex = () => (vicinity === -1) ? steps.length - 1 : Math.min(selectedStepIndex + vicinity, steps.length - 1);

    const getVisibleSteps = () => {
        let out = null;
        if(vicinity === -1) {
            out = steps;
        } else {
            const [leftmostStepIndex, rightmostStepIndex] = [
                getLeftmostStepIndex(),
                getRightmostStepIndex()
            ];
            out = steps.filter((_, i) => leftmostStepIndex <= i && i <= rightmostStepIndex);
        }
        return out;
    };

    const stepWidth = (100 - offset) / getVisibleSteps().length;
    const fakeStepWidth = offset / 2;
    const pointerHeight = height * 0.55;

    const renderStep = (step, i, isFake = false) => {
        const out = (
            <Step
                step={step}
                i={i}
                nSteps={steps.length}
                height={height}
                pointer={{ visible: showPointers, height: pointerHeight }}
                width={!isFake ? stepWidth : fakeStepWidth}
                selectedStepIndex={selectedStepIndex}
                showIcon={showIcons}
                isFake={isFake}
            />
        );
        return out;
    };

    const [leftFakeStep, rightFakeStep] = [
        renderStep(steps[getLeftmostStepIndex()], getLeftmostStepIndex(), true),
        renderStep(steps[getRightmostStepIndex()], getRightmostStepIndex(), true)
    ];

    return (
        <div className={styles['stepper-container']}>
            {leftFakeStep}
            {getVisibleSteps().map((step, i) => renderStep(step, getLeftmostStepIndex() + i))}
            {rightFakeStep}
        </div>
    );
};

/*
    The step element is logically divided into 2 parts, i.e.
      1. main part: The part that contains the icon and title
      2. pointer: A pointy end that a step can have serving the purpose of visually separating the steps from one another

    Two fake steps are also rendered and serve the purpose of offsetting the first and last steps from the sides of the containing parent element
*/
const Step = ({ step, i, nSteps, height, pointer, width, selectedStepIndex, showIcon, isFake }) => {
    const { icon, title, locked, isSelected, onSelected, bgColorCompleted, bgColorSelected, borderColor } = step;
    const { visible: pointerVisible, height: pointerHeight } = pointer;

    const shouldRenderIcon = () => showIcon;

    const shouldRenderPointer = i => pointerVisible && !isFake && i < nSteps - 1;
    
    /* <STYLE> */
    const getBackgroundColor = () => isSelected ? bgColorSelected : (i <= selectedStepIndex ? bgColorCompleted : 'transparent');

    const getZIndex = (n, i) => !isFake ? (2 * (n - i - 1) + 1) : 0;

    const stepStyle = {
        height: `${height}px`,
        minWidth: `${width}%`
    };

    const mainPartStyle = {
        backgroundColor: getBackgroundColor(),
        borderColor: (i <= selectedStepIndex) ? borderColor : '',
        paddingLeft: pointerVisible ? `10px` : '0',
        zIndex: getZIndex(nSteps, i)
    };
    
    const pointerStyle = {
        backgroundColor: getBackgroundColor(),
        borderColor: (i === selectedStepIndex) ? borderColor : '',
        height: `${pointerHeight}px`,
        width: `${pointerHeight}px`,
        zIndex: getZIndex(nSteps, i) - 1
    };
    /* </STYLE> */

    let iconToShow;
    if (shouldRenderIcon()) {
        iconToShow = (
            <span style={{ marginRight: '0.25em' }}>
                {React.isValidElement(icon) ? icon : (
                    <Icon
                        name={locked ? 'lock' : icon}
                        color={locked ? 'yellow' : 'black'}
                    />
                )}
            </span>
        );
    }

    return (
        <div className={styles['step']} style={stepStyle}>
            <div className={styles['step-main-part']} style={mainPartStyle} onClick={onSelected}>
                {
                    !isFake && (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {iconToShow}
                            {title}
                        </div>
                    )
                }
            </div>
            {
                shouldRenderPointer(i) && (
                    <div className={styles.pointer} style={pointerStyle} onClick={onSelected} />
                )
            }
        </div>
    );
};

export default Stepper;
