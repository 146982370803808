import React, { useRef } from 'react';
import ProductPage from 'design/molecules/ProductPage';
import PageHeader from 'design/atoms/PageHeader';
import PaymentStepper from './PaymentStepper';

const PaymentShell = ({ title, children, steps, disabled, paymentDone, selectedStepIdx, onSelectedStepChange }) => {
    const containerID = useRef(window.btoa(Math.random()));

    const stepper = steps?.length > 0 && (
        <PaymentStepper
            containerID={containerID.current}
            disabled={disabled || paymentDone}
            paymentDone={paymentDone}
            selectedIndex={selectedStepIdx}
            onStepSelected={onSelectedStepChange}
            steps={steps}
        />
    );

    return (
        <ProductPage
            subHeader={
                <>
                    <PageHeader content={title} />
                    {stepper}
                </>
            }
            children={<div id={containerID.current} children={children} />}
        />
    );
};

export default PaymentShell;