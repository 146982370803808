import React from 'react';
import { Grid, Header, Icon, Label, Segment, Button, Divider, Transition } from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';
import { Media, useBreakpoints } from 'design/atoms/Media';
import { formatPrice } from 'util/format/Price';
import SemanticMarkdown from 'design/atoms/SemanticMarkdown';
import FoldableSegment from 'design/atoms/FoldableSegment';

export const ShoppingCartSegment = ({ title, icon, children, rightContent, hidden, background }) => {
    const titleElement = title && (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Header style={{ margin: 0, flex: 1 }}>
                <Icon name={icon} /> {title}
            </Header>
            <div>{rightContent}</div>
        </div>
    );

    return (
        <Transition visible={!hidden} animation='fade down'>
            <div style={{ marginBottom: !hidden ? '2em' : 0 }}>
                <FoldableSegment
                    title={titleElement}
                    style={{ background: background || 'white' }}
                    children={children}
                    defaultOpen={true}
                    isOpen
                />
            </div>
        </Transition>
    );
};

const formatPrices = ({ price, beforeDiscountPrice, active, proactiveVat }) => {
    if (!active) {
        return [formatPrice(price, proactiveVat)];
    }

    if (price === beforeDiscountPrice) {
        return [formatPrice(price, proactiveVat)];
    }

    return [formatPrice(price, proactiveVat), formatPrice(beforeDiscountPrice, proactiveVat)];
};

export function CartItem ({
    id,
    name,
    description,
    taxYear,
    icon,
    price,
    discount,
    proactiveVat,
    discountActive,
    beforeDiscountPrice,
    chosen,
    disabled,
    hideChooseOption,
    onChoose,
}) {
    // format prices (and apply vat if specified)
    [price, beforeDiscountPrice] = formatPrices({
        price,
        beforeDiscountPrice,
        proactiveVat,
        active: discountActive,
    });

    // prepare card header
    let cardHeader;

    if (taxYear) {
        cardHeader = <Header size='small'>
            {taxYear}
            &nbsp;
            <ColoredText color='grey'>
                ∙
            </ColoredText>
            &nbsp;
            <span>
                {name}
            </span>
        </Header>;
    } else {
        cardHeader = <Header size='small'>{name}</Header>;
    }

    // component that will add/remove items to the shopping cart
    const renderAction = () => {
        // shared regardless of returned component
        const sharedProps = {
            onClick: () => onChoose(id),
            labelPosition: 'right',
            disabled: disabled,
        };

        if (hideChooseOption) {
            return null;
        }
        
        if (chosen) {
            return (
                <Button
                    {...sharedProps}
                    content='Fjern fra kurv'
                    size='mini'
                    icon='minus'
                />
            );
        }

        return (
            <Button
                {...sharedProps}
                content='Læg i kurv'
                icon='plus'
                size='mini'
                primary
            />
        );
    };
    
    // shows the price (w/ discount)
    const renderPrice = () => {
        const priceDisplay = (
            <span>
                {
                    beforeDiscountPrice && (
                        <>
                            <span>
                                {beforeDiscountPrice}
                            </span>
                            <span>&nbsp;</span>
                        </>
                    )
                }
                {price} DKK
            </span>
        );

        return (
            <>
                <Label
                    icon={<Icon name='tag' />}
                    content={priceDisplay}
                    color='black'
                    basic
                    circular
                />
                {discount && (
                    <Label
                        content={`Spar ${discount} %`}
                        circular
                        color='green'
                        basic
                    />
                )}
            </>
        );
    };

    const descriptionDisplayer = (
        <p>
            <SemanticMarkdown markdown={description} />
        </p>
    );
    
    const cardIcon = (
        <Icon
            name={icon}
            size='huge'
        />
    );

    return (
        <Segment key={id}>
            {/* Computer view */}
            <Media gte='computer'>
                <Grid columns={2} verticalAlign='middle' divided>
                    <Grid.Column width={3} textAlign='center'>
                        {cardIcon}
                    </Grid.Column>

                    <Grid.Column width={13}>
                        {cardHeader}
                        {descriptionDisplayer}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                {renderPrice()}
                            </div>
                            <div>
                                {renderAction()}
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </Media>

            {/* Tablet view */}
            <Media gte='tablet' lt='computer'>
                {cardHeader}
                {descriptionDisplayer}
                <Grid columns={2} verticalAlign='bottom' stackable>
                    <Grid.Column>
                        {renderPrice()}
                    </Grid.Column>
                    {
                        !hideChooseOption && 
                        <Grid.Column textAlign='right'>
                            {renderAction()}
                        </Grid.Column>
                    }
                </Grid>
            </Media>
            
            {/* Mobile view */}
            <Media lt='tablet'>
                <div>
                    {cardHeader}
                    {descriptionDisplayer}
                    {renderPrice()}
                    {!hideChooseOption && <Divider />}
                    {renderAction()}
                </div>
            </Media>
        </Segment>
    );
}

function PurchaseOptions ({ header, subheader, items, loadPastYears, loadFutureYears }) {
    const hidden = (
        items.length === 0 &&
        !(loadFutureYears || loadFutureYears)
    );

    const renderSegmentButton = ({ icon, label, onClick }) => {
        return (
            <div
                onClick={onClick}
                basic
                style={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    opacity: 0.75,
                }}
                children={
                    <span>
                        <Icon name={icon} /> {label}
                    </span>
                }  
            />
        );
    };

    const loadPrevious = loadPastYears && renderSegmentButton({
        icon: 'chevron up',
        label: 'Vis tidligere skatteår',
        onClick: loadPastYears,
    });
    
    const loadNext = loadFutureYears && renderSegmentButton({
        icon: 'chevron down',
        label: 'Vis senere skatteår',
        onClick: loadFutureYears,
    });

    return (
        <ShoppingCartSegment title={header} icon='plus' rightContent={subheader} hidden={hidden}>
            {loadPrevious}
            {items}
            {loadNext}
        </ShoppingCartSegment>
    );
}

function ShoppingCart ({
    pickedItems,
    unpickedItems,
    addToCart,
    removeFromCart,
    quantityDiscount,
    proactiveVat,
    disabled,
    loadPastYears,
    loadFutureYears,
}) {
    const breakpoints = useBreakpoints();

    const renderCartItem = (item, chosen, chooseHandler) => {
        return <CartItem
            {...item}
            proactiveVat={proactiveVat}
            onChoose={chooseHandler}
            chosen={chosen}
            disabled={disabled}
        />;
    };

    const renderCartItems = (items, chosen) => {
        // decide choose handler based on "chosen" flag
        const chooseHandler = chosen ? removeFromCart : addToCart;

        // render items
        return items.map(item => renderCartItem(item, chosen, chooseHandler));
    };

    // amount of picked items in total
    const aopi = pickedItems.length;
    
    const unpickedTaxYears = unpickedItems.filter(ui => !ui.isOnetimePayment);

    const renderCart = () => {
        const cartItems = renderCartItems(pickedItems, true);

        const productsCountDisplayer = (
            <ColoredText color='grey'>
                {aopi} vare{aopi !== 1 && 'r'}
            </ColoredText>
        );

        return (
            <ShoppingCartSegment
                title='Din indkøbskurv'
                icon='shopping cart'
                rightContent={productsCountDisplayer}
            >
                {cartItems.length === 0 && <span>Din kurv er tom</span>}
                {cartItems}
            </ShoppingCartSegment>
        );
    };

    const renderExtraTaxYears = () => {
        const subheader = quantityDiscount > 0 && (
            <ColoredText color='grey'>
                {breakpoints.select({
                    'gt mobile': <span>Få {quantityDiscount} % rabat på køb af ekstra skatteår</span>,
                    'default': <span>{quantityDiscount} % rabat</span>,
                })}
            </ColoredText>
        );

        return (
            <PurchaseOptions
                header='Ekstra skatteår'
                icon='plus'
                subheader={subheader}
                items={renderCartItems(unpickedTaxYears, false)}
                loadPastYears={!disabled && loadPastYears}
                loadFutureYears={!disabled && loadFutureYears}
            />
        );
    };

    return <>
        {renderCart()}
        {renderExtraTaxYears()}
    </>;
}

export default ShoppingCart;