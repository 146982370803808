import Tree from "util/data_structures/adt/tree/Tree";
import { iterate, IteratorTypes } from "util/data_structures/adt/tree/iterators";
import HintSectionAnalysis from "./HintSectionAnalysis";

class HintSectionAnalyser {
    /**
     * Converts fields to a tree of fields
     * 
     * @param {*} fields
     *  
     * @returns Tree of fields
     */
    static _toFieldTree = fields => {
        const fieldTree = Tree.create(null, node => {
            const isRoot = node.isRoot();
            if(isRoot) {
                return fields;
            }

            const field = node.value;
            const { type } = field;

            let children = [];
            switch(type) {
                case 'resource': {
                    const childrenBuffer = [];
                    field.field.resourceRenders.forEach(resourceRender => childrenBuffer.push(...(resourceRender.fields)));
                    children = childrenBuffer;
                    break;
                }
                default: {
                    children = Array.isArray(node.value.field) ? node.value.field : [];
                    break;
                }
            }

            return children;
        });
        return fieldTree;
    };

    /**
     * Analyses hint section
     * 
     * @param {*} section
     * @param {*} fields 
     * 
     * @returns Hint section analysis
     */
    static analyseHintSection = (section, fields) => {
        const analysis = new HintSectionAnalysis();

        // --------------------------------------------------

        if(section.isAssociatedWithHint) {
            analysis.setSectionHint(section.hint);
        }

        // --------------------------------------------------

        // Fields contain hints?
        const fieldTree = this._toFieldTree(fields);
        iterate(fieldTree.root, IteratorTypes.DEPTH_FIRST, node => {
            // Field tree root is a fake root, do nothing while visiting
            if(node.isRoot()) {
                return;
            }

            const field = node.value;
            if(field.isAssociatedWithHint) {
                analysis.addFieldHint(field.hint);
            }
        });

        // --------------------------------------------------

        return analysis;
    };
}

export default HintSectionAnalyser;
