import React, { useEffect, useState } from 'react';
import { describeYoutubeVideo } from 'http/youtube';

import UiElementEmbeddedVideo from 'design/pages/Product/components/UiElementEmbeddedVideo';
import { Loader } from 'semantic-ui-react';

const HintVideo = props => {
    const { hint } = props;
    const { title, description, ytVideoID } = hint.data;

    const [youtubeVideo, setYoutubeVideo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const describeYTVideo = async () => {
            const youtubeVideo = await describeYoutubeVideo(ytVideoID);
            setYoutubeVideo(youtubeVideo);
            setLoading(false);
        };
        describeYTVideo();
    }, [ytVideoID]);

    // ==================================================

    const renderTitle = () => {
        if(!title) {
            return null;
        }

        return (
            <p>
                <b>{title}</b>
            </p>
        );
    };

    const renderDescription = () => {
        if(!description) {
            return null;
        }

        return (
            <p>{description}</p>
        );
    };

    const renderYTVideo = () => {
        if(loading) {
            return <Loader active={loading} />;
        }

        return (
            <UiElementEmbeddedVideo youtubeVideo={youtubeVideo}/>
        );
    };

    return (
        <>
            {renderYTVideo()}
            {renderTitle()}
            {renderDescription()}
        </>
    );
};

export default HintVideo;
