import { reportWarn, reportError } from 'http/api';
import { hasQueryParam, setQueryParam } from './QueryParams';

const FORCED_RELOAD_PARAM = 'forced_reload';

const handleComponentError = ({ error, errorInfo, history }) => {
    const justReloaded = hasQueryParam(FORCED_RELOAD_PARAM);
    const outdatedBundleError = /chunk [0-9]+ failed/.test(error?.message);

    // check if the error is chunk load failure
    // (which means the app has been updated while the user is using the app)
    if (outdatedBundleError && !justReloaded) {
        // report that the reload took place
        reportWarn('app: outdated bundle', {
            path: window.location.pathname,
        });

        // save the fact that we're about to force a reload
        setQueryParam(FORCED_RELOAD_PARAM, true);

        // reload to update bundle references
        return window.location.reload(true);
    }

    console.error('Component error:', error, errorInfo);
    reportError(`app error: ${error.message}`, {
        errorMessage: error.message,
        errorStack: error.stack,
        componentStack: errorInfo.componentStack,
        path: window.location.pathname,
        userAgent: window.navigator?.userAgent,
        outdatedBundleError,
        justReloaded,
    });
    
    history.push('/ukendt-fejl');
};

export default handleComponentError;