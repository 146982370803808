import { FBTRACK } from '../config/config';

function setupTracking () {
    /* eslint-disable */
    if (process.env.NODE_ENV === 'production') {
        window.ga=window.ga||function () {
            (ga.q=ga.q||[]).push(arguments);
        };ga.l=+new Date;
    
        ga('create', 'UA-85618116-4', 'auto');
    
        ga('require', 'eventTracker');
        ga('require', 'outboundLinkTracker');
        ga('require', 'urlChangeTracker');
    
        if (FBTRACK) {
            !function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            }( window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js' );
                fbq('init', '1231396000272002'); // Insert your pixel ID here.
                fbq('track', 'PageView');
        } else {
            window.fbq = () => {};
        }
    }
    /* eslint-enable */
}

export default setupTracking;