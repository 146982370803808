import React from 'react';
import ColoredText from 'design/atoms/ColoredText';
import withUserData from 'util/withUserData';
import { postResource } from 'network/fetch';
import { erpIdentifiers } from '../';

const SwedishSIEConnector = ({ onRegister }) => {
    const doConnect = async () => {
        await postResource('erp', {
            service: erpIdentifiers.swedishSIE,
        });

        onRegister();
    };

    let description;
    let connectText;

    description = <p>
        Bruger du ikke et af ovenstående økonomisystemer, kan du vælge at importere bogføringsdata fra en SIE-fil.
    </p>;
    connectText = 'Importer bogføringsdata fra SIE';

    return (
        <div>
            {description}
            <ColoredText
                onClick={doConnect}
                content={connectText}
                iconPosition='left'
                size='large'
                color='green'
                icon='arrow right'
                link
            />
        </div>
    );
};

export default withUserData(SwedishSIEConnector);