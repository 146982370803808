import React, { Component } from 'react';
import { Grid, Button, Icon } from 'semantic-ui-react';
import { Media, withBreakpoints } from '../Media';

import styles from './Navigation.module.scss';

class Navigation extends Component {
    renderGoBack = () => {
        const { onPrev, hasPrev, disabled, breakpoints } = this.props;

        return (
            <Grid.Column textAlign='left'>
                <Button
                    basic
                    content='Tilbage'
                    color='black'
                    onClick={onPrev}
                    disabled={!hasPrev || disabled}
                    className={styles.navbtn}
                    fluid={breakpoints.select({ 'eq mobile': true })}
                    style={{
                        opacity: 0.5,
                    }}
                />
            </Grid.Column>
        );
    };

    renderPrint = () => {
        const { disabled, onPrint } = this.props;

        if (!onPrint) {
            return null;
        }

        return (
            <Grid.Column textAlign='center'>
                <Button
                    animated='vertical'
                    basic
                    disabled={disabled}
                    onClick={onPrint}
                >
                    <Button.Content hidden>
                        Print
                    </Button.Content>
                    <Button.Content visible>
                        <Icon name='print' />
                    </Button.Content>
                </Button>
            </Grid.Column>
        );
    };

    renderGoForward = () => {
        const { onNext, hasNext, disabled, demo, breakpoints } = this.props;

        const buttonText = hasNext
            ? (demo ? 'Fortsæt demo' : 'Næste')
            : (demo ? 'Afslut demo' : 'Afslut');

        return (
            <Grid.Column textAlign='right'>
                <Button
                    basic
                    className={styles.navbtn}
                    onClick={onNext}
                    content={buttonText}
                    disabled={disabled}
                    color={demo ? 'orange' : 'green'}
                    fluid={breakpoints.select({ 'eq mobile': true })}
                />
            </Grid.Column>
        );
    };

    render = () => {

        return (
            <Grid columns='equal' style={{ marginTop: '0.25em' }}>
                {this.renderGoBack()}
                <Media gte='tablet'>
                    {this.renderPrint()}
                </Media>
                {this.renderGoForward()}
            </Grid>
        );
    };
}

export default withBreakpoints(Navigation);
