export const ProductElementInteractionDataTypes = {
    FIELD_WRAPPER: 'FieldInteraction',
    FOLDABLE_SEGMENT_PANEL: 'FoldableSegmentPanel'
};

class ProductElementInteractionData {
    constructor(type, interactionContext, customData) {
        this.type = type;
        this.interactionContext = interactionContext;
        this.customData = customData;
    }
}

export default ProductElementInteractionData;
