import debounce from 'lodash.debounce';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Checkbox, Dropdown, Form, Icon, Input, Label, List, Modal, Popup, Table, TableCell } from 'semantic-ui-react';
import { formatNumber, formatDecimalNumber } from 'util/format/Number';
import capitalize from 'util/capitalize';
import {  uploadFile } from 'http/file-storage';
import useUser from 'util/useUser';
import CSV from 'util/CSV';
import ColoredText from 'design/atoms/ColoredText';
import ContextMenu from 'design/atoms/ContextMenu';
import { useBreakpoints } from 'design/atoms/Media';
import { NONE, accountantCodeDescriptions, xbrlTagDescriptions } from './tagDescriptions';
import { OVERSKRIFT } from './accountTypes';
import AccountOptions, { accountOptionsCategories, getAccountOptionsMenuItems } from './AccountOptions';

import styles from './index.module.scss';

const rightBorder = { borderRight: '1px solid lightgray' };

const getTableColumnCount = () => {
    return 9;
};

const doFormatNumber = num => formatNumber(num) || '0';

const makeEllipsisStyle = (pixels) => {
    return {
        maxWidth: pixels + 'px',
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };
};

const TagPicker = ({
    open,
    setOpen,
    children,
    allTags,
    modelTags,
    accountType,
    hasOverride,
    onChange,
    onReset,
    chosenTag,
    isSmallScreen,
    resourceInfo,
    accountNumber,
}) => {
    const [query, setQuery] = useState('');
    const [hovered, setHovered] = useState(false);
    const [hoverScrollY, setHoverScrollY] = useState(-1);
    const [mountScrollY, setMountScrollY] = useState(-1);
    const searchField = useRef();

    const prepareTags = () => {
        const tags = [];

        for (const [tag, { description, kind }] of Object.entries(allTags)) {
            if (query) {
                const match = description.toLowerCase().includes(query) || tag.toLowerCase().includes(query);

                if (!match) {
                    continue;
                }
            }

            if (!modelTags.has(tag)) {
                continue;
            }

            if (kind !== accountType) {
                continue;
            }

            tags.push({ tag, description });
        }

        const sortedTags = tags.sort((a, b) => {
            return a.description.localeCompare(b.description);
        });

        sortedTags.push({ description: NONE, tag: NONE });

        return sortedTags;
    };

    useEffect(() => {
        if (hovered) {
            setHoverScrollY(window.scrollY);
        } else {
            setHoverScrollY(-1);
        }
    }, [hovered]);

    useLayoutEffect(() => {
        if (!open) return;
        if (mountScrollY === -1) return;

        const onScroll = () => {
            if (hoverScrollY !== -1) {
                window.scrollTo(window.scrollX, hoverScrollY);
            }

            const startPosDiff = Math.abs(mountScrollY - window.scrollY);
            const maxScrollDiff = 400;
            if (startPosDiff > maxScrollDiff) setOpen(false);
        };

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [mountScrollY, hoverScrollY, open, setOpen]);

    const onResourceSelected = (_, { value }) => {
        resourceInfo.setAccountResourceMapping(accountNumber, value);
    };

    const content = (
        <Form
            style={{ width: '500px' }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {resourceInfo && resourceInfo.mappableXbrlTags.has(chosenTag) && (
                <Form.Field>
                    <label>{resourceInfo.template.name}</label>
                    <Dropdown
                        selection
                        fluid
                        placeholder={`Vælg ${resourceInfo.template.name.toLowerCase()}`}
                        defaultValue={resourceInfo.accountToResourceTable[accountNumber]}
                        onChange={onResourceSelected}
                        options={resourceInfo.resources.map(({ label, slug }) => ({
                            text: label,
                            value: slug,
                            key: slug,
                        }))}
                    />
                </Form.Field>
            )}
            <Form.Field>
                <label>Klassificering</label>
                <Input
                    fluid
                    icon='search'
                    placeholder='Søg efter ny klassificering...'
                    iconPosition='left'
                    ref={searchField}
                    onChange={(_, { value }) => setQuery(value.toLowerCase())}
                />
            </Form.Field>
            <div
                style={{
                    height: '150px',
                    overflowY: 'scroll',
                    marginTop: '1em',
                    marginBottom: '1em',
                }}
                children={
                    <List divided>
                        {prepareTags().map(({ description, tag }) => {
                            return (
                                <List.Item
                                    title={tag}
                                    content={description}
                                    className={styles.selectable}
                                    onClick={() => {
                                        onChange(tag);
                                        setOpen(false);
                                    }}
                                />
                            );
                        })}
                    </List>
                }
            />
            {hasOverride && (
                <Button
                    icon='undo'
                    content='Nulstil'
                    floated='right'
                    basic
                    onClick={() => {
                        onReset();
                        setOpen(false);
                    }}
                />
            )}
        </Form>
    );

    return (
        <Popup
            open={open}
            onClose={() => {
                setMountScrollY(-1);
                setOpen(false);
            }}
            onMount={() => {
                setQuery('');

                if (isSmallScreen) {
                    return;
                }

                // save current scroll position
                const { scrollX, scrollY } = window;
                
                // focus + move back to original position
                searchField.current.focus();
                window.scrollTo(scrollX, scrollY);
                setMountScrollY(scrollY);
            }}
            basic
            trigger={children}
            content={content}
            style={{ width: '500px' }}
            position={isSmallScreen ? undefined : 'bottom right'}
            on='click'
        />
    );
};

const HeaderRow = ({ number, text }) => {
    return (
        <Table.Row active onContextMenu={e => e.preventDefault()}>
            <Table.Cell width={1} style={rightBorder}>
                <strong>{number}</strong>
            </Table.Cell>
            <Table.Cell colSpan={getTableColumnCount() - 1}>
                <strong>{text}</strong>
            </Table.Cell>
        </Table.Row>
    );
};

const MappingCell = ({
    mapping,
    accountType,
    tagDescriptions,
    modelTags,
    onChange,
    onReset,
    inactive,
    resourceInfo,
    accountNumber,
    isSmallScreen,
    resourceMappingMissing,
    style = {},
}) => {
    const [pickerOpen, setPickerOpen] = useState(false);
    
    if (inactive) {
        return <Table.Cell style={{ ...style, borderTop: 'none' }}>-</Table.Cell>;
    }

    const {
        chosenTag,
        confidencePercentage,
        hasWarning,
        hasCriticalError,
        isOverridden,
    } = mapping;

    let cellLabel;
    let cellTooltip;

    if (hasCriticalError) {
        cellLabel = <Icon name='exclamation circle' color='red' />;
        cellTooltip = 'Denne klassificering er ukendt for systemet og skal rettes';
    } else if (!isOverridden && confidencePercentage < 100) {
        cellLabel = (
            <Label
                size='tiny'
                color={hasWarning ? 'orange' : 'grey'}
                content={`${confidencePercentage}%`}
                style={{
                    marginRight: '0.25em',
                    padding: '0.33em',
                    fontFamily: 'monospace',
                }}
            />
        );
        cellTooltip = `Systemet er ${confidencePercentage}% sikker på at denne klassificering er korrekt`;
    }

    const labelToShow = cellLabel && (
        !cellTooltip
            ? cellLabel
            : (
                <Popup
                    size='tiny'
                    position='top center'
                    content={cellTooltip}
                    trigger={cellLabel}
                />
            )
    );

    const cellText = tagDescriptions[chosenTag]?.description || chosenTag;

    const renderResourceLabel = () => {
        if (!resourceInfo) {
            return null;
        }

        if (!resourceInfo.mappableXbrlTags.has(chosenTag)) {
            return null;
        }

        const mappedResource = resourceInfo.resources.find(resource => {
            return resource.slug === resourceInfo.accountToResourceTable[accountNumber];
        });

        const resourceIcon = (
            <Icon.Group>
                <Icon
                    name={resourceInfo.template.semanticIcon}
                    color={mappedResource ? 'black' : 'gray'}
                />
                <Icon
                    corner='top right'
                    color={mappedResource ? 'green' : 'red'}
                    name={mappedResource ? 'check' : 'exclamation'}
                />
            </Icon.Group>
        );

        const popupContent = (
            mappedResource
                ? `${resourceInfo.template.name}: ${mappedResource.label}`
                : `${resourceInfo.template.name} ikke valgt!`
        );

        return (
            <Popup
                trigger={resourceIcon}
                position='top center'
                content={popupContent}
            />
        );
    };
    
    const handleClick = e => {
        // shortcut for confirming tag
        if (e.altKey) {
            return onChange(chosenTag);
        }

        setPickerOpen(true);
    };

    return (
        <TagPicker
            open={pickerOpen}
            setOpen={setPickerOpen}
            allTags={tagDescriptions}
            modelTags={modelTags}
            accountType={accountType}
            chosenTag={chosenTag}
            hasOverride={isOverridden}
            onChange={onChange}
            onReset={onReset}
            resourceInfo={resourceInfo}
            accountNumber={accountNumber}
            isSmallScreen={isSmallScreen}
        >
            <Table.Cell
                onClick={handleClick}
                className={styles.selectable}
                warning={mapping.hasWarning || resourceMappingMissing}
                error={mapping.hasCriticalError}
                title={cellText}
                style={{
                    ...style,
                    ...makeEllipsisStyle(150),
                    cursor: 'pointer',
                    fontWeight: mapping.isOverridden ? 'bold' : 'normal',
                }}
            >
                {labelToShow}
                {renderResourceLabel()}
                {cellText}
            </Table.Cell>
        </TagPicker>
    );
};

const RenameGroupModal = ({ currentName, onSave, onCancel }) => {
    const inputRef = useRef();

    useLayoutEffect(() => {
        const inputField = inputRef.current.inputRef.current;
        inputField.select();
        inputField.setSelectionRange(0, currentName.length);
    }, [currentName]);

    const handleSave = () => {
        const newName = inputRef.current.inputRef.current.value;

        if (newName === currentName) {
            onCancel();
            return;
        }

        onSave(inputRef.current.inputRef.current.value);
    };

    return (
        <Modal open>
            <Modal.Header><Icon name='pencil' />Omdøb gruppe</Modal.Header>
            <Modal.Content>
                <Input
                    onKeyDown={e => e.key === 'Enter' && handleSave()}
                    defaultValue={currentName}
                    ref={inputRef}
                    fluid
                />
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' content='Annuller' onClick={onCancel} />
                <Button content='Gem' primary icon='save' onClick={handleSave} />
            </Modal.Actions>
        </Modal>
    );
};

const MappingCheckbox = ({ checked, onClick }) => {
    const [localChecked, setLocalChecked] = useState(checked);

    const icon = (
        <Icon
            color={localChecked ? 'green' : 'black'}
            name={localChecked ? 'checkmark box' : 'square outline'}
            link
            onClick={() => {
                setLocalChecked(!localChecked);
                onClick();
            }}
        />
    );

    return (
        <Popup
            disabled={localChecked}
            position='top center'
            content='Markér som afstemt'
            mouseEnterDelay={500}
            trigger={icon}
        />
    );
};

const MappingRow = ({ 
    account,
    number,
    text,
    accountType,
    vatType,
    currentYearAmount,
    lastYearAmount,
    unroundedCurrentYear,
    unroundedLastYearAmount,
    xbrlMapping,
    accountantCodeMapping,
    onClick,
    modelXbrlTags,
    modelAccountantCodes,
    selected,
    selectedAccounts,
    patchAccountInfo,
    lastYearEditable,
    groupAccounts,
    isExpanded,
    toggleExpand,
    isMaster,
    isChild,
    isGroupToggler,
    renameGroup,
    disbandAccountGroup,
    accountPostEntries,
    accountInfo,
    removeAccountFromGroup,
    accountIsChecked,
    toggleAccountChecked,
    setActiveAccountNumber,
    setActiveMenuItemIndex,
    resourceInfo,
    isSmallScreen,
    resourceMappingMissing,
    amountOfUnansweredQuestions,
    updateAccountInfo,
    showDecimalAccounts,
}) => {
    const [newLastYearValue, setNewLastYearValue] = useState(0);
    const [isRenamingGroup, setIsRenamingGroup] = useState(false);
    const [hovered, setHovered] = useState(false);
    const dropZoneRef = useRef(null);


    const renderAmount = (currentAmount, showDecimalAccounts, isCurrentYear) => {  

            if (isCurrentYear && showDecimalAccounts) {
                if (unroundedCurrentYear !== 0) {
                    return formatDecimalNumber(unroundedCurrentYear);
                }
                else {
                    return formatDecimalNumber(currentAmount);
                }
            }

            if (!isCurrentYear && showDecimalAccounts) {
                if (unroundedLastYearAmount !== 0) {
                    return formatDecimalNumber(unroundedLastYearAmount);
                }
                else {
                    return formatDecimalNumber(currentAmount);
                }
            }
        return doFormatNumber(currentAmount);
    }

    const attachedFiles = accountInfo?.attachedFiles || [];

    const handleDragOver = (e) => {
        e.preventDefault();
        setHovered(true);
    };

    const handleDragLeave = () => {
        setHovered(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setHovered(false);
        handleFileUpload(e);
    };

    const handleFileUpload = async (e) => { 
        const files = e.target.files || e.dataTransfer.files;
        if (files.length !== 1) {
            return;
        }

        const [fileToUpload] = files;

        const fileID = await uploadFile(fileToUpload);

        await updateAccountInfo(number, {
            ...accountInfo,
            attachedFiles: [...attachedFiles, fileID],
        });
        if (e.target.value) {
            e.target.value = '';
        }
    };

    const openAccountMenu = menuItemIndex => {
        setActiveAccountNumber(number);
        setActiveMenuItemIndex(menuItemIndex);
    };

    const borderStyle = isChild ? { borderTop: 'none' } : {};

    const amountBoldStyle = isGroupToggler ? { fontWeight: 'bold' } : {};


    const lastYearTableCell = (
        <Table.Cell
            width={1}
            textAlign={isSmallScreen ? 'left' : 'right'}
            children={renderAmount(lastYearAmount, showDecimalAccounts, false)}
            style={{ 
                ...borderStyle,
                ...rightBorder,
                ...amountBoldStyle,
                cursor: lastYearEditable ? 'pointer' : undefined,
            }}
        />
    );
    
    let lastYearCell = lastYearTableCell;
    if (lastYearEditable) {
        const saveLastYearNumber = () => {
            patchAccountInfo({ newLastYearValue });
        };

        // wrap cell in popup
        lastYearCell = (
            <Popup
                on='click'
                position='top center'
                trigger={lastYearTableCell}
                content={
                    <div style={{ width: '150px' }}>
                        <Input
                            type='number'
                            placeholder={renderAmount(lastYearAmount, showDecimalAccounts, false)}
                            onChange={(_, { value }) => setNewLastYearValue(Number(value))}
                            onKeyUp={e => e.code === 'Enter' && saveLastYearNumber()}
                            fluid
                        />
                        <Button
                            fluid
                            content='Gem'
                            style={{ marginTop: '0.5em' }}
                            onClick={saveLastYearNumber}
                        />
                    </div>
                }
            />
        );
    }

    const doGroupAccounts = () => {
        const accountsToGroup = new Set(selectedAccounts);
        accountsToGroup.delete(number);
        if (!isExpanded) toggleExpand();
        groupAccounts(number, [...accountsToGroup]);
    };

    const getContextMenuItems = () => {
        if (selectedAccounts.size >= 1) {
            if (isChild) {
                // nested grouping is not allowed
                return [];
            }

            const accountToGroupIsSelected = selectedAccounts.size === 1 && selected;

            if (!accountToGroupIsSelected) {
                return [{
                    text: `Læg markerede konti sammen under konto nr. ${number}`,
                    icon: 'compress',
                    onSelect: doGroupAccounts,
                }];
            }

            return [];
        }

        const menuItems = [];

        menuItems.push(...getAccountOptionsMenuItems(account).map(({ title, icon }, idx) => ({
            text: title,
            icon,
            onSelect: () => openAccountMenu(idx),
        })));

        if (isMaster) {
            menuItems.push(...[
                {
                    text: 'Omdøb gruppe',
                    icon: 'pencil',
                    onSelect: () => setIsRenamingGroup(true),
                },
                {
                    text: 'Opløs gruppe',
                    icon: 'trash',
                    onSelect: () => disbandAccountGroup(number),
                },
            ]);
        } else if (isChild) {
            menuItems.push({
                text: 'Fjern fra gruppe',
                icon: 'trash',
                onSelect: () => removeAccountFromGroup(number),
            });
        }

        return menuItems;
    };

    const renderConfirmationCell = () => {           
        if (isGroupToggler) {
            return <TableCell />;
        }

        const possibleIndicators = [
            {
                ...accountOptionsCategories.postings,
                count: accountPostEntries?.length || 0,
            },
            {
                ...accountOptionsCategories.attachments,
                count: accountInfo?.attachedFiles?.length || 0,
            },
            {
                ...accountOptionsCategories.notes,
                count: accountInfo?.comments?.length || 0,
            },
        ];

        const accountChangedIndicators = possibleIndicators.map(({ count, indicatorTitle, icon, Component }) => {
            if (count === 0) {
                return null;
            }

            const handleIndicatorClicked = () => {
                const activeMenuItemIndex = getAccountOptionsMenuItems(account).findIndex(item => item.Component === Component);
                openAccountMenu(activeMenuItemIndex);
            };

            const titleToUse = count === 1 ? indicatorTitle.singular : indicatorTitle.plural;
    
            return (
                <Popup
                    position='top center'
                    content={`${count} ${titleToUse.toLowerCase()}`}
                    trigger={
                        <Icon
                            link
                            color='grey'
                            name={icon}
                            onClick={handleIndicatorClicked}
                        />
                    }
                />
            );
        });

        const confirmationIndicator = (
            <MappingCheckbox
                key={`${number}:${accountIsChecked}`}
                checked={accountIsChecked}
                onClick={() => toggleAccountChecked(number)}
            />
        );

        const hasUnansweredQuestions = account.amountOfUnansweredQuestions > 0;
        const questionsIndicator = account.questionsAndAnswers.length > 0 && (
            <Popup
                position='top center'
                content={`${hasUnansweredQuestions ? `Du mangler at besvare ${amountOfUnansweredQuestions} spørgsmål` : 'Du har besvaret alle spørgsmål'}`}
                trigger={
                    <Icon.Group>
                        <Icon
                            link
                            name='question circle'
                            onClick={() => {
                                const questionsMenuItemIndex = getAccountOptionsMenuItems(account).findIndex(item => {
                                    return item.Component === accountOptionsCategories.questions.Component;
                                });
                                openAccountMenu(questionsMenuItemIndex);
                            }}
                        />
                        <Icon
                            corner='top right'
                            name={hasUnansweredQuestions ? 'circle' : 'check'}
                            color={hasUnansweredQuestions ? 'red' : 'green'}
                        />
                    </Icon.Group>
                }
            />
        );

        if (hovered) {
            borderStyle.border = '1px solid grey';
            borderStyle.cursor = 'pointer';
        }

        return (
            <Popup
                position='left center'
                content='Slip fil her for at uploade'
                mouseEnterDelay={100}
                open={hovered}
                trigger={
                    <Table.Cell
                        style={{ width: '112px', ...borderStyle }}
                        ref={dropZoneRef}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}                      
                        textAlign='right'
                    >
                        {questionsIndicator}
                        {accountChangedIndicators}
                        {confirmationIndicator}
                    </Table.Cell>
                }
            />
        );
    };

    const row = (
        <ContextMenu options={getContextMenuItems()} header={`Konto ${number}`}>
            {open => (
                <Table.Row
                    onClick={onClick}
                    onContextMenu={open}
                    className={selected ? styles.activeLine : undefined}
                >
                    <Table.Cell
                        active={!selected}
                        className={selected ? styles.activeAccountNumber : undefined}
                        title={number}
                        style={{
                            ...rightBorder,
                            ...borderStyle,
                            ...makeEllipsisStyle(isSmallScreen ? Number.MAX_VALUE : 32),
                        }}
                    >
                        {isGroupToggler ? '-' : number}
                    </Table.Cell>
                    <Table.Cell
                        style={{
                            ...makeEllipsisStyle(isSmallScreen ? Number.MAX_VALUE : 100),
                            ...borderStyle,
                            fontWeight: isGroupToggler ? 'bold' : undefined,
                            cursor: isGroupToggler ? 'pointer' : undefined,
                        }}
                        title={text}
                        onClick={() => isGroupToggler && toggleExpand()}
                        content={<span>
                            {isGroupToggler && <Icon name={isExpanded ? 'chevron down' : 'chevron right'} />}
                            {isChild && <Icon name='long arrow alternate right' style={{ opacity: 0 }} />}
                            {text}
                        </span>}
                    />
                    <Table.Cell width={1} style={{ ...borderStyle }}>{accountType}</Table.Cell>
                    <Table.Cell width={1} style={{ ...borderStyle }}>{vatType}</Table.Cell>
                    <Table.Cell  width={1} textAlign={isSmallScreen ? 'left' : 'right'} style={{ ...borderStyle, ...amountBoldStyle}}>{renderAmount(currentYearAmount, showDecimalAccounts, true)}
                    </Table.Cell>
                    {lastYearCell}
                    <MappingCell
                        inactive={isChild}
                        accountType={accountType}
                        tagDescriptions={xbrlTagDescriptions}
                        modelTags={modelXbrlTags}
                        mapping={xbrlMapping}
                        style={{ ...rightBorder, ...borderStyle }}
                        onChange={newTag => patchAccountInfo({ newXbrlCode: newTag })}
                        onReset={() => patchAccountInfo({ resetXbrl: true })}
                        accountNumber={number}
                        resourceInfo={resourceInfo}
                        isSmallScreen={isSmallScreen}
                        resourceMappingMissing={resourceMappingMissing}
                    />
                    <MappingCell
                        inactive={isChild}
                        accountType={accountType}
                        tagDescriptions={accountantCodeDescriptions}
                        modelTags={modelAccountantCodes}
                        mapping={accountantCodeMapping}
                        style={{ position: 'relative', ...borderStyle, ...rightBorder }}
                        onChange={newTag => patchAccountInfo({ newAccountantCode: newTag })}
                        onReset={() => patchAccountInfo({ resetAccountantCode: true })}
                        isSmallScreen={isSmallScreen}
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                    />
                    {renderConfirmationCell()}
                </Table.Row>
            )}
        </ContextMenu>
    );

    return (
        <>
            {row}
            {isRenamingGroup && (
                <RenameGroupModal
                    currentName={text}
                    onCancel={(() => setIsRenamingGroup(false))}
                    onSave={newName => {
                        setIsRenamingGroup(false);
                        renameGroup(number, newName);
                    }}
                />
            )}
        </>
    );
};

const AccountPlanTable = ({
    setFilterQuery,
    showZeroAccounts,
    setShowZeroAccounts,
    showDecimalAccounts,
    setShowDecimalAccounts,
    showOnlyErrorLines,
    setShowOnlyErrorLines,
    showOnlyUncheckedLines,
    setShowOnlyUncheckedLines,
    actionsRequired,
    filteredAcccounts,
    selectedAccounts,
    setSelectedAccounts,
    toggleAccountSelection,
    updateMappings,
    groupAccounts,
    renameGroup,
    disbandAccountGroup,
    modelXbrlTags,
    modelAccountantCodes,
    accountplan,
    lastYearEditable,
    getEntriesByAccountNumber,
    addPostEntry,
    updatePostEntry,
    postEntries,
    accountInfoTable,
    updateAccountInfo,
    fiscalPeriod,
    removeAccountFromGroup,
    deletePostEntriesByNumber,
    toggleAccountChecked,
    masterAccountNameOverrides,
    resourceInfo,
    provideQuestionAnswer,
    taxYear,
}) => {
    const [expandedGroups, setExpandedGroups] = useState(new Set());
    const [activeMenuItemIndex, setActiveMenuItemIndex] = useState(null);
    const [activeAccountNumber, setActiveAccountNumber] = useState(null);
    const user = useUser();

    const breakpoint = useBreakpoints();
    const isSmallScreen = breakpoint.current === 'mobile';

    const downloadAccountplanAsCSV = () => {
        const csv = new CSV([
            'number',
            'vatcode',
            'accountType',
            'text',
            'currentYearAmount',
            'lastYearAmount',
            'xbrlTag',
            'accountantCode',
        ]);

        for (const account of accountplan) {
            csv.addToRow('number', account.number);
            csv.addToRow('vatcode', account.vatcode);
            csv.addToRow('accountType', account.accountType);
            csv.addToRow('text', account.text);
            csv.addToRow('currentYearAmount', (account.currentYearAmount || 0).toString());
            csv.addToRow('lastYearAmount', (account.lastYearAmount || 0).toString());
            csv.addToRow('xbrlTag', account.xbrlMapping.chosenTag);
            csv.addToRow('accountantCode', account.accountantCodeMapping.chosenTag);
            csv.newLine();
        }

        let fiscalClassPrefix = '';
        if (user.businessCategory === 'Virksomhed') {
            fiscalClassPrefix = 'A_';
        } else if (user.businessCategory === 'Selskab') {
            fiscalClassPrefix = 'B_';
        }

        const sanitizedDisplayName = (
            user
                .displayName
                .replace(/ /g, '_')
                .replace(/[^a-zæøå0-9_]/gi, '')
        );

        csv.download(fiscalClassPrefix + sanitizedDisplayName);
    };

    const getAccountOptionsMenu = () => {
        if (activeMenuItemIndex === null) return null;
        if (activeAccountNumber === null) return null;

        const account = accountplan.find(account => {
            if (account.accountType === OVERSKRIFT) return false;
            return account.number === activeAccountNumber;
        });
        
        if (!account) {
            return null;
        }

        const handleClose = () => {
            setActiveMenuItemIndex(null);
            setActiveAccountNumber(null);
        };

        const accountInfo = accountInfoTable[account.number] || {};
        const menuItemsWithActivity = [
            {
                Component: accountOptionsCategories.attachments.Component,
                hasActivity: accountInfo.attachedFiles?.length > 0,
            },
            {
                Component: accountOptionsCategories.notes.Component,
                hasActivity: accountInfo.comments?.length > 0,
            },
        ].filter(item => item.hasActivity).map(item => item.Component);

        return (
            <AccountOptions
                header={`Konto ${account.number} - ${account.text}`}
                onClose={handleClose}
                activeMenuItemIndex={activeMenuItemIndex}
                setActiveMenuItemIndex={setActiveMenuItemIndex}
                menuItemsWithActivity={new Set(menuItemsWithActivity)}
                text={account.text}
                account={account}
                accountNumber={account.number}
                entriesFetcher={getEntriesByAccountNumber}
                accountPostEntries={account.postEntries}
                addPostEntry={addPostEntry}
                updatePostEntry={updatePostEntry}
                postEntries={postEntries}
                accountInfo={accountInfo}
                updateAccountInfo={updateAccountInfo}
                fiscalPeriod={fiscalPeriod}
                accountplan={accountplan}
                deletePostEntriesByNumber={deletePostEntriesByNumber}
                provideQuestionAnswer={provideQuestionAnswer}
                showDecimalAccounts={showDecimalAccounts}
                unroundedCurrentYearAmount={account.unroundedCurrentYearAmount}

            />
        );
    };

    const filterOptions = [
        {
            label: 'Vis 0-konti',
            checked: showZeroAccounts,
            onChange: () => setShowZeroAccounts(!showZeroAccounts),
        },
        {
            label: <span>
                Vis påkrævede handlinger
                (<ColoredText
                    bold
                    content={`${actionsRequired} stk.`}
                    color={actionsRequired > 0 ? 'orange' : 'green'}
                />)
            </span>,
            checked: showOnlyErrorLines,
            onChange: () => setShowOnlyErrorLines(!showOnlyErrorLines),
        },
    ];

    filterOptions.push({
        label: 'Skjul afstemte konti',
        checked: showOnlyUncheckedLines,
        onChange: () => setShowOnlyUncheckedLines(!showOnlyUncheckedLines),
    });
    
    filterOptions.push({
        label: 'Vis decimaltal på konti',
        checked: showDecimalAccounts,
        onChange: () => setShowDecimalAccounts(!showDecimalAccounts),
    }
);

    const renderTableHeader = () => {
        return (
            <Table.Header style={{ position: isSmallScreen ? undefined : 'sticky', top: '140px', zIndex: 1 }}>
                <Table.Row>
                    <Table.HeaderCell colSpan={getTableColumnCount()} style={{ fontWeight: 'normal' }} verticalAlign='middle'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
                                <Input
                                    onChange={debounce((_, { value }) => setFilterQuery(value), 200)}
                                    placeholder='Søg på kontotekst...'
                                    iconPosition='left'
                                    icon='search'
                                />
                            </div>
                            
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {filterOptions.map(({ label, onChange, checked }) => (
                                    <>
                                        <Checkbox
                                            onChange={onChange}
                                            checked={checked}
                                            fitted
                                            style={{ marginLeft: '1em', marginRight: '0.5em' }}
                                        />
                                        <span
                                            onClick={onChange}
                                            style={{ cursor: 'pointer' }}
                                            children={label}
                                        />
                                    </>
                                ))}
                            </div>
                        </div>
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell style={rightBorder}>Nr.</Table.HeaderCell>
                    <Table.HeaderCell>Kontotekst</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Moms</Table.HeaderCell>
                    <Table.HeaderCell textAlign={isSmallScreen ? 'left' : 'right'}>{taxYear}</Table.HeaderCell>
                    <Table.HeaderCell textAlign={isSmallScreen ? 'left' : 'right'} style={rightBorder}>{taxYear - 1}</Table.HeaderCell>
                    <Table.HeaderCell style={rightBorder}>Rapport</Table.HeaderCell>
                    <Table.HeaderCell style={rightBorder}>Skat</Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
        );
    };

    const renderAccountLine = accountLine => {
        const {
            number,
            text,
            accountType,
            vatcode,
            currentYearAmount,
            lastYearAmount,
            unroundedCurrentYearAmount,
            unroundedLastYearAmount,
            xbrlMapping,
            accountantCodeMapping,
            childAccounts,
            masterAccountNumber,
            postEntries,
            isChecked,
            groupTotal,
            resourceMappingMissing,
            amountOfUnansweredQuestions,
        } = accountLine;

        // hide child acconts of closed groups
        if (masterAccountNumber !== undefined && !expandedGroups.has(masterAccountNumber)) {
            return null;
        }

        if (accountType === OVERSKRIFT) {
            return <HeaderRow number={number} text={text} />;
        }

        const handlePatch = (dataToPatch) => {
            const accountsToPatch = {};

            if (selectedAccounts.size > 0) {
                // patch all selected accounts
                for (const accountNumber of selectedAccounts) {
                    accountsToPatch[accountNumber] = dataToPatch;
                }
            } else {
                // patch single
                accountsToPatch[number] = dataToPatch;
            }

            updateMappings(accountsToPatch);
        };

        const handleRowClicked = e => {
            const accountIsSelectable = ({ accountType, masterAccountNumber }) => {
                if (accountType === OVERSKRIFT) {
                    return false
                }

                if (masterAccountNumber !== undefined) {
                    return false;
                }

                return true;
            };

            if (!accountIsSelectable(accountLine)) {
                return;
            }

            if (e.ctrlKey || e.metaKey) {
                toggleAccountSelection(number);
                return;
            }

            if (e.shiftKey) {
                if (selectedAccounts.size === 0) {
                    toggleAccountSelection(number);
                    return;
                }

                const selectionCopy = new Set(selectedAccounts);
                const loAccNum = Math.min(...selectedAccounts);
                const hiAccNum = Math.max(...selectedAccounts);

                if (number > hiAccNum) {
                    for (const acc of filteredAcccounts) {
                        if (!accountIsSelectable(acc)) continue;
                        if (acc.number <= hiAccNum) continue;
                        if (acc.number > number) break;
                        selectionCopy.add(acc.number);
                    }
                }
                
                if (number < loAccNum) {
                    for (const acc of filteredAcccounts) {
                        if (!accountIsSelectable(acc)) continue;
                        if (acc.number < number) continue;
                        if (acc.number >= loAccNum) break;
                        selectionCopy.add(acc.number);
                    }
                }

                setSelectedAccounts(selectionCopy);
                return;
            }

            if (!selectedAccounts.has(number)) {
                setSelectedAccounts(new Set());
            }
        };

        const toggleExpand = () => {
           const cpy = new Set(expandedGroups);
            if (cpy.has(number)) {
                cpy.delete(number);
            } else {
                cpy.add(number);
            }
            setExpandedGroups(cpy);
        };

        const vatType = vatcode !== 'none' ? capitalize(vatcode) : '';

        const accountInfo = accountInfoTable[number] || {};

        const renderRow = (renderAsGroupTogglerRow = false) => {
            let currentYearAmountToUse = currentYearAmount;
            let lastYearAmountToUse = lastYearAmount;
            let unroundedCurrentYearAmountToUse = unroundedCurrentYearAmount;
            let unroundedLastYearAmountToUse = unroundedLastYearAmount;
            let textToUse = text;

            let isChild = masterAccountNumber !== undefined;
            let isMaster = childAccounts !== undefined;
            let isExpanded = expandedGroups.has(number);

            if (renderAsGroupTogglerRow) {
                // use the group total amount + text override
                currentYearAmountToUse = groupTotal.currentYear;
                lastYearAmountToUse = groupTotal.lastYear;
                unroundedCurrentYearAmountToUse = groupTotal.unroundedCurrentYear;
                unroundedLastYearAmountToUse = groupTotal.unroundedLastYear;
                textToUse = masterAccountNameOverrides[number] || text;
            } else if (isMaster) {
                if (!isExpanded) {
                    return null;
                }

                isChild = true;
            }

            return (
                <MappingRow
                    account={accountLine}
                    number={number}
                    text={textToUse}
                    accountType={accountType}
                    vatType={vatType}
                    currentYearAmount={currentYearAmountToUse}
                    lastYearAmount={lastYearAmountToUse}
                    unroundedCurrentYear={unroundedCurrentYearAmountToUse}
                    unroundedLastYearAmount={unroundedLastYearAmountToUse}
                    xbrlMapping={xbrlMapping}
                    accountantCodeMapping={accountantCodeMapping}
                    modelXbrlTags={modelXbrlTags}
                    modelAccountantCodes={modelAccountantCodes}
                    onClick={handleRowClicked}
                    toggleExpand={toggleExpand}
                    selectedAccounts={selectedAccounts}
                    selected={selectedAccounts.has(number) || selectedAccounts.has(masterAccountNumber)}
                    patchAccountInfo={handlePatch}
                    groupAccounts={groupAccounts}
                    lastYearEditable={lastYearEditable}
                    isExpanded={isExpanded}
                    isMaster={isMaster}
                    isChild={isChild}
                    isGroupToggler={renderAsGroupTogglerRow}
                    renameGroup={renameGroup}
                    disbandAccountGroup={disbandAccountGroup}
                    getEntriesByAccountNumber={getEntriesByAccountNumber}
                    accountPostEntries={postEntries}
                    addPostEntry={addPostEntry}
                    accountInfo={accountInfo}
                    updateAccountInfo={updateAccountInfo}
                    fiscalPeriod={fiscalPeriod}
                    removeAccountFromGroup={removeAccountFromGroup}
                    accountplan={accountplan}
                    activeAccountNumber={activeAccountNumber}
                    setActiveAccountNumber={setActiveAccountNumber}
                    setActiveMenuItemIndex={setActiveMenuItemIndex}
                    deletePostEntriesByNumber={deletePostEntriesByNumber}
                    accountIsChecked={isChecked}
                    toggleAccountChecked={toggleAccountChecked}
                    resourceInfo={resourceInfo}
                    isSmallScreen={isSmallScreen}
                    resourceMappingMissing={resourceMappingMissing}
                    amountOfUnansweredQuestions={amountOfUnansweredQuestions}
                    showDecimalAccounts={showDecimalAccounts}
                    setShowDecimalAccounts={setShowDecimalAccounts}
                />
            );
        };

        const groupTogglerRow = childAccounts !== undefined && renderRow(true);

        return (
            <>
                {groupTogglerRow}
                {renderRow()}
            </>
        );
    };

    const renderTableBody = () => {
        return (
            <Table.Body>
                {filteredAcccounts.map(accountLine => renderAccountLine(accountLine))}
                {accountplan.length === 0 && (
                    <Table.Row>
                        <Table.Cell colSpan={getTableColumnCount()} textAlign='center'>
                            Ingen kontoplan indlæst...
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        );
    };

    const renderTableFooter = () => {
        const anyAccountsSelected = selectedAccounts.size > 0;

        const shouldShowFooter = anyAccountsSelected || user.isAdmin();
        if (!shouldShowFooter) {
            return null;
        }

        return (
            <Table.Footer style={{ position: 'sticky', bottom: 0 }}>
                <Table.Row textAlign='right'>
                    <Table.HeaderCell colSpan={getTableColumnCount()}>
                        <strong>{selectedAccounts.size}</strong>
                        {selectedAccounts.size === 1 ? ' konto ' : ' konti '}
                        markeret
                        <ColoredText color='grey' content=' ∙ ' />
                        <ColoredText 
                            link
                            content='Nulstil markering'
                            onClick={() => setSelectedAccounts(new Set())}
                        />
                        {user.isAdmin() && (
                            <ColoredText
                                link
                                float='left'
                                icon='cloud download'
                                iconPosition='left'
                                content='Download som CSV'
                                underlined={false}
                                onClick={downloadAccountplanAsCSV}
                            />
                        )}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        );
    };

    const table = (
        <Table
            size='small'
            compact='very'
            onMouseDown={e => e.shiftKey && e.preventDefault()}
        >
            {renderTableHeader()}
            {renderTableBody()}
            {renderTableFooter()}
        </Table>
    );

    return (
        <>
            {table}
            {getAccountOptionsMenu()}
        </>
    );
};

export default AccountPlanTable;