import React from 'react';
import { Form, Modal } from 'semantic-ui-react';
import debounce from 'lodash.debounce';

const QuestionsViewer = ({ account, provideQuestionAnswer }) => {
    const handleQuestionAnswerChange = debounce((questionID, questionAnswerValue) => {
        provideQuestionAnswer(questionID, questionAnswerValue);
    }, 600);

    const questionForm = account.questionsAndAnswers.length > 0 && (
        <Form>
            {account.questionsAndAnswers.map(({ id, questionText, answerValue }) => {
                return (
                    <Form.Field key={id}>
                        <label>{questionText}</label>
                        <textarea
                            onChange={e => handleQuestionAnswerChange(id, e.target.value)}
                            defaultValue={answerValue}
                        />
                    </Form.Field>
                );
            })}
        </Form>
    );
    
    return (
        <Modal.Content>
            {questionForm || <i>Ingen spørgsmål at vise...</i>}
        </Modal.Content>
    );
};

export default QuestionsViewer;