/**
 * FieldRenderingContext schema:
 * Used by the ProductPage component
 * to render the fields of the product.
 */
class FieldRenderingContext {
    constructor({
        values,
        onChange,
        isVisible,
        validationResult,
        createFileUploadID,
    }) {
        // aggregation of input values
        this.values = values;

        // handler that is fired when a field value changes
        this.onChange = onChange;

        // function for checking if a given field is visible
        this.isVisible = isVisible;

        // result of field validation (accumulated upon changing field values OR changing section)
        this.validationResult = validationResult;

        // function that takes the field wrapper as input and returns an unique ID for fileupload
        this.createFileUploadID = createFileUploadID;
    }
}

export default FieldRenderingContext;
