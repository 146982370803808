import React         		from 'react';
import PropTypes     		from 'prop-types';
import debounce      		from 'lodash.debounce';
import i18n                 from 'i18n/pages/Product/components/InputFieldWidget';
import Form        		    from '../../../../atoms/Form'
import NumberInput          from '../../../../atoms/NumberInput';
import FileAttachmentWidget from '../../../../molecules/FileAttachmentWidget';

class InputFieldWidget extends React.Component {
    constructor() {
        super();
		this.handleChange = debounce(this.handleChange.bind(this), 600);
    }

    static propTypes = {
		defaultValue: PropTypes.number,
		label: PropTypes.string,
		error: PropTypes.string,
        isLocked: PropTypes.bool,
		tooltip: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.array,
		]),
		youtubeVideo: PropTypes.object,
	};

	static defaultProps = {
		error: null,
	};

	getCurrentYearValue = () => {
		return this.props.defaultValue;
	};

	getLastYearValue = () => {
		const { value } = this.props;
		try {
			return value.lastyear;
		} catch {
			return undefined;
		}
	};

	handleChange = data => {
		const { onChange, supply } = this.props;
        onChange(supply, data);
	};

	handleInput = isLastYear => {
		return num => {
			if (isLastYear) {
				this.handleChange({
					lastyear: num,
					number: this.getCurrentYearValue(),
				});
			} else {
				this.handleChange({
					lastyear: this.getLastYearValue(),
					number: num,
				});
			}
		};
	};

	render () {
		const { label, error, suffix, isLocked, tooltip, youtubeVideo, askForLastYear } = this.props;
		const currentYearValue = this.getCurrentYearValue();
		const lastYearValue = this.getLastYearValue();
		return <>
			<Form.Row>
				<Form.LabelField
					label={label}
					tooltip={tooltip}
					youtubeVideo={youtubeVideo}
				/>
				<Form.Field error={error} textAlign='right'>
					<NumberInput
						value={currentYearValue}
						suffix={suffix || 'kr.'}
						disabled={isLocked}
						onChange={this.handleInput(false)}
						leftAttachment={<FileAttachmentWidget {...this.props} />}
					/>
				</Form.Field>
			</Form.Row>
			{
				askForLastYear && (
					<Form.Row>
						<Form.LabelField
							label={label + ` ${i18n.lastYear}`}
							tooltip={tooltip}
							youtubeVideo={youtubeVideo}
						/>
						<Form.Field error={error} textAlign='right'>
							<NumberInput
								value={lastYearValue}
								suffix={suffix || 'kr.'}
								disabled={isLocked}
								onChange={this.handleInput(true)}
								leftAttachment={<FileAttachmentWidget {...this.props} />}
							/>
						</Form.Field>
					</Form.Row>
				)
			}
		</>;
	}
}

export default InputFieldWidget;
