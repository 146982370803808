import React, { Component } from 'react'
import { Message, Form, Input, Button } from 'semantic-ui-react';
import { registerCredentials } from 'http/xena';

import styles from './connectors.module.scss';

class XenaConnector extends Component {
    state = { failed: false, working: false };

    setCredentialValue = credProp => {
        return (_, { value }) => {
            this.setState({ [credProp]: value });
        };
    };

    displayErrorMessage = () => {
        const { failed } = this.state;
        if (failed) {
            return <Message negative>
                <Message.Header>Der opstod en fejl</Message.Header>
                <p>Kontroller oplysningerne</p>
            </Message>;
        }
    };

    registerERP = async () => {
        const { fiscalId, apiKey } = this.state;
        if (!fiscalId || !apiKey) {
            return;
        }

        this.setState({ working: true });

        try {
            await registerCredentials(fiscalId, apiKey);
            this.props.onRegister();
        } catch {
            this.setState({ failed: true, working: false })
        }
    };

    renderCredsForm = () => {
        return <Form>
            <p>Fiscal ID er et tal du finder i din webbrowser på Xena:</p>
            <p>Eksempel https://my.xena.biz/Fiscal/<u><b>123456</b></u>/Bookkeeping/Classic</p>
            <Form.Field>
                <label>Fiscal ID</label>
                <Input
                    placeholder='Indtast Fiscal ID'
                    onChange={this.setCredentialValue('fiscalId')}
                    disabled={this.state.working}
                />
            </Form.Field>
            <p>API nøglen er en serie af bogstaver og tal du finder på Xena her:</p>
            <p>Åben menuen Opsætning -{'>'} Import/eksport data -{'>'} API-nøgler. Tryk på Opret-knappen, giv den et sigende navn - og systemet danner automtisk en nøgle.</p>
            <Form.Field>
                <label>Xena API Nøgle</label>
                <Input
                    placeholder='Indtast API nøgle'
                    onChange={this.setCredentialValue('apiKey')}
                    disabled={this.state.working}
                />
            </Form.Field>
        </Form>
    };

    render = () => {
        return (
            <div>
                {this.renderCredsForm()}
                <div className={styles.actionsContainer}>
                    <Button
                        onClick={this.registerERP}
                        disabled={this.state.working}
                        loading={this.state.working}
                        content='Forbind'
                        primary
                    />
                </div>
                {this.displayErrorMessage()}
            </div>
        );
    };
}

export default XenaConnector;