import React from 'react';
import { Form } from 'semantic-ui-react';
import ToolTip from 'design/atoms/Tooltip';

const mediaTooltip = <div>Hvordan skal anmodningen sendes til underskriverne? <br/>I begge tilfælde bliver der bagefter sendt en kopi af det underskrevne dokument via email</div>;

export const Email = 1;
export const SMS = 2;

const SigningMedia = ({ label, notificationMedia, onChange, disabled }) => {
    return <Form.Group inline>
        {label}
        <Form.Radio
            label='Email'
            value='Email'
            name='radioGroup'
            checked={notificationMedia === Email}
            disabled={disabled}
            onChange={onChange}
        />
        <Form.Radio
            label='SMS'
            value='SMS'
            name='radioGroup'
            checked={notificationMedia === SMS}
            disabled={disabled}
            onChange={onChange}
        />
        <ToolTip data={mediaTooltip} inverted disabled={disabled} />
    </Form.Group>;
}

export default SigningMedia;
