import { getAccountPlansFact } from './FactMapUtil';

// Whether or not ManualReview is still needed.
// Ie. the årsrapport has been flagged for "ManualReview" AND that review has not yet been performed
export function isManualReviewNeeded(values) {    
    const accountPlans = getAccountPlansFact(values);
    if (!accountPlans) return false;

    const { needsRevision, revised } = accountPlans.value;
    const manualRevisionNeeded = (needsRevision && !revised);
    return manualRevisionNeeded;
}


// Whether or not ManualReview is passed.
// Ie. the årsrapport has been flagged for "ManualReview" AND that review has been performed and is passed
export function isManualReviewPassed(values) {    
    const accountPlans = getAccountPlansFact(values);
    if (!accountPlans) return false;

    const { needsRevision, revised } = accountPlans.value;
    return (needsRevision && revised);
}
