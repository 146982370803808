import { RECAPTCHA_SITE_KEY } from 'config/config';
import loadScript from './loadScript';

const doInitRecaptcha = async () => {
    if (!recaptchaIsActive()) {
        return Promise.resolve();
    }

    await loadScript('https://www.google.com/recaptcha/api.js?render=' + RECAPTCHA_SITE_KEY);

    await new Promise(resolve => {
        window.grecaptcha.ready(resolve);
    });
};

let initRecaptchaPromise;

export const initRecaptcha = async () => {
    if (!initRecaptchaPromise) {
        initRecaptchaPromise = doInitRecaptcha();
    }
    await initRecaptchaPromise;
};

export const recaptchaIsActive = () => !!RECAPTCHA_SITE_KEY;

export const getRecaptchaToken = async (action = '') => {
    await initRecaptcha();
    return await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action });
};

