import React from 'react';
import { getLoggedInUserData, patchLoggedInUser } from 'http/accounts';
import ProfileForm from './ProfileForm';
import { useRootUser } from 'util/useUser';
import { isAccountant } from 'util/userMethods';
import countries from 'lang/countries';

const EntityInformation = () => {
    const user = useRootUser();

    const segmentSpecificFormFields = (
        user.isSegmentPrivat() ?
        // private user form values
        [
            [
                {
                    path: 'firstname',
                    label: 'Fornavn',
                    disabled: !user.isEntityAdmin(),
                },
                {
                    path: 'lastname',
                    label: 'Efternavn',
                    disabled: !user.isEntityAdmin(),
                },
            ],
        ]
        :
        // business user form values
        [
            {
                path: 'companyName',
                label: 'Virksomhedsnavn',
                disabled: true,
            },
            {
                path: 'cvr',
                label: 'CVR-nr.',
                disabled: true,
            },
            [
                {
                    path: entity => entity && `${entity.address.streetName} ${entity.address.streetNumber}`,
                    label: 'Adresse',
                    disabled: true,
                },
                {
                    path: entity => entity && `${entity.address.zipcode} ${entity.address.ziparea}`,
                    label: 'Postnummer og by',
                    disabled: true,
                },
            ],
            !isAccountant(user) ? {
                path: 'rapportLanguage',
                label: 'Rapportsprog',
                type: 'selection',
                disabled: false,
                options: countries.map(country => ({
                    key: country.name,
                    value: country.language.code,
                    flag: country.flagCode,
                    text: country.language.name
                })),
                defaultOption: 'da-DK'
            } : null,
        ].filter(x => x)
    );

    return (
        <ProfileForm
            getData={getLoggedInUserData}
            patchData={patchLoggedInUser}
            form={[
                ...segmentSpecificFormFields,

                // common form fields
                {
                    path: 'createdAt',
                    label: 'Oprettelsesdato på Digital Revisor',
                    type: 'date',
                    disabled: true,
                },
                {
                    path: 'email',
                    label: 'Faktureringsmail',
                    type: 'string',
                    disabled: !user.isEntityAdmin(),
                },
            ]}
        />
    );
};

export default EntityInformation;