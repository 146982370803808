import React, { useEffect } from 'react';
import { Loader, Segment } from 'semantic-ui-react';
import AuthHelper from 'util/AuthHelper';

const ClearTokens = () => {
    useEffect(() => {
        AuthHelper.deAuthenticate('/');
    }, []);

    return (
        <>
            <br />
            <Segment>
                <Loader inline='centered' active size='large' />
            </Segment>
        </>
    );
};

export default ClearTokens;