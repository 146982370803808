import React, { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { fetchFlow } from 'http/esignatur';


import styles from './Esignatur.module.scss';

// RefreshLink
// Link that refreshes product data (factmap, etc)
// 
export default ({reloadProductData, setFlow, esignaturSignFlowId}) => {

    let [loading, setLoading] = useState(false);

    const onClick = async () => {
        setLoading(true);
        await reloadProductData();
        const fetchedFlow = await fetchFlow(esignaturSignFlowId)
        setFlow(fetchedFlow)
        setLoading(false);
    }

    const trigg = <div className={styles.externalLink} onClick={onClick}>
        <Icon name="refresh" loading={loading} />
        <span>{loading ? "Indlæser..." : "Opdater"}</span>
    </div>;
    return <Popup
        inverted
        content={"Klik her for at hente evt. opdateringer fra serveren"}
        trigger={trigg}
    />;
}
