import React                from 'react';
import cn                   from 'classnames';
import get                  from 'lodash.get';
import { Button }           from 'semantic-ui-react';
import StandardAnswer       from '../StandardAnswer';
import FileAttachmentWidget from '../../../../molecules/FileAttachmentWidget';
import ValueDisplay         from '../../../../atoms/ValueDisplay';
import Form                 from '../../../../atoms/Form';

class SimpleLabel extends React.Component {
    renderContent = () => {
        const { fileUpload, value, suffix, fieldOptions } = this.props;
        if (fileUpload) {
            return <FileAttachmentWidget
                renderTrigger={this.renderFileuploadTrigger}
                {...this.props}
            />;
        }

        let suffixFields = [];
        if (suffix) {
            suffixFields.push(suffix);
        }

        if (get(fieldOptions, 'isStandardAnswer', false)) {
            suffixFields.push(<StandardAnswer />);
        } 

        return <ValueDisplay
            value={value}
            suffix={suffixFields}
            float='right'
        />;
    };

    renderFileuploadTrigger = ({ files }, toggleModal) => {
        let txt;
        if (files.length === 0) {
            txt = 'Ingen filer';
        } else if (files.length === 1) {
            txt = '1 fil';
        } else {
            txt = files.length + ' filer';
        }
        return <>
            <i>{txt + ' uploadet '}</i>
            <Button
                primary
                icon='attach'
                content='Vælg fil'
                labelPosition='right'
                onClick={() => toggleModal(true)}
            />
        </>;
    };

    getClassNames = () => {
        const styleOptions = get(this.props, 'styleOptions', {});
        const classNames = [];
        for (let [ cname, isActive ] of Object.entries(styleOptions)) {
            if (isActive) {
                classNames.push(cname);
            }
        }
        return classNames
    };

    render () {
        const { label, tooltip, youtubeVideo, error } = this.props;
        const cnames = cn(...this.getClassNames());
        return (
			<Form.Row>
				<Form.LabelField
					label={label}
                    tooltip={tooltip}
                    youtubeVideo={youtubeVideo}
                    className={cnames}
				/>
				<Form.Field error={error} textAlign='right' className={cnames}>
                    {this.renderContent()}
				</Form.Field>
			</Form.Row>
		);
    }
}

export default SimpleLabel;
