// TODO: fetch labels from xbrl-taxonomy service

import xbrlCodes from './xbrlCodesDK';
import { revcodesDrift, revcodesBalance } from './accountantCodesDK';
import { BALANCE, DRIFT } from './accountTypes';

export const NONE = 'None';

// xbrl
export const xbrlTagDescriptions = {};

for (const { tag, description } of xbrlCodes.balance) {
    xbrlTagDescriptions[tag] = { kind: BALANCE, description };
}

for (const { tag, description } of xbrlCodes.drift) {
    xbrlTagDescriptions[tag] = { kind: DRIFT, description };
}

// accountant codes
export const accountantCodeDescriptions = {};

for (const [tag, description] of revcodesBalance.entries()) {
    accountantCodeDescriptions[tag] = { kind: BALANCE, description };
}

for (const [tag, description] of revcodesDrift.entries()) {
    accountantCodeDescriptions[tag] = { kind: DRIFT, description };
}