import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { TooltipVideoModal } from 'design/atoms/Tooltip';

import styles from './YouTubeSubheader.module.scss';

function YouTubeSubheader ({ youtubeVideo, iconKey }) {
    if (!youtubeVideo) {
        return null;
    }

    const playIcon = (
        <Icon
            name='video play'
            color='blue'
            className={styles.animatedIcon}
            key={iconKey}
            link
            fitted
        />
    );

    const tooltipMessage = (
        <span>
            <b><Icon name='rocket' />Opstartshjælp video:</b>
            <br />
            {youtubeVideo.title}
        </span>
    );

    const introVideo = <Popup
        trigger={playIcon}
        content={tooltipMessage}
        on='hover'
        position='top center'
    />;

    return <TooltipVideoModal
        trigger={<span>{introVideo}</span>}
        youtubeVideo={youtubeVideo}
    />
}

export default YouTubeSubheader;