import React         from 'react';
import Form from '../../../../atoms/Form';

class UiElementDivider extends React.Component {
	render () {
		return <Form.RowDivider title={this.props.divider?.title} icon={this.props.divider?.icon} />;
	}
}

export default UiElementDivider;
