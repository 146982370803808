import React from 'react';
import { Button, Header, Icon, Loader, Segment, Tab } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import i18n from 'i18n/pages/ArchiveDetail';
import ProductPage from 'design/molecules/ProductPage';
import { Media } from 'design/atoms/Media';
import print, { templates } from 'util/print';
import { getArchivedAppEngineVariation } from 'http/accounts';
import ArchiveDetailStep from './ArchiveDetailStep';
import styles from './ArchiveDetail.module.scss';

class ArchiveDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productName: '',
            stepsProductInfo: [],
            loadingProductInfo: true,
            printProductInfoAsPdf: async () => {},
            variationBaseInfo: '',
            stepsBaseInfo: [],
            loadingBaseInfo: true,
            printBaseInfoAsPdf: async () => {},
        };
    }

    async componentDidMount() {
        const { productID, variation, taxYear } = this.props.match.params;

        const archivedVariation = await getArchivedAppEngineVariation(productID, variation, taxYear);
        const archivedBaseInformation = await getArchivedAppEngineVariation(productID, 'base');

        this.setState({
            productName: archivedVariation.subHeader,
            stepsProductInfo: archivedVariation.steps,
            printProductInfoAsPdf: () => print(templates.APP_ENGINE_STATE, archivedVariation),
            loadingProductInfo: false,
        });

        this.setState({
            stepsBaseInfo: archivedBaseInformation.steps,
            printBaseInfoAsPdf: () => print(templates.APP_ENGINE_STATE, archivedBaseInformation),
            loadingBaseInfo: false,
        });
    }

    composeTitle(productName, variation, taxYear) {
        return i18n.title
            .replace("<product>", productName)
            .replace("<variation>", variation)
            .replace("<year>", taxYear);
    }

    renderTab(steps, printFunc, isLoading) {
        if(isLoading()) {
            return <Segment basic textAlign='center'><Loader size='massive' inline active /></Segment>
        } else {
            return (
                <Tab.Pane>
                    <div className={styles['container']}>
                        <div className={styles['buttons-container']}>
                            <div className={styles['save-as-pdf-label']}>
                                <Link onClick={() => printFunc()}>
                                    <Icon disabled name='save' />
                                    <p>{i18n.saveAsPdfLabel}</p>
                                </Link>
                            </div>
                            <Button primary onClick={() => { this.props.history.goBack() }}>{i18n.goBack}</Button>
                        </div>
                        <div className={styles['steps-list-container']}>
                            { steps.map(s => <ArchiveDetailStep step={s} />) }
                        </div>
                    </div>
                </Tab.Pane>
            );
        }
    }

    render() {
        let content = null;
        const titleIsLoading = Boolean(this.state.loadingProductInfo);
        if(titleIsLoading) {
            content = <Segment basic textAlign='center'><Loader size='massive' inline active /></Segment>;
        } else {
            const { 
                stepsProductInfo, printProductInfoAsPdf, loadingProductInfo, 
                stepsBaseInfo, printBaseInfoAsPdf, loadingBaseInfo,
            } = this.state;
            const { variation, taxYear } = this.props.match.params;
            const title = this.composeTitle(this.state.productName, variation.toLowerCase(), taxYear);
            const panes = [
                {
                    menuItem: i18n.productInfo.replace('<year>', taxYear),
                    render: () => this.renderTab(stepsProductInfo, printProductInfoAsPdf, () => loadingProductInfo),
                },
                {
                    menuItem: i18n.baseInfo,
                    render: () => this.renderTab(stepsBaseInfo, printBaseInfoAsPdf, () => loadingBaseInfo),
                },
            ];
            content = <>
                <Media lt='tablet'>
                    <Header as='h3'>{title}</Header>
                    <Tab className={styles['tab-mobile']} menu={{ attached: false }} panes={panes}/>
                </Media>
                <Media gte='tablet'>
                    <Header as='h2'>{title}</Header>
                    <Tab panes={panes}/>
                </Media>
            </>;
        } 
        return (
            <ProductPage>
                {content}
            </ProductPage>
        );
    }
}

export default withRouter(ArchiveDetail);
