import { formatNumber } from './Number';
import { moms } from '../moms';

export const formatPrice = (price, vat = false) => {
    if (isNaN(price)) {
        return null;
    }

    // apply vat, if specified
    if (vat) {
        price = moms(price);
    }

    // split number up into integer and decimal
    const intPart = Math.trunc(price);
    const remainder = price % 1;

    // round according to danish rounding rules
    let toFormat;
    if (remainder < 0.25) {
        toFormat = intPart;
    } else if (remainder < 0.75) {
        toFormat = intPart + 0.50;
    } else {
        toFormat = intPart + 1;
    }

    // format the number
    const formatted = toFormat.toFixed(2);
    const [int, dec] = formatted.split('.');

    return `${formatNumber(int)},${dec}`;
};