import { POST } from './index';
import { setAccessToken, getChosenUser } from '../../util/token';

let activeAccessTokenRequest;

async function fetchAccessToken () {
    const chosenUserID = getChosenUser();

    const resp = await POST(`token/access/${chosenUserID}`, {}, {
        token: 'refreshToken',
        skipTokenValidation: true,
    });
    if (!resp.wasSuccessful()) {
        throw new Error(resp.getMessage());
    }
    setAccessToken(resp.getData());
}

export default async function () {
    if (!activeAccessTokenRequest) {
        activeAccessTokenRequest = fetchAccessToken();
    }
    await activeAccessTokenRequest;
    activeAccessTokenRequest = null;
}