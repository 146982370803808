import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import countries from 'lang/countries';
import withUserData from 'util/withUserData';

class RapportLanguagePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: ''
        };
    }

    componentDidMount = () => {
        const { defaultLanguage } = this.props;
        this.setState({
            language: defaultLanguage
        });
    };

    toDropdownOption = (country) => ({
        key: country.name,
        value: country.language.code,
        flag: country.flagCode,
        text: country.language.name
    });

    createPrimaryLanguageOptions = () => {
        return countries.map(country => this.toDropdownOption(country));
    };

    render() {
        const {
            defaultLanguage,
            onChange,
            placeholder,
            title
        } = this.props;
        return <Form.Field>
            { title && <label>{title}</label> }
            <Dropdown
                placeholder={placeholder}
                fluid
                selection
                options={this.createPrimaryLanguageOptions()}
                defaultValue={defaultLanguage}
                onChange={onChange}
            ></Dropdown>
        </Form.Field>;
    }
}

export default withUserData(RapportLanguagePicker);
