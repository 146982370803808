import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, Icon, Loader } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { getReportingEntities } from 'http/accounts';
import { refreshTokens } from 'network/fetch/refreshTokens';
import { isImpersonating, stopImpersonation } from 'util/impersonation';
import { useRootUser } from 'util/useUser';
import { setChosenUser } from 'util/token';
import { useBreakpoints } from 'design/atoms/Media';
import ColoredText from 'design/atoms/ColoredText';
import ImpersonationSearch from 'design/molecules/ImpersonationSearch';

import styles from './CompanyPicker.module.scss';

const DropdownContainer = ({ children }) => {
    return (
        <div className={styles.dropdownContainer}>
            {children}
        </div>
    );
};

const ReportingEntityPicker = () => {
    const [entities, setEntities] = useState([]);
    const [open, setOpen] = useState(false);
    const [workingIdx, setWorkingIdx] = useState(-1);
    const [loading, setLoading] = useState(true);
    
    const impSearchRef = useRef();
    const history = useHistory();
    const breakpoints = useBreakpoints();
    const user = useRootUser();

    const working = workingIdx !== -1;

    useEffect(() => {
        if (!open) {
            setLoading(true);
            return;
        }

        const doFetch = async () => {
            const entities = await getReportingEntities();
            impSearchRef.current && impSearchRef.current.focus();
            
            setEntities(entities);
            setLoading(false);
        };

        doFetch();
    }, [open]);

    const newReportingEntitySelected = async (entityID, entityIdx) => {        
        if (user.id === entityID) {
            return;
        }
        
        setWorkingIdx(entityIdx);
        isImpersonating() && stopImpersonation();
        setChosenUser(entityID);

        await refreshTokens();

        setOpen(false);
        setWorkingIdx(-1);
        history.push('/');
    };

    const createNewReportingEntity = () => {
        history.push('/indstillinger/konto/opret_virksomhed');
        setOpen(false);
    };

    const renderEntities = () => {
        if (loading) {
            return <Dropdown.Item disabled>
                <Loader inline='centered' active size='tiny' />
            </Dropdown.Item>;
        }

        const entityItems = entities.map(({ displayName, uid }, idx) => {
            const selected = (
                working ?
                idx === workingIdx :
                uid === user.id
            );
            return <Dropdown.Item
                selected={selected}
                key={uid}
                content={displayName}
                disabled={working}
                onClick={() => newReportingEntitySelected(uid, idx)}
            />;
        });

        return <>
            {entityItems}
            <Dropdown.Divider />
            <Dropdown.Item
                disabled={working}
                content='Ny virksomhed'
                icon='plus'
                onClick={createNewReportingEntity}
            />
        </>;
    };

    const renderImpersonation = () => {
        if (loading) {
            return null;
        }

        if (!user.isAdmin()) {
            return null;
        }

        return <>
            <Dropdown.Divider />
            <DropdownContainer>
                <ImpersonationSearch
                    open={open}
                    searchRef={impSearchRef}
                    disabled={working}
                    onPick={async () => {
                        setOpen(false);
                    }}
                />
                <div className={styles.bottomDivider} />
            </DropdownContainer>
        </>
    };

    const [text, icon] = breakpoints.select({
        'gte computer': [user.displayName],
        'eq tablet': ['Virksomheder'],
        'lt tablet': [<span><Icon name='building' />Virksomheder</span>, null],
    });

    const onClick = breakpoints.select({
        'lte mobile': () => history.push('/indstillinger/konto/virksomheder'),
        'default': () => setOpen(true),
    });

    const shouldShowWidget = () => {
        if (user.isAdmin()) {
            return true;
        }

        if (user.isPrivat()) {
            return false;
        }

        if (user.isAccountant()) {
            // accountants confuse the company picker with the client list
            // so don't show it unless they are actually connected to multiple companies
            return user.login.entityCount > 1;
        }

        return true;
    };

    if (!shouldShowWidget()) {
        return null;
    }

    return (
        <>
            <Dropdown
                item
                text={text}
                open={open}
                icon={icon}
                onClick={onClick}
                children={
                    <Dropdown.Menu className={styles.entityMenu}>
                        <Dropdown.Header>
                            <ColoredText
                                icon='building'
                                size='large'
                                content='Virksomheder'
                                iconPosition='left'
                            />
                        </Dropdown.Header>
                        {renderEntities()}
                        {renderImpersonation()}
                    </Dropdown.Menu>
                }
            />
            {/* slightly darkens app when selecting reporting entities */}
            {
                open &&
                <div
                    onClick={() => setOpen(false)}
                    className={styles.overlay}
                />
            }
        </>
    );
};

export default ReportingEntityPicker;