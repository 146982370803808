import React from 'react';
import { Icon, Message } from 'semantic-ui-react';

function TeaserWall () {
    return <Message>
        <Icon name='lock' />
        Denne sektion er slået fra i prøvetilstand.
    </Message>;
}

export default TeaserWall;