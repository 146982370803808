import i18n from '../../i18n';
import lc from '../../languageCodes';

const {daDK, enUS} = lc;

export default{
    welcome: i18n({
        [daDK]: 'Velkommen',
        [enUS]: 'Welcome', 
    }),
    startImpersonationError: i18n ({
        [daDK]: 'Der opstod en fejl. Prøv igen, eller kontakt supporten.',
        [enUS]: 'An error has occurred. Please try again, or contact our support.',
    }), 
    welcomeMessage: i18n({
        [daDK] : 'Vi kan se, at du er bogholder, og har givet dig rettighed til at oprette klienter nedenfor.',
        [enUS] : 'We have identified you as an accountant and you have been authorized to create clients.',
    }),
    enjoyDigitalRevisor : i18n({
        [daDK] : 'God fornøjelse på Digital Revisor.',
        [enUS] : 'Enjoy Digital Revisor',
    }),
    searchInClients: i18n({
        [daDK] : 'Søg i dine klienter...',
        [enUS] : 'Search in your clients...',
    }),
    createYourFirstClient : i18n({
        [daDK] : 'Opret din første klient her',
        [enUS] : 'Create your first client here',
    }),
    clientName : i18n ({
        [daDK] : 'Navn',
        [enUS] : 'Name',
    }),
    clientStatus : i18n({
        [daDK] : 'Status',
        [enUS] : 'Status',
    }),
    firstClientTeaserCompany: i18n({
        [daDK] : 'ApS & A/S',
        [enUS] : 'ApS & A/S',
    }),
    firstClientTeaserFirm: i18n({
        [daDK] : 'Enkeltmandsvirksomhed m.m.',
        [enUS] : 'Personally owned company etc.',
    }),
    firstClientTeaserPrivat: i18n({
        [daDK] : 'Boligudlejer, forældrekøber',
        [enUS] : 'House rental, parental purchase',
    }),
    progressInTaxYearInfo : i18n({
        [daDK] : 'Denne kolonne viser, hvor langt klienten er kommet med hvert skatteår.',
        [enUS]  : 'This column shows how far the client is in each tax year.',
    }),
    progressInTaxYearStarted : i18n({
        [daDK] : ' Påbegyndt',
        [enUS] : ' Started',
    }),
    progressInTaxYearErpDataFetched : i18n({
        [daDK] : ' Bogføringsdata hentet',
        [enUS] : ' Accounting data fetched',
    }),
    progressInTaxYearToBeSigned : i18n({
        [daDK] : ' Underskrives',
        [enUS] : ' To be signed',
    }),
    progressInTaxYearSigned : i18n({
        [daDK] : ' Underskrevet',
        [enUS] : ' Signed',
    }),
    progressInTaxYearReported : i18n({
        [daDK] : ' Indberettet',
        [enUS] : ' Reported',
    }),
    numberOfClientsSingular : i18n({
        [daDK] : 'klient',
        [enUS] : 'client',
    }),
    numberOfClientsPlural : i18n({
        [daDK] : 'klienter',
        [enUS] : 'clients',
    }),
    noActiveSubscription : i18n({
        [daDK] : 'Klienten har ingen aktive abonnementer',
        [enUS] : 'The client has no active subscriptions',
    }),
    goToProductAsAClient : i18n({
        [daDK] : 'Gå til produkt',
        [enUS] : 'Go to product',
    }),
    errorGettingListOfClients : i18n({
        [daDK] : 'Der opstod et problem da vi forsøgte at hente listen over dine klienter...',
        [enUS] : 'A problem occurred when we tried to get a list of your clients...',
    }),
}

