import React, { useCallback, useEffect, useState } from 'react';
import { Input, Icon, Header, Segment } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import useSearch from 'util/useSearch';
import { isAccountant } from 'util/userMethods';
import { getAccountsFiltered } from 'http/accounts';
import { getImpersonatedID, beginImpersonation } from 'util/impersonation';
import ColoredText from 'design/atoms/ColoredText';

import styles from './ImpersonationSearch.module.scss';

const ImpersonationSearch = ({ open, onPick, searchRef, disabled }) => {
    const searchHandler = useCallback(async query => {
        const accounts = await getAccountsFiltered(query, 3);
        return accounts.filter(account => {
            if (isAccountant(account)) {
                return false;
            }

            if (getImpersonatedID() === account.id) {
                return false;
            }

            return true;
        });
    }, []);
    const history = useHistory();
    const search = useSearch(searchHandler);
    const [working, setWorking] = useState(false);
    const [selectionIndex, setSelectionIndex] = useState(0);

    const handlePick = async entity => {
        setWorking(true);
        
        await beginImpersonation(entity.id);

        setWorking(false);
        onPick && onPick();
        history.push('/');
        toast.success(
            <ColoredText>
                Impersonerer {entity.displayName}
            </ColoredText>
        );
    };

    useEffect(() => {
        if (!search.results) {
            return;
        }

        setSelectionIndex(0);
    }, [search.results]);

    useEffect(() => {
        if (!open) {
            // reset search
            search.setQuery('');
            return;
        }

        searchRef.current.inputRef.current.focus();
    }, [open, searchRef, search]);

    const updateSelectionIndex = newIndex => {
        if (newIndex < 0) {
            return;
        }

        if (newIndex >= search?.results.length) {
            return;
        }

        setSelectionIndex(newIndex);
    };

    const handleKeyPress = e => {
        // dirty! checks if space is pressed
        if (e.keyCode === 32) {
            // adds a space to the value of the input field
            //
            // for some reason spaces do not work in input fields
            // that are contained in Semantic UI dropdowns
            searchRef.current.inputRef.current.value += ' ';
            return;
        }
        
        if (!search.results) {
            return;
        }

        switch (e.keyCode) {
            case 38: // up
                updateSelectionIndex(selectionIndex - 1);
                return;
            case 40: // down
                updateSelectionIndex(selectionIndex + 1);
                return;
            case 13: // enter
                e.preventDefault();
                search.results[selectionIndex] && handlePick(search.results[selectionIndex]);
                return;
            default:
        }
    };

    const renderSearchResults = () => {
        if (working) {
            return null;
        }

        if (!search.results) {
            return null;
        }

        if (search.results.length === 0) {
            return (
                <div className={styles.center}>
                    <ColoredText color='blue' icon='info circle' iconPosition='left'>
                        Ingen konti fundet...
                    </ColoredText>
                </div>
            );
        }

        return (
            <Segment.Group>
                {search.results.map((entity, idx) => {
                    const { id, displayName, creator, cvr } = entity;

                    let subheader;
                    let icon;
                    if (creator) {
                        subheader = 'Klient';
                        icon = 'user';
                    } else if (cvr) {
                        subheader = cvr;
                        icon = 'building';
                    } else {
                        subheader = 'Boligudlejer';
                        icon = 'home';
                    }

                    const isActive = idx === selectionIndex;

                    return (
                        <Segment
                            key={id}
                            className={styles.entityChoice}
                            secondary={!isActive}
                            onMouseEnter={() => setSelectionIndex(idx)}
                            onClick={() => handlePick(entity)}
                            children={
                                <Header size='tiny' color={isActive ? 'green' : 'black'}>
                                    <Icon name={icon} />
                                    <Header.Content>
                                        {displayName}
                                        <Header.Subheader>
                                            <ColoredText color={isActive ? 'green' : 'black'}>
                                                {subheader}
                                            </ColoredText>
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                            }
                        />
                    );
                })}
            </Segment.Group>
        );
    };

    return (
        <>
            <Input
                fluid
                ref={searchRef}
                className={styles.impersonationSearch}
                loading={search.searching}
                disabled={disabled || working}
                onChange={debounce((_, { value }) => search.setQuery(value), 500)}
                onKeyDown={handleKeyPress}
                icon='spy'
                placeholder='Impersonér...'
            />
            {renderSearchResults()}
            {
                working &&
                <div className={styles.center}>
                    <ColoredText 
                        color='grey' 
                        icon='spinner' 
                        content='Impersonerer...'
                        iconPosition='left'
                        loading
                    /> 
                </div>
            }
        </>
    );
};

export default ImpersonationSearch;
