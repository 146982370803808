import { triggerPrint } from './triggerPrint';
import printTemplates from './templates';

class PrintError extends Error {
    constructor (msg) {
        super(msg);
        this.name = 'PrintError';
        this.message = 'PrintError: ' + msg;
    }
}

function print (template, data) {
    if (!template) {
        throw new PrintError('No template provided');
    }
    if (!template.render && typeof template.render !== 'function') {
        throw new PrintError('Bad template provided: No render method');
    }
    const markup = template.render(data);
    triggerPrint(markup);
}

export const templates = printTemplates;
export default print;