import React, { useEffect, useState } from 'react';
import { Loader, Message, Divider, Segment } from 'semantic-ui-react';
import Menu from '../../molecules/Menu';
import { getParam } from 'util/QueryParams';
import { unsubscribeNewsletter } from 'http/accounts';
import FormBox from 'design/atoms/FormBox';
import { renderForm } from './renderForm';

const CODE_PARAM = 'code';
const CancelNews = () => {
    const [verifying, setverifying] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const verificationCode = getParam(CODE_PARAM);
        if (!verificationCode) {
            setError(true);
            setverifying(false);
            return;
        }

        unsubscribeNewsletter(verificationCode)
            .catch(() => setError(true))
            .finally(() => setverifying(false));
    }, []);

    const renderContent = () => {
        let icon;
        let color;
        let header;
        let content;

        if (verifying) {
            return (
                <center>
                    <Loader
                        inline='centered'
                        size='huge'
                        color='green'
                        active
                    />
                    <p>Verificerer link</p>
                </center>
            );
        }

        /* If mistake render error message to user */
        if (error) {
            icon = 'warning circle';
            color = 'red';
            header = 'Fejl';
            content =
                <span>
                    <p>Der er sket en fejl. Prøv venligst igen</p>
                </span>;
            return (
                <div>
                    <Message
                        icon={icon}
                        color={color}
                        header={header}
                        content={content}
                        size='large'
                    />
                </div>
            );
        }

        /* If no mistake render content for user  */
        if (!error) {
            color = 'yellow';
            icon = 'warning circle';
            header = 'Du er nu afmeldt nyhedsbreve fra Digital Revisor.';
/*             content = 'Ønsker du at tilmelde dig igen, er det altid en mulighed';
 */
            return (
                <div>
                    <div>
                        <Message
                            icon={icon}
                            color={color}
                            header={header}
                            content={content}
                            size='large'
                        />
                    </div>
                    <Segment padded='very'>
                        {renderForm()}
                        <Divider hidden/>
                    </Segment>
                </div>
            );
        }
    };

    return (
        <div>
            <Menu/>
            <div style={{maxWidth: "1200px", width:"100%", minHeight: "100vh", height: "100%", margin: "auto"}}>
                <FormBox >
                    {renderContent()}
                </FormBox>
            </div>
        </div>
    );
};

export default CancelNews;