// ------------------------- CSS STYLE -------------------------

/**
 * Aggregates classes given their associated predicates are true. If no predicate is provided, the predicate is considered true by default.
 * 
 * @param {{_class: string, predicate: Function}[]} array 
 * @param {} style Style object imported from *.module.scss file 
 */
const cssAggregateClasses = (array, style) => {
    return array.filter(({ predicate }) => !predicate || predicate()).map(({ _class }) => style[_class]).join(' ');
};

// ----------------------------------------------------------

export {
    cssAggregateClasses
};
