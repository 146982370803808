import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';

const PAGINATION_CEIL = 15;

const ValidationTable = ({ validationErrors }) => {
    const [rowsToRender, setRowsToRender] = useState(0);
    const [errorCount, setErrorCount] = useState(0);
    const [formattedErrors, setFormattedErrors] = useState([]);
    const [resultIsPaginated, setResultIsPaginated] = useState(false);

    // reacts on input errors and decides if the result is paginated or not
    useEffect(() => {
        const totalErrorCount = (
            validationErrors ?
            validationErrors.reduce((acc, cur) => acc += cur.errors.length, 0) :
            0
        );

        if (totalErrorCount > PAGINATION_CEIL) {
            setResultIsPaginated(true);
        }

        setErrorCount(totalErrorCount);
        setRowsToRender(Math.min(totalErrorCount, PAGINATION_CEIL));
    }, [validationErrors]);

    // update list of errors to show when the desired amount changes
    useEffect(() => {
        const prepareErrors = () => {
            const errors = [];

            for (let row of validationErrors) {
                for (let error of row.errors) {
                    errors.push({
                        rowNumber: row.rowNumber,
                        ...error,
                    });

                    if (errors.length >= rowsToRender) {
                        return errors;
                    }
                }
            }

            return errors;
        };

        setFormattedErrors(prepareErrors());
    }, [rowsToRender, validationErrors]);

    const renderTableFooter = () => {
        if (!resultIsPaginated) {
            return null;
        }

        const nextErrors = Math.min(
            errorCount - rowsToRender,
            PAGINATION_CEIL,
        );

        const remainingErrors = errorCount - rowsToRender;

        let paginationOptions;
        if (nextErrors === 0) {
            paginationOptions = <ColoredText content='Alle fejl indlæst' disabled />;
        } else {
            paginationOptions = (
                <>
                    <ColoredText
                        link
                        content={`Indlæs næste ${nextErrors} fejl`}
                        onClick={() => setRowsToRender(rowsToRender + nextErrors)}
                    />
                    {
                        remainingErrors > PAGINATION_CEIL &&
                        <>
                            &nbsp;∙&nbsp;
                            <ColoredText
                                link
                                content={`Indlæs alle ${errorCount} fejl`}
                                onClick={() => setRowsToRender(errorCount)}
                            />
                        </>
                    }
                </>
            );
        }
        
        return (
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.Cell colSpan={3} textAlign='right'>
                        Viser {rowsToRender} ud af {errorCount} fejl
                        ∙&nbsp;
                        {paginationOptions}
                    </Table.Cell>
                </Table.Row>
            </Table.Footer>
        );
    };

    return (
        <Table basic='very'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Rækkenummer</Table.HeaderCell>
                    <Table.HeaderCell>Kolonne</Table.HeaderCell>
                    <Table.HeaderCell>Fejl</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {formattedErrors.map(({ rowNumber, columnName, message }) => {
                   return (
                        <Table.Row key={rowNumber + columnName}>
                            <Table.Cell>{rowNumber}</Table.Cell>
                            <Table.Cell>{columnName}</Table.Cell>
                            <Table.Cell>{message}</Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
            {renderTableFooter()}
        </Table>
    );
};

export default ValidationTable;