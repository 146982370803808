import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Header, Icon, Loader, Message, Modal, Segment, Table } from 'semantic-ui-react';
import { refreshTokens } from 'network/fetch/refreshTokens';
import { getAccountantCard, getInvoiceMethod, unlockClientTaxYear } from 'http/payment';
import { getCreditsBox } from 'http/credits';
import useRestResource from 'util/useRestResource';
import { getProductByID } from 'util/getProducts';
import { useRootUser } from 'util/useUser';
import { useCreditsContext } from 'design/molecules/CreditsProvider';
import CreditsIcon from 'design/atoms/CreditsIcon';
import ColoredText from 'design/atoms/ColoredText';
import UpdateCardForm from 'design/molecules/UpdateCardForm';
import Tooltip from 'design/atoms/Tooltip';
import { invoiceMethods } from '../Profile/InvoiceMethod';

const CompactContainer = ({ children }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Segment style={{ width: '400px' }} raised>
                {children}
            </Segment>
        </div>
    );
};

const CreditsTaxYearUnlocker = ({ productID, taxYear }) => {
    const [didBuy, setDidBuy] = useState(false);
    const [working, setWorking] = useState(false);
    const [goBackTimeout, setGoBackTimeout] = useState(null);
    const [cardModalOpen, setCardModalOpen] = useState(false);

    const history = useHistory();
    const user = useRootUser();
    const creditsContext = useCreditsContext();

    useEffect(() => {
        creditsContext.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            window.clearTimeout(goBackTimeout);
        };
    }, [goBackTimeout]);

    const productsFetcher = useCallback(() => {
        return getProductByID(productID);
    }, [productID]);

    const productResource = useRestResource({
        fetcher: productsFetcher,
    });

    const creditsBoxResource = useRestResource({
        fetcher: getCreditsBox,
    });

    const doUnlockTaxYear = async () => {
        setWorking(true);

        try {
            await unlockClientTaxYear(productID, taxYear);
        } catch {
            setWorking(false);
            toast.error('Købet blev ikke registreret');
            return;
        }

        setDidBuy(true);

        await Promise.all([
            refreshTokens(),
            creditsContext.refetch(),
        ]);
        
        setGoBackTimeout(window.setTimeout(() => history.goBack(), 2000));
    };

    const buyCreditsClicked = async () => {
        if (creditsContext.credits !== 0) {
            return doUnlockTaxYear();
        }

        const invoiceMethod = await getInvoiceMethod();
        if (invoiceMethod === invoiceMethods.MANUAL) {
            return doUnlockTaxYear();
        }

        const card = await getAccountantCard();
        if (card) {
            return doUnlockTaxYear();
        }

        setCardModalOpen(true);
    };

    const renderContent = () => {
        if (didBuy) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <strong>Skatteåret er nu åbnet</strong>
                    <div style={{ margin: '1em' }}>
                        <Icon name='check circle' color='green' size='big' />
                    </div>
                    <ColoredText link content='Gå tilbage' onClick={() => history.goBack()} />
                </div>
            );
        }

        if (productResource.loading || creditsBoxResource.loading || !creditsContext.initialFetchDone) {
            return (
                <Loader inline='centered' active />
            );
        }

        if (productResource.error || creditsContext.error || creditsBoxResource.error) {
            return (
                <Message
                    error
                    icon='warning sign'
                    content='Der opstod en fejl'
                />
            );
        }

        const creditBalance = creditsContext.credits;
        const creditPrice = 1;
        const newBalance = creditBalance - creditPrice;
        const creditsFormatter = Intl.NumberFormat();
        
        const creditsBox = creditsBoxResource.data;
        const unlockButtonIsEnabled = creditBalance > 0 || creditsBox.overdrawAllowed; 

        return (
            <div>
                <Header icon='unlock' content='Få fuld adgang' />

                {unlockButtonIsEnabled && (
                    <Table basic='very' compact='very'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Nuværende balance</Table.Cell>
                                <Table.Cell textAlign='right'>
                                    {creditsFormatter.format(creditBalance)} × <CreditsIcon orange />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{ borderTop: 'none' }}>
                                    {productResource.data.name} {taxYear}
                                </Table.Cell>
                                <Table.Cell style={{ borderTop: 'none' }} textAlign='right'>
                                    -{creditsFormatter.format(creditPrice)} × <CreditsIcon orange />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Ny balance</Table.Cell>
                                <Table.Cell textAlign='right'>
                                    {creditsFormatter.format(newBalance)} × <CreditsIcon orange />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                )}

                {!unlockButtonIsEnabled && (
                    <div style={{ marginBottom: '1em' }}>
                        Du har <strong><CreditsIcon orange /> × 0</strong> credits.

                        <br />
                        <br />

                        For at åbne op for regnskabet skal du købe credits.

                        <br />
                        <br />

                        {!user.isEntityAdmin() && (
                            <>
                                <Icon name='info circle' fitted />
                                {' '} OBS: Det er kun administratorer af {user.displayName}, der har rettighed til at købe credits.
                                <br />
                                <br />
                            </>
                        )}

                        <Tooltip
                            size={null}
                            position='top center'
                            triggerOverwrite={
                                <ColoredText color='grey' icon='question circle' iconPosition='left'>Hvad er credits?</ColoredText>
                            }
                            data={
                                <div>
                                    <strong>Hvad er credits?</strong>
                                    <br />
                                    Credits er en valuta på Digital Revisor som du kan bruge til at åbne op for skatteår for dine klienter.
                                    Med <b>1 credit</b> kan du låse op for <b>1 skatteår</b>.
                                    <br />
                                    
                                    <br />
                                    <strong>Hvor kan jeg købe credits?</strong>
                                    <br />
                                    Du kan klikke på knappen "Køb credits" herunder. Jo flere credits du køber ad gangen, jo større rabat får du.
                                    <br />
                                    
                                    {creditsBox.overdrawAllowed && (
                                        <>
                                            <br />
                                            <strong>Behøver jeg at købe credits for at prøve jeres produkt?</strong>
                                            <br />
                                            Nej, det behøver du ikke.
                                            Du kan vælge at "trække over" på credits. Hvis du har en negativ balance
                                            i slutningen af måneden, vil du modtage en faktura, hvor du betaler {creditsBox.overdrawPricePerCredit} kr. pr. credit.
                                            <br />
                                        </>
                                    )}
                                </div>
                            }
                        />
                    </div>
                )}
                
                <Button
                    fluid
                    primary
                    icon='unlock'
                    content={`Brug ${creditPrice} credit${creditPrice === 1 ? '' : 's'} & lås op`}
                    onClick={buyCreditsClicked}
                    loading={working}
                    disabled={working || !unlockButtonIsEnabled}
                />

                <div style={{ textAlign: 'center', paddingTop: '0.5em' }}>
                    <Button
                        fluid
                        primary
                        basic={creditBalance > 0}
                        content='Køb credits'
                        icon='shopping cart'
                        iconPosition='left'
                        onClick={() => history.push('/betaling/credits' + window.location.search)}
                        disabled={!user.isEntityAdmin()}
                    />
                </div>
            </div>
        );
    };

    const renderCardModal = () => {
        if (!cardModalOpen) {
            return null;
        }

        return (
            <Modal open closeIcon onClose={() => setCardModalOpen(false)}>
                <Modal.Header>Kortoplysninger</Modal.Header>
                <Modal.Content>
                    <UpdateCardForm
                        onCardUpdated={() => {
                            setCardModalOpen(false);
                            doUnlockTaxYear();
                        }}
                    />
                </Modal.Content>
            </Modal>
        );
    };

    return (
        <CompactContainer>
            {renderContent()}
            {renderCardModal()}
        </CompactContainer>
    );
};

export default CreditsTaxYearUnlocker;