import React from 'react';
import styles from './UiElementLink.module.scss';

class UiElementLink extends React.Component {
	render () {
		const { label, link } = this.props
		return <div><a
			target='_blank'
			rel='noopener noreferrer'
			className={styles.link}
			href={link}
			children={label}
		/></div>;
	}
}

export default UiElementLink;
