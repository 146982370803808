import React, {useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Form, Header, Icon, Input, Loader, Message, Modal } from 'semantic-ui-react';
import { deleteClient, getEntityLogins, patchClient } from 'http/accounts';
import useRestResource from 'util/useRestResource';
import { useRootUser } from 'util/useUser';
import { formatDate } from 'util/format/DateTime';
import { isErhverv } from 'util/userMethods';
import { toast } from 'react-toastify';

const Field = ({ label, value }) => (
    <Form.Field>
        <label>{label}</label>
        <Input
            disabled
            value={value}
        />
    </Form.Field>
);

const ClientSettingsForm = ({
    client,
    responsibleLoginIds,
    setResponsibleLoginIds,
    onlyResponsibleLoginsMayAccess,
    setOnlyResponsibleLoginsMayAccess,
}) => {
    const user = useRootUser();

    const loginsResource = useRestResource({
        fetcher: getEntityLogins,
    });

    if (loginsResource.loading) {
        return <Loader inline='centered' active />;
    }

    if (loginsResource.error) {
        return (
            <Message
                color='orange'
                icon='warning circle'
                content='Klients indstillinger kunne ikke indlæses'
            />
        );
    }

    const logins = loginsResource.data;

    return (
        <Form>
            <Field label='Klientens navn' value={client.displayName} />
            {
                isErhverv(client) &&
                <Field label='CVR-nummer' value={client.cvr} />
            }
            <Form.Group widths='equal'>
                <Field label='Oprettet d.' value={formatDate(client.createdAt)} />
                <Field label='Sidst tilgået d.' value={formatDate(client.latestActivity)} />
            </Form.Group>

            {user.isEntityAdmin() && logins.length > 1 && (
                <Form.Field>
                    <label>Tilknyt kollegaer til klienten</label>
                    <Dropdown
                        fluid
                        selection
                        search
                        multiple
                        placeholder='Ingen kollegaer valgt...'
                        value={responsibleLoginIds}
                        options={logins.map(login => {
                            const youLabel = login.id === user.login.id ? ' (dig)' : '';

                            return {
                                text: login.email + youLabel,
                                value: login.id,
                            };
                        })}
                        onChange={(_, { value }) => setResponsibleLoginIds(value)}
                    />
                </Form.Field>
            )}

            {user.isEntitySuperAdmin() && logins.length > 1 && (
                <Form.Field>
                    <Checkbox
                        label='Skjul klient for alle andre end valgte kollegaer?'
                        toggle
                        defaultChecked={onlyResponsibleLoginsMayAccess}
                        onChange={(_, { checked }) => setOnlyResponsibleLoginsMayAccess(checked)}
                    />
                </Form.Field>
            )}
        </Form>
    );
};

const ClientSettings = ({ client, close, onDelete, onUpdate }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [working, setWorking] = useState(false);

    const user = useRootUser();
    const [responsibleLoginIds, setResponsibleLoginIds] = useState(undefined);
    const [onlyResponsibleLoginsMayAccess, setOnlyResponsibleLoginsMayAccess] = useState(undefined);

    useEffect(() => {
        if (user.isEntityAdmin()) {
            setResponsibleLoginIds(client.responsibleLoginIds);
        }

        if (user.isEntitySuperAdmin()) {
            setOnlyResponsibleLoginsMayAccess(client.onlyResponsibleLoginsMayAccess);
        }
    }, [user, client]);

    const doDeleteClient = async () => {
        setWorking(true);

        try {
            await deleteClient(client.id);
            toast.success('Klienten blev slettet');
            onDelete();
        } catch {
            toast.error('Klienten kunne ikke slettes');
        }

        setWorking(false);
    };

    const handleSave = async () => {
        setWorking(true);

        try {
            await patchClient(client.id, {
                responsibleLoginIds,
                onlyResponsibleLoginsMayAccess,
            });
            toast.success('Klienten blev opdateret');
            onUpdate();
            close();
        } catch {
            toast.error('Klienten kunne ikke opdateres...');
        }

        setWorking(false);
    };

    const deleteClientModal = isDeleting && (
        <Modal open onClose={() => setIsDeleting(false)}>
            <Modal.Header>
                <Icon name='trash alternate' />
                Slet klient
            </Modal.Header>
            <Modal.Content>
                <b>OBS:&nbsp;</b>
                Ved sletning af klienten vil alle klientents regnskabsdata også blive slettet.
                Har du købt et skatteår på klienten i denne måned, vil klientens skatteår stadig fremgå på månedsfakturaen.
                <br />
                <br />
                Er du sikker på at du vil fjerne
                <strong> {client.displayName} </strong>
                fra klientlisten?
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content='Annuller'
                    icon='x'
                    color='black'
                    onClick={() => setIsDeleting(false)}
                    disabled={working}
                />
                <Button
                    content='Slet'
                    icon='trash alternate'
                    color='red'
                    onClick={doDeleteClient}
                    disabled={working}
                />
            </Modal.Actions>
        </Modal>
    );

    return (
        <>
            <Modal
                open
                closeIcon
                size='large'
                onClose={close}
                closeOnDimmerClick={false}
                header={
                    <Modal.Header>
                        <Header as='h3'>
                            <Icon name='cog' />
                            <Header.Content>
                                Indstillinger
                                <Header.Subheader>{client.displayName}</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Modal.Header>
                }
                content={
                    <Modal.Content>
                        <ClientSettingsForm
                            client={client}
                            onDelete={onDelete}

                            responsibleLoginIds={responsibleLoginIds}
                            setResponsibleLoginIds={setResponsibleLoginIds}

                            onlyResponsibleLoginsMayAccess={onlyResponsibleLoginsMayAccess}
                            setOnlyResponsibleLoginsMayAccess={setOnlyResponsibleLoginsMayAccess}
                        />
                    </Modal.Content>
                }
                actions={
                    <Modal.Actions>
                        {user.isEntityAdmin() && (
                            <Button
                                basic
                                color='red'
                                floated='left'
                                content='Slet klient'
                                onClick={() => setIsDeleting(true)}
                            />
                        )}
                        <Button
                            primary
                            content='Gem'
                            onClick={handleSave}
                        />
                    </Modal.Actions>
                }
            />
            {deleteClientModal}
        </>
    )
};

export default ClientSettings;