import React from 'react';
import { Checkbox, Icon, Popup } from 'semantic-ui-react';

const BORDER_RADIUS = '4px';

const SectionMenuItem = ({ isFirst, isLast, title, isActive, isHidden, onHiddenChange, onClick }) => {
    return (
        <div
            children={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div
                        children={title}
                        style={{
                            flexGrow: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textDecoration: isHidden ? 'line-through' : undefined,
                        }}
                    />
                    {isActive && (
                        <div>
                            <Popup
                                on='click'
                                position='bottom center'
                                trigger={<Icon name='ellipsis horizontal' link />}
                                content={
                                    <Checkbox
                                        label='Skjul sektion'
                                        checked={isHidden}
                                        onChange={() => onHiddenChange(!isHidden)}
                                    />
                                }
                            />
                        </div>
                    )}
                </div>
            }
            onClick={() => onClick()}
            style={{
                padding: '0.5em',
                border: '1px solid lightgray',
                cursor: isActive ? 'initial' : 'pointer',
                opacity: 0.5 + (!isHidden ? 0.25 : 0) + (isActive ? 0.25 : 0),
                background: isActive ? undefined : 'rgb(240, 240, 240)',
                marginTop: isFirst ? undefined : '-1px',
                borderTopLeftRadius: isFirst ? BORDER_RADIUS : undefined,
                borderTopRightRadius: isFirst ? BORDER_RADIUS : undefined,
                borderBottomLeftRadius: isLast ? BORDER_RADIUS : undefined,
                borderBottomRightRadius: isLast ? BORDER_RADIUS : undefined,
            }}
        />
    );
};

export default SectionMenuItem;