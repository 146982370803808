import downloadBlob from './downloadBlob';

class CSV {
    /**
     * Constructs a new CSV writer
     * @param {string[]} columnNames - The column names of the CSV
     * @param {string} [seperator] - The char that seperates the values. Default char is ';'
     */
    constructor (columnNames, seperator = ';') {
        this.seperator = seperator;
        this.columnNames = columnNames;
        this.currentRow = {};
        this.data = '';
    }

    _currentRowAsString () {
        const out = [];
        this.columnNames.forEach(cn => {
            out.push(this.currentRow[cn] || '');
        });
        return out.join(this.seperator) + '\n';
    }

    /**
     * Puts the specified value into the column of the current row
     * @param {string} colName - The column to write to
     * @param {string} data - The data to write
     */
    addToRow (colName, data) {
        this.currentRow[colName] = data;
    }

    /**
     * Writes the current row to the buffer and prepares a new row
     */
    newLine () {
        this.data += this._currentRowAsString();
        this.currentRow = {};
    }

    /**
     * Gets the current CSV buffer as a blob.
     * If the current row buffer contains data, it is written to the main buffer
     * @returns {Blob}
     */
    asBlob () {
        if (Object.keys(this.currentRow) > 0) {
            this.newLine();
        }
        const header = this.columnNames.join(this.seperator) + '\n';
        const out = header + this.data;
        return new Blob(['\ufeff', out]);
    }

    /**
     * Queues the browser to download the CSV.
     * If the current row buffer contains data, it is written to the main buffer 
     * before the download is triggered
     * @param {string} filename - Desired filename. The .csv extension is added automatically
     */
    download (filename = 'data') {
        downloadBlob(this.asBlob(), `${filename}.csv`);
    }
}

export default CSV;