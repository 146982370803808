import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorDisplayer.module.scss';

class ErrorDisplayer extends React.Component {
	static propTypes = {
        otherError: PropTypes.bool,
        error: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool]),
    };

	static defaultProps = {
		otherError: false,
	}

	render () {
		if(this.props.error) {
			return (
				<div className={ `${styles.base} ${this.props.otherError ? styles.other : styles.normal}` }>
					{ this.props.error }
				</div>
			);
		}else{
			return null;
		}
	}
}

export default ErrorDisplayer;
