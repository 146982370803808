import React, { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import styles from './VismaSignature.module.scss';

// RefreshLink
// Link that refreshes product data (factmap, etc)
// 
export default ({reloadProductData}) => {

    let [loading, setLoading] = useState(false);

    const onClick = async () => {
        setLoading(true);
        await reloadProductData();
        setLoading(false);
    }

    const trigg = <div className={styles.externalLink} onClick={onClick}>
        <Icon name="refresh" loading={loading} />
        <span>{loading ? "Indlæser..." : "Opdater"}</span>
    </div>;

    return <Popup
        inverted
        content={"Klik her for at hente evt. opdateringer fra serveren"}
        trigger={trigg}
    />;
}
