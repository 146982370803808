import get from 'lodash.get';
import set from 'lodash.set';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Form, Input } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { formatDate } from 'util/format/DateTime';
import { refreshTokens } from 'network/fetch/refreshTokens';
import { useRootUser } from 'util/useUser';
import Tooltip from 'design/atoms/Tooltip';

const FormField = ({ label, value, disabled, onChange, tooltip, secret, type = 'string', options, defaultOption }) => {
    const renderInputWidget = () => {
        switch (type) {
            case 'date':
                // change into date picker if needed
                return <Input value={formatDate(value)} disabled={disabled} />
            case 'boolean':
                return (
                    <Checkbox
                        toggle
                        defaultChecked={value}
                        disabled={disabled}
                        onChange={(_, { checked }) => onChange(checked)}
                    />
                );
            case 'selection':
                return (
                    <Dropdown
                        placeholder={`${label}...`}
                        fluid
                        selection
                        disabled={disabled}
                        options={options}
                        defaultValue={value || defaultOption}
                        onChange={(_, { value }) => onChange(value) }
                    ></Dropdown>
                );
            default: // string
                return (
                    <Input
                        value={value}
                        disabled={disabled}
                        placeholder={`${label}...`}
                        type={secret ? 'password' : 'text'}
                        onChange={(_, { value }) => onChange(value)}
                    />
                );
        }
    };

    return (
        <Form.Field>
            <label>
                {label}
                <Tooltip
                    data={tooltip}
                    inverted
                />
            </label>
            {renderInputWidget()}
        </Form.Field>
    );
};

/**
 * 
 * @param {Object} props
 * @param {{label: string, path: string|(data: *) => string, disabled: boolean, type: 'string'|'boolean'|'selection'|'date'}[]} props.form 
 * @returns 
 */
const ProfileForm = ({ getData, patchData, form, onUpdate, echoError }) => {
    const [canSubmit, setCanSubmit] = useState(false);
    const [updateProposition, setUpdateProposition] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const tokenUser = useRootUser();

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const currentData = await getData();
                setCurrentData(currentData);
            } catch (e) {
                toast.error('Data kunne ikke hentes');
                setError(true);
            }
            setLoading(false);
        };

        fetchInitialData();
    }, [getData, tokenUser]);

    useEffect(() => {
        // check if changes were made since load
        const allRequiredFilled = (
            form
            .flat()
            .filter(formValue => formValue.required)
            .every(formValue => !!({ ...currentData, ...updateProposition })[formValue.path])
        );

        if (!allRequiredFilled) {
            return setCanSubmit(false);
        }

        // check if there even was a change
        let canSubmit = false;
        for (let [key, value] of Object.entries(updateProposition)) {
            if (value === undefined) {
                continue;
            }

            if (currentData[key] !== value) {
                canSubmit = true;
                break;
            }
        }

        setCanSubmit(canSubmit);
    }, [currentData, updateProposition, form]);

    const updateFieldValue = (path, value) => {
        const newUpdateProposition = { ...updateProposition };
        set(newUpdateProposition, path, value);
        setUpdateProposition(newUpdateProposition);
    };

    const doSaveChanges = async () => {
        setLoading(true);

        try {
            await patchData(updateProposition);
            const [newData] = await Promise.all([
                getData(),
                refreshTokens(),
            ]);
            setCurrentData(newData);
            setUpdateProposition({});
            onUpdate && onUpdate();
            toast.success('Dine oplysninger blev opdateret');
        } catch (e) {
            toast.error(echoError ? e.message : 'Dine oplysninger kunne ikke opdateres');
        }
        
        setLoading(false);
    };

    const renderFormFields = formFields => {
        return formFields.map(formElement => {
            // handle groups
            if (Array.isArray(formElement)) {
                return (
                    <Form.Group widths='equal'>
                        {renderFormFields(formElement)}
                    </Form.Group>
                );
            }

            const { path, ...elementProps } = formElement;

            const value = (
                typeof path === 'string' ?
                get(updateProposition, path) ?? get(currentData, path) :
                path(currentData)
            );

            return (
                <FormField
                    {...elementProps}
                    value={value}
                    onChange={value => updateFieldValue(path, value)}
                />
            );
        });
    };

    return (
        <Form loading={loading} error={error} key={loading}>
            {renderFormFields(form)}
            <Form.Field>
                <Button
                    onClick={doSaveChanges}
                    disabled={!canSubmit}
                    content='Gem'
                    icon='save'
                    primary
                    fluid
                />
            </Form.Field>
        </Form>
    );
};

export default ProfileForm;