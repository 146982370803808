import React from 'react';
import { Segment, Grid, Label, Icon } from 'semantic-ui-react';
import { Media } from '../Media';

const KeyColumn = ({ children }) => {
    return (
        <>
            <Media gte='tablet'>
                <Grid.Column>{children}</Grid.Column>
            </Media>
            <Media lt='tablet'>
                <Grid.Column>
                    <b>{children}</b>
                </Grid.Column>
            </Media>
        </>
    );
};

function PaymentCard ({ brand, expMonth, expYear, last4, isExpired }) {
    return (
        <Segment>
            <Label attached='top' size='large' color={isExpired ? 'red' : undefined}>
                <Icon name='payment' />
                {
                    isExpired ?
                    <span>Dit kort er udløbet</span> :
                    <span>Nuværende betalingskort</span>
                }
            </Label>
            <Grid columns='equal' stackable>
                <Grid.Row>
                    <KeyColumn>Mærke</KeyColumn>
                    <Grid.Column>{brand}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <KeyColumn>Udløbsmåned</KeyColumn>
                    <Grid.Column>{expMonth}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <KeyColumn>Udløbsår</KeyColumn>
                    <Grid.Column>{expYear}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <KeyColumn>Kortnummer</KeyColumn>
                    <Grid.Column>●●●● ●●●● ●●●● {last4}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

export default PaymentCard;