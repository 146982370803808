import { refreshTokens } from 'network/fetch/refreshTokens';
import { GET } from '../network/fetch';
import userSessionStore from './userSessionStore';

let productsCache;
let productsPromise;

async function getProducts () {
    if (productsCache) {
        return productsCache;
    }

    if (userSessionStore.has('token') && !userSessionStore.has('accessToken')) {
        await refreshTokens();
    }

    if (!productsPromise) {
        productsPromise = GET('payments/products');
    }
    const { data, success } = await productsPromise;
    productsPromise = null;
    if (success) {
        productsCache = data;
    } else {
        throw new Error('Failed to fetch products');
    }
    return productsCache;
}

export function refreshProducts() {
    productsCache = undefined;
    return getProducts();
}

export async function getProductByID (productID) {
    const products = await getProducts();
    return products.find(p => p.id === productID);
}

export default getProducts;