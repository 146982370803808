import React, { useCallback } from 'react';
import { Button, Header, Icon, Message, Popup, Segment } from 'semantic-ui-react';
import { patchAddonProductPurchaseData } from 'http/payment';
import CalendlyBookingPopup from 'design/molecules/CalendlyBookingPopup';

const AccountantSparring = ({ ownedAddonProduct, onUpdate, paymentButton, hasPaid, product }) => {
    const onEventScheduled = useCallback(async ({ event }) => {
        if (!ownedAddonProduct) return;

        await patchAddonProductPurchaseData(ownedAddonProduct.productID, ownedAddonProduct._id, {
            calendlyBookingLink: event.uri,
        });

        onUpdate();
    }, [ownedAddonProduct, onUpdate]);

    const bookingExists = !!ownedAddonProduct?.data?.calendlyBookingLink;

    const calendlyBookingButton = (
        <CalendlyBookingPopup
            onEventScheduled={onEventScheduled}
            triggerRenderer={({ loading, gotError, onClick }) => (
                <Button
                    primary
                    onClick={onClick}
                    loading={loading}
                    disabled={loading || gotError || !hasPaid}
                    labelPosition='right'
                    icon='calendar alternate'
                    content='Book et møde'
                    size='small'
                />
            )}
        />
    );

    const createBookingButton = hasPaid ? calendlyBookingButton : (
        <Popup
            position='top center'
            content='Du skal købe fuld adgang for at booke et møde'
            basic
            trigger={
                <span>
                    {calendlyBookingButton}
                </span>
            }
        />
    );

    let productDescription;
    if (!hasPaid) {
        productDescription = product.description;
    } else if (!bookingExists) {
        productDescription = (
            <span>
                Du har købt adgang til revisorsparring og kan dermed få en personlig gennemgang af dine tal.
                Vi ser meget frem til at hjælpe dig helt i mål.
                Inden gennemgangen anbefaler vi, at du har været igennem værktøjets trin og indtastet dine tal efter bedste evne.
                Jo flere tal vi har at se på, jo bedre kan vi tage hånd om din unikke sag.
            </span>
        );
    } else {
        productDescription = (
            <span>
                Vi har et møde i kalenderen med dig, og vi glæder os allerede til at gå dine tal igennem.
                Du kan finde oplysningerne om mødet i din e-mail, og hvis du er tvivl, kan du skrive til os i chatten.
            </span>
        );
    }

    return (
        <Segment padded>
            <div style={{ display: 'flex' }}>
                <Header as='h3' style={{ flex: 1 }}>{product.name}</Header>
                {!hasPaid && paymentButton}
            </div>
            <div style={{ marginTop: '0.5em' }}>{productDescription}</div>
            <div style={{ textAlign: 'center', marginTop: '1em' }}>
                {bookingExists && (
                    <Popup
                        basic
                        content='Du kan finde oplysningerne om din booking på mail'
                        position='top center'
                        trigger={
                            <Message compact>
                                <Icon name='calendar alternate' />
                                Møde er booket
                            </Message>
                        }
                    />
                )}

                {!bookingExists && createBookingButton}
            </div>
        </Segment>
    );
}

const addonProductComponents = {
    accountantSparring: AccountantSparring,
};

export default addonProductComponents;