import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon, Image, Modal } from 'semantic-ui-react';
import { useBreakpoints } from 'design/atoms/Media';
import { useHistory } from 'react-router-dom';
import YouTube from 'react-youtube';

import styles from './UiElementEmbeddedVideo.module.scss';

const Description = ({ description }) => {
	const [open, setOpen] = useState(false);
	const breakpoints = useBreakpoints();

	if (breakpoints.isMobile) {
		return null;
	}

	return (
		<div className={styles.description} onClick={() => setOpen(!open)}>
			{open && (
				<div style={{ width: '500px' }}>
					{description}
				</div>
			)}

			{!open && (
				<span>
					<Icon name='info circle' /> Om denne video
				</span>
			)}
		</div>
	);
};

const UiElementEmbeddedVideo = ({ youtubeVideo, fieldOptions, hasPayed, paymentURL }) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [width, setWidth] = useState(0);
	const [paymentModalOpen, setPaymentModalOpen] = useState(false);
	const videoRef = useRef();
	const history = useHistory();

    useEffect(() => {
        const updateWidth = () => {
            setWidth(videoRef.current?.offsetWidth);
        };

        updateWidth();

        window.addEventListener('resize', updateWidth);

        return () => window.removeEventListener('resize', updateWidth);
    }, []);

	if (!youtubeVideo) {
		return null;
	}

	const aspectWidth = 12;
	const aspectHeight = 7;
	const playerHeight = `${Math.round(width * (aspectHeight / aspectWidth))}px`;

	const handlePlayClicked = () => {
		const lockedInFreebieVersion = fieldOptions?.lockedInFreebieVersion;

		if (lockedInFreebieVersion && !hasPayed) {
			setPaymentModalOpen(true);
		} else {
			setIsPlaying(true);
		}
	};

	const renderContent = () => {
		if (!isPlaying) {
			return (
				<div className={styles.thumbnailWrapper}>
					<Image
						style={{ height: playerHeight }}
						className={styles.thumbnail}
						src={youtubeVideo.thumbnail}
					/>
					<div className={styles.title}>
						{youtubeVideo.title}
					</div>
					<div className={styles.thumbnailOverlay}>
						<Icon
							onClick={handlePlayClicked}
							name='play circle'
							size='huge'
							className={styles.playIcon}
						/>
						<div className={styles.duration}>
							{youtubeVideo.duration}
						</div>
					</div>
					<Description description={youtubeVideo.description} />
				</div>
			);
		}
	
		return (
			<div style={{ height: playerHeight }}>
				<YouTube
					className={styles.ytplayer}
					videoId={youtubeVideo.id}
					key={youtubeVideo.id}
					onReady={e => e.target.playVideo()}
					onEnd={() => setIsPlaying(false)}
					opts={{
						width: '100%',
						height: playerHeight,
						playerVars: {
							rel: 0,
							modestbranding: 1,
						},
					}}
				/>
			</div>
		);
	};

	return (
		<div className={styles.wrapper} ref={videoRef}>
			{renderContent()}
			{paymentModalOpen && (
				<Modal open onClose={() => setPaymentModalOpen(false)}>
					<Modal.Header>Video låst i demoversion</Modal.Header>
					<Modal.Content>
						For at se videoen, skal du have fuld adgang til produktet.
					</Modal.Content>
					<Modal.Actions>
						<Button
							color='black'
							content='Luk'
							icon='x'
							onClick={() => setPaymentModalOpen(false)}
						/>
						<Button
							primary
							content='Få fuld adgang'
							icon='unlock'
							onClick={() => history.push(paymentURL)}
						/>
					</Modal.Actions>
				</Modal>
			)}
		</div>
	);
};

export default UiElementEmbeddedVideo;
