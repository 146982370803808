import React, { Suspense } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { getAccessToken, hasRefreshToken } from 'util/token';
import TokenUser from 'util/TokenUser';
import ProductPage from 'design/molecules/ProductPage';

const createRedirectRoute = ({ redirectFn, redirectTo, saveTargetLocation }) => {
    return withRouter(({ location, history, skipEntityCheck, ...otherProps }) => {
        if (redirectFn()) {
            // save desired route as query param
            let redirectParam;

            if (saveTargetLocation) {
                const { pathname, search, hash } = location;
                const redirectPath = pathname + search + hash;
                redirectParam = `?redirect=${encodeURIComponent(redirectPath)}`;
            } else {
                redirectParam = '';
            }
            
            history.push(redirectTo + redirectParam);
            return null;
        }

        const user = new TokenUser(getAccessToken());
        if (!skipEntityCheck) {
            // redirect users without any reporting entities to use
            if (user.noEntities) {
                history.push('/getting-started');
                return null;
            }
        } else {
            if (!user.noEntities) {
                history.push('/')
                return null;
            }
        }
        
        const fallback = <ProductPage />;

        return <Suspense fallback={fallback}>
            <Route {...otherProps} />
        </Suspense>;
    });
};

export const AuthRoute = createRedirectRoute({
    redirectFn: () => !hasRefreshToken(),
    redirectTo: '/login',
    saveTargetLocation: true,
});

export const NoAuthRoute = createRedirectRoute({
    redirectFn: () => hasRefreshToken(),
    redirectTo: '/',
    saveTargetLocation: false,
});