import { FBTRACK, IS_PRODUCTION, TRACKING_LOGGING } from '../config/config';
import { ERHVERV } from 'util/userMethods';

/* eslint-disable */
/* prettier-ignore */
function pushFBQ (...args) {
    fbq('track', ...args);
    // eslint-disable-next-line no-console
    // console.log('FB pixel: ', args);
    // eslint-disable-next-line no-console
}
function pushEvent (e) {
    if (FBTRACK) {
        var dataLayer = window.dataLayer || [];
        dataLayer.push(e);
    } else {
        // eslint-disable-next-line no-console
        console.log('Disabled Pushing Google Tag Manager Events:', e);
        // eslint-disable-next-line no-console
    }
}
function pushHSView () {
    if (FBTRACK) {
        const _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', `${window.location.pathname}${window.location.hash}`]);
        _hsq.push(['trackPageView']);
    }
}

function trackingEvent (fn) {
    return (...args) => {
        if (IS_PRODUCTION) {
            fn(...args);
        } else if (TRACKING_LOGGING) {
            // eslint-disable-next-line no-console
            console.log('Tracking event pushing disabled', args);
        }
    };
}

// Segments
const SEGMENTS = {
    PRIVATE: 'private',
    BUSINESS: 'business'
};

export default {
    SEGMENTS: SEGMENTS,
    pushRegisterNewUser: trackingEvent((productId = 'Forskudsopgoerelse', type) => {
        pushFBQ('AddToCart', { content_name: productId });
        pushEvent({
            'event': 'serverEvent',
            'eventCategory': 'sign up',
            'eventAction': productId,
            'eventLabel': document.referrer,
            'userType': 'new',
            'userSegment': type === ERHVERV ? SEGMENTS.BUSINESS : SEGMENTS.PRIVATE
        });
    }),
    pushTrialPeriod: trackingEvent(productId => {
        pushFBQ('Purchase', { content_name: 'Forskudsopgoerelse' });
        pushEvent({
            'event': 'serverEvent',
            'eventCategory': 'trial',
            'eventAction': productId,
            'eventLabel': 'submit',
            'userType': 'lead',
            'userSegment': SEGMENTS.PRIVATE
        });
    }),
    pushPurchaseEvent: trackingEvent(ecommerceData => {
        // pushFBQ('Purchase', { content_name: productId });
        pushEvent({
            event: 'purchase',
            ecommerce: ecommerceData,
        });
    }),
    pushPageViewEvent: trackingEvent(page => {
        pushHSView();
        pushFBQ('ViewContent');
        pushEvent({
            'event': 'VirtualPageView',
            'virtualpageURL': page
        });
        window.ga('send', 'pageview');
    })
};
