import { userStatusesEnum } from 'design/molecules/AppLauncher/userStatuses';
import { getFactValueByTag } from 'http/productEngine';
import { getReportingPeriodsByTaxYears } from 'http/financial-dates';
import { FLOW_CONTEXT_TAG } from 'util/FactMapUtil';
import groupTaxYears from 'util/groupTaxYears';
import variationLabels from 'util/variationLabels';

function generateVariationLink(productID, { type, year, isArchived, label = variationLabels.regular.id }) {
    let pathParts = [productID, type, year, label];

    const productPath = pathParts.join('/');

    return (
        isArchived ?
        `/arkiv/${productPath}` :
        `/service/${productPath}/index`
    );
}

export function extractValue (key) {
    return Object.values(key)[0];
}

export async function prepareVariations (productID, variations, isErhverv) {
    const years = variations.map(v => v.year);

    let taxYearToReportingPeriod;
    if (isErhverv) {
        // fetch reporting period information
        taxYearToReportingPeriod = await getReportingPeriodsByTaxYears(years);
    }

    const preparedVariations = [];

    await Promise.all(variations.map(async v => {
        const { status, type, year, requiresPayment, modelId, isArchived } = v;

        const variationBase = {
            status,
            type,
            productID,
            modelId,
            requiresPayment,
            isArchived,
            year: Number(year),
        };
        
        if (!taxYearToReportingPeriod) {
            // amount of variations pr. tax year is predefined
            // => push the base data of the variation
            preparedVariations.push({
                label: variationLabels.regular.id,
                productLink: generateVariationLink(productID, v),
                ...variationBase,
            });
            return;
        }
    
        const taxYearVariations = taxYearToReportingPeriod[year];
        if (!taxYearVariations) {
            // current tax year is not in scope for given company
            return;
        }

        for (let details of taxYearVariations) {
            const { start, end, label } = details;
            const [startDate, endDate] = [start, end].map(({ year, month, day }) => {
                return new Date(Date.UTC(year, month - 1, day));
            });

            const predefinedLabel = (
                label === variationLabels.conversion.id &&
                variationLabels.conversion.description
            );

            const flowContextFactValue = await getFactValueByTag(modelId, label, FLOW_CONTEXT_TAG);
            const primaryLanguage = flowContextFactValue?.languageContext?.primaryLanguage;

            const variation = {
                ...variationBase,
                startDate,
                endDate,
                predefinedLabel,
                label,
                primaryLanguage,
                firstYear: details.isFirstYear,
                lastYear: details.isLastYear,
                highlighted: details.isHighlighted,
                future: details.isFutureYear,
                teaser: details.isTeaser,
                // @TODO: lastYear: taxYearDetails.isLastYear,
                // @TODO: dissolved: taxYearDetails.isAfterLastYear,
            };

            variation.productLink = generateVariationLink(productID, variation);

            preparedVariations.push(variation);
        }
    }));

    const groupedTaxYears = groupTaxYears(preparedVariations.filter(v => {
        return typeof v !== 'undefined';
    }));

    return groupedTaxYears;
}

export function isCompleted (status) {
    return status === userStatusesEnum.COMPLETED || status === userStatusesEnum.REPORTED;
}
