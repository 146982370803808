import i18n from '../../../../i18n';
import lc from '../../../../languageCodes';

const { daDK, enUS } = lc;

export default {
    linkBtn: i18n({
        [daDK]: 'Læs mere',
        [enUS]: 'Read more',
    }),
    youtubeBtn: i18n({
        [daDK]: 'Se videoen',
        [enUS]: 'Watch',
    }),
};