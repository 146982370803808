import React, { Component } from 'react';
import ColoredText from '../ColoredText';
import ToolTip from '../Tooltip'
import styles from './FormLabel.module.scss';

class Label extends Component {
    renderTooltips = () => {
        const { tooltip, youtubeVideo } = this.props;
        
        const regular = tooltip && <ToolTip data={tooltip} />;
        const video = youtubeVideo && <ToolTip youtubeVideo={youtubeVideo} fitted={!!regular} />;

        return (
            <>
                {regular}
                {video}
            </>
        );
    };

    renderGeneralTooltip = () => {
        const { generalTooltip, generalVideoTooltip } = this.props;
        if (!generalTooltip && !generalVideoTooltip) {
            return null;
        }

        const generalTooltipJSX = (
            generalTooltip &&
            <i>{generalTooltip}</i>
        );

        const generalVideoTooltipJSX = (
            generalVideoTooltip &&
            <ToolTip
                youtubeVideo={generalVideoTooltip}
                trigger={
                    <ColoredText
                        icon='play circle'
                        iconPosition='left'
                        color='blue'
                        content={'Se video: ' + generalVideoTooltip.title}
                        underlined={false}
                        link
                    />
                }
            />
        );

        return (
            <span className={styles.generalTooltip}>
                <br />
                {generalTooltipJSX}
                {generalVideoTooltipJSX}
            </span>
        );
    };

    render () {
        const { label, style, children } = this.props;
        const labelToUse = label || children;
        return <>
            <span className={styles[style]}>{labelToUse}</span>
            {this.renderTooltips()}
            {this.renderGeneralTooltip()}
        </>;
    }
}

export default Label;
