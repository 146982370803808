import React from 'react';
import ColoredText from 'design/atoms/ColoredText';

import styles from './EditableIndicator.module.scss';

const EditableIndicator = ({ children, hasChanges, onClick }) => {
    const changeBadge = hasChanges && (
        <div
            children={<ColoredText content='*' color='green' />}
            style={{
                position: 'absolute',
                top: -10,
                left: -6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                fontSize: '18px',
                zIndex: 1,
            }}
        />
    );

    return (
        <div className={styles.editable} style={{ position: 'relative' }} onClick={onClick}>
            {changeBadge}
            {children}
        </div>
    );
};

export default EditableIndicator;