import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

const ResetForm = {
    passwordLabel: i18n({
        [daDK]: 'Nyt kodeord: ',
        [enUS]: 'New password: ',
    }),
    passwordPlaceholder: i18n({
        [daDK]: 'Vælg nyt kodeord',
        [enUS]: 'Choose new password',
    }),
    repeatPasswordLabel: i18n({
        [daDK]: 'Gentag kodeord: ',
        [enUS]: 'Repeat password: ',
    }),
    repeatPasswordPlaceholder: i18n({
        [daDK]: 'Gentag kodeord',
        [enUS]: 'Repeat password',
    }),
    resetButton: i18n({
        [daDK]: 'Opdater',
        [enUS]: 'Update',
    }),
};

export default {
    title: i18n({
        [daDK]: 'Nulstil kodeord',
        [enUS]: 'Reset password',
    }),
    subTitle: i18n({
        [daDK]: 'Vælg dit nye kodeord nedenfor.',
        [enUS]: 'Choose a new password.',
    }),
    successMsg: i18n({
        [daDK]: 'Dit kodeord blev opdateret.',
        [enUS]: 'Your password has been updated.',
    }),
    redirectMsg: i18n({
        [daDK]: 'Du vil blive sendt videre til log ind siden om få sekunder',
        [enUS]: 'You will be redirected to the login page shortly',
    }),
    ResetForm,
};