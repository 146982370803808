import React, { useRef, useState } from 'react';
import { Button, Card, Icon, Input, Label, Segment, Transition } from 'semantic-ui-react';
import CreditsIcon from 'design/atoms/CreditsIcon';
import { useBreakpoints } from 'design/atoms/Media';
import { formatNumber } from 'util/format/Number';
import { teal } from 'styles/colors';
import ColoredText from 'design/atoms/ColoredText';
import CornerRibbon from 'design/atoms/CornerRibbon';
import RotationAnimaiton from 'design/atoms/RotationAnimaiton';
import AnimatedNumber from 'design/atoms/AnimatedNumber';
import useUser from 'util/useUser';
import { isAkademi} from 'util/userMethods';

const clamp = (min, max, num) => {
    if (num > max) return max;
    if (num < min) return min;
    return num;
};

const calculateTotalSavedAmount = ({ priceSteps, quantity, unitPrice }) => {
    const [firstPriceStep] = priceSteps;
    const defaultPrice = firstPriceStep.unitPrice;
    return (defaultPrice - unitPrice) * quantity;
};

const CardItem = ({ quantity, unitPrice, discount, isChosen, ribbonText, ribbonColor, ...cardProps }) => {
    const rotationAnimationRef = useRef();
    const user = useUser();
    const isAkademiUser = isAkademi(user);

    const coin = (
        <RotationAnimaiton rotationHandlerChanged={handler => rotationAnimationRef.current = handler}>
            <div style={{ display: 'inline-block' }}>
                <CreditsIcon orange />
            </div>
        </RotationAnimaiton>
    );

    const rotateTheCoin = () => rotationAnimationRef?.current();

    return (
        <Card
            {...cardProps}
            link
            onMouseEnter={rotateTheCoin}
            color='teal'
            style={{
                color: 'black',
                background: `linear-gradient(white 50%, #e4f5f1 100%)`,
            }}
        >
            {!isAkademiUser && ribbonText && <CornerRibbon color={ribbonColor} content={ribbonText} />}

            <Card.Content textAlign='center'>
                <span style={{ fontSize: '185%', fontWeight: 'bold' }}>
                    {coin}
                    <ColoredText color='grey' content=' × ' />
                    {quantity}
                </span>
            </Card.Content>

            <Card.Content textAlign='center'>
                <b>{unitPrice}</b> kr. pr. credit

                <div style={{ marginTop: '0.75em'}}>
                    <Icon name='tag' color={discount > 0 ? 'green' : 'grey'} />
                    {discount > 0 && (
                        <>
                            Spar{' '}
                            <strong>
                                <ColoredText>{formatNumber(discount)}</ColoredText>
                            </strong>{' '}
                            kr.
                        </>
                    )}
                    {discount === 0 && (
                        <>Standardpris</>
                    )}
                </div>
            </Card.Content>

            <Card.Content textAlign='center'>
                <Button
                    fluid
                    size='mini'
                    style={{ background: teal }}
                    icon={isChosen ? 'check circle' : undefined}
                    content={isChosen ? 'Valgt' : 'Vælg'}
                />
            </Card.Content>
        </Card>
    );
};

const CustomAmountSelector = ({ priceSteps, onAmountChosen }) => {
    const [desiredAmount, setDesiredAmount] = useState(0);

    const selectedPriceStep = [...priceSteps].reverse().find(({ minimumCreditsRequired }) => {
        return desiredAmount >= minimumCreditsRequired;
    });

    const maxPurchasableAmount = Math.max([...priceSteps].pop().minimumCreditsRequired, 100);

    const totalSavings = calculateTotalSavedAmount({
        priceSteps,
        quantity: Math.min(desiredAmount, maxPurchasableAmount),
        unitPrice: selectedPriceStep.unitPrice,
    });

    const tooManyChosen = desiredAmount > maxPurchasableAmount;

    const renderAnimatedNumber = ({ visibleIf, label, number }) => {
        return (
            <Transition visible={visibleIf} animation='fade down'>
                <div style={{ marginTop: '0.5em' }}>
                    {label}:{' '}
                    <b>
                        <AnimatedNumber number={number} />
                    </b> kr.
                </div>
            </Transition>
        );
    };

    return (
        <Segment>
            <div style={{ fontWeight: 'bold' }}>
                Du kan også selv vælge det antal credits, du vil købe:
            </div>
            <div style={{ display: 'flex', marginTop: '0.5em' }}>
                <Input
                    autoFocus
                    type='number'
                    placeholder='Indtast ønskede antal credits'
                    style={{ flexGrow: 1 }}
                    label={<Label><CreditsIcon orange /></Label>}
                    onChange={(_, { value }) => {
                        const integerAmount = Math.floor(Number(value))
                        setDesiredAmount(Math.max(0, integerAmount));
                    }}
                />
                <Button
                    content='Vælg'
                    disabled={tooManyChosen || desiredAmount === 0}
                    style={{
                        flexShrink: 0,
                        marginLeft: '1em',
                        background: teal,
                    }}
                    onClick={() => {
                        const correctedAmount = clamp(0, maxPurchasableAmount, desiredAmount);
                        onAmountChosen(correctedAmount);
                    }}
                />
            </div>

            {renderAnimatedNumber({
                visibleIf: desiredAmount > 0,
                label: 'Beregnet stykpris',
                number: selectedPriceStep.unitPrice,
            })}

            {renderAnimatedNumber({
                visibleIf: totalSavings > 0,
                label: 'Rabat',
                number: totalSavings,
            })}

            <Transition visible={tooManyChosen} animation='fade down'>
                <div style={{ marginTop: '0.5em' }}>
                    <Icon name='warning circle' /> Du kan maks købe {maxPurchasableAmount} credits ad gangen.
                    Kontakt os for et skræddersyet tilbud, hvis du har brug for flere!
                </div>
            </Transition>
        </Segment>
    );
};

const CreditsPuchaseForm = ({ creditsToBuy, setCreditsToBuy, priceSteps, disabled }) => {
    const breakpoints = useBreakpoints();

    const itemsPerRow = breakpoints.select({
        'gte computer': 3,
        'eq tablet': 2,
        'default': 1,
    });

    const cards = (
        <Card.Group itemsPerRow={itemsPerRow}>
            {priceSteps.map(({ minimumCreditsRequired, unitPrice, popular }, i) => {
                const quantity = minimumCreditsRequired || 1;
                
                const isChosen = quantity === creditsToBuy;

                let ribbonText;
                let ribbonColor;
                if (i === priceSteps.length - 1) {
                    ribbonText = 'Mest værdi';
                    ribbonColor = 'green';
                } else if (popular) {
                    ribbonText = 'Populær';
                    ribbonColor = 'teal';
                }

                const discount = calculateTotalSavedAmount({
                    priceSteps,
                    unitPrice,
                    quantity,
                });

                return (
                    <CardItem
                        onClick={() => setCreditsToBuy(quantity)}
                        quantity={quantity}
                        unitPrice={unitPrice}
                        discount={discount}
                        isChosen={isChosen}
                        ribbonText={ribbonText}
                        ribbonColor={ribbonColor}
                    />
                );
            })}
        </Card.Group>
    );

    const style = {};
    if (disabled) {
        style.opacity = 0.75;
        style.pointerEvents = 'none';
    }

    return (
        <div style={style}>
            {cards}
            <CustomAmountSelector
                priceSteps={priceSteps}
                onAmountChosen={amount => setCreditsToBuy(amount)}
            />
        </div>
    );
};

export default CreditsPuchaseForm;