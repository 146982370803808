import React from 'react';
import { getCurrentLogin, patchCurrentLogin, getLoggedInUserData, patchLoggedInUser } from 'http/accounts';
import withUserData from 'util/withUserData';
import { isAkademi } from '../../../util/userMethods';
import i18n from 'i18n/pages/Profile/WantsNews';
import ProfileForm from './ProfileForm';

const getData = async () => {
    const [login, entity] = await Promise.all([
        getCurrentLogin(),
        getLoggedInUserData(),
    ]);

    return {
        login,
        entity,
    };
};

const patchData = async ({ login, entity }) => {
    await Promise.all([
        login && patchCurrentLogin(login),
        entity && patchLoggedInUser(entity),
    ]);
};

const PrivateAccountData = ({ userData, isEntityAdmin }) => {
    const [firstLastNameGroup, classNameField] = [
        [
            {
                path: 'entity.firstname',
                label: 'Fornavn',
            },
            {
                path: 'entity.lastname',
                label: 'Efternavn',
            },
        ],
        {
            path: 'entity.className',
            label: 'Klassenavn',
            disabled: !isEntityAdmin,
        },
    ];
    return (
        <ProfileForm
            getData={getData}
            patchData={patchData}
            form={[
                !isAkademi(userData) ? firstLastNameGroup : classNameField,
                {
                    path: 'login.loginEmail',
                    label: 'Loginmail',
                },
                !isAkademi(userData) ? {
                    path: 'entity.email',
                    label: 'Faktureringsmail',
                } : null,
                {
                    path: 'login.phone',
                    label: 'Telefon',
                },
                {
                    path: 'entity.createdAt',
                    label: 'Oprettelsesdato',
                    type: 'date',
                    disabled: true,
                },
                [
                    {
                        path: 'login.wantsNews',
                        label: 'Tilmeldt nyhedsbrev',
                        type: 'boolean',
                        tooltip: i18n.wantsNews,
                    },
                    {
                        path: 'login.wantsStatusMails',
                        label: 'Tilmeldt driftsmails',
                        type: 'boolean',
                        tooltip: i18n.wantsStatusMails,
                    },
                ],
            ].filter(x => x)}
        />
    );
};

export default withUserData(PrivateAccountData);