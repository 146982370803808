const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
const DATE_OPTIONS_SHORT = { month: 'short', day: 'numeric' };
const TIME_OPTIONS = { hour: '2-digit', minute:'2-digit' };

export const unixSeconds = date => {
    const asDate = new Date(date);
    return Math.floor(asDate.getTime() / 1000);
};

export function formatDate (time, locale = 'da-DK') {
    if (time === undefined) {
        return '';
    }
    try {
        return new Date(time).toLocaleDateString(locale, DATE_OPTIONS);
    } catch (err) {
        return time;
    }
}

export function formatDateShort (time, locale = 'da-DK') {
    if (time === undefined) {
        return '';
    }
    try {
        return new Date(time).toLocaleDateString(locale, DATE_OPTIONS_SHORT);
    } catch (err) {
        return time;
    }
}

export function formatDateTime (time, locale = 'da-DK') {
    if (time === undefined) {
        return '';
    }
    try {
        return new Date(time).toLocaleTimeString(locale, { ...DATE_OPTIONS, ...TIME_OPTIONS });
    } catch (err) {
        return time;
    }
}

const pad = num => {
    let s = num + '';
    while (s.length < 2) {
        s = '0' + s;
    }
    return s;
};

export const formatISODate = date => {
    const asDate  = new Date(date);
    const year    = asDate.getFullYear();
    const month   = pad(asDate.getMonth() + 1);
    const day     = pad(asDate.getDate());
    return `${year}-${month}-${day}`;
};

export const ISOFromYearMonthDay = ({ year, month, day }) => {
    return [year, month, day].map(pad).join('-');
};

export const dayAbbreviationsDK = [
    'Sø',
    'Ma',
    'Ti',
    'On',
    'To',
    'Fr',
    'Lø',
];

export const monthNamesDK = [
    'Januar',
    'Februar',
    'Marts',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'December',
];

export const danishLocale = {
    localize: {
        month: n => monthNamesDK[n],
        day: n => dayAbbreviationsDK[n],
    },
    formatLong: {},
};