import React, { useCallback, useEffect, useState } from 'react';
import { Button, Message, Form, Grid, Loader, Modal, List } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import compose from 'lodash.flowright';
import { refreshTokens } from 'network/fetch/refreshTokens';
import { getCard, updateCard, getUnpaidSubscriptions, payUnpaidSubscriptions } from 'http/payment';
import { withStripe, getCardToken } from 'util/stripe';
import { isAccountant } from 'util/userMethods';
import withUserData from 'util/withUserData';
import i18n from 'i18n/pages/Payment';
import CardForm from 'design/molecules/CardForm';
import PaymentCard from 'design/atoms/PaymentCard';
import SecurePaymentMessage from 'design/atoms/SecurePaymentMessage';
import handleSCA from 'util/Stripe/handleSCA';

class ConfirmCardError extends Error {
    constructor (message) {
        super(message);
        this.i18n = message;
    }
}

const ConfirmPayment = ({ unpaidSubscriptions, open, onCancel, onConfirm }) => {
    const plur = unpaidSubscriptions.length !== 1 && 'er';
    return (
        <Modal
            open={open}
            onCancel={onCancel}
            onConfirm={onConfirm}
        >
            <Modal.Header>Abonnementbetaling</Modal.Header>
            <Modal.Content>
                Der mangler betaling for følgende abonnement{plur}:
                <List bulleted>
                    {unpaidSubscriptions.map(({ productName }) => (
                        <List.Item content={productName} />
                    ))}
                </List>
                <p>
                    Ved opdatering af kortoplysninger, køres der automatisk
                    en betaling på ovenstående abonnement{plur}.
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button content='Annuller' onClick={onCancel} />
                <Button primary content='OK' onClick={onConfirm} />
            </Modal.Actions>
        </Modal>
    );
};

const UpdateCardForm = props => {
    const [loading, setLoading] = useState(true);
    const [working, setWorking] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [currentCard, setCurrentCard] = useState(null);
    const [updateError, setUpdateError] = useState(null);
    const [unpaidSubscriptions, setUnpaidSubscriptions] = useState([]);
    const [cardStatus, setCardStatus] = useState({});
    const [sessionToken] = useState(window.btoa(Math.random()));

    const {
        onCardUpdated,
        stripe,
        elements,
        userData,
        hideCurrentCard,
        hideCardForm,
        stacked,
    } = props;

    const doFetchUnpaidSubscriptions = useCallback(async () => {
        if (!userData) {
            return setUnpaidSubscriptions([]);
        }

        if (isAccountant(userData)) {
            return setUnpaidSubscriptions([]);
        };

        const unpaidSubscriptions = await getUnpaidSubscriptions();
        setUnpaidSubscriptions(unpaidSubscriptions);
    }, [userData]);

    useEffect(() => {
        doFetchUnpaidSubscriptions();
    }, [doFetchUnpaidSubscriptions]);

    const doFetchCard = useCallback(async () => {
        const card = await getCard(userData);
        setCurrentCard(card);
        setLoading(false);
    }, [userData]);

    useEffect(() => {
        !currentCard && doFetchCard();
    }, [currentCard, doFetchCard]);

    const doCardConfirmation = async clientSecret => {
        const { error } = await stripe.confirmCardSetup(clientSecret);
        if (error) {
            throw new ConfirmCardError(error.message);
        }
    };

    const doUpdateCard = async cardToken => {
        try {
            const resp = await updateCard(cardToken, userData);
            if (resp.requiresAction) {
                await doCardConfirmation(resp.clientSecret);
            }
        } catch (e) {
            const errorMessage = (
                e.i18n ||
                i18n.stripeErrorResponses[e.i18nKey] ||
                i18n.unknownError
            );
            
            throw new Error(errorMessage);
        }
    };

    const onSaveCard = async () => {
        // step 1: get card token
        let tokResp;

        try {
            tokResp = await getCardToken(stripe, elements);
        } catch {
            throw new Error(i18n.unknownError);
        }

        if (tokResp.error) {
            throw new Error(tokResp.error.message);
        }

        if (!tokResp.token) {
            throw new Error(i18n.unknownError);
        }

        // step 2: update card
        if (unpaidSubscriptions?.length > 0) {
            // update card (no SCA) + pay unpaid subscription (w/ SCA)
            await doPayUnpaidSubscriptions(tokResp.token);
        } else {
            // regular card update w/ SCA
            await doUpdateCard(tokResp.token);
        }

        await doFetchCard();
    };

    const doPayUnpaidSubscriptions = async cardToken => {
        const plur = unpaidSubscriptions.length !== 1 ? 'er' : '';

        const paymentResult = await payUnpaidSubscriptions(cardToken.id, sessionToken);
        if (paymentResult.requires3DConfirmation) {
            // handle SCA
            const scaResult = await handleSCA(stripe, paymentResult);
            if (!scaResult.success) {
                throw new Error(scaResult.message);
            }
        } else if (!paymentResult.paid) {
            throw new Error(`Kunne ikke gentegne abonnement${plur}`);
        }

        await refreshTokens(); 
        setUnpaidSubscriptions([]);
        toast.success(`Abonnement${plur} gentegnet`);
    };

    const onCardStatusChanged = status => {
        setCardStatus({ ...status });
        setUpdateError(null);
    };

    const updateCardClicked = () => {
        // tell the user that any unpaid subscriptions will be
        // paid for during the card update
        if (unpaidSubscriptions.length > 0) {
            setConfirming(true);
            return;
        }

        onUpdateCard();
    };

    const onUpdateCard = async () => {
        setWorking(true);
        setUpdateError(null);

        try {
            await onSaveCard();
            onCardUpdated && onCardUpdated();
            toast.success(i18n.cardUpdated);
        } catch (e) {
            setUpdateError(e.message);
        }
        
        setWorking(false);
    };

    const CardFormFields = () => {
        const error = updateError || cardStatus.error;

        const securePaymentColumn = (
            <Grid.Column>
                <SecurePaymentMessage size='medium' />
            </Grid.Column>
        );

        const paymentButtonColumn = (
            <Grid.Column textAlign='right'>
                <Button
                    content={currentCard ? i18n.updateCard : i18n.saveCard}
                    type='submit'
                    onClick={updateCardClicked}
                    disabled={working || !cardStatus.ready}
                    loading={working}
                    primary
                    fluid={stacked}
                />
            </Grid.Column>
        );

        let columnsPerRow;
        let columnOrder;

        if (stacked) {
            columnsPerRow = 1;
            columnOrder = [
                paymentButtonColumn,
                securePaymentColumn,
            ];
        } else {
            columnsPerRow = 2;
            columnOrder = [
                securePaymentColumn,
                paymentButtonColumn,
            ];
        }

        return <Form.Field>
            <Grid columns={columnsPerRow} verticalAlign='middle' stackable>
                {columnOrder}
            </Grid>
            {
                error &&
                <Message
                    content={error}
                    color='red'
                />
            }
        </Form.Field>;
    };

    const CurrentCard = () => {
        return currentCard && <PaymentCard {...currentCard} />;
    };

    if (loading) {
        return <Loader inline='centered' active />;
    }

    if (!currentCard && !isAccountant(userData)) {
        return <p>{i18n.noCardFound}</p>;
    }

    return <>
        {
            !hideCardForm &&
            <CardForm
                disabled={working}
                onChange={onCardStatusChanged}
                fields={<CardFormFields />}
            />
        }
        {
            !hideCurrentCard &&
            <CurrentCard />
        }
        <ConfirmPayment
            open={confirming}
            unpaidSubscriptions={unpaidSubscriptions}
            onCancel={() => setConfirming(false)}
            onConfirm={() => {
                setConfirming(false);
                onUpdateCard();
            }}
        />
    </>;
};

export default compose(
    withUserData,
    withStripe,
)(UpdateCardForm);
