import { IteratorTypes, createIterator } from "./iterators";

class Tree {
    constructor(rootValue) {
        this.root = new Node(rootValue);
    }

    static create(rootValue, getChildrenValues) {
        const addChildrenRec = (parentNode, childrenValues) => {
            childrenValues.forEach(childValue => {
                const newNode = parentNode.add(childValue);
                const newNodeChildren = getChildrenValues(newNode);
                addChildrenRec(newNode, newNodeChildren);
            });
        };
        
        const tree = new Tree(rootValue);
        const rootChildrenValues = getChildrenValues(tree.root);
        addChildrenRec(tree.root, rootChildrenValues);

        return tree;
    }

    // --------------------------------------------------

    findNode(predicate) {
        const iterator = createIterator(IteratorTypes.DEPTH_FIRST, this.root);
        let foundNode = null;
        while(iterator.hasNext() && foundNode === null) {
            const node = iterator.next();
            if(predicate(node)) {
                foundNode = node;
            }
        }
        return foundNode;
    }

    filterNodes(predicate) {
        const iterator = createIterator(IteratorTypes.DEPTH_FIRST, this.root);
        let filteredNodes = [];
        while(iterator.hasNext()) {
            const node = iterator.next();
            if(predicate(node)) {
                filteredNodes.push(node);
            }
        }
        return filteredNodes;
    }

    getDescendants(parentNode, includeParentNode) {
        const descendants = [];

        const iterator = createIterator(IteratorTypes.DEPTH_FIRST, parentNode);
        while(iterator.hasNext()) {
            const node = iterator.next();
            if(node === parentNode) {
                if(includeParentNode) {
                    descendants.push(node);
                }
            } else {
                descendants.push(node);
            }
        }

        return descendants;
    }
}

class Node {
    constructor(value) {
        this.value = value;

        this.parent = null;
        this.children = [];
    }

    isRoot() {
        return this.parent === null;
    }

    add(value) {
        const newNode = new Node(value);
        newNode.parent = this;
        this.children.push(newNode);

        return newNode;
    }
}

export default Tree;
