class TreeDepthFirstIterator {
    /**
     * @param {Object} treeNode - Node of the tree whose descendants are to be traversed
     */
    constructor(treeNode) {
        if(!treeNode) {
            throw new Error(`Missing 'treeNode' parameter`);
        }

        this.treeNode = treeNode;

        // -------------------------

        /* A path is a composition of current node & its ascendants. It is used while backtracking. */
        this.path = [];
        this.visitedNodes = [];
    }

    /**
     * Returns whether the iterator has next element.
     * 
     * @returns {Boolean} Has next element value
     */
    hasNext() {
        const currentNode = this._getCurrentNode();
        if(currentNode === null) {
            return true;
        }

        const next = currentNode.children.find(child => !this.visitedNodes.includes(child));

        return Boolean(next);
    }

    /**
     * Returns the next element based on iterator's current state.
     * 
     * @returns {*} Next element in iteration
     */
    next() {
        const currentNode = this._getCurrentNode();
        if(currentNode === null) {
            this._visitNode(this.treeNode);
            return this.treeNode;
        }

        const next = currentNode.children.find(child => !this.visitedNodes.includes(child)) || null;
        if(next) {
            this._visitNode(next);
        }
          
        /*
            Having visited another node it is preferable to select the next node which has unvisited children by backtracking along current path's ascendants.
            Never bring the path length to 0.
        */
        while(this._childrenVisited() && this.path.length > 1) {
            this.path.pop();
        }

        return next;
    }

    // --------------------------------------------------

    _getCurrentNode() {
        if(this.path.length === 0) {
            return null;
        }

        const lastIndex = this.path.length - 1;
        return this.path[lastIndex];
    };

    _childrenVisited() {
        const currentNode = this._getCurrentNode();
        return currentNode.children.every(child => this.visitedNodes.includes(child));
    }

    _visitNode(node) {
        this.visitedNodes.push(node);
        this.path.push(node);
    }
}

export default TreeDepthFirstIterator;
