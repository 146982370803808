import React from 'react';
import { Radio } from 'semantic-ui-react';
import classNames from 'classnames';

import styles from './RadioOption.module.scss';

const RadioOption = ({ checked, label, disabled, onChange, ...other }) => {    
    return (
        <Radio
            className={classNames(styles.yesNo, ...(checked ? [styles.checked] : []))}
            label={label}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            {...other}
        />
    );
};

export default RadioOption;