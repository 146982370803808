import { getResource, postResource } from 'network/fetch';

const severities = {
    ERR: 'ERR',
    WARN: 'WARN',
    INFO: 'INFO',
};

const report = (severity, message, toLog = {}) => {
    return postResource('app-error', {
        severity,
        message,
        ...toLog,
    });
};

export const getCurrentEntityChatEntries = () => {
    return getResource('reporting-entities/current/chat-entries');
};

export const saveChatEntry = ({ agentName, message, link }) => {
    return postResource('reporting-entities/current/chat-entries', {
        agentName,
        message,
        link,
    });
};

export const reportError = (message, toLog) => {
    return report(severities.ERR, message, toLog);
};

export const reportWarn = (message, toLog) => {
    return report(severities.WARN, message, toLog);
};

export const reportInfo = (message, toLog) => {
    return report(severities.INFO, message, toLog);
};