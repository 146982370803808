import { API_LOCATION } from '../../config/config';
import { DRError } from './DRResponse';
import httpRequest from './httpRequest';

function stringify (x) {
    if (x === null || x === undefined) {
        return null;
    }
    return JSON.stringify(x);
} 

/**
 * @typedef HTTPOptions
 * @type {object}
 * @property {string} [api] - Location of the API to call
 * @property {*} [requestHeaders] - Object containing headers for the HTTP call
 * @property {('impersonationToken'|'accessToken'|'refreshToken')} [token] - Customize which token should be used for the call
 * @property {*} [fetchOptions] - Object containing extra options for the fetch call
 * @property {boolean} [skipTokenValidation] - If true, validate/refresh the users tokens before the call
 */

/**
 * @param {string} url 
 * @param {HTTPOptions} [options]
 */
export function GET (url, options) {
    return httpRequest('GET', url, null, options);
}

/**
 * @param {string} url 
 * @param {any} [body]
 * @param {HTTPOptions} [options]
 */
export function POST (url, body, options) {
    return httpRequest('POST', url, stringify(body), options);
}

/**
 * @param {string} url 
 * @param {any} [body]
 * @param {HTTPOptions} [options]
 */
export function PUT (url, body, options) {
    return httpRequest('PUT', url, stringify(body), options);
}

/**
 * @param {string} url 
 * @param {any} [body]
 * @param {HTTPOptions} [options]
 */
 export function PATCH (url, body, options) {
    return httpRequest('PATCH', url, stringify(body), options);
}

/**
 * @param {string} url 
 * @param {any} [body]
 * @param {HTTPOptions} [options]
 */
export function DELETE (url, body, options) {
    return httpRequest('DELETE', url, stringify(body), options);
}

export function UPLOAD (formData, api = API_LOCATION, path = 'files/upload') {
    return httpRequest('POST', path, formData, { api, requestHeaders: {} });
}

export function REUPLOAD (formData, api = API_LOCATION, path = 'files/upload') {
    return httpRequest('PUT', path, formData, { api, requestHeaders: {} });
}

async function _resource (method, url, body, options) {
    const response = await httpRequest(method, url, stringify(body), options);
    if (!response.wasSuccessful()) {
        throw new DRError(
            response.getMessage(),
            response.getI18n(),
            response.getStatusCode(),
            response.getData(),
        );
    }
    return response.getData();
}

/**
 * @param {string} url 
 * @param {HTTPOptions} [options]
 */
export function getResource (url, options) {
    return _resource('GET', url, null, options);
}

/**
 * @param {string} url
 * @param {*} body 
 * @param {HTTPOptions} [options]
 */
export function postResource (url, body, options) {
    return _resource('POST', url, body, options);
}

/**
 * @param {string} url
 * @param {*} body 
 * @param {HTTPOptions} [options]
 */
export function putResource (url, body, options) {
    return _resource('PUT', url, body, options);
}

/**
 * @param {string} url
 * @param {*} body 
 * @param {HTTPOptions} [options]
 */
export function deleteResource (url, body, options) {
    return _resource('DELETE', url, body, options);
}

/**
 * @param {string} url
 * @param {*} body 
 * @param {HTTPOptions} [options]
 */
 export function patchResource (url, body, options) {
    return _resource('PATCH', url, body, options);
}

export class HTTPClient {
    constructor ({ apiLocation = null, prefix = '' }) {
        this.apiLocation = apiLocation;
        this.prefix = prefix;
    }

    mkUrl = url => {
        if (url?.startsWith('/')) {
            url = url.substring(1);
        }
        
        return `${this.prefix}/${url}`;
    };

    /**
     * @param {HTTPOptions} opts
     * @returns {HTTPOptions}
     */
    mkOpts = (opts = {}) => {
        if (this.apiLocation) {
            opts.api = this.apiLocation;
        }
        return opts;
    };

    /**
     * @param {string} url 
     * @param {HTTPOptions} [options]
     */
    get = (url, options) => {
        return GET(this.mkUrl(url), this.mkOpts(options));
    };

    /**
     * @param {string} url
     * @param {*} body
     * @param {HTTPOptions} [options]
     */
    post = (url, body, options) => {
        return POST(this.mkUrl(url), body, this.mkOpts(options));
    };

    /**
     * @param {string} url
     * @param {*} body
     * @param {HTTPOptions} [options]
     */
    put = (url, body, options) => {
        return PUT(this.mkUrl(url), body, this.mkOpts(options));
    };

    /**
     * @param {string} url
     * @param {*} body
     * @param {HTTPOptions} [options]
     */
    post = (url, body, options) => {
        return DELETE(this.mkUrl(url), body, this.mkOpts(options));
    };

    /**
     * @param {string} url 
     * @param {*} body 
     * @param {HTTPOptions} [options] 
     * @returns 
     */
    patch = (url, body, options) => {
        return PATCH(this.mkUrl(url), body, this.mkOpts(options));
    };

    /**
     * @param {string} url 
     * @param {HTTPOptions} [options]
     */
    getResource = (url, options) => {
        return getResource(this.mkUrl(url), this.mkOpts(options));
    };

    /**
     * @param {string} url
     * @param {*} body
     * @param {HTTPOptions} [options]
     */
    postResource = (url, body, options) => {
        return postResource(this.mkUrl(url), body, this.mkOpts(options));
    };

    /**
     * @param {string} url
     * @param {*} body
     * @param {HTTPOptions} [options]
     */
    putResource = (url, body, options) => {
        return putResource(this.mkUrl(url), body, this.mkOpts(options));
    };

    /**
     * @param {string} url
     * @param {*} body
     * @param {HTTPOptions} [options]
     */
    deleteResource = (url, body, options) => {
        return deleteResource(this.mkUrl(url), body, this.mkOpts(options));
    };

    /**
     * @param {string} url
     * @param {*} body
     * @param {HTTPOptions} [options]
     */
    patchResource = (url, body, options) => {
        return patchResource(this.mkUrl(url), body, this.mkOpts(options));
    };
}