import React from 'react';
import { Icon, Popup, Step } from 'semantic-ui-react';
import { Media } from 'design/atoms/Media';
import { background } from 'styles/colors';
import Stepper from '../../../../atoms/Stepper';

import styles from './breadcrumbBar.module.scss';

class BreadcrumbBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stepperWidth: 80,
        };
    }

    repositionStepper = () => {
        const productPageContainer = document.getElementById('product-wrapper-content');

        const [productWrapperWidth, pageWidth] = [
            productPageContainer.clientWidth,
            document.body.clientWidth
        ];
        const ratio = productWrapperWidth / pageWidth;

        this.setState({
            stepperWidth: ratio * 100
        });
    };

    componentDidMount = () => {
        this.repositionStepper();
        window.addEventListener('resize', this.repositionStepper);
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.repositionStepper);
    };

    maxThree = list => {
        let start = Math.max(0, this.props.highlightIndex - 1);
        let out = [];
        
        if (start === list.length - 2) {
            start--;
        }

        while (out.length < 3 && start < list.length) {
            out.push(list[start++]);
        }

        return out;
    };

    renderBreadcrumb = (section, i) => {
        const { highlightIndex, changeSection, hasPayed, completed } = this.props;
        const { title, breadcrumbTitle, id, icon, requiresPayment } = section;
        const active = highlightIndex === i;
        const locked = !!requiresPayment && !hasPayed;
        // TODO: Reevaluate this solution

        if (locked) {
            return (
                <Popup
                    trigger={
                        <Icon
                            name='lock'
                            color='orange'
                            style={{
                                position: 'absolute',
                                right: '4px',
                                top: '-2px',
                                zIndex: 10,
                            }}
                        />
                    }
                    inverted
                    content='Sektionen er låst'
                    position='top center'
                />
            );
        }

        let bgColor = '';
        if(hasPayed) {
            bgColor = active ? '#d3f1de' : (completed ? '#edf9f1' : 'white');
        } else {
            bgColor = active ? '#ffdec3' : (completed ? '#fff1e6' : 'white');
        }

        return (
            <Step
                link={true}
                active={active}
                completed={completed}
                key={id}
                onClick={() => changeSection(highlightIndex, i - highlightIndex)}
                style={{
                    background: bgColor,
                    color: 'black',
                }}
            >
                <Icon name={icon} size='huge'/>
                <Step.Content>{breadcrumbTitle || title}</Step.Content>
            </Step>
        );
    };

    getMenuProps = ({ children, size }) => {
        return {
            // shared
            tabular: true,
            compact: true,
            attached: 'bottom',
            style: {
                background,
                justifyContent: 'center',
                outline: `1px solid ${background}`,
            },
            icon: 'labeled',

            // device specific properties
            size,
            children,
        };
    };

    getComputerMenuProps = () => this.getMenuProps({
        children: this.props.sections.map(this.renderBreadcrumb),
        size: 'large',
    });

    getTabletMenuProps = () => this.getMenuProps({
        children: this.props.sections.map(this.renderBreadcrumb),
        size: 'tiny',
    });

    getMobile = () => this.getMenuProps({
        children: this.maxThree(this.props.sections.map(this.renderBreadcrumb)),
        size: 'mini',
    });

    render () {
        const { sections, highlightIndex, changeSection, hasPayed, completed } = this.props;
        const { stepperWidth } = this.state;

        
        const steps = sections.map((section, i) => {
            const { title, breadcrumbTitle, icon, requiresPayment } = section;
            const locked = !!requiresPayment && !hasPayed;
            const isSelected = highlightIndex === i;

            return {
                icon,
                title: breadcrumbTitle || title,
                locked,
                isCompleted: completed,
                onSelected: () => changeSection(highlightIndex, i - highlightIndex),
                isSelected,
                bgColorCompleted: hasPayed ? '#edf9f1' : '#fff1e6',
                bgColorSelected: hasPayed ? '#d3f1de' : '#ffdec3',
                borderColor: hasPayed ? '#46C476' : '#ff7606',
            };
        });

        return (
            <div className={styles.stepperContainer}>
                <Media gte='computer'>
                    <Stepper steps={steps} height={50} offset={100 - stepperWidth} selectedStepIndex={highlightIndex} />
                </Media>
                <Media lt='computer' gte='tablet'>
                    <Stepper steps={steps} height={50} offset={100 - stepperWidth} selectedStepIndex={highlightIndex} showPointers={false} showIcons={false} />
                </Media>
                <Media lt='tablet'>
                    <Stepper steps={steps} height={50} offset={100 - stepperWidth} selectedStepIndex={highlightIndex} vicinity={1} showIcons={true} />
                </Media>
            </div>
        );
    }
}

export default BreadcrumbBar;
