import React from 'react';
import { Icon, Label, Popup } from 'semantic-ui-react';
import { SigneeStateCreated } from './SigneeStatus';

// Status på en Visma Addo flow. Altså en samlet status for alle underskrivere i et flow
export const SigningStateFailed          = -1
export const SigningStateCreated         =  1
export const SigningStateStarted         =  2
export const SigningStateCompleted       =  3
export const SigningStateExpired         =  4
export const SigningStateStopped         =  5
export const SigningStateCampaignStarted =  6
export const SigningStateRejected        =  7
// kunstig digital revisor state (den eneste som ikke kommer fra visma addo)
// når nogen har klikket "Lås op"
// overskrives af den efterfølgende opdatering fra Visme
export const SigningStateAdminCancelled  =  100

// states where the sign flow isn't configurable
// where the user has to manually unlock the flow to make changes
export const isFlowLocked = status => {
    return [
        SigningStateCreated,
        SigningStateStarted,
        SigningStateCompleted,
        SigningStateCampaignStarted,
    ].includes(status);
};

const FlowStatus = ({ status }) => {

    let icon;
    let tooltip;
    let text;
    switch (status) {
        case SigningStateFailed:
            icon = <Icon color="red" name='warning sign' />;
            tooltip = "Error occurred.";
            text = "Fejlet";
            break ;
        case SigningStateCreated:
            icon = <Icon color="grey" name='clock' />;
            tooltip = "Signing is created";
            text = "Oprettet";
            break ;
        case SigningStateStarted:
            icon = <Icon color="grey" name='paper plane' />;
            tooltip = "Signing reached its signing start date and sent notifications to recipients.";
            text = "I gang";
            break ;
        case SigningStateCompleted:
            icon = <Icon color="green" name='check' />;
            tooltip = "Finished distribution. Signing is completed.";
            text = "Underskrevet";
            break ;
        case SigningStateExpired:
            icon = <Icon color="yellow" name='clock' />;
            tooltip = "Reached selected signing template expiration date";
            text = "Udløbet";
            break ;
        case SigningStateStopped:
            icon = <Icon color="yellow" name='x' />;
            tooltip = "Stopped by user";
            text = "Stoppet";
            break ;
        case SigningStateCampaignStarted:
            icon = <Icon color="grey" name='question' />;
            tooltip = "Related to Campaigns";
            text = "Kampagne startet";
            break ;
        case SigningStateRejected:
            icon = <Icon color="yellow" name='warning sign' />;
            tooltip = "Recipient rejected to sign document";
            text = "Afvist";
            break ;
        case SigningStateAdminCancelled:
            icon = <Icon color="yellow" name='warning sign' />;
            tooltip = "Processen er annulleret";
            text = "Annulleret";
            break ;    
        default:
            icon = <Icon color="grey" name='question' />;
            tooltip = "Ukendt status: " + status;
    }

    const label = <Label>
        {icon} {text}
    </Label>
    return <Popup trigger={label} content={tooltip} inverted />;
}

export default FlowStatus;

export const FlowStatusToLabel = (status) => {
    switch (status) {
        case SigningStateFailed:
            return "Fejlet";
        case SigningStateCreated:
            return "Oprettet";
        case SigningStateStarted:
            return "I gang";
        case SigningStateCompleted:
            return "Underskrevet";
        case SigningStateExpired:
            return "Udløbet";
        case SigningStateStopped:
            return "Stoppet";
        case SigningStateCampaignStarted:
            return "Kampagne startet";
        case SigningStateRejected:
            return "Afvist";
        case SigningStateAdminCancelled:
            return "Annulleret";
        default:
            return "?";
    }
}

export const FlowStatusDescription = (status) => {
    switch (status) {
        case SigningStateFailed:
            return "Der er sket en fejl";
        case SigningStateCreated:
            return <>Underskriftsprocessen er oprettet. I takt med at anmodninger bliver afsendt,
                    <br/>
                   kan du se status for hver enkelt underskriver nedenfor</>;
        case SigningStateStarted:
            return "Underskriftsprocessen er i gang. Du kan se status for hver enkelt underskriver nedenfor";
        case SigningStateCompleted:
            return "Underskriftsprocessen er gennemført";
        case SigningStateExpired:
            return "Tidsfristen for underskriftsprocessen er udløbet";
        case SigningStateStopped:
            return "Underskriftsprocessen er afbrudt.";
        case SigningStateCampaignStarted:
            return "";
        case SigningStateRejected:
            return "Underskriftsprocessen er afbrudt. Nogen har afvist at skrive under";
        case SigningStateAdminCancelled:
            return "Underskriftsprocessen er annulleret";
        default:
            return "?";
    }
}

// Used to determine field group progress
export const isFlowDone = (status) => {
    if (status === SigningStateCompleted) {
        return true;
    }
    return false;
}

// Callback for LockStatus "Lås op"-button.
// Overwrites flowStatus and all signee.txStatus in "docGenFact"
// and writes to server.
export const resetSignflow = async (docGenFact, fieldDataChanged) => {
    try {
        if (!docGenFact) return ;
        if (!docGenFact.value) return ;
        if (!docGenFact.value.doc_gen_data) return ;
        if (!docGenFact.value.doc_gen_data.signFlow) return ;
        docGenFact.value.doc_gen_data.signFlow.flowStatus = SigningStateAdminCancelled;
        docGenFact.value.doc_gen_data.signFlow.signees.forEach(signee => {
            signee.txStatus = SigneeStateCreated;
        });
        await fieldDataChanged(docGenFact.id, { doc_gen_data: docGenFact.value.doc_gen_data });
        window.location.reload(true)
    } catch (err) {
        console.error("Reset signflow error:", err);
    }
}
