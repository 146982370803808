import React, { Component } from 'react'
import { Message, Button } from 'semantic-ui-react';
import { getOrganisations, registerKey, hasTemporaryRegistration, getOAUTHConsentLink } from 'http/dinero';
import withUserData from 'util/withUserData';
import styles from './connectors.module.scss';

class DineroConnector extends Component {
    state = {
        erpFetching: false,
        organisations: null,
        selectedOrgID: null,
        failed: false,
    };

    setFailed = failed => this.setState({
        failed,
        erpFetching: false,
    });

    async componentDidMount() {
        try {
            if (await hasTemporaryRegistration()) {
                this.fetchAvailableOrganisations();
            }
        } catch {}
    }

    fetchAvailableOrganisations = async () => {
        this.setState({ failed: false, erpFetching: true });
        let organisations;

        try {
            organisations = await getOrganisations();
        } catch {
            this.setFailed(true);
            return;
        }

        this.setState({
            erpFetching: false, 
        });

        if (organisations.length > 1) {
            // let the user choose which organisation to integrate with
            this.setState({ organisations });
            return;
        }

        // auto-pick the first organisation
        this.registerERP(organisations[0].id);
    }

    onDineroSelectOrg = (e) => {
        e.preventDefault();
        this.registerERP(this.state.selectedOrgID);
    }

    registerERP = async (org) => {
        try {
            await registerKey(org);
            this.props.onRegister();
        } catch {
            this.setFailed(true);
        }
    }

    redirectToOauthConsent = async () => {
        window.location.href = await getOAUTHConsentLink(window.location.pathname);
    };

    renderConnectorModule = () => { 
        return (
            <div>
                Alle Dinero-brugere kan lave årsrapport på Digital Revisor - både Dinero Pro og Dinero Starter.
                <div className={styles.actionsContainer}>
                    <Button
                        onClick={this.redirectToOauthConsent}
                        content='Forbind med Dinero'
                        primary
                    />
                </div>
            </div>
        );
    };

    renderCompanySelector = () => {
        const { erpFetching, organisations: orgs, selectedOrgID } = this.state;

        return (
            <div>
                <p>Vælg det firma som du vil forbinde til</p>
                <select
                    size={10}
                    className={styles.orglist}
                    disabled={erpFetching}
                    onChange={e => this.setState({ selectedOrgID: e.target.value })}
                    children={orgs.map(({ id, name }) => {
                        return (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        );
                    })}
                />
                <div style={{ textAlign: 'right' }}>
                    <Button
                        onClick={e => this.onDineroSelectOrg(e)}
                        disabled={erpFetching || !selectedOrgID}
                        loading={erpFetching}
                        content='Vælg'
                        primary
                    />
                </div>
            </div>
        );
    };

    render = () => {
        const { failed, organisations } = this.state;

        const content = (
            organisations?.length > 0
                ? this.renderCompanySelector()
                : this.renderConnectorModule()
        );

        return <>
            {content}
            {failed && (
                <Message negative>
                    Der opstod en fejl. Kontakt os supporten så vi kan hjælpe dig videre.
                </Message>
            )}
        </>
    };
}
 
export default withUserData(DineroConnector);