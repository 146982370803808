export const ERROR_ICON = 'warning circle';
export const WARNING_ICON = 'warning sign';

export const sortChecklist = checklist => {
    const failedChecks = [];
    const passedChecks = [];
    for (let check of checklist) {
        if (check.success) {
            passedChecks.push(check);
        } else {
            failedChecks.push(check);
        }
    }
    return [ ...failedChecks, ...passedChecks ];
};

export const failedChecksReducer = (acc, { success }) => acc += !success;