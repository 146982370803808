/**
 * Maps from file extension to semantic UI icon
 */
 const ext2icon = {
    // image
    jpg:  'image',
    jpeg: 'image',
    png:  'image',
    tiff: 'image',
    gif:  'image',
    svg:  'image',

    // audio
    mp3: 'audio',
    wav: 'audio',
    ogg: 'audio',

    // video
    mp4: 'video',
    avi: 'video',

    // archive
    zip:  'archive',
    rar:  'archive',

    // code
    js:   'code',
    html: 'code',
    css:  'code',

    // pdf
    pdf: 'pdf',

    // excel
    xls:  'excel',
    xlw:  'excel',
    xlt:  'excel',
    csv:  'excel',
    xlsx: 'excel',

    // word
    doc:  'word',
    docx: 'word',
    
    // powerpoint
    ppt:  'powerpoint',
    pptx: 'powerpoint',
    pptm: 'powerpoint',
    
    // alternate (text)
    txt: 'alternate',
    wps: 'alternate',
    md:  'alternate',
};

export const getIconFromExtension = (extension, outline = false) => {
    let lookup = ext2icon[extension];
    let icon = 'file';
    
    if (lookup) {
        icon += ` ${lookup}`;
    }
    
    if (outline) {
        icon += ' outline';
    }

    return icon;
};