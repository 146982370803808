import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Input, Loader, Segment, Icon, Dropdown, Checkbox, Popup } from 'semantic-ui-react';
import get from 'lodash.get';

import crc32 from 'util/crc32';
import withUserData from 'util/withUserData';
import NumberInput from 'design/atoms/NumberInput';
import Text from 'design/atoms/ColoredText';
import ColoredText from 'design/atoms/ColoredText';

import { getExpectedAccountType as getExpectedAccountTypeXbrl } from '../../ClosingSheet/xbrlCodesDK';
import { getExpectedAccountType as getExpectedAccountTypeRevcode } from '../../ClosingSheet/accountantCodesDK';
import TagPicker from './TagPicker';
import styles from './PlanEditor.module.scss';
import { getTaxYearLabels } from 'util/getTaxYearLabels';


const TAG_PROPS = { overrideValue: '', confidence: '1' };
const XBRL_DEFAULT = { value: 'None', ...TAG_PROPS };
const ACC_DEFAULT  = { value: 'none', ...TAG_PROPS };
const HIDE_ACC_NO = false; // skjul kontonummer

const ptypes = {
    TEXT: 'text',
    NUMBER: 'number',
    ENUM: 'enum',
    XBRL_TAG: 'xbrlTag',
    ACCOUNTANT_CODE: 'accountantCode',
    OPTIONS: 'options',
};

const accountTypes = {
    Drift: 'Drift',
    Balance: 'Balance',
    Sum: 'Sum',
    Overskrift: 'Overskrift',
};

const properties = [
    {
        prop: 'number',
        header: () => 'Nr.',
        type: ptypes.TEXT,
        width: 1,
        immutable: true,
        sortable: true,
        className: styles.inputField,
    },
    {
        prop: 'text',
        header: () => 'Kontotekst',
        placeholder: 'Kontotekst...',
        type: ptypes.TEXT,
        width: 2,
        sortable: true,
        debounce: 400,
        className: styles.inputField,
    },
    {
        prop: 'accountType',
        header: () => 'Type',
        type: ptypes.ENUM,
        options: Object.values(accountTypes).map(at => ({
            id: at,
            text: at,
        })),
        className: styles.tcellAccountType,
        width: 2,
        sortable: true,
    },
    {
        prop: 'amount',
        header: ({ taxYearLabels }) => taxYearLabels.activeYear.label,
        tooltip: ({ taxYearLabels }) => taxYearLabels.activeYear.tooltip,
        placeholder: 'Beløb...',
        type: ptypes.NUMBER,
        debounce: 400,
        width: 2,
        className: styles.inputField,
    },
    {
        prop: 'amount',
        lastyear: true,
        placeholder: 'Beløb...',
        header: ({ taxYearLabels }) => taxYearLabels.lastYear.label,
        tooltip: ({ taxYearLabels }) => taxYearLabels.lastYear.tooltip,
        type: ptypes.NUMBER,
        debounce: 400,
        width: 2,
        className: styles.inputField,
    },
    {
        prop: 'taxonomy',
        header: () => 'Rapport',
        className: styles.tcell,
        type: ptypes.XBRL_TAG,
        width: 3,
    },
    {
        prop: 'accountantCode',
        header: () => 'Skat',
        className: styles.tcell,
        type: ptypes.ACCOUNTANT_CODE,
        width: 3,
    },
];


function InputCell ({ Component, onChange, className, ...props }) {
    const valueChanged = value => {
        onChange(value);
    };

    return <Component
        input={<input className={className} />}
        onChange={valueChanged}
        transparent
        {...props}
    />;
}

function TextInputCell ({ onChange, ...props }) {
    const valueChanged = (_, { value }) => {
        onChange(value);
    };

    return <Input
        onChange={valueChanged}
        transparent
        {...props}
    />;
}


function DropdownCell ({ onChange, options, ...props }) {
    const valueChanged = (_, { value }) => {
        onChange(value);
    };

    const formatOptions = () => {
        return options.map(({ id, text }) => ({
            key: id,
            value: id,
            text,
        }));
    };

    return <Dropdown
        onChange={valueChanged}
        options={formatOptions()}
        {...props}
    />;
}


class PlanEditor extends Component {
    state = {
        accounts: [],
        lastyear: [],
        loading: true,
        xbrlTags: null,
        accCodes: null,
        accKeyPrefix: null,
        filters: {
            hideTagsOutsideModel: false,
            hideSumAccounts: true,
        },
        sortBy: 'number',
        sortDirection: 1,
    };

    saveTimer = null;

    componentDidMount = async () => {
        // get product information
        await this.fetchProduct();

        // load last year XBRL figures
        await this.fetchLastYearXBRL();

        // load account plan from props into state
        this.setAccountPlanFromProps();

        this.setState({
            loading: false,
        });
    };

    componentDidUpdate = prevProps => {
        if (prevProps.updating && !this.props.updating) {
            if (prevProps.factsBeingUpdated.has(this.props.options.trigger)) {
                // re-load account plan from props
                this.setAccountPlanFromProps();
            }
        }
    };

    fetchLastYearXBRL = () => {
        if (this.hasRawAccountPlanFromProps()) {
            // already fetched => skip
            return;
        }
        
        if (this.isFetchingLastYearXBRL()) {
            // already fetching => skip
            return;
        }

        // do fetch
        return this.props.fetchERPData();
    };

    getRawAccountPlanFromProps = () => {
        const props = this.props;

        // fetch account plan from full fact map
        const values = get(props, 'values', {});
        const id = get(props, 'id');
        const fact = get(values, id, {});
        const accountPlan = get(fact, 'value');

        return accountPlan;
    };

    hasRawAccountPlanFromProps = () => {
        return !!this.getRawAccountPlanFromProps();
    };

    getAccountPlanFromProps = () => {
        const rawAccountPlan = this.getRawAccountPlanFromProps();
        if (!rawAccountPlan || Object.keys(rawAccountPlan).length === 0) {
            return { accounts: [], lastyear: [] }; 
        }

        return rawAccountPlan;
    };

    hasAccounts = () => {
        const accountPlan = this.getAccountPlanFromProps();
        return Object.keys(accountPlan).length > 0;
    };

    setAccountPlanFromProps = () => {
        const accountPlan = this.getAccountPlanFromProps();

        // "accKeyPrefix" used to create stronger react keys
        // as the account number is an arbitrary integer
        // that could easily colide with an account number
        // from another account plan
        const accKeyPrefix = crc32(JSON.stringify(accountPlan));

        this.setState({
            accounts: accountPlan.accounts || [],
            lastyear: accountPlan.lastyear || [],
            accKeyPrefix,
        });
    };

    sortAccounts = accounts => {
        const { sortBy, sortDirection } = this.state;

        // sorts accounts according to:
        // - picked sort property
        // - sort direction
        return accounts.sort((a, b) => {
            const av = get(a, sortBy);
            const bv = get(b, sortBy);

            if (av > bv) {
                return 1 * sortDirection;
            }

            if (av < bv) {
                return -1 * sortDirection;
            }

            return 0;
        });
    };

    doUnregisterERP = async () => {
        this.setState({ loading: true });

        // causes this component to unmount
        await this.props.unregisterERP();
    };

    isXBRLTagOutsideModel = tag => {
        const { xbrlTags } = this.state;
        return !xbrlTags.includes(tag);
    };

    prepareTagList = list => {
        list = list.filter(li => li.trim());

        // sort tags alphabetically (ascending)
        list.sort((a, b) => {
            if (a > b) {
                return 1;
            }

            if (b > a) {
                return -1;
            }

            return 0;
        });
        
        return list;
    };

    fetchProduct = async () => {
        const {
            requiredXbrlTags,
            requiredAccountantCodes,
            year,
        } = this.props.productMetadata;

        // trim and sort tags
        const xbrlTags = this.prepareTagList(requiredXbrlTags || []);
        const accCodes = this.prepareTagList(requiredAccountantCodes || []);

        const { currentPeriod, lastPeriod } = this.props;

        this.setState({
            xbrlTags,
            accCodes,
            year: Number(year),
            taxYearLabels: getTaxYearLabels(currentPeriod, lastPeriod),
        });
    };

    savePlan = debounce => {
        const { onChange, id } = this.props;

        // prepare save handler
        const doSavePlan = () => {
            onChange(id, {
                // gets account plan properties from props...
                ...this.getAccountPlanFromProps(),

                // ...and overwrite current + last year accounts w/ accounts from state
                accounts: this.state.accounts,
                lastyear: this.state.lastyear,
            });
        };

        // clear current timer
        window.clearTimeout(this.saveTimer);

        // start new timer (if debounce provided)
        if (debounce) {
            this.saveTimer = window.setTimeout(doSavePlan, debounce);
            return;
        }

        // save immediately
        doSavePlan();
    };

    updateAccount = (value, number, prop, lastyear = false) => {
        // get state prop for either current year or last year accounts
        let stateProp;
        if (lastyear) {
            stateProp = 'lastyear';
        } else {
            stateProp = 'accounts';
        }

        const accounts = this.state[stateProp];

        // find account to update & update the value
        const account = accounts.find(a => {
            return number === a.number;
        });
        account[prop] = value;

        // update account plan locally
        this.setState({ [stateProp]: accounts }, () => {
            // update plan serverside
            const property = properties.find(p => p.prop === prop);
            this.savePlan(property.debounce);
        });
    }
    
    accountChanged = (number, prop, lastyear = false) => {
        return value => {
            this.updateAccount(value, number, prop, lastyear);
        };
    };

    newAccount = () => {
        const { accounts, lastyear } = this.state;

        // get next account number based on last account in account plan
        // defaults to 1000
        const lastAccount = accounts[accounts.length - 1];
        const number = lastAccount ? lastAccount.number + 1 : 1000;

        // construct new account and push it to current year and last year accounts
        const account = {
            number,
            taxonomy: { ...XBRL_DEFAULT },
            accountantCode: { ...ACC_DEFAULT },
        };
        accounts.push({ ...account });
        lastyear.push({ ...account });
        this.setState({ accounts, lastyear });
    };

    removeAccount = number => {
        const { accounts, lastyear } = this.state;

        const idx = accounts.findIndex(a => a.number === number);
        if (idx !== -1) {
            // remove locally
            accounts.splice(idx, 1);
            lastyear.splice(idx, 1);

            this.setState({ accounts, lastyear }, () => {
                // save serverside
                this.savePlan();
            });
        }
    };

    shouldRenderAccount = account => {
        const {
            hideTagsOutsideModel,
            hideSumAccounts,
        } = this.state.filters;

        // Is tag outside model?
        if (hideTagsOutsideModel) {
            const outsideModel = this.isXBRLTagOutsideModel(account.taxonomy.value);
            if (outsideModel) {
                return false;
            }
        }

        // Hide sum accounts
        if (hideSumAccounts && account.accountType === accountTypes.Sum) {
            return false;
        }

        // Render account
        return true;
    };

    onFilterChanged = (_, { checked, id }) => {
        const { filters } = this.state;
        filters[id] = checked;
        this.setState({ filters });
    };

    // whether or not the table cell should display with an error
    // currently true for "account type" cells with no value
    getError = (property, account) => {
        const { prop } = property;
        if (prop !== 'accountType') {
            return false;
        }
        const value = account[prop];
        return !value;
    };

    isFetchingLastYearXBRL = () => {
        const { factsBeingUpdated, options } = this.props;

        return factsBeingUpdated.has(options.trigger);
    };

    // hide "account nuymber" column?
    hideAccountNumberColumn = pt => !HIDE_ACC_NO || pt.prop !== "number";

    renderAccProperty = (property, account) => {
        const locked = this.props.isLocked;

        // Prepare updaters
        const updateCurrentYear = p => this.accountChanged(account.number, p, false);
        const updateLastYear = p => this.accountChanged(account.number, p, true); 
        const updateBothYears = p => {
            return value => {
                this.updateAccount(value, account.number, p, false);
                this.updateAccount(value, account.number, p, true);
            };
        };

        // Render the account property column
        const { prop, type, width, placeholder, lastyear, className } = property;
        const value = account[prop];
        const updateRelevantYear = lastyear ? updateLastYear : updateCurrentYear;
        switch (type) {
            case ptypes.NUMBER:
                return <InputCell
                    Component={NumberInput}
                    onChange={updateRelevantYear(prop)}
                    placeholder={placeholder}
                    className={className}
                    value={Number(value)}
                    width={width}
                    disabled={locked}
                    fraction={0}
                />;
            case ptypes.TEXT:
                return <TextInputCell
                    onChange={updateBothYears(prop)}
                    placeholder={placeholder}
                    className={className}
                    defaultValue={value}
                    width={width}
                    disabled={locked}
                />;
            case ptypes.ENUM:
                return <DropdownCell
                    account={account}
                    defaultValue={value}
                    options={property.options}
                    placeholder='Vælg en...'
                    onChange={updateBothYears(prop)}
                    disabled={locked}
                />;                
            case ptypes.XBRL_TAG:
            case ptypes.ACCOUNTANT_CODE:
                let list = [];
                let isXBRL = false;
                if (type === ptypes.XBRL_TAG) {
                    isXBRL = true;
                    list = this.state.xbrlTags;
                    if (account.accountType) {
                        list = list.filter(xtag => account.accountType === getExpectedAccountTypeXbrl(xtag));
                    }
                } else if (type === ptypes.ACCOUNTANT_CODE) {
                    list = this.state.accCodes;
                    if (account.accountType) {
                        list = list.filter(revcode => account.accountType === getExpectedAccountTypeRevcode(revcode));
                    }
                }
                return <TagPicker
                    value={value}
                    onChange={updateBothYears(prop)}
                    tags={list}
                    disabled={!account.accountType || locked}
                    displayError={isXBRL}
                />;
            default:
                console.error('Unknown account prop type: ' + type);
        }
    };

    renderAccount = account => {
        if (!this.shouldRenderAccount(account)) {
            return;
        }
        const outsideModel = this.isXBRLTagOutsideModel(account.taxonomy.value);
        const key = `${this.state.accKeyPrefix}#${account.number}`;
        const locked = this.props.isLocked;
        return (
            <Table.Row key={key} error={outsideModel}>
                {
                    properties
                    .filter(this.hideAccountNumberColumn)
                    .map(pt => {
                        let accountToUse;
                        if (pt.lastyear) {
                            accountToUse = this.state.lastyear.find(a => a.number === account.number);
                        } else {
                            accountToUse = account;
                        }
                        const error = this.getError(pt, accountToUse);
                        const content = this.renderAccProperty(pt, accountToUse);
                        return <Table.Cell
                            key={`${account.number}:${pt.prop}:${!!pt.lastyear}`}
                            className={pt.className}
                            disabled={pt.immutable}
                            width={pt.width}
                            content={content}
                            error={error}
                            icon={error ? "warning" : null}
                        />
                    })
                }
                <Table.Cell textAlign='center'>
                    <Popup
                        inverted
                        content='Slet'
                        position='right center'
                        disabled={locked}
                        trigger={
                            <Icon
                                link={!locked}
                                name='x'
                                color='red'
                                disabled={locked}
                                onClick={() => this.removeAccount(account.number)}
                            />
                        }
                    />
                </Table.Cell>
            </Table.Row>
        );
    };

    renderAccounts = () => {
        const accounts = this.sortAccounts(this.state.accounts);
        return accounts.map(this.renderAccount);
    };

    renderTableHeader = () => {
        const { sortBy, sortDirection } = this.state;

        return (
            <Table.Header>
                <Table.Row>
                    {properties
                    .filter(this.hideAccountNumberColumn)
                    .map(({ sortable, prop, header: h, tooltip: t }) => {
                        // prepare header text
                        const header = h(this.state);

                        // prepare tooltip text
                        const tooltip = t && t(this.state);

                        // check if current column is sort target
                        let sorted;
                        let nextDirection = 1;
                        if (sortable && sortBy === prop) {
                            if (sortDirection === 1) {
                                sorted = 'ascending';
                                nextDirection = -1;
                            } else {
                                sorted = 'descending';
                                nextDirection = 1;
                            }
                        }

                        const headerSpan = <span>{header}</span>;
                        const content = (
                            tooltip
                                ? (
                                    <Popup
                                        inverted
                                        trigger={headerSpan}
                                        content={tooltip}
                                        position='top center'
                                    />
                                )
                                : headerSpan
                        );

                        return (
                            <Table.HeaderCell
                                key={header}
                                content={content}
                                sorted={sorted}
                                onClick={() => {
                                    if (sortable) {
                                        this.setState({
                                            sortBy: prop,
                                            sortDirection: nextDirection,
                                        });
                                    }
                                }}
                            />
                        );
                    })}
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
        );
    };

    renderTableBody = () => (
        <Table.Body>
            {this.renderAccounts()}
        </Table.Body>
    );

    renderTableFooter = () => (
        <Table.Footer>
            <Table.Row textAlign='right'>
                <Table.HeaderCell colSpan={properties.length + 1}>
                    <Text color='green' link padded onClick={this.newAccount} disabled={this.props.isLocked}>
                        <Icon name='plus' />
                        Tilføj kontolinje
                    </Text>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    );

    renderAccountsTable = () => (
        <Table size='small' striped celled sortable>
            {this.renderTableHeader()}
            {this.renderTableBody()}
            {this.renderTableFooter()}
        </Table>
    );

    renderLoading = loadingMessage => (
        <Segment basic textAlign='center'>
            <Loader inline active size='big' />
            {loadingMessage && <p>{loadingMessage}</p>}
        </Segment>
    );

    renderFilterOptions = () => {
        const { 
            hideTagsOutsideModel,
            hideSumAccounts,
        } = this.state.filters;

        return (
            <Segment>
                <Checkbox
                    label='Skjul tags der ikke indgår i modellen'
                    id='hideTagsOutsideModel'
                    className={styles.filtercb}
                    defaultChecked={hideTagsOutsideModel}
                    onChange={this.onFilterChanged}
                    toggle
                />
                <br />
                <Checkbox
                    label='Skjul konti af typen "Sum"'
                    id='hideSumAccounts'
                    className={styles.filtercb}
                    defaultChecked={hideSumAccounts}
                    onChange={this.onFilterChanged}
                    toggle
                />
            </Segment>
        );
    };

    renderChangeERP = () => {
        return <ColoredText
            link
            content='Skift bogføringssystem'
            color='blue'
            icon='exchange'
            onClick={this.doUnregisterERP}
        />;
    };
 
    goToCVR = () => {
        const { userData } = this.props;
        return window.open("https://datacvr.virk.dk/enhed/virksomhed/"+ userData?.cvr +"/", "_blank")
    };

    renderGoToCVR = () => {
        return <ColoredText
        link
        content='Hop til CVR'
        color='blue'
        icon="archive"
        onClick={this.goToCVR}
    />
    };

    render () {
        const { loading } = this.state;
        // render spinner, if loading product data
        // (component scope)
        if (loading) {
            return this.renderLoading();
        }

        // render spinner, if fetching last year XBRL
        // (product engine scope)
        if (this.isFetchingLastYearXBRL()) {
            return this.renderLoading('Indlæser...');
        }
    
        return <>
            {this.renderFilterOptions()}
            {this.renderAccountsTable()}
            {this.renderChangeERP()}
            <br/>
            {this.renderGoToCVR()}
        </>;
    }
}

export default withUserData(withRouter(PlanEditor));