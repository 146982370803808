import { useEffect, useState } from 'react';
import TokenUser from './TokenUser';
import * as token from './token';
import * as events from './pubsub';

// @todo: refactor and use react context
// for this hook (and withUserData)

const getRelevantToken = tokenHierarchy => {
    for (let { hasToken, getToken } of tokenHierarchy) {
        if (hasToken()) {
            return getToken();
        }
    }

    return null;
};

const createTokenUserHook = (tokenHierarchy) => {
    return () => {
        const [user, setUser] = useState(new TokenUser(getRelevantToken(tokenHierarchy)));

        useEffect(() => {
            const subscriptions = tokenHierarchy.map(({ topic }) => {
                return events.subscribe(topic, () => {
                    const newUser = new TokenUser(getRelevantToken(tokenHierarchy));
                    setUser(newUser);
                });
            });

            return () => {
                subscriptions.forEach(events.unsubscribe);
            };
        }, []);
        
        return user;
    };
};

// will use the impersonated user, if available
// else the root user
const useUser = createTokenUserHook([
    {
        topic: token.IMPERSONATION_TOKEN_CHANGED,
        hasToken: token.hasImpersonationToken,
        getToken: token.getImpersonationToken,
    },
    {
        topic: token.ACCESS_TOKEN_CHANGED,
        hasToken: token.hasAccessToken,
        getToken: token.getAccessToken,
    },
]);

// will only use the root user, even if impersonation is active
export const useRootUser = createTokenUserHook([
    {
        topic: token.ACCESS_TOKEN_CHANGED,
        hasToken: token.hasAccessToken,
        getToken: token.getAccessToken,
    },
]);

export default useUser;