const languageCodes = {
    enUS: 'en-US',
    daDK: 'da-DK',
};

const languageCodeDescriptions = {
    [languageCodes.daDK]: {
        description: 'Dansk',
        semanticFlag: 'dk',
    },
    [languageCodes.enUS]: {
        description: 'English',
        semanticFlag: 'us',
    },
};

const LANG_PROP = 'drAppLang';

export const getLanguageDescription = lcode => {
    let lookup = languageCodeDescriptions[lcode];
    if (!lookup) {
        lookup = languageCodeDescriptions[languageCodes.daDK];
    }
    return lookup;
};

export const getSelectedLanguage = () => {
    return sessionStorage[LANG_PROP] || languageCodes.daDK;
};

export const setSelectedLanguage = lang => {
    sessionStorage[LANG_PROP] = lang;
    window.location.reload();
};

export default languageCodes;