import React from 'react'
import { Header, Icon } from 'semantic-ui-react';

const SegmentHeader = ({ icon, content, subheader }) => (
    <Header as='h3'>
        <Icon name={icon} />
        <Header.Content>
            <span>{content}</span>
            { subheader && <Header.Subheader>{subheader}</Header.Subheader> }
        </Header.Content>
    </Header>
);

export default SegmentHeader;