import React, { useState } from 'react';
import { Button, Grid, Header, Icon, Message, Modal } from 'semantic-ui-react';
import {
    sortChecklist,
    failedChecksReducer,
    ERROR_ICON,
    WARNING_ICON,
} from './util';
import ColoredText from 'design/atoms/ColoredText';
import { isManualReviewNeeded, isManualReviewPassed } from 'util/ManualReview.js';
import withUserData from 'util/withUserData';
import { isAdmin } from 'util/userMethods';

import { TypeYearReportData } from '../../Fact';
import CheckAccordion from './CheckAccordion';
import FieldGroup from 'design/atoms/FieldGroup';

const textVariations = {
    header: {
        privat: 'Kvalitetskontrol af indberetning',
        erhverv: 'Kvalitetskontrol mod Erhvervsstyrelsen',
    },
    errorAdviceSuffix: {
        privat: 'før du indberetter til SKAT',
        erhverv: 'før årsrapporten kan sendes til underskrift',
    },
    warningAdviceSuffix: {
        privat: 'for at øge kvaliteten af din indberetning',
        erhverv: 'for at opnå en årsrapport af højere kvalitet',
    },
    fixErrorsSuffix: {
        privat: 'før du kan indberette',
        erhverv: 'før du kan underskrive rapporten',
    },
    fixWarningsSuffix: {
        privat: 'som kan rettes for at forøge kvaliteten af din indberetning',
        erhverv: 'som kan rettes for at forøge kvaliteten af årsrapporten',
    },
};

const getSegment = userData => (
    userData?.cvr
        ? 'erhverv'
        : 'privat'
);

// UI components for the first part of the "Tjekliste" modal
// detailing the status of a manual review, as applicable.
const getManualReviewItems = (allGreen, isManReviewNeeded, isManReviewPassed) => {
    const mrHeader = <Header size='small' key='mrheader'>
        Manuel kvalitetssikring af din årsrapport
    </Header>;
    if (isManReviewNeeded) {
        const mrBody = <Message icon info key="mrbody">
            <Icon name='attention' />
            <Message.Content>
                <Message.Header>Hvad gør vi</Message.Header>
                Din årsrapport kunne ikke kvalitetssikres automatisk.
                Derfor kigger vores eksperter nu manuelt din årsrapport igennem for følgende:
                <ul>
                <li>At kontoplan og tal er kommet korrekt fra bogføring</li>
                <li>At kontoplanen omdannes til en korrekt opstillet årsrapport</li>
                <li>Korrekt håndtering af skattefradrag</li>
                <li>At årsrapporten stemmer og balancerer</li>
                </ul>
                <Message.Header>Hvad kan du gøre</Message.Header>
                Du fortsætter blot processen på Digital Revisor, svarer på spørgsmål og kigger årsrapporten igennem.<br/>
                Vi sender en mail, når årsrapporten er gennemgået og klar til underskrift og indsendelse.<br/>
                Vi gennemgår den indenfor 24 timer.
            </Message.Content>
        </Message>;
        return [ mrHeader, mrBody ];
    } else if (isManReviewPassed) {
        const tekst1 = <>Vores eksperter har manuelt gennemgået din årsrapport og kvalitetssikret niveauet.</>;
        const tekst2 = <>Vi har gennemgået din årsrapport, men kunne ikke kvalitetssikre niveauet.<br/>Kontakt venligst supporten, så vi kan hjælpe dig i mål.</>;
        const tekst = allGreen ? tekst1 : tekst2;
        const icon = allGreen ? 'check circle' : 'attention';
        const mrBody = <Message icon info key="mrbody">
            <Icon name={icon} />
            <Message.Content>
                { tekst }
            </Message.Content>
        </Message>;
        return [ mrHeader, mrBody ];
    }

    return [];
}

// om alle punkter i Tjeklisten er OK (kun errors, ej warnings)
const isChecklistAllGreen = (errors) => {
    errors = errors || [];
    return errors.every(e => e.success)
}

const renderModalContent = ({ errors, warnings }, isManReviewNeeded, isManReviewPassed, userSegment) => {
    errors = sortChecklist(errors);
    warnings = sortChecklist(warnings);

    let manualReviewItems = getManualReviewItems(isChecklistAllGreen(errors), isManReviewNeeded, isManReviewPassed);

    return <>
        { manualReviewItems }
        <Header size='small'>
            Følgende tjek <u>SKAL</u> være bestået {textVariations.errorAdviceSuffix[userSegment]}:
        </Header>
        <CheckAccordion
            color='red'
            msgOnFail='Fejlet'
            failHeader='Fejl'
            list={errors}
            icon={ERROR_ICON}
            openUnsuccessfulChecks
            disabled={isManReviewNeeded}
        />
        <Header size='small'>
            Følgende tjek kan imødekommes {textVariations.warningAdviceSuffix[userSegment]}:
        </Header>
        <CheckAccordion
            color='yellow'
            msgOnFail='Advarsel'
            failHeader='Advarsel'
            list={warnings}
            icon={WARNING_ICON}
            disabled={isManReviewNeeded}
        />
    </>;
}

const checklistIsRunning = (productMetadata, factsBeingUpdated) => {
    if (factsBeingUpdated.size === 0) {
        return false;
    }

    const yearReportDataFact = Object.values(productMetadata.facts).find(fact => {
        return fact.dataType === TypeYearReportData;
    });

    if (!yearReportDataFact) {
        return false;
    }

    return factsBeingUpdated.has(yearReportDataFact.options?.triggerFact);
};

function Checklist({ value: checklist, hasPayed, options = {}, values, userData, productMetadata, factsBeingUpdated }) {
    const isManReviewNeeded = isManualReviewNeeded(values);
    const isManReviewPassed = isManualReviewPassed(values);

    const [modalOpen, setModalOpen] = useState(false);
    const openModal  = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    if (!checklist) return null;
    if (!hasPayed && !isAdmin(userData)) return null; // don't show to freebee users. always show to admins

    const userSegment = getSegment(userData);
    const header = options.title || textVariations.header[userSegment];
    
    const renderModal = () => {
        if (!modalOpen) {
            return;
        }
        return (
            <Modal
                open
                onOpen={openModal}
                onClose={closeModal}
            >
                <Header icon='tasks' content={header} />
                <Modal.Content scrolling>
                    {renderModalContent(checklist, isManReviewNeeded, isManReviewPassed, userSegment)}
                </Modal.Content>
                <Modal.Actions>
                    <Button className='greyBtn' onClick={closeModal}>
                        OK
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    const errorCount = checklist.errors.reduce(failedChecksReducer, 0);
    const warningCount = checklist.warnings.reduce(failedChecksReducer, 0);
    const successCount = checklist.errors.length + checklist.warnings.length - errorCount - warningCount;

    const checklistSummary = [
        {
            failed: errorCount > 0,
            failIcon: 'times circle',
            failColor: 'red',
            count: errorCount,
            text: `fejl`,
        },
        {
            failed: warningCount > 0,
            failIcon: 'warning circle',
            failColor: 'yellow',
            count: warningCount,
            text: `${warningCount === 1 ? 'advarsel' : 'advarsler'}`,
        },
        {
            failed: false,
            count: successCount,
            text: `${successCount === 1 ? 'godkendt tjek' : 'godkendte tjek'}`,
        },
    ];

    checklistSummary.sort((a, b) => {
        if (a.failed !== b.failed) {
            return b.failed - a.failed;
        }

        return undefined;
    });

    const summaryItems = checklistSummary.map(({ failed, failIcon, failColor, count, text }) => (
        <div style={{ cursor: 'pointer' }}>
            <Icon
                name={failed ? failIcon : 'check circle'}
                color={failed ? failColor : 'green'}
            /> <b>{count}</b> {text}
        </div>
    ));

    const getStatusMessage = () => {
        if (errorCount === 0) {
            const successIcon = warningCount === 0 && <Icon name='check circle' color='green' />;

            const warningSuffix = warningCount > 0 && (
                <span> med <ColoredText color='yellow' link>advarsler</ColoredText></span>
            );

            return (
                <span>
                    {successIcon}Kvalitetskontrol bestået{warningSuffix}
                </span>
            );
        }

        const errCountText = <ColoredText color='red' bold link>{errorCount} fejl</ColoredText>;

        return (
            <span>
                Du skal løse {errCountText} {textVariations.fixErrorsSuffix[userSegment]}
            </span>
        );
    };

    const renderContent = () => {
        if (checklistIsRunning(productMetadata, factsBeingUpdated)) {
            return (
                <div>
                    <b>Status:</b>
                    <br />
                    <Icon name='spinner' loading />Kvalitetskontrollen eksekveres...
                </div>
            );
        }

        return (
            <Grid
                verticalAlign='middle'
                columns={2}
                divided
                stackable
            >
                <Grid.Column>
                    <b>Status:</b>
                    <br />
                    {getStatusMessage()}
                </Grid.Column>
                <Grid.Column>
                    {summaryItems}
                </Grid.Column>
            </Grid>
        );
    };

    return (
        <>
            <FieldGroup
                title={<span><Icon name='tasks' />{header}</span>}
                children={<div>{renderContent()}</div>}
                onClick={openModal}
            />
            {renderModal()}
        </>
    );
}

export default withUserData(Checklist);
