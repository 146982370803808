import React, { useState, useEffect } from 'react';
import { Button, Modal, Dropdown } from 'semantic-ui-react';
import UploadLogo, { CurrentLogo } from '../../molecules/UploadLogo';
import { toast } from 'react-toastify';
import { patchLoggedInUser, getLoggedInUserData } from 'http/accounts';


const LogoSettings = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [position, setPosition] = useState('');
    const [positionUpdate, setPositionUpdate] = useState('');
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            const userData = await getLoggedInUserData();
            setUser(userData);
            setLoading(false);
        };
    
        fetchUser();
    }, []);
    
    if (loading) {
        return <div>Loading...</div>; 
    }
    
    const hasLogo = user && !!user.logo;
    
    let content;
    let border;
    let text;

    if (hasLogo) {
        content = <CurrentLogo userData={user} />;
        border = '1px solid lightgray';
        text = 'Skift firmalogo';
    } else {
        content = <i>Intet firmalogo valgt</i>;
        border = '2px dashed lightgray';
        text = 'Vælg firmalogo';
    }

   const positionOptions = [
        { key: 'left', text: 'Venstre', value: 'left' },
        { key: 'center', text: 'Midten', value: 'center' },
        { key: 'right', text: 'Højre', value: 'right' },
    ];

    /* Pure UI so the information is given in danish */
    let userContext;
    if (user.logoPosition === 'left') {
        userContext = 'Venstre';

    } else if (user.logoPosition === 'center') {
        userContext = 'Midten';

    } else if (user.logoPosition === 'right') {
        userContext = 'Højre';

    } else {
        userContext = 'Vælg logo position';
    }

    return (
        <>
            <div
                style={{
                    border,
                    textAlign: 'center',
                    borderRadius: '4px',
                    padding: '1em',
                }}
            >
                {content}
            </div>
            <br />
            <Dropdown
                placeholder={`Logo position: ${userContext}`}
                fluid
                selection
                options={positionOptions}
                value={`Vælg logo position:${positionOptions.text}`}
                onChange={async (e, { value }) => {
                    setPosition(value);
                    setPositionUpdate(value);
                    user.logoPosition = value;
                    await patchLoggedInUser({
                        logoPosition: user.logoPosition,
                    },
                       
                );
                    toast.success(`Logo position opdateret til ${user.logoPosition}`);
                }}
            />
            <br />

            <Button
                primary
                fluid
                icon='image'
                content={text}
                onClick={() => setModalOpen(true)}
            />
            <Modal
                onClose={() => setModalOpen(false)}
                onOpen={() => setModalOpen(true)}
                open={modalOpen}
                style={{
                    display: 'inline-block',
                    width: 'initial',
                    minWidth: '500px'
                }}
            >
                <Modal.Header>{text}</Modal.Header>
                <Modal.Content>
                    <UploadLogo />
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setModalOpen(false)}>
                        Luk
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default LogoSettings;