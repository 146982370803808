import React, { createContext, useContext, useEffect, useState } from 'react';
import { getBalance } from 'http/credits';
import { useRootUser } from 'util/useUser';

// Create a context for the credits
export const CreditsContext = createContext({
    credits: 0,
    loading: true,
    error: null,
    initialFetchDone: false,
    setCredits: () => {},
    refetch: async () => {},
});

export const useCreditsContext = () => {
    return useContext(CreditsContext);
};

let _currentRequestPromise = null;
const doGetBalance = async () => {
    if (!_currentRequestPromise) {
        _currentRequestPromise = getBalance();
    }

    try {
        const balance = await _currentRequestPromise;
        _currentRequestPromise = null;
        return balance;
    } catch (e) {
        _currentRequestPromise = null;
        throw e;
    }
};

const CreditsProvider = ({ children }) => {
    const [credits, setCredits] = useState(0);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialFetchDone, setInitialFetchDone] = useState(false);

    const user = useRootUser();

    const fetchBalance = async () => {
        setLoading(true);
        try {
            const result = await doGetBalance();
            setCredits(result);
            setError(null);
            setInitialFetchDone(true);
        } catch (error) {
            setError(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!user.id) return;
        
        fetchBalance();
    }, [user.id]);

    return (
        <CreditsContext.Provider
            value={{
                credits,
                setCredits,
                loading,
                error,
                initialFetchDone,
                refetch: fetchBalance,
            }}
            children={children}
        />
    );
};

export default CreditsProvider;