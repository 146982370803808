import React from 'react';
import { Form, Message, Button, Header } from 'semantic-ui-react';

import { requestPasswordReset } from '../../../http/accounts';
import i18n from '../../../i18n/pages/ResetRequest';
import HeroForm from 'design/atoms/HeroForm';
import ColoredText from 'design/atoms/ColoredText';

class ResetRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isFetching: false,
            resetSent: false,
            emailVal: '',
        };
    }

    handleResetRequest = async (e) => {
        const { emailVal } = this.state;
        e && e.preventDefault();

        if (this.state.resetSent || this.state.isFetching) {
            return;
        }

        this.setState({ isFetching: true });

        try {
            await requestPasswordReset(emailVal);
            this.setState({ resetSent: true });
        } catch (e) {
            this.setState({ error: e.message });
        }

        this.setState({ isFetching: false });
    };

    handleEmailChange = (event) => {
        this.setState({
            emailVal: event.target.value,
            error: null,
        });
    };

    renderHeader = () => {
        return (
            <Form.Field>
                <Header>Glemt kodeord?</Header>
                {!this.state.resetSent && (
                    <div style={{ marginBottom: '2em' }}>
                        Indtast din email nedenfor og følg instruktioner i mail.
                    </div>
                )}
            </Form.Field>
        );
    };

    renderResetConfirmation = () => {
        return (
            <Form.Field>
                <Message color='green'>
                    <Message.Header>{i18n.successMsgHeader}</Message.Header>
                    {i18n.successMsg}
                </Message>
            </Form.Field>
        );
    };

    renderEmailPrompt = () => {
        const { error } = this.state;

        return (
            <Form.Input
                id='email'
                label={i18n.ResetForm.emailLabel}
                autoCorrect='off'
                autoCapitalize='off'
                type='email'
                placeholder={i18n.ResetForm.emailPlaceholder}
                value={this.state.emailVal}
                onChange={this.handleEmailChange}
                error={error}
            />
        );
    };

    render = () => {
        const { isFetching, resetSent } = this.state;

        let body;
        if (resetSent) {
            body = this.renderResetConfirmation();
        } else {
            body = this.renderEmailPrompt();
        }

        return (
            <HeroForm>
                <Form onSubmit={this.handleResetRequest}>
                    {this.renderHeader()}
                    {body}
                    <Form.Field>
                        <Button
                            primary
                            fluid
                            onClick={this.handleLogin}
                            disabled={isFetching || resetSent}
                            loading={this.state.isFetching}
                            content={i18n.ResetForm.resetButton}
                            style={{ marginTop: '2em' }}
                        />
                    </Form.Field>
                    <Form.Field style={{ textAlign: 'center' }}>
                        <ColoredText
                            link='/login'
                            content={i18n.linkToLogin}
                            color='green'
                        />
                    </Form.Field>
                </Form>
            </HeroForm>
        );
    };
}

export default ResetRequest;
