import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    sorry: i18n({
        [daDK]: 'Beklager! Noget gik galt',
        [enUS]: 'Sorry! Something went wrong',
    }),
    unexpectedError: i18n({
        [daDK]: 'Der opstod en ukendt fejl. Vores udviklerteam er blevet underrettet. Hvis problemet fortsætter, må du gerne skrive til os på support@digitalrevisor.nu eller skriv til os i chatten.',
        [enUS]: 'An unknown error occurred. Our development team has been notified. If the issue persists, please contact us at support@digitalrevisor.nu or send a direct message.',
    }),
    options: {
        youCanTryTo: i18n({
            [daDK]: 'Du kan prøve at',
            [enUS]: 'You can try',
        }),
        gotoPreviousPage: i18n({
            [daDK]: 'gå tilbage til den side du kom fra',
            [enUS]: 'going back to the page you came from',
        }),
        or: i18n({
            [daDK]: 'eller',
            [enUS]: 'or',
        }),
        gotoFrontpage: i18n({
            [daDK]: 'gå til forsiden',
            [enUS]: 'go to the frontpage',
        }),
    },
};