import React from 'react';
import UiElementHeader from '../components/UiElementHeader';
import UiElementText from '../components/UiElementText';
import UiElementDivider from '../components/UiElementDivider';
import UiElementLink from '../components/UiElementLink';
import UiElementSegment from '../components/UiElementSegment';
import UiGroupToggler from '../components/UiElementGroupToggler';
import UiElementAutomatedBooking from '../components/UiElementAutomatedBooking';
import UiElementEmbeddedVideo from '../components/UiElementEmbeddedVideo';
import UiElementBookingDiscrepancyChecker from '../components/UiElementBookingDiscrepancyChecker';

export const UiElementTypes = {
    'header': {
        id: 'header',
        Component: UiElementHeader,
    },

    'divider': {
        id: 'divider',
        Component: UiElementDivider,
    },

    'link': {
        id: 'link',
        Component: UiElementLink,
    },

    'segment': {
        id: 'segment',
        Component: UiElementSegment,
    },

    'group-toggler': {
        id: 'group-toggler',
        Component: UiGroupToggler,
    },

    'automated-booking': {
        id: 'automated-booking',
        Component: UiElementAutomatedBooking,
    },

    'text': {
        id: 'text',
        Component: UiElementText,
    },

    'embedded-video': {
        id: 'embedded-video',
        Component: UiElementEmbeddedVideo,
    },

    'booking-discrepancy-checker': {
        id: 'booking-discrepancy-checker',
        Component: UiElementBookingDiscrepancyChecker,
    },
};

class UiElement extends React.Component {
    getUiElementComponent (type) {
        const lookup = UiElementTypes[type];
        if (!lookup) {
            throw new Error(`Unknown type '${type}'`);
        }

		return lookup.Component;
    }

    render () {
        const { field, label, text, suffix, tooltip, youtubeID, youtubeVideo, link, divider, automatedBooking, segment, styleOptions, values, taxYear, productLabel, onChange, hasPayed, fieldOptions, paymentURL, primoModelData } = this.props;
        const Component = this.getUiElementComponent(field.type);

        return (
            <Component
                key={field.type}
                label={label}
                text={text}
                suffix={suffix}
                tooltip={tooltip}
                youtubeID={youtubeID}
                youtubeVideo={youtubeVideo}
                link={link}
                divider={divider}
                automatedBooking={automatedBooking}
                segment={segment}
                styleOptions={styleOptions}
                {...field}
                paymentURL={paymentURL}
                fieldOptions={fieldOptions}
                values={values}
                taxYear={taxYear}
                productLabel={productLabel}
                onChange={onChange}
                hasPayed={hasPayed}
                primoModelData={primoModelData}
	        />
        );
    }
}

export default UiElement;
