import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    nameLabel: i18n({
        [daDK]: 'Fornavn:',
        [enUS]: 'First name:',
    }),
    lastnameLabel: i18n({
        [daDK]: 'Efternavn:',
        [enUS]: 'Last name:',
    }),
    firstnamePlaceholder: i18n({
        [daDK]: 'Fornavn',
        [enUS]: 'First name',
    }),
    lastnamePlaceholder: i18n({
        [daDK]: 'Efternavn',
        [enUS]: 'Last name',
    }),
    classNameLabel: i18n({
        [daDK]: 'Klassenavn:',
        [enUS]: 'Class name:',
    }),
    classNamePlaceholder: i18n({
        [daDK]: 'Klassenavn',
        [enUS]: 'Class name',
    }),
    emailLabel: i18n({
        [daDK]: 'Email:',
        [enUS]: 'Email:',
    }),
    emailPlaceholder: i18n({
        [daDK]: 'ditnavn@email.com',
        [enUS]: 'yourname@email.com',
    }),
    cvrLabel: i18n({
        [daDK]: 'CVR:',
        [enUS]: 'CVR:',
    }),
    cvrPlaceholder: i18n({
        [daDK]: 'CVR nummer',
        [enUS]: 'CVR identifier',
    }),
    cvrNamePlaceholder: i18n({
        [daDK]: 'Virksomhed',
        [enUS]: 'Company',
    }),
    cvrRoadPlaceholder: i18n({
        [daDK]: 'Adresse',
        [enUS]: 'Address',
    }),
    cvrCityPlaceholder: i18n({
        [daDK]: 'Postkode',
        [enUS]: 'Zipcode',
    }),
    phoneLabel: i18n({
        [daDK]: 'Telefon:',
        [enUS]: 'Phone:',
    }),
    phonePlaceholder: i18n({
        [daDK]: '12345678',
        [enUS]: '12345678',
    }),
    passwordLabel: i18n({
        [daDK]: 'Kodeord: ',
        [enUS]: 'Password: ',
    }),
    passwordPlaceholder: i18n({
        [daDK]: 'Vælg kodeord',
        [enUS]: 'Choose password',
    }),
    repeatPasswordLabel: i18n({
        [daDK]: 'Gentag kodeord: ',
        [enUS]: 'Repeat password: ',
    }),
    repeatPasswordPlaceholder: i18n({
        [daDK]: 'Gentag kodeord',
        [enUS]: 'Repeat password',
    }),
    newsLetterLabel: i18n({
        [daDK]: 'Hold dig opdateret: Få relevante nyheder, tips og tricks fra Digital Revisor – vi spammer ikke!',
        [enUS]: `Stay updated: Get relevant news, tips, and tricks from Digital Revisor – we don't spam!`,
    }),
    termsLabel1: i18n({
        [daDK]: 'Jeg accepterer Digital Revisors ',
        [enUS]: 'I accept Digital Revisors ',
    }),
    termsLabel2: i18n({
        [daDK]: 'Forretningsbetingelser',
        [enUS]: 'Terms of use',
    }),
    consentLabel1: i18n({
        [daDK]: 'Jeg accepterer Digital Revisors ',
        [enUS]: 'I accept Digital Revisors ',
    }),
    consentLabel2: i18n({
        [daDK]: 'Samtykkeerklæring',
        [enUS]: 'Declaration of consent',
    }),
    registerButton: i18n({
        [daDK]: 'Opret',
        [enUS]: 'Register',
    }),
    registerNewPrivateClient: i18n({
        [daDK]: 'Opret Boligudlejer',
        [enUS]: 'Create Rental Client',
    }),
    registerNewCompanyClient: i18n({
        [daDK]: 'Opret selskab',
        [enUS]: 'Create company',
    }),
    registerNewFirmClient: i18n({
        [daDK]: 'Opret virksomhed',
        [enUS]: 'Create firm',
    }),
    newPrivateClient: i18n({
        [daDK]: 'Ny boligudlejer',
        [enUS]: 'New rental client',
    }),
    newCompanyClient: i18n({
        [daDK]: 'Nyt selskab',
        [enUS]: 'New company client',
    }),
    newFirmClient: i18n({
        [daDK]: 'Ny virksomhed',
        [enUS]: 'New firm client',
    }),
    cancelButton: i18n({
        [daDK]: 'Annuller',
        [enUS]: 'Cancel',
    }),
    successMsg: i18n({
        [daDK]: 'Tillykke! Din bruger blev oprettet.',
        [enUS]: 'Congratulations! Your user has been created.',
    }),
    redirectNormalMsg: i18n({
        [daDK]: 'Du vil blive logget ind om få sekunder.',
        [enUS]: 'Your will be logged in shortly.',
    }),
    redirectPaymentMsg: i18n({
        [daDK]: 'Du vil blive logget ind og blive sendt til betaling om få sekunder.',
        [enUS]: 'You will be logged in and sent to payment shortly',
    }),
    linkToLogin: i18n({
        [daDK]: 'Har du allerede en konto? Hop til login',
        [enUS]: 'Login',
    }),
    linkToLoginMobile: i18n({
        [daDK]: 'Login',
        [enUS]: 'Login',
    }),
};

