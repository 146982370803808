import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

export default {
    title: i18n({
        [daDK]: 'Sprog',
        [enUS]: 'Language',
    }),
    subheader: i18n({
        [daDK]: 'Vælg hvilket sprog du vil bruge i appen',
        [enUS]: 'Select your preferred language',
    }),
    saveBtn: i18n({
        [daDK]: 'Gem',
        [enUS]: 'Save',
    }),
    cancelBtn: i18n({
        [daDK]: 'Annuller',
        [enUS]: 'Cancel',
    }),
};