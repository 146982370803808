import React from 'react';
import { Segment, Table } from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';
import { downloadExampleCSV } from './exampleCsv';
import * as csvImporter from './csvImporter';

const ToolDescription = () => {
    return (
        <Segment>
            <p>
                Her kan du importere din kontoplan på Digital Revisor. Vi kræver at den kontoplan du ønsker at importere er af filformatet CSV.
            </p>
            <p>
                Vi stiller nogle krav til opsætningen af CSV-filen for at vi kan importere den på korrekt vis.
                Derfor skal CSV-filen du importerer indeholde disse&nbsp;
                {csvImporter.requiredColumns.length} forskellige kolonner:             
            </p>
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Kolonnenavn
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Indhold
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        csvImporter.requiredColumns.map(requiredColumn => {
                            const { inputName, description } = requiredColumn;
                            return (
                                <Table.Row key={inputName}>
                                    <Table.Cell>{inputName}</Table.Cell>
                                    <Table.Cell>{description}</Table.Cell>
                                </Table.Row>
                            );
                        })
                    }
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.Cell colSpan={3}>
                            <ColoredText
                                content='Download eksempel'
                                iconPosition='left'
                                icon='download'
                                color='green'
                                onClick={() => downloadExampleCSV()}
                                link
                            />
                            &nbsp;
                            på CSV-fil som er klar til at blive importeret
                        </Table.Cell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Segment>
    );
};

export default ToolDescription;