import React from 'react';
import Navigation from '../../../../atoms/Navigation';

class NavigationWidget extends React.Component {
    render() {
        const { next, prev, hasNext, updating, onPrint, demo } = this.props;
        return (
            <Navigation
                hasPrev
                hasNext={hasNext}
                onNext={next}
                onPrev={prev}
                onPrint={onPrint}
                disabled={updating}
                demo={demo}
            />
        );
    }
}

export default NavigationWidget;
