import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Dropdown, Form, Icon, Input, Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { createEntityInvitation, getEntityInvitations, getEntityLogins, deleteEntityLogin, getEntityRoles, deleteEntitiyInvitation, patchEntityLoginRole } from 'http/accounts';
import { formatDate, formatDateTime } from 'util/format/DateTime';
import { useRootUser } from 'util/useUser';
import { useBreakpoints } from 'design/atoms/Media';
import ColoredText from 'design/atoms/ColoredText';
import IconHeader from './IconHeader';
import ProfileTable from './ProfileTable';
import RoleLabel from './RoleLabel';
import getEmailRegexp from 'util/getEmailRegexp';

const IAMTable = ({ logins, refetchLogins, loading, entityRoles }) => {
    const user = useRootUser();

    const revokeAccess = async loginIdx => {
        const login = logins[loginIdx];
        if (!login) {
            return;
        }

        await deleteEntityLogin(login.id);
        await refetchLogins();
        toast.success('Adgang fjernet');
    };

    const isDeleteDisabled = loginIdx => {
        if (!user.isEntityAdmin()) {
            // must be at least admin to revoke access
            return true;
        }

        if (!user.hasHigherAuthority(logins[loginIdx].role.level)) {
            // must have higher level of authority than the user to remove
            return true;
        }

        return false;
    };

    const patchLoginRole = async (loginID, newRole) => {
        await patchEntityLoginRole(loginID, newRole);
        await refetchLogins();
        toast.success('Tilknytning opdateret');
    };

    return (
        <ProfileTable
            loading={loading}
            header={['E-mail', 'Dato tiltrådt', 'Tilknytning']}
            widths={[6, 4, 4]}
            body={logins.map(({ id, email, dateJoined, role }) => {
                return {
                    key: id,
                    cells: [
                        email,
                        formatDate(dateJoined),
                        <RoleLabel
                            {...role}
                            editable={id !== user.login.id}
                            entityRoles={entityRoles.filter(role => role.level > user.login.roleLevel && role.level !== 0)}
                            onChange={newRole => patchLoginRole(id, newRole)}
                        />,
                    ],
                };
            })}
            actions={[
                {
                    label: 'Fjern bruger',
                    icon: 'x',
                    onClick: revokeAccess,
                    disabled: isDeleteDisabled,
                },
            ]}
        />
    );
};

const InvitationManager = ({ entityRoles }) => {
    const [pendingInvitations, setPendingInvitations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [role, setRole] = useState(null);
    const emailRef = useRef();
    const user = useRootUser();
    const breakpoints = useBreakpoints();

    useEffect(() => {
        if (email) {
            return;
        }

        if (!emailRef?.current) {
            return;
        }

        if (!emailRef.current.inputRef?.current) {
            return;
        }

        emailRef.current.inputRef.current.value = '';
    }, [email]);

    useEffect(() => {
        const fetchData = async () => {
            const pendingInvitations = await getEntityInvitations();
            setPendingInvitations(pendingInvitations);
            setLoading(false);
        };
        fetchData();
    }, [user]);

    const emailValid = getEmailRegexp().test(email);
    /**
     * Cases:
     * [default]:           Email and role must be provided.
     * [academy user case]: Only email must be provided. The role will always be 'academy'.
     */
    const canSend = !user.isSegmentAkademi() ? emailValid && role : emailValid;
    const ACADEMY_ROLE = 'academy';

    const submitInvitation = async () => {
        if (!canSend) {
            return;
        }

        await createEntityInvitation({
            emailToInvite: email,
            desiredRole: !user.isSegmentAkademi() ? role : ACADEMY_ROLE,
        });

        toast.success(`Invitation sendt til ${email}!`);
        const pendingInvitations = await getEntityInvitations();
        setPendingInvitations(pendingInvitations);
        setEmail('');
        setRole(null);
    };

    const deleteInvitation = async token => {
        await deleteEntitiyInvitation(token);
        const invitations = await getEntityInvitations();
        setPendingInvitations(invitations);
        toast.success('Invitation tilbagekaldt');
    };

    const renderPendingInvitations = () => {
        const renderContent = () => {
            if (loading) {
                return <Loader inline='centered' active />
            }

            if (pendingInvitations.length === 0) {
                return (
                    <span>
                        Ingen aktive invitationer fundet
                        <Divider hidden />
                    </span> 
                );
            }

            return (
                <ProfileTable
                    header={['E-mail', 'Udløbsdato', 'Status']}
                    widths={[6, 4, 4]}
                    body={pendingInvitations.map(({ email, expirationDate, isExpired }) => {
                        return {
                            key: email,
                            cells: [
                                email,
                                formatDateTime(expirationDate),
                                <ColoredText
                                    content={isExpired ? 'Udløbet' : 'Sendt'}
                                    color={isExpired ? 'red' : 'black'}
                                    icon={isExpired ? 'warning circle' : 'send'}
                                    iconPosition='left'
                                />,
                            ],
                        };
                    })}
                    actions={[
                        {
                            label: 'Tilbagekald invitation',
                            icon: 'x',
                            onClick: idx => deleteInvitation(pendingInvitations[idx].token),
                            disabled: !user.isEntityAdmin(),
                        },
                    ]}
                />
            );
        };

        return (
            <>
                <IconHeader
                    content='Invitationer'
                    icon='mail'
                />
                {renderContent()}
            </>
        );
    };

    const renderInvitationSender = () => {
        if (!user.isEntityAdmin()) {
            return null;
        }

        const showAffiliation = !user.isSegmentAkademi();

        return (
            <>
                <IconHeader
                    content='Send invitation'
                    icon='mail forward'
                />
                <Form onSubmit={submitInvitation}>
                    <Form.Field>
                        <label>E-mail</label>
                        <Input
                            placeholder='Indtast e-mail på personen du ønsker at invitere'
                            defaultValue={email}
                            onChange={(_, { value }) => setEmail(value)}
                            ref={emailRef}
                        />
                    </Form.Field>
                    { showAffiliation &&
                        <Form.Field>
                            <label>Tilknytning</label>
                            <Dropdown
                                selection
                                value={role}
                                onChange={(_, { value }) => setRole(value)}
                                loading={loading}
                                placeholder='Vælg personens adgangsniveau'
                                options={entityRoles.filter(role => role.level !== 0).map(role => ({
                                    text: role.title,
                                    icon: <Icon name={role.semanticIcon} color={role.color} />,
                                    value: role.id,
                                    description: breakpoints.select({
                                        'gte tablet': role.description,
                                        'default': undefined, // hide description on small screens
                                    }),
                                }))}
                            />
                        </Form.Field>
                    }
                    <Form.Field>
                        <Button
                            disabled={!canSend}
                            fluid primary
                            content='Send invitation'
                            icon='mail'
                        />
                    </Form.Field>
                </Form>
            </>
        );
    };

    return (
        <>
            {renderPendingInvitations()}
            {renderInvitationSender()}
        </>
    );
};

const EntityIAM = () => {
    const [entityLogins, setEntityLogins] = useState([]);
    const [entityRoles, setEntityRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const user = useRootUser();

    useEffect(() => {
        const doFetch = async () => {
            setLoading(true);

            const [entityLogins, entityRoles] = await Promise.all([
                getEntityLogins(),
                getEntityRoles(),
            ]);

            setEntityLogins(entityLogins);
            setEntityRoles(entityRoles);
            setLoading(false);
        };

        doFetch();
    }, [user]);

    const refetchLogins = async () => {
        const entityLogins = await getEntityLogins();
        setEntityLogins(entityLogins); 
    };

    return (
        <>
            <IAMTable
                loading={loading}
                logins={entityLogins}
                refetchLogins={refetchLogins}
                entityRoles={entityRoles}
            />
            <InvitationManager entityRoles={entityRoles} />
        </>
    );
};

export default EntityIAM;