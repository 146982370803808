import AuthHelper from '../AuthHelper';
import fetchAccessToken from '../../network/fetch/fetchAccessToken';
import fetchImpersonationToken from '../../network/fetch/fetchImpersonationToken';
import { getImpersonatedID } from '../impersonation';
import {
    isRefreshTokenExpired,
    isAccessTokenExpired,
    isImpersonationTokenExpired,
    hasAccessToken,
    hasImpersonationToken
} from './index';

/**
 * Validates the users refresh, access, and impersonation token. The function does the following:
 * 1. Checks if the refresh token is valid. If not, deauthenticate the user, and return false.
 * 2. Check if the access token is valid. If not, refresh it.
 * 3. Check if the impersonation exists and is valid. If exists and not valid, refresh it.
 * 4. Return true (assuming no error from step 2 & 3)
 * @returns {Promise<boolean>} promise with true/false. true = tokens are valid, false = user is de-authenticated
 */
export async function tokenCheck () {
    if (isRefreshTokenExpired()) {
        const { pathname, search } = window.location;
        AuthHelper.deAuthenticate(pathname + search);
        return false;
    }

    if (!hasAccessToken() || isAccessTokenExpired()) {
        await fetchAccessToken();
    }

    if (hasImpersonationToken() && isImpersonationTokenExpired()) {
        const impID = getImpersonatedID();
        await fetchImpersonationToken(impID);
    }
    return true;
}