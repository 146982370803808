import { API_LOCATION } from 'config/config';
import { hasAnyToken, getRelevantToken } from 'util/token';
import { tokenCheck } from 'util/token/tokenCheck'
import * as systemUpdate from 'util/systemUpdate';
import DRResponse, { unmarshalResponse } from './DRResponse'; /* eslint-disable-line */
import userSessionStore from 'util/userSessionStore';

const DEFAULT_OPTIONS = {
    api: API_LOCATION,
    requestHeaders: { 'Content-Type': 'application/json' },
    token: null,
    fetchOptions: {},
    skipTokenValidation: false,
};

/**
 * @param {*} options - User defined options
 * @returns {*} - The final HTTP options
 */
function mergeOptions (options) {
    const out = {};
    for (let [key, value] of Object.entries(DEFAULT_OPTIONS)) {
        const opt = options[key];
        if (opt !== undefined) {
            out[key] = opt;
        } else {
            out[key] = value;
        }
    }
    return out;
}

/**
 * @param {string} method 
 * @param {string} url 
 * @param {any} body 
 * @param {*} options 
 * @returns {Promise<DRResponse>}
 */
async function httpRequest (method, url, body, options = {}) {
    options = mergeOptions(options);
    // If the user has tokens in localStorage, validate & refresh if necessary
    if (!options.skipTokenValidation && hasAnyToken()) {
        const tokensValid = await tokenCheck();
        if (!tokensValid) {
            return;
        }
    }
    const fetchUrl = `${options.api}${url}`;
    const fetchOptions = {
        method,
        headers: {
            ...getAuthHeader(options.token),
            ...options.requestHeaders,
        },
        mode: 'cors',
        credentials: 'include',
        ...options.fetchOptions,
    };
    if (body) {
        fetchOptions.body = body;
    }
    const res  = await fetch(fetchUrl, fetchOptions);
    const responseBody = await res.json();
    if (!responseBody && !res.ok) {
        throw new Error(res.statusText);
    }

    if (responseBody.systemUpdateIsInProgress) {
        systemUpdate.setStatus(true);
    }

    return unmarshalResponse({
        status: res.status,
        statusText: res.statusText,
        ...responseBody,
    });
}

const tokenTypeMap = {
    impersonationToken: 'impersonationToken',
    accessToken: 'accessToken',
    refreshToken: 'token',
};

function getAuthHeader(tokenType) {
    const token = userSessionStore.get(tokenTypeMap[tokenType]) || getRelevantToken();
    if (!token) return {};
    
    return { Authorization: `Bearer ${token}` };
}

export default httpRequest;
