import { useEffect, useMemo, useState } from 'react';
import { Button, Header, Icon, Message, Segment, Table } from 'semantic-ui-react';
import { getFactValueByTag } from 'http/productEngine';
import { formatNumber } from 'util/format/Number';
import CSV from 'util/CSV';
import { BALANCE, DRIFT } from '../../FieldTypes/FactWidgets/ClosingSheet/accountTypes';
import { getAccountPlansFact } from 'util/FactMapUtil';

const UiElementBookingDiscrepancyChecker = ({ primoModelData, values }) => {
    const [gotError, setGotError] = useState(false);
    const [discrepancies, setDiscrepancies] = useState([]);
    const [amountToShow, setAmountToShow] = useState(5);

	const currentYearAccountPlansFact = useMemo(() => {
        return getAccountPlansFact(values) || {};
    }, [values]);

    useEffect(() => {
        if (!primoModelData) return;
        if (!currentYearAccountPlansFact) return;

        const { exists, isValidPrimoModel, modelID, label } = primoModelData;

        if (!exists) return;
        if (!isValidPrimoModel) return;

        getFactValueByTag(modelID, label, currentYearAccountPlansFact.tag)
            .catch(() => setGotError(true))
            .then(lastYearAccountPlansFact => {
                const accountsReportedLastYearTable = {};

                for (const lastYearAccount of lastYearAccountPlansFact.accounts) {
                    const isDriftOrBalance = [DRIFT, BALANCE].includes(lastYearAccount.accountType);
                    if (!isDriftOrBalance) {
                        continue;
                    }

                    accountsReportedLastYearTable[lastYearAccount.number] = lastYearAccount;
                }

                const lastYearAccountFromBookkeepingSystem = currentYearAccountPlansFact.value.lastyear;

                const discrepancies = [];

                for (const account of lastYearAccountFromBookkeepingSystem) {
                    const isDriftOrBalance = [DRIFT, BALANCE].includes(account.accountType);
                    if (!isDriftOrBalance) {
                        continue;
                    }
                    
                    const reportedAmountLastYear = accountsReportedLastYearTable[account.number]?.amount || '0';

                    if (account.amount === reportedAmountLastYear) {
                        continue;
                    }

                    discrepancies.push({
                        text: account.text,
                        accountNumber: account.number,
                        reportedAmount: reportedAmountLastYear,
                        amountFromBookkeeping: account.amount,
                        diff: Number(account.amount) - Number(reportedAmountLastYear),
                    });
                }

                setDiscrepancies(discrepancies);
            });
    }, [primoModelData, currentYearAccountPlansFact]);

    if (gotError) {
        return (
            <Message
                negative
                header='Der opstod en fejl'
                content='Kunne ikke indlæse sidste års bogføringstal'
            />
        );
    }

    if (discrepancies.length === 0) {
        return null;
    }

    const discrepanciesTable = (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Kontonr.</Table.HeaderCell>
                    <Table.HeaderCell>Kontotekst</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Bogført beløb</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Indberettet beløb</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Difference</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {discrepancies.slice(0, amountToShow).map(({ accountNumber, text, amountFromBookkeeping, reportedAmount, diff }) => {
                    return (
                        <Table.Row>
                            <Table.Cell>{accountNumber}</Table.Cell>
                            <Table.Cell>{text}</Table.Cell>
                            <Table.Cell textAlign='right'>{formatNumber(amountFromBookkeeping)} kr.</Table.Cell>
                            <Table.Cell textAlign='right'>{formatNumber(reportedAmount)} kr.</Table.Cell>
                            <Table.Cell textAlign='right'>{formatNumber(diff)} kr.</Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={5} textAlign='right'>
                            <Button
                                content='Download som CSV'
                                icon='cloud download'
                                onClick={() => {
                                    const csv = new CSV(['kontonummer', 'kontotext', 'bogfoert', 'indberettet', 'difference']);
                                    for (const discrepancy of discrepancies) {
                                        csv.addToRow('kontonummer', discrepancy.accountNumber);
                                        csv.addToRow('kontotext', discrepancy.text);
                                        csv.addToRow('bogfoert', discrepancy.amountFromBookkeeping);
                                        csv.addToRow('indberettet', discrepancy.reportedAmount);
                                        csv.addToRow('difference', discrepancy.diff.toString());
                                        csv.newLine();
                                    }
                                    csv.download('uoverensstemmelser');
                                }}
                            />
                            {amountToShow < discrepancies.length && (
                                <>
                                    <Button
                                        content='Vis flere'
                                        icon='arrow down'
                                        onClick={() => setAmountToShow(amountToShow + 5)}
                                    />
                                    <Button
                                        content={`Vis alle ${discrepancies.length} uoverensstemmelser`}
                                        icon='list'
                                        onClick={() => setAmountToShow(discrepancies.length)}
                                    />
                                </>
                            )}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
        </Table>
    );

    return (
        <Segment>
            <Header
                content='Uoverensstemmelser fundet'
                icon={<Icon name='warning circle' color='orange' />}
            />
            <div>
                Der blev fundet <b>{discrepancies.length}</b> uoverensstemmelse
                {discrepancies.length === 1 ? '' : 'r'} mellem de tal,
                der blev indberettet gennem Digital Revisor sidste år,
                og sammenligningstallene i bogføringssystemet fra sidste år.
            </div>
            {discrepanciesTable}
        </Segment>
    );
};

export default UiElementBookingDiscrepancyChecker;