import React, { useState } from 'react';
import styles from './Rotation.module.scss';

const RotationAnimaiton = ({ children, rotationHandlerChanged }) => {
    const [rotate, setRotate] = useState(false);

    const triggerRotation = () => {
        setRotate(true);
        setTimeout(() => setRotate(false), 400);
    };

    if (rotationHandlerChanged instanceof Function) {
        rotationHandlerChanged(triggerRotation);
    }

    const classes = [];
    if (rotate) classes.push(styles.rotation);

    return (
        <span className={classes.join(' ')}>
            {children}
        </span>
    );
};

export default RotationAnimaiton;
