import React from 'react';
import { Form, Message, Button, Header } from 'semantic-ui-react';
import { executeResetPassword } from 'http/accounts';
import QueryParams from 'util/QueryParams';
import i18n from 'i18n/pages/ResetExecute';
import ColoredText from 'design/atoms/ColoredText';
import HeroForm from 'design/atoms/HeroForm';

class ResetExecute extends React.Component {
    state = {
        error: null,
        isFetching: false,
        isReset: false,
        password: '',
        repeatPassword: '',
    };

    handleReset = async (e) => {
        e && e.preventDefault();

        if (this.state.isFetching || this.state.isReset) {
            return;
        }

        const { password, repeatPassword } = this.state;
        if (!password || !repeatPassword) {
            return;
        }

        this.setState({ isFetching: true });
        const query = QueryParams();
        try {
            await executeResetPassword(
                query.resetToken,
                password,
                repeatPassword
            );
            this.setState({ isReset: true });
            setTimeout(() => {
                this.props.history.push('/login');
            }, 2500);
        } catch (e) {
            this.setState({ error: e.message });
        } finally {
            this.setState({ isFetching: false });
        }
    };

    formValueChanged = (_, { id, value }) => {
        this.setState({ [id]: value, error: null });
    };

    renderHeader = () => (
        <Form.Field>
            <Header>{i18n.title}</Header>
            <div style={{ marginBottom: '2em' }}>{i18n.subTitle}</div>
        </Form.Field>
    );

    renderContent = () => {
        if (this.state.isReset) {
            return this.renderResetSuccess();
        }

        return this.renderResetForm();
    };

    renderActions = () => {
        const { isFetching, isReset, password, repeatPassword } = this.state;
        const fieldsFilled = password && repeatPassword;
        return (
            <>
                <Form.Field>
                    <Button
                        fluid
                        style={{ marginTop: '2em' }}
                        onClick={this.handleReset}
                        content={i18n.ResetForm.resetButton}
                        disabled={!fieldsFilled || isReset || isFetching}
                        loading={isFetching}
                        primary
                    />
                </Form.Field>
                <Form.Field style={{ textAlign: 'center' }}>
                    <ColoredText
                        content='Gå til login'
                        color='green'
                        link='/login'
                    />
                </Form.Field>
            </>
        );
    };

    renderResetSuccess = () => (
        <Message
            icon='check'
            header={i18n.successMsg}
            content={i18n.redirectMsg}
            positive
        />
    );

    renderResetForm = () => {
        return (
            <>
                <Form.Input
                    placeholder={i18n.ResetForm.passwordPlaceholder}
                    label={i18n.ResetForm.passwordLabel}
                    onChange={this.formValueChanged}
                    value={this.state.emailVal}
                    autoCapitalize='off'
                    autoCorrect='off'
                    id='password'
                    type='password'
                />
                <Form.Input
                    placeholder={i18n.ResetForm.repeatPasswordPlaceholder}
                    label={i18n.ResetForm.repeatPasswordLabel}
                    onChange={this.formValueChanged}
                    value={this.state.emailVal}
                    autoCapitalize='off'
                    autoCorrect='off'
                    type='password'
                    id='repeatPassword'
                />
                {this.state.error && (
                    <Form.Field>
                        <Message
                            color='red'
                            content={this.state.error}
                            icon='warning circle'
                        />
                    </Form.Field>
                )}
            </>
        );
    };

    render() {
        return (
            <HeroForm>
                <Form onSubmit={this.handleReset}>
                    {this.renderHeader()}
                    {this.renderContent()}
                    {this.renderActions()}
                </Form>
            </HeroForm>
        );
    }
}

export default ResetExecute;
