import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { useRootUser } from 'util/useUser';
import ProductPage from 'design/molecules/ProductPage';
import CreateEntityForm from 'design/molecules/CreateEntityForm';

import styles from './GettingStarted.module.scss';

const GettingStarted = () => {
    const user = useRootUser();

    const renderIntroText = () => {
        if (user.isSegmentErhverv()) {
            return (
                <p>
                    Du er ikke knyttet til nogen virksomheder på Digital Revisor endnu.
                    Opret din første virksomhed herunder.
                </p>
            );
        }

        return (
            <p>
                For at vi kan forsætte med din boligudlejning, skal vi bruge et par oplysninger fra dig.
            </p>
        );
    };

    return (
        <ProductPage>
           <Segment compact className={styles.welcomeSegment}>
                <Header icon='handshake' content='Velkommen på Digital Revsior' />
                {renderIntroText()}
                <CreateEntityForm />
            </Segment>
        </ProductPage>
    );
};

export default GettingStarted;