import React      from 'react';
import SimpleValue from '../SimpleValue';

const UiElementText = ({ text, ...props }) => {
	return <SimpleValue
		staticValue={text}
		{...props}
	/>;
};

export default UiElementText;
