import React from 'react';
import { Icon, Loader, Popup, Table } from 'semantic-ui-react';
import { Media } from 'design/atoms/Media';
import ColoredText from 'design/atoms/ColoredText';

/**
 * Renders a mobile-view of a table cell such that
 * the column name is rendered on one row and the
 * corrosponding value is rendered on the next
 */
const MobileTableCell = ({ label, value, shouldDivide }) => {
    const labelCellStyle = {
        borderTop: (
            shouldDivide ?
            '2px solid lightgray' :
            'none'
        ),
        paddingBottom: '0.5em'
    };

    const valueCellStyle = {
        border: 'none',
        paddingTop: 0,
    };

    const renderRow = (children, style) => {
        return <Table.Row>
            <Table.Cell style={style}>
                {children}
            </Table.Cell>
        </Table.Row>;
    };

    return (
        <>
            {renderRow(<b>{label}:</b>, labelCellStyle)}
            {renderRow(value, valueCellStyle)}
        </>
    );
};

/**
 * Used for rendering mobile friendly tables across the profile submenus
 * @param {Object} props
 * @param {string|JSX.Element[]} props.header
 * @param {{key: string, error?: boolean, cells: any[]}[]} props.body
 * @param {number[]} props.widths
 * @param {boolean} props.loading
 * @param {{label: string, icon: string, onClick: (rowIndex: number) => void}[]} props.actions
 * @param {(rowIndex: number) => void} props.onDelete
 * @param {string} props.deleteTooltip
 */
const ProfileTable = ({ header, body, widths, loading, actions = [] }) => {
    const getWidth = idx => {
        if (!Array.isArray(widths)) {
            return undefined;
        }
        
        return widths[idx];
    };

    const getActionProperties = (rowIdx, propmap) => {
        /**
         * @returns {boolean}
         */
        const check = property => {
            const checker = propmap[property];

            if (typeof checker === 'function') {
                return checker(rowIdx);
            }

            return !!checker;
        };

        return {
            disabled: check('disabled'),
            loading:  check('loading'),
            hidden:   check('hidden'),
        };
    };

    const getActionIcon = (icon, loading) => {
        return loading ? 'spinner' : icon;
    };

    const renderActions = (rowIdx, mobile) => {
        if (!Array.isArray(actions)) {
            return null;
        }

        // remove falsy actions
        const filteredActions = actions.filter(action => !!action);

        if (mobile) {
            return filteredActions.map(action => {
                if (action instanceof Function) {
                    return action(rowIdx);
                }

                const { label, icon, onClick, ...options } = action;

                const actionProps = getActionProperties(rowIdx, options);

                if (actionProps.hidden) {
                    return null;
                }

                return (
                    <MobileTableCell
                        label={label}
                        value={
                            <ColoredText
                                icon={getActionIcon(icon, actionProps.loading)}
                                iconPosition='left'
                                content={label}
                                underlined={false}
                                onClick={() => onClick(rowIdx)}
                                link={!actionProps.disabled}
                                disabled={actionProps.disabled}
                                loading={actionProps.loading}
                            />
                        }
                    />
                );
            });
        }

        return <Table.Cell textAlign='right'>
            {filteredActions.map(action => {
                if (action instanceof Function) {
                    return action(rowIdx);
                }

                const { label, icon, onClick, ...options } = action;

                const actionProps = getActionProperties(rowIdx, options);

                if (actionProps.hidden) {
                    return null;
                }
                
                return (
                    <Popup
                        trigger={
                            <Icon
                                onClick={() => onClick(rowIdx)}
                                link={!actionProps.disabled}
                                disabled={actionProps.disabled}
                                loading={actionProps.loading}
                                name={getActionIcon(icon, actionProps.loading)}
                            />
                        }
                        content={label}
                        disabled={actionProps.disabled}
                        position='top center'
                    />
                );
            })}
        </Table.Cell>;
    };

    const renderComputerTabletVerison = () => {
        return (
            <Media gte='tablet'>
                <Table.Header>
                    <Table.Row>
                        {header.map(cell => (
                            <Table.HeaderCell>
                                {cell}
                            </Table.HeaderCell>
                        ))}

                        {/* add an extra empty header cell for the "actions" column */}
                        {actions?.length > 0 && <Table.HeaderCell />}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {body.map(({ key, cells, error }, rowIdx) => (
                        <Table.Row key={key} error={error}>
                            {cells.map((cell, celldx) => {
                                return (
                                    <Table.Cell width={getWidth(celldx)}>
                                        {cell}
                                    </Table.Cell>
                                );
                            })}
                            {renderActions(rowIdx, false)}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Media>
        );
    };

    const renderMobileVersion = () => {
        const bodyJSX = body.map((({ cells }, rowIdx) => {
            return [
                ...cells.map((cell, cellIdx) => {
                    const shouldDivide = cellIdx === 0 && rowIdx !== 0;
    
                    return (
                        <MobileTableCell
                            label={header[cellIdx]}
                            value={cell}
                            shouldDivide={shouldDivide}
                        />
                    );
                }),
                ...renderActions(rowIdx, true),
            ];
        }));

        return (
            <Media lt='tablet'>
                <Table.Body>
                    {bodyJSX}
                </Table.Body>
            </Media>
        );
    };

    const renderSpinner = () => {
        return <Loader inline='centered' active />;
    };

    return (
        <Table basic='very' unstackable>
            {loading && renderSpinner()}
            {!loading && renderComputerTabletVerison()}
            {!loading && renderMobileVersion()}
        </Table>
    );
};

export default ProfileTable;