import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { formatDateTime } from 'util/format/DateTime';
import { readAsText } from 'util/files'
import FileuploadButton from 'design/atoms/FileuploadButton';
import ColoredText from 'design/atoms/ColoredText';

import * as csvImporter from './csvImporter';
import ToolDescription from './toolDescription';
import ValidationTable from './validationTable';

const STATUSES = {
    AWAITING_CSV: {
        title: 'Afventer CSV-fil',
        color: 'grey',
        icon: 'file excel',
    },

    VALIDATION_ERROR: {
        color: 'orange',
        icon: 'warning circle',
    },

    CSV_ACCEPTED: {
        title: 'CSV-filen er læst og valideret',
        color: 'green',
        icon: 'check circle',
    },

    IMPORTING: {
        title: 'Importerer kontoplan',
        color: 'grey',
        icon: 'spinner',
        loading: true,
    },

    IMPORT_ERROR: {
        title: 'Kontoplanen kunne ikke importeres',
        color: 'orange',
        icon: 'warning circle',
    },

    DATA_IMPORTED: {
        title: 'Bogføringsdata importeret',
        color: 'green',
        icon: 'check circle',
    },
};

const PlanImporter = ({ fetchERPData, values, id, options, unregisterERP }) => {
    const [status, setStatus] = useState(STATUSES.AWAITING_CSV);
    const [title, setTitle] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [currentAccounts, setCurrentAccounts] = useState(null);

    useEffect(() => {
        const fact = get(values, id, {});
        setCurrentAccounts(get(fact, 'value'));
    }, [id, values]);

    useEffect(() => {
        if (!currentAccounts) {
            setStatus(STATUSES.AWAITING_CSV);
            return;
        }

        if (currentAccounts.error) {
            setStatus(STATUSES.IMPORT_ERROR);
            return;
        }

        if (currentAccounts.accounts.length === 0) {
            setStatus(STATUSES.AWAITING_CSV);
            return;
        }

        setStatus(STATUSES.DATA_IMPORTED);
    }, [currentAccounts]);
    
    useEffect(() => {
        if (status?.title) {
            setTitle(status.title);
        }
    }, [status]);

    const onFileSelected = async file => {
        // exit early if user cancelled the file popup
        if (!file) {
            return;
        }

        // reset any previous error messages
        setErrorMessage(null);

        const rawCsv = await readAsText(file);

        // wrapper for setting status to VALIDATION_ERROR & setting an error msg
        const setValidationError = ({ message, title }) => {
            setStatus(STATUSES.VALIDATION_ERROR);
            setTitle(title);
            setErrorMessage(message);
        };

        // parse the actual CSV-file
        const parsedCsv = csvImporter.parseCSV(rawCsv);
        if (!parsedCsv.valid) {
            setValidationError({
                title: 'CSV-filen er ugyldig',
                message: parsedCsv.message,
            });
            return;
        }

        // validate that the headers conform to the required format
        const headerValidation = csvImporter.validateHeaders(parsedCsv.data.headers);
        if (!headerValidation.valid) {
            setValidationError({
                title: 'Manglende kolonner i CSV-filen',
                message: headerValidation.message,
            });
            return;
        }

        const sanitizedRows = csvImporter.sanitizeRows({ ...parsedCsv.data });

        // do validation of the data of the rows
        const rowValidation = csvImporter.validateData(sanitizedRows);
        if (!rowValidation.valid) {
            setValidationError({
                title: 'CSV-filen indeholder ugyldig data',
                message: (
                    <div>
                        Valgte CSV-fil indeholder rækker med ugyldig data.
                        <br />
                        Følgende fejl skal løses før CSV-filen kan accepteres:
                        <br />
                        <ValidationTable validationErrors={rowValidation.validationErrors} />
                    </div>
                ),
            });
            return;
        }

        // convert accounts to internal format
        const importedAccounts = csvImporter.convertToInternalFormat(sanitizedRows);
        
        // trigger ERP-action w/ imported accounts in the update context
        setStatus(STATUSES.IMPORTING);
        await fetchERPData({
            importedAccounts,
        });

        // the useEffect on [values] will run when the accounts have finished importing
        // and change the status there
    };

    const onUnregisterERP = () => {
        // causes this component to unmount
        return unregisterERP();
    };

    const renderChooseCSVSection = () => {
        const uploadTrigger = (
            <Button
                primary
                content='Vælg CSV-fil'
                size='small'
                icon='upload'
            />
        );

        return (
            <>
                <div style={{ textAlign: 'right' }}>
                    <FileuploadButton
                        clearFileAfterSelection={true}
                        accept='csv'
                        onChange={onFileSelected}
                        trigger={uploadTrigger}
                    />
                </div> 
            </>
        );
    };

    const renderTimeOfImport = () => {
        // get trigger fact
        const triggerFact = values[options?.trigger];
        if (!triggerFact) {
            return null;
        }

        const dateUpdated = triggerFact.value?.number;
        if (!dateUpdated) {
            return null;
        }    

        return <span>
            Kontoplan sidst importeret den {formatDateTime(dateUpdated)}.
        </span>;
    };

    const renderImporterContent = () => {
        switch (status) {
            case STATUSES.AWAITING_CSV:
                return (
                    <div>
                        Når du har forberedt en CSV-fil, der opfylder ovenstående krav, kan du importere den her.
                        {renderChooseCSVSection()}
                    </div>
                );

            case STATUSES.VALIDATION_ERROR:
                return (
                    <div>
                        {errorMessage}
                        {renderChooseCSVSection()}
                    </div>
                );

            case STATUSES.IMPORTING:
                return (
                    <div>
                        Din kontoplan importeres. Vent venligst...
                    </div>
                );
            
            case STATUSES.IMPORT_ERROR:
                return (
                    <div>
                        Der opstod en fejl da vi forsøgte at importere din kontoplan.
                        {renderChooseCSVSection()}
                    </div>
                );

            case STATUSES.DATA_IMPORTED:
                return (
                    <div>
                        <p>
                            Kontoplanen er blevet importeret og vi har gjort din bogføring klar til årsrapporten.
                            Du kan nu se og rette i den importerede kontoplan på næste sektion.
                        </p>
                        <p>
                            Du kan overskrive nuværende bogføringsdata ved at importere en CSV-fil på ny.
                        </p>
                        <p>
                            {renderTimeOfImport()}
                        </p>
                        {renderChooseCSVSection()}
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <Header>Importér kontoplan</Header>
            <ToolDescription />

            {/* Widget for CSV import */}
            <Segment color={status.color}>
                <Header
                    icon={
                        <Icon
                            name={status.icon}
                            color={status.color}
                            loading={status.loading}
                        />
                    }
                    content={
                        <>
                            Status:
                            &nbsp;
                            <ColoredText
                                color={status.color}
                                content={title}
                            />
                        </>
                    }
                />
                {renderImporterContent()}
            </Segment>
            <ColoredText
                link
                content='Skift bogføringssystem'
                iconPosition='left'
                color='blue'
                onClick={onUnregisterERP}
            />
        </>
    );
};

export default PlanImporter;