import React from 'react';
import { Modal, Header, Icon, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class UnlockModal extends React.Component {
    static propTypes = {
        isModalOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired,
        onUnlock: PropTypes.func.isRequired,
    };

    render() {
        return (
        <Modal open={this.props.isModalOpen} onClose={() => this.props.toggleModal(false)} >
            <Modal.Header>
            <Header>
                <Icon.Group>
                    <Icon name='pencil' />
                    <Icon corner='top left' color='red' name='remove' />
                </Icon.Group>
                <Header.Content>
                    Stop underskrift
                    <Header.Subheader>
                        Stop igangværende digital signatur af dokumentet
                    </Header.Subheader>
                </Header.Content>
            </Header>
            </Modal.Header>

            <Modal.Content>
                Er du sikker på, at du vil annullere dine underskrifter?<br/>
                Du vil miste alle nuværende underskrifter
            </Modal.Content>

            <Modal.Actions>
                <Button color='black' onClick={() => { this.props.toggleModal(false) }}>
                    Annuller
                </Button>
                <Button primary onClick={() => {
                    this.props.toggleModal(false);
                    this.props.onUnlock();
                }}>
                    Lås op
                </Button>
            </Modal.Actions>
        </Modal>);
    }
}

export default UnlockModal;
