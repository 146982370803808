import React, { useEffect } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import CardFromComponent from 'design/molecules/CardForm';
import SecurePaymentMessage from 'design/atoms/SecurePaymentMessage';
import { ShoppingCartSegment } from './ShoppingCart';

function CardForm ({ working, cardStatus, setCardStatus, onMounted }) {
    useEffect(() => {
        typeof onMounted === 'function' && onMounted();
        // eslint-disable-next-line
    }, []);

    const renderStatusIcon = () => {
        const allComplete = cardStatus.filled;
        const error = cardStatus.error;

        let icon;
        let color;
        let message;
        let disabled;

        if (error) {
            color = 'orange';
            icon = 'warning circle';
            message = error;
        } else if (allComplete) {
            color = 'green';
            icon = 'check';
        } else {
            return null;
        }

        const statusIcon = <Icon
            name={icon}
            color={color}
            disabled={disabled}
            size='large'
        />;

        if (message) {
            return <Popup
                trigger={statusIcon}
                content={message}
                inverted
                position='top center'
            />;
        }

        return statusIcon;
    };

    return (
        <ShoppingCartSegment
            title='Kortoplysninger'
            icon='credit card outline'
            rightContent={renderStatusIcon()}
        >
            <CardFromComponent
                onChange={status => setCardStatus({ ...status })}
                disabled={working}
                fields={<SecurePaymentMessage size='small' />}
            />
        </ShoppingCartSegment>
    );
}

export default CardForm;