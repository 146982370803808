import React, { Component } from 'react';
import { Button, Input, Label, Icon, Dropdown } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import { monthNamesDK } from 'util/format/DateTime';
import styles from './DatePicker.module.scss';

const dateOptions = [];
const nowYear = new Date().getFullYear();
for (let year = 1900; year <= nowYear + 75; year++) {
    dateOptions.push({
        text: year.toString(),
        value: year,
    });
}

class DatePicker extends Component {
    datepickerRef = React.createRef();

    compareDates = (d1, d2) => {
        return (
            d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getFullYear() === d2.getFullYear()
        );
    };

    onChange = date => {
        let cDate = date ? date.getTime() : null;
        this.props.onChange(cDate);
    };

    dateIconClicked = () => {
        return this.datepickerRef.current?.input?.select();
    };

    renderInputField = () => {
        const { leftAttachment, fluid } = this.props;
        return (
            <Input labelPosition='right' fluid={fluid}>
                { !!leftAttachment && leftAttachment }
                <input />
                <Button 
                    as={Label} 
                    onClick={this.dateIconClicked} 
                    className={styles.labelDateBtn} 
                    icon={<Icon  name='calendar alternate'/>}
                />
            </Input>
        );
    };

    getDayClassName = date => {
        const { value } = this.props;
        if (value && this.compareDates(date, new Date(value))) {
            return styles.selected;
        }
    };

    renderHeader = ({ date, decreaseMonth, increaseMonth, changeYear }) => {
        const renderMonthNavigationArrow = ({ direction, onClick }) => {
            return (
                <div
                    style={{   
                        display: 'flex',   
                        alignItems: 'center',  
                        justifyContent: 'center', 
                        marginTop: '-4px',
                    }}
                    children={
                        <Icon
                            name={`chevron circle ${direction}`}
                            link
                            fitted
                            onClick={onClick}
                        />
                    }
                />
            );
        };

        const defaultYear = new Date(this.props.value || Date.now()).getFullYear();

        const yearPicker = (
            <div
                style={{
                    display: 'flex',
                    fontSize: '1em',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                }}
            >
                <span>
                    {monthNamesDK[new Date(date).getMonth()]}
                    &nbsp;
                    <Dropdown
                        compact
                        search
                        noResultsMessage='Indtast et årstal'
                        options={dateOptions}
                        defaultValue={defaultYear}
                        onChange={(_, { value }) => changeYear(value)}
                    />
                </span>
            </div>
        );

        return (
            <div style={{ display: 'grid', gridTemplateColumns: '32px auto 32px' }}>
                {renderMonthNavigationArrow({ direction: 'left', onClick: decreaseMonth })}
                {yearPicker}
                {renderMonthNavigationArrow({ direction: 'right', onClick: increaseMonth })}
            </div>
        );
    };

    renderDayName = date => {
        return ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'][new Date(date).getDay()];
    };


    render () {
        const { value, disabled, minDate, maxDate, placeholder } = this.props;
        return (
            <ReactDatePicker
                dayClassName={this.getDayClassName}
                customInput={this.renderInputField()}
                formatWeekDay={this.renderDayName}
                renderCustomHeader={this.renderHeader}
                onChange={this.onChange}
                wrapperClassName={styles.wrapper}
                ref={this.datepickerRef}
                selected={value && value}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText={placeholder}
                dropdownMode='select'
                dateFormat='dd/MM/yyyy'
                popperPlacement='bottom'
                disabledKeyboardNavigation
                showYearDropdown
                fixedHeight
            />
        );
    }
}

export default DatePicker;
