/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React                                 from 'react';
import { Modal, Icon, Table, Popup, Button } from 'semantic-ui-react';
import { prepareDownloadLink }               from '../../../http/file-storage';
import styles                                from './FileItem.module.scss';

export const DISPOSITION = {
	ATTACHMENT: 'attachment',
	INLINE: 'inline',
};

export default class FileItemComponent extends React.Component {
	constructor (props) {
		super(props);
		this.getFileType.bind(this);
		this.state = {
			confirmModalActive: false,
		};
	}

	toggleConfirmModal = (state, e) => {
		e.preventDefault();
		this.setState({confirmModalActive: state});
	}

	prepareDownloadLink = async (disposition) => {
		try {
			const link = await prepareDownloadLink(this.props.fileid, disposition);
			return link;
		} catch (err) {
			console.error("Error", err);
		}
	};

	deleteFile = () => {
		const { fileid, fileindex } = this.props;
		this.props.deleteFile(fileid, fileindex);
		this.setState({
			confirmModalActive: false,
		});
	}

	downloadFile = async () => {
		const dlLink = await this.prepareDownloadLink(DISPOSITION.ATTACHMENT);
		this.downloadElm.href = dlLink;
		this.downloadElm.click();
	}

	getFileType (fileName) {
		for (let i = fileName.length-1; i >= 0; i--) {
			if (fileName.charAt(i) === '.') {
				return fileName.substring(i+1, fileName.length);
			}
		}
	}

	getUIFilesize (size) {
		if (size >= 1000) {
			return {
				size: (size / 1000000).toFixed(2),
				sizeType: 'MB',
			};
		} else {
			return {
				size,
				sizeType: 'B',
			};
		}
	}

	showFile = async () => {
		const dlLink = await this.prepareDownloadLink(DISPOSITION.INLINE);
		this.downloadElm.href = dlLink;
		this.downloadElm.click();
	}

	render () {
        const { fileid, filename, filesize } = this.props;
		const { size, sizeType } = this.getUIFilesize(filesize);
		return (
			<Table.Row>
				<Table.Cell className={ styles.fileName }>
                    { filename }
                </Table.Cell>

                <Table.Cell>
                    { size + ' ' + sizeType}
                </Table.Cell>

				<Table.Cell className={ styles.fileActions } textAlign='right' collapsing>
					<a target="_blank" rel="noopener noreferrer" ref={a => this.downloadElm = a}></a>
					<Popup
						trigger={ <Icon name="eye" disabled={!fileid} fitted onClick={this.showFile} /> }
						content="Åbn i browser"
						inverted position="left center"
						disabled={!fileid}
					/>
                </Table.Cell>

				<Table.Cell className={ styles.fileActions } textAlign='right' collapsing>
					<Popup
						trigger={ <Icon name="download" disabled={!fileid} fitted onClick={this.downloadFile} /> }
						content="Download"
						inverted position="left center"
						disabled={!fileid}
					/>
                </Table.Cell>

				<Table.Cell className={ `${styles.fileActions} ${styles.delete}` } textAlign='right' collapsing>
					<Popup
						trigger={ <Icon name="remove" fitted onClick={e => this.toggleConfirmModal(true, e)} /> }
						content="Slet fil"
						inverted position="left center"
					/>
                </Table.Cell>

				<Modal
					open={ this.state.confirmModalActive }
					onClose={ e => this.toggleConfirmModal(false, e) }
				>
					<Modal.Content>
						<p>Vil du slette &#34;<b>{filename}</b>&#34;?</p>
					</Modal.Content>
					<Modal.Actions>
						<Button color='black' onClick={ e => this.toggleConfirmModal(false, e) }>Nej</Button>
						<Button color='red' onClick={this.deleteFile}>Ja</Button>
					</Modal.Actions>
				</Modal>
			</Table.Row>
		);
	}
}

class FileItemHeader extends React.Component {
	render() {
		return (
		<Table.Row>
			<Table.HeaderCell>
				Fil
			</Table.HeaderCell>
			<Table.HeaderCell>
				Størrelse
			</Table.HeaderCell>
			<Table.HeaderCell colSpan='3' textAlign='right'>
				Actions
			</Table.HeaderCell>
		</Table.Row>);
	}
}

export { FileItemHeader };