import { confirmPayment } from 'http/payment';

/**
 * Handles SCA for Stripe payments
 * @param {*} stripeClient - Stripe client provided by StripeElements
 * @param {*} purchaseResult - Data returned from requesting a purchase 
 */
const handleSCA = async (stripeClient, { requires3DConfirmation, clientSecret, token }) => {
    // skip if 3D confirmation isn't required
    if (!requires3DConfirmation) {
        return { success: true };
    }

    const result = await stripeClient.handleCardPayment(clientSecret);

    // 3D confirmation failed
    if (result.error) {
        return {
            success: false,
            message: result.error.message,
        };
    }

    // 3D confirmation success
    await confirmPayment(token);

    return { success: true };
};

export default handleSCA;