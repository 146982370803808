import React     from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import FileItem, { FileItemHeader }  from './FileItem';
import styles from './FileList.module.scss';

class FileList extends React.Component {
	static propTypes = {
        files: PropTypes.array,
	};

    render () {
        const { files, updateFileList, deleteFile } = this.props;
        if (files.length) {
            return (
                <div className={styles.fileListWrapper}>
                    <Table striped compact>
                        <Table.Header>
                            <FileItemHeader />
                        </Table.Header>
                        <Table.Body>
                            { files.map((file, i) => (
                                <FileItem
                                    key={i}
                                    fileindex={i}
                                    filename={file.name}
                                    fileid={file.id || file.fileID}
                                    filesize={file.size}
                                    updateFileList={updateFileList}
                                    deleteFile={deleteFile}
                                />
                            )) }
                        </Table.Body>
                    </Table>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default FileList;
