import React from 'react';
import { patchCurrentLogin } from 'http/accounts';
import ProfileForm from './ProfileForm';

const LoginChangePassword = () => {
    return (
        <>
            <ProfileForm
                echoError
                patchData={updatePasswordData => patchCurrentLogin({ ...updatePasswordData })}
                getData={() => ({})}
                form={[
                    {
                        path: 'oldPassword',
                        label: 'Gammelt kodeord',
                        required: true,
                        secret: true,
                    },
                    {
                        path: 'password',
                        label: 'Nyt kodeord',
                        required: true,
                        secret: true,
                    },
                    {
                        path: 'passwordRepeat',
                        label: 'Gentag nyt kodeord',
                        required: true,
                        secret: true,
                    },
                ]}
            />
        </>
    );
};

export default LoginChangePassword;