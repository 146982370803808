import React from 'react';
import { Grid, Icon, Popup, Segment } from 'semantic-ui-react';
import { getSignedDocument, getAttachment } from 'http/esignatur';
import { downloadBase64 } from 'util/downloadBlob';
import ColoredText from 'design/atoms/ColoredText';
import RefreshLink from './RefreshLink';

const AttachedFilesDownloader = ({ flowId, attachedDocuments }) => {
    const downloadDocument = async (documentId, sign) => {
        const fetchDocumentHandler = sign ? getSignedDocument : getAttachment;
        const { dataBase64, fileName } = await fetchDocumentHandler(flowId, documentId);
        downloadBase64('application/pdf', dataBase64, fileName);
    };

    return (
        <Popup
            basic
            position='bottom left'
            on='click'
            trigger={
                <ColoredText
                    link
                    underlined={false}
                    icon='attach'
                    iconPosition='left'
                    content='Vedhæftede dokumenter'
                />
            }
            content={attachedDocuments.map(({ filename, documentId, sign }) => {
                return (
                    <div
                        onClick={() => downloadDocument(documentId, sign)}
                        title={filename}
                        style={{
                            maxWidth: '400px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <ColoredText
                            link
                            underlined={false}
                            icon='file pdf'
                            iconPosition='left'
                            content={filename}
                        />
                    </div>
                );
            })}
        />
    );
};

// FlowHeader
// Viser status på et Esignatur flow
// Altså en samlet status for alle underskrivere i et flow
//
export default ({ flowId, flowStatus, reloadProductData, setFlow, esignaturSignFlowId, attachedDocuments }) => {
    if (flowStatus === "") {
        return null; // no status box until we initial the flow with Esignatur
    }

    if (flowStatus === undefined) {
        return null; // no status box until we initial the flow with Esignatur
    }

    let isSigned = false;
    let icon;
    let color;
    let flowDescription
    if (flowStatus === "Failed") {
        // attrs = { error: true };
        icon = 'warning circle';
        color = 'red';
        flowStatus = "Fejlet"
        flowDescription = "Underskriftsflowet er fejlet. Kontakt supporten."
    }
    if (flowStatus === "Completed") {
        // attrs = { positive: true };
        icon = 'check circle';
        color = 'green';
        flowStatus = "Underskrevet"
        flowDescription = "Underskriftsflowet er underskrevet og færdigt"
        isSigned = true;
    }
    if (flowStatus === "Active") {
        // attrs = { color: 'blue' };
        icon = 'paper plane';
        color = 'blue';
        flowStatus = "Igang"
        flowDescription = "Underskriftsflowet er igang med at blive underskrevet"
    }
    if ( flowStatus === "Expired" ) {
        icon = 'time';
        color = 'yellow';
        flowStatus = "Udløbet"
        flowDescription = "Underskriftsflowet er udløbet"
    }
    if ( flowStatus === "Stopped" || flowStatus === "Rejected" || flowStatus === "Admin cancelled" || flowStatus === "Cancelled") {
        // attrs = { color: 'yellow' }; // "warning" virker ikke
        icon = 'warning circle';
        color = 'yellow';
        flowStatus = "Annulleret"
        flowDescription = "Underskriftsflowet er blevet annulleret"
    }

    return <Segment>
        <Grid columns={2}>
            <Grid.Column width={12}>
                <b>
                    Status:
                    &nbsp;
                    <ColoredText color={color}>
                        { flowStatus }
                    </ColoredText>
                </b>
                &nbsp;
                { icon && <Icon name={icon} color={color} /> }
                <br />
                { flowDescription }
                {isSigned && attachedDocuments?.length > 0 && (
                    <div>
                        <AttachedFilesDownloader
                            flowId={flowId}
                            attachedDocuments={attachedDocuments}
                        />
                    </div>
                )}
            </Grid.Column>
            <Grid.Column width={4} verticalAlign='middle'>
                <RefreshLink reloadProductData={reloadProductData} setFlow={setFlow} esignaturSignFlowId={esignaturSignFlowId} />
            </Grid.Column>
        </Grid>
    </Segment>;
}

export const LegacyFlowStatus = ({done, ongoing}) => {
    if (done) return <div><Icon name="pencil" /> &nbsp; Underskriftsproces afsluttet</div>
    if (ongoing) return <div><Icon name="pencil" /> &nbsp; Underskriftsproces i gang</div>
    return null;
}