import React, { Component } from 'react';
import { Message, Container, Button, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

class Paywall extends Component {
    buyButtonClicked = () => {
        const { paymentURL, returnURL } = this.props;
        if (returnURL) {
            this.props.history.push(`${paymentURL}?redirect=${returnURL}`);
        } else {
            this.props.history.push(paymentURL);
        }
    };

    render () {
        return (
            <Segment>
                <Message
                    color='orange'
                    header='Manglende adgang'
                    content='Du skal købe fuld adgang til produktet for at kunne se denne sektion'
                    icon='lock'
                />
                <Container textAlign='center'>
                    <Button
                        onClick={this.buyButtonClicked}
                        content='Køb fuld adgang'
                        icon='unlock'
                        primary
                    />
                </Container>
            </Segment>
        );
    }
}

export default withRouter(Paywall);