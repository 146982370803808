import fetchAccessToken from './fetchAccessToken';
import fetchImpersonationToken from './fetchImpersonationToken';
import AuthHelper from 'util/AuthHelper';
import { hasAccessToken } from 'util/token';
import { getImpersonatedID } from 'util/impersonation';

export async function refreshTokens() {
    try {
        await fetchAccessToken();
    } catch (e) {
        if (!hasAccessToken()) {
            AuthHelper.deAuthenticate();
        }
        throw e;
    }

    // also refetch access token, if impersonation active
    const impID = getImpersonatedID();
    impID && await fetchImpersonationToken(impID);
}