import React from 'react';
import styles from './CornerRibbon.module.scss';

const CornerRibbon = ({ content, children, color }) => {
    const colorClass = styles[color] || styles.grey;

    return (
        <div className={`${styles.ribbon} ${colorClass}`}>
            <span>{children || content}</span>
        </div>
    );
};

export default CornerRibbon;