import React from 'react';
import { Segment } from 'semantic-ui-react';
import cn from 'classnames';
import styles from './FormBox.module.scss';

function FormBox ({ className, disabled, loading, children, textAlign, ...props }) {
    return <Segment
        {...props}
        className={cn(styles.formBox, className)}
        disabled={disabled}
        loading={loading}
        children={children}
        textAlign={textAlign}
    />;
}

export default FormBox;