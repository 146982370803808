import React                from 'react';
import cn                   from 'classnames';
import get                  from 'lodash.get';
import StandardAnswer       from '../StandardAnswer';
import FileAttachmentWidget from '../../../../molecules/FileAttachmentWidget';
import ValueDisplay         from '../../../../atoms/ValueDisplay';
import Form                 from '../../../../atoms/Form';

class SimpleValue extends React.Component {
    getValue = () => {
        const { id, values, staticValue } = this.props;

        if (staticValue) {
            return staticValue;
        }

        return get(values, `${id}.value.number`);
    };

    renderContent = () => {
        const { fileUpload, suffix, fieldOptions } = this.props;
        if (fileUpload) {
            return <FileAttachmentWidget {...this.props} />;
        }

        const value = this.getValue();

        let suffixFields = [];
        if (suffix) {
            const trimmedSuffix = suffix.trim();
            trimmedSuffix && suffixFields.push(trimmedSuffix);
        } else {
            suffixFields.push(<span>kr.</span>);
        }

        if (get(fieldOptions, 'isStandardAnswer', false)) {
            suffixFields.push(<StandardAnswer />);
        }

        const valueDisplay = <ValueDisplay
            value={value}
            suffix={suffixFields}
            float='right'
            number={!this.getStyleOptions().removeNumberFormatting}
        />;

        return valueDisplay;
    };

    getStyleOptions = () => get(this.props, 'styleOptions', {});

    getClassNames = () => {
        const { hideIfZero, ...styleOptions } = this.getStyleOptions();
        const classNames = [];
        for (let [ cname, isActive ] of Object.entries(styleOptions)) {
            if (isActive) {
                classNames.push(cname);
            }
        }
        return classNames
    };

    render () {
        const { label, tooltip, youtubeVideo, error } = this.props;
        const cnames = cn(...this.getClassNames());

        return (
			<Form.Row>
				<Form.LabelField
					label={label}
                    tooltip={tooltip}
                    youtubeVideo={youtubeVideo}
                    className={cnames}
				/>
				<Form.Field error={error} textAlign='right' className={cnames}>
                    {this.renderContent()}
				</Form.Field>
			</Form.Row>
		);
    }
}

export default SimpleValue;
