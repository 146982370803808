import { HTTPClient } from '../network/fetch';

const client = new HTTPClient({ prefix: 'hubspot' });

/**
 * Confirms tickets for the impersonated user
 * in the "Hent tal" pipeline on hubspot.
 * 
 * Will return 403 if not impersonating.
 * @param {string} productID 
 * @param {string|number} taxYear 
 * @returns 
 */
export function confirmERPTicket (productID, taxYear) {
    return client.postResource('tickets/henttal/confirm-account-plan', {
        productID,
        taxYear,
    });
}

export function shoppingCartEnterd(user, productID) {
    return client.postResource('deals/shopping-cart-enterd', {
        user,
        productID,
    });
}