import React, { Component } from 'react';
import { formatNumber } from 'util/format/Number';
import { formatPrice } from 'util/format/Price';

class AnimatedNumber extends Component {
    constructor (props) {
        super(props);

        this.state = {
            displayNumber: props.number || 0,
            targetNumber: props.number || 0,
            changePerTick: 0,
        };
    }

    componentWillUnmount = () => {
        this.stopAnimation();
    };

    startAnimation = () => {
        // skip if animation is already running
        if (this.animation) {
            return;
        }

        // start animation
        this.animation = setInterval(() => {
            this.doAnimationTick();
        }, 1000 / this.props.fps);
    };

    stopAnimation = () => {
        this.animation && window.clearInterval(this.animation);
        this.animation = null;
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.number !== this.props.number) {
            const currentNumber = this.state.displayNumber;
            const diff = Math.abs(this.props.number - currentNumber);
            const msPrFrame = 1000 / this.props.fps;
            const amountOfFrames = Math.max(1, Math.round(this.props.duration / msPrFrame));
            const changePerTick = diff / amountOfFrames;

            this.setState({
                targetNumber: this.props.number,
                changePerTick,
            }, this.startAnimation);
        }
    };

    doAnimationTick = () => {
        let { displayNumber, targetNumber, changePerTick } = this.state;

        if (displayNumber > targetNumber) {
            displayNumber = Math.max(targetNumber, displayNumber - changePerTick);
        } else if (displayNumber < targetNumber) {
            displayNumber = Math.min(targetNumber, displayNumber + changePerTick);
        }

        this.setState({ displayNumber });

        if (displayNumber === targetNumber) {
            this.stopAnimation();
        }
    };

    render = () => {
        const { price, key } = this.props;

        let formatted;
        if (price) {
            formatted = formatPrice(this.state.displayNumber);
        } else {
            formatted = formatNumber(this.state.displayNumber);
        }
        
        return <span key={key}>
            {formatted}
        </span>;
    };
}

AnimatedNumber.defaultProps = {
    fps: 60,
    duration: 750,
    price: false,
};

export default AnimatedNumber;