import i18n from '../../i18n';
import lc from '../../languageCodes';

const { daDK, enUS } = lc;

const ResetForm = {
    emailLabel: i18n({
        [daDK]: 'Email:',
        [enUS]: 'Email:',
    }),
    emailPlaceholder: i18n({
        [daDK]: 'ditnavn@email.com',
        [enUS]: 'yourname@email.com',
    }),
    resetButton: i18n({
        [daDK]: 'Send nyt kodeord',
        [enUS]: 'Send new password',
    }),
};

export default {
    title: i18n({
        [daDK]: 'Glemt kodeord?',
        [enUS]: 'Forgot password?',
    }),
    subHeader: i18n({
        [daDK]: 'Nulstil dit password her',
        [enUS]: 'Reset your password here',
    }),
    successMsg: i18n({
        [daDK]: 'Vi har sendt dig en e-mail, der forklarer, hvad du skal gøre for at ændre dit kodeord.',
        [enUS]: 'We have sent you an e-mail with instructions on how to change your password',
    }),
    successMsgHeader: i18n({
        [daDK]: 'Sådan!',
        [enUS]: 'There you go!',
    }),
    linkToLogin: i18n({
        [daDK]: 'Gå til loginsiden',
        [enUS]: 'Go to login page',
    }),
    error: i18n({
        [daDK]: 'Fejl',
        [enUS]: 'Error',
    }),
    ResetForm,
};