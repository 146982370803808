import { HTTPClient } from 'network/fetch';

const client = new HTTPClient({ prefix: 'dinero/api' });

export function getOrganisations() {
    return client.postResource('organisations');
}

export function registerKey(organisationID) {
    return client.postResource('register', {
        orgid: organisationID,
    });
}

export function hasTemporaryRegistration() {
    return client.postResource('register/has-temp-reg');
}

export function getOAUTHConsentLink(returnPath, isRenwal = false) {
    return client.getResource(`consent-link?returnpath=${returnPath}&renewal=${isRenwal}`);
}

const prefillClient = new HTTPClient({ prefix: 'dinero/prefill' });

export function getOrganisationsPrefill(token) {
    return prefillClient.postResource(`/organisations/${token}`);
}