import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useDeferredRedirect = (shouldRedirect, redirectLocation, timeout = 3000) => {
    const history = useHistory();

    useEffect(() => {
        if (!shouldRedirect) {
            return;
        }

        const redirectTimeout = window.setTimeout(() => {
            history.push(redirectLocation);
        }, timeout);

        return () => window.clearTimeout(redirectTimeout);
    }, [shouldRedirect, redirectLocation, timeout, history]);
};

export default useDeferredRedirect;