import React, { useCallback, useState } from 'react';

const localStorageKeyPrefix = '_dr_persisted_state';

const makeLocalStorageKey = storageKey => {
    return [localStorageKeyPrefix, storageKey].join('.');
};

const parse = toParse => {
    if (toParse === 'undefined') {
        return undefined;
    }

    return JSON.parse(toParse);
};

const loadConfigValue = (storageKey, defaultValue) => {
    const key = makeLocalStorageKey(storageKey);
    if (localStorage[key] === undefined) {
        return defaultValue;
    }

    return parse(localStorage[key]);
};

const saveConfigValue = (storageKey, value) => {
    const key = makeLocalStorageKey(storageKey);
    if (value === undefined) {
        delete localStorage[key];
        return;
    }

    localStorage[makeLocalStorageKey(storageKey)] = JSON.stringify(value);
};

const usePersistedState = (storageKey, defaultValue) => {
    const [value, _setValue] = useState(() => loadConfigValue(storageKey, defaultValue));

    const setValue = useCallback(newValue => {
        saveConfigValue(storageKey, newValue);
        _setValue(newValue);
    }, [storageKey]);

    return [value, setValue];
};

export const readPersistedState = (storageKey, defaultValue) => {
    return loadConfigValue(storageKey, defaultValue);
};

export const clearPersistedState = (storageKey) => {
    localStorage.removeItem(makeLocalStorageKey(storageKey));
};

export const withPersistedState = (Component, storageKey, defaultValue) => {
    return props => {
        const [value, setValue] = usePersistedState(storageKey, defaultValue);

        const storageProps = {
            [storageKey]: {
                value,
                setValue,
            },
        };

        return (
            <Component
                {...props}
                {...storageProps}
            />
        );
    };
};

export default usePersistedState;